
import { buildGetUrl, parse } from 'utils/api'
import { dataToFormData, objectToInsertData, formKeyDataToObject } from 'utils/mapperHelper'
import { safeFetchJson } from 'utils/safeFetch'

import {
  GET_TREATMENTS,
  GET_TREATMENTS_COUNT,
  DELETE_TREATMENT,
  CREATE_TREATMENT,
  UPDATE_TREATMENT,
  SET_GLOBAL_FORM,
  SET_IS_CREATE,
  GET_TREATMENT,
  RESET_FORM,
  CLEAR_TREATMENT,
} from './types'

const dataSetName = 'treatment'
const fields = getFields()
const initialState = {
  dataSetName,
  fields,
  treatments: [],
  activeForm: getDefaultForm(),
  activeTreatment: getDefaultTreatment(),
}

export default function treatmentsReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
  case GET_TREATMENTS_COUNT: {
    return {
      ...state,
      treatmentsCount: payload,
    }
  }
  case GET_TREATMENTS: {
    return {
      ...state,
      treatments: payload,
    }
  }
  case GET_TREATMENT: {
    return buildTreatmentState(state, payload)
  }
  case SET_IS_CREATE: {
    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        isCreate: payload,
      },
    }
  }
  case CREATE_TREATMENT: {
    return buildTreatmentState(state, payload)
  }
  case UPDATE_TREATMENT: {
    return buildTreatmentState(state, payload)
  }
  case SET_GLOBAL_FORM: {
    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        hasChanges: hasChanges({ ...state.activeForm, global: payload }),
        isValid: isFormValid(payload),
        global: payload,
      },
    }
  }
  case CLEAR_TREATMENT: {
    return {
      ...state,
      activeTreatment: getDefaultTreatment(),
      activeForm: getDefaultForm(),
    }
  }
  case RESET_FORM: {
    const globalForm = dataToFormData(state.activeTreatment, getFields(true))

    const newActiveForm = {
      ...state.activeForm,
      isValid: isFormValid(globalForm),
      resetCount: state.activeForm.resetCount +1,
      global: globalForm,
    }

    return {
      ...state,
      activeForm: {
        ...newActiveForm,
        hasChanges: hasChanges(newActiveForm),
      },
    }
  }
  default: {
    return state
  }
  }
}

export function parseTreatment(treatment) {
  const options = {
    defaultData: getDefaultTreatment(),
    fields: initialState.fields,
    dataSetName,
  }
  return parse(treatment, options)
}

export function getFields(editOnly) {
  const editFields = {
    'id': { dataSetName, dbField: 'id', isEdit: false },
    'title': { dataSetName, dbField: 'title', isEdit: true },
    'code': { dataSetName, dbField: 'code', isEdit: true },
  }
  if (editOnly) {
    return editFields
  }

  const fields = {
    'createdDate': { dataSetName, dbField: 'created_date', type: 'date' },
    'createdBy': { dataSetName, dbField: 'created_by' },
    'createdById': { dataSetName, dbField: 'created_by_id' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'date' },
    'modifiedBy': { dataSetName, dbField: 'modified_by' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id' },
    'privateTag': { dataSetName, dbField: 'private_tag' },
  }

  return { ...fields, ...editFields }
}

function buildTreatmentState(state, payload) {
  if (!payload) {
    return state
  }

  const globalForm = dataToFormData(payload, getFields(true))
  const newActiveForm = {
    ...state.activeForm,
    isCreate: false,
    isValid: isFormValid(globalForm),
    global: globalForm,
  }

  return {
    ...state,
    activeTreatment: payload,
    activeForm: {
      ...newActiveForm,
      hasChanges: hasChanges(newActiveForm),
    },
  }
}

export function resetForm(dispatch) {
  dispatch({ type: RESET_FORM })
}

export function fetchTreatmentsCount(data) {
  return async function fetchTreatmentsCountThunk(dispatch) {
    try {
      const result = await (await fetch(buildGetUrl(
        '/new_api/inventories/treatments/count',
        { ...data },
      ))).json()
      if (result.isSuccess) {
        const count = +result.result[0].count || 0
        dispatch({ type: GET_TREATMENTS_COUNT, payload: count })
        return count
      }

      return 0
    } catch (err) {
      console.error(err)
    }
    return 0
  }
}

export function fetchTreatments(data = {}) {
  return async function fetchTreatmentsThunk(dispatch) {
    let treatments = []
    try {
      const result = await (await fetch(buildGetUrl(
        '/new_api/inventories/treatments',
        { ...data },
      ))).json()
      if (result.isSuccess) {
        treatments = result.result.map((treatment) => parseTreatment(treatment))
        dispatch({ type: GET_TREATMENTS, payload: treatments })
      }
    } catch (err) {
      console.error(err)
    }

    return treatments
  }
}

export async function fetchTreatmentsByIds(ids = []) {
  if (!ids.length) return []

  const { isSuccess, result } = await safeFetchJson(`/new_api/inventories/treatments/${ids}`)

  return isSuccess ? result.map((treatment) => parseTreatment(treatment)) : []
}

export function deleteTreatment(treatmentId) {
  return async function deleteTreatmentThunk(dispatch) {
    try {
      const result = await (await fetch(`/new_api/inventories/treatments/${treatmentId}`, { method: 'DELETE' })).json()
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: DELETE_TREATMENT, payload: result.isSuccess, error })
      return result
    } catch (error) {
      dispatch({ type: DELETE_TREATMENT, error })
    }
  }
}

function getDefaultTreatment() {
  return parse({}, { fields })
}

export function saveTreatment(setPage) {
  return async function saveTreatmentThunk(dispatch, getState) {
    const treatmentsStore = getState().treatments
    const globalFormData = formKeyDataToObject(treatmentsStore.activeForm.global)
    if (treatmentsStore.activeForm.isCreate) {
      setPage((page) => ({ ...page, isCreating: true }))
      return _createTreatment(dispatch, globalFormData)
    } else {
      const treatment = {
        id: treatmentsStore.activeTreatment.id,
        ...globalFormData,
      }
      return _updateTreatment(dispatch, treatment)
    }
  }
}

async function _createTreatment(dispatch, treatment) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: objectToInsertData(treatment) }),
  }
  try {
    const result = await (await fetch(`/new_api/inventories/treatments`, requestOptions)).json()
    const payload = result.isSuccess ? parseTreatment(result.result) : null
    const error = !result.isSuccess ? result.result : null
    dispatch({ type: CREATE_TREATMENT, payload, error })
    return { isCreate: true, treatment: payload }
  } catch (error) {
    dispatch({ type: CREATE_TREATMENT, error })
  }
}

async function _updateTreatment(dispatch, treatment) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: objectToInsertData(treatment) }),
  }
  try {
    const result = await (await fetch(`/new_api/inventories/treatments/${treatment.id}`, requestOptions)).json()
    const [updated] = result.isSuccess ? result.result : []
    const payload = updated ? parseTreatment(updated) : null
    const error = !result.isSuccess ? result.result : null
    dispatch({ type: UPDATE_TREATMENT, payload, error })
    return { treatment: payload }
  } catch (error) {
    dispatch({ type: UPDATE_TREATMENT, error })
  }
}

export function fetchTreatment(treatmentId) {
  return async function fetchTreatmentThunk(dispatch) {
    if (treatmentId === 'new') {
      dispatch({ type: SET_IS_CREATE, payload: true })
      return null
    } else {
      let parsedTreatment = null
      try {
        const result = await (await fetch(`/new_api/inventories/treatments/${treatmentId}`)).json()
        if (result.isSuccess) {
          const [treatment] = result.result
          parsedTreatment = parseTreatment(treatment)
          dispatch({ type: GET_TREATMENT, payload: parsedTreatment })
        }
      } catch (err) {
        console.error(err)
      }
      return parsedTreatment
    }
  }
}

export function clearTreatment(dispatch) {
  dispatch({ type: CLEAR_TREATMENT })
}

// form exports
export function updateGlobalFormFields(fieldValues) {
  return async function updateGlobalFormFieldsThunk(dispatch, getState) {
    const treatmentsStore = getState().treatments
    const payload = { ...treatmentsStore.activeForm.global }

    fieldValues.forEach((fieldValue) => {
      payload[fieldValue.field] = {
        ...treatmentsStore.activeForm.global[fieldValue.field],
        value: fieldValue.value,
        data: fieldValue.data,
        isChanged: true,
      }
    })

    dispatch({ type: SET_GLOBAL_FORM, payload })
  }
}

function getDefaultForm() {
  return {
    isCreate: false,
    hasChanges: false,
    isValid: false,
    resetCount: 0,
    global: dataToFormData(getDefaultTreatment(), getFields(true)),
  }
}

function isFormValid(globalForm) {
  return !!globalForm.title.value
}

function hasChanges(form) {
  return Object.keys(form.global).some((key) => form.global[key].isChanged)
}

export function getTitle(treatment) {
  return treatment.title || ''
}
