import {
  CLEAR_SAVE_CONSIGNMENT_LINE_ITEMS_FORM,
  SAVE_CONSIGNMENT_LINE_ITEMS_FORM,
} from './types'

const initState = {
  saveError: null,
}

export default function consignmentItemsErrorReducer(state = initState, action) {
  const { error } = action

  switch (action.type) {
  case SAVE_CONSIGNMENT_LINE_ITEMS_FORM: {
    return {
      ...state,
      saveError: error,
    }
  }
  case CLEAR_SAVE_CONSIGNMENT_LINE_ITEMS_FORM: {
    return {
      ...state,
      saveError: null,
    }
  }
  default:
    return state
  }
}

export function clearSaveConsignmentLineItemsFormError(dispatch) {
  dispatch({ type: CLEAR_SAVE_CONSIGNMENT_LINE_ITEMS_FORM })
}
