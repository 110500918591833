export const GET_MATERIALS = 'GET_MATERIALS'
export const GET_MATERIALS_COUNT = 'GET_MATERIALS_COUNT'
export const GET_MATERIAL = 'GET_MATERIAL'
export const CREATE_MATERIAL = 'CREATE_MATERIAL'
export const UPDATE_MATERIAL = 'UPDATE_MATERIAL'
export const DELETE_MATERIAL = 'DELETE_MATERIAL'

export const CLEAR_MATERIAL = 'CLEAR_MATERIAL'
export const CLEAR_CREATE_SUCCESS = 'CLEAR_MATERIAL_CREATE_SUCCESS'
export const CLEAR_UPDATE_SUCCESS = 'CLEAR_MATERIAL_UPDATE_SUCCESS'
export const CLEAR_DELETE_SUCCESS = 'CLEAR_MATERIAL_DELETE_SUCCESS'
export const CLEAR_CREATE_ERROR = 'CLEAR_MATERIAL_CREATE_ERROR'
export const CLEAR_UPDATE_ERROR = 'CLEAR_MATERIAL_UPDATE_ERROR'
export const CLEAR_DELETE_ERROR = 'CLEAR_MATERIAL_DELETE_ERROR'

export const SET_IS_CREATE = 'SET_MATERIAL_IS_CREATE'
export const SET_GLOBAL_FORM = 'SET_MATERIAL_GLOBAL_FORM'
export const RESET_FORM = 'RESET_MATERIAL_FORM'
