
import { buildGetUrl, parse } from 'utils/api'
import { dataToFormData, formKeyDataToObject } from 'utils/mapperHelper'
import { safeFetchJson } from 'utils/safeFetch'

import {
  GET_CATEGORIES,
  GET_CATEGORIES_COUNT,
  DELETE_CATEGORY,
  CREATE_CATEGORY,
  UPDATE_CATEGORY,
  SET_GLOBAL_FORM,
  SET_IS_CREATE,
  GET_CATEGORY,
  RESET_FORM,
  CLEAR_CATEGORY,
} from './types'

const dataSetName = 'category'
const fields = getFields()
const initialState = {
  dataSetName,
  fields,
  categoriesCount: 0,
  categories: [],
  activeForm: getDefaultForm(),
  activeCategory: getDefaultCategory(),
}

export default function categoriesReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
  case GET_CATEGORIES_COUNT: {
    return {
      ...state,
      categoriesCount: payload,
    }
  }
  case GET_CATEGORIES: {
    return {
      ...state,
      categories: payload,
    }
  }
  case GET_CATEGORY: {
    return buildCategoryState(state, payload)
  }
  case SET_IS_CREATE: {
    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        isCreate: payload,
      },
    }
  }
  case CREATE_CATEGORY: {
    return buildCategoryState(state, payload)
  }
  case UPDATE_CATEGORY: {
    return buildCategoryState(state, payload)
  }
  case SET_GLOBAL_FORM: {
    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        hasChanges: hasChanges(payload),
        isValid: isFormValid(payload),
        global: payload,
      },
    }
  }
  case CLEAR_CATEGORY: {
    return {
      ...state,
      activeCategory: getDefaultCategory(),
      activeForm: getDefaultForm(),
    }
  }
  case RESET_FORM: {
    const globalForm = dataToFormData(state.activeCategory, getFields(true))

    const newActiveForm = {
      ...state.activeForm,
      isValid: isFormValid(globalForm),
      resetCount: state.activeForm.resetCount +1,
      global: globalForm,
    }

    return {
      ...state,
      activeForm: {
        ...newActiveForm,
        hasChanges: hasChanges(newActiveForm),
      },
    }
  }
  default: {
    return state
  }
  }
}

export function parseCategory(category) {
  const options = {
    defaultData: getDefaultCategory(),
    fields: initialState.fields,
    dataSetName,
  }
  return parse(category, options)
}

export function getFields(editOnly) {
  const editFields = {
    'id': { dataSetName, dbField: 'id', isEdit: false },
    'title': { dataSetName, dbField: 'title', isEdit: true },
    'code': { dataSetName, dbField: 'code', isEdit: true },
  }
  if (editOnly) {
    return editFields
  }

  const fields = {
    'createdDate': { dataSetName, dbField: 'created_date', type: 'date' },
    'createdBy': { dataSetName, dbField: 'created_by' },
    'createdById': { dataSetName, dbField: 'created_by_id' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'date' },
    'modifiedBy': { dataSetName, dbField: 'modified_by' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id' },
  }

  return { ...fields, ...editFields }
}

function buildCategoryState(state, payload) {
  if (!payload) {
    return state
  }

  const globalForm = dataToFormData(payload, getFields(true))
  const newActiveForm = {
    ...state.activeForm,
    isCreate: false,
    isValid: isFormValid(globalForm),
    global: globalForm,
  }

  return {
    ...state,
    activeCategory: payload,
    activeForm: {
      ...newActiveForm,
      hasChanges: hasChanges(newActiveForm),
    },
  }
}

export function resetForm(dispatch) {
  dispatch({ type: RESET_FORM })
}

export function fetchCategoriesCount(data) {
  return async function fetchCategoriesCountThunk(dispatch) {
    try {
      const result = await (await fetch(buildGetUrl(
        '/new_api/inventories/categories/count',
        { ...data },
      ))).json()
      if (result.isSuccess) {
        const count = +result.result[0].count || 0
        dispatch({ type: GET_CATEGORIES_COUNT, payload: count })
        return count
      }
    } catch (err) {
      console.error(err)
    }
    return 0
  }
}

export function fetchCategories(data) {
  return async function fetchCategoriesThunk(dispatch) {
    let categories = []
    try {
      const result = await (await fetch(buildGetUrl(
        '/new_api/inventories/categories',
        { ...data },
      ))).json()
      if (result.isSuccess) {
        categories = result.result.map((category) => parseCategory(category))
        dispatch({ type: GET_CATEGORIES, payload: categories })
      }
    } catch (err) {
      console.error(err)
    }

    return categories
  }
}

export async function fetchCategoriesByIds(ids = []) {
  if (!ids.length) return []

  const { isSuccess, result } = await safeFetchJson(`/new_api/inventories/categories/${ids}`)

  return isSuccess ? result.map((category) => parseCategory(category)) : []
}

export function deleteCategory(categoryId) {
  return async function deleteCategoryThunk(dispatch) {
    try {
      const result = await (await fetch(`/new_api/inventories/categories/${categoryId}`, { method: 'DELETE' })).json()
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: DELETE_CATEGORY, payload: result.isSuccess, error })
      return result
    } catch (error) {
      dispatch({ type: DELETE_CATEGORY, error })
    }
  }
}

function getDefaultCategory() {
  return parse({}, { fields })
}

export function saveCategory(setPage) {
  return async function saveCategoryThunk(dispatch, getState) {
    const categoriesStore = getState().categories
    const globalFormData = formKeyDataToObject(categoriesStore.activeForm.global)
    if (categoriesStore.activeForm.isCreate) {
      setPage((page) => ({ ...page, isCreating: true }))
      return _createCategory(dispatch, globalFormData)
    } else {
      const category = {
        id: categoriesStore.activeCategory.id,
        ...globalFormData,
      }
      return _updateCategory(dispatch, category)
    }
  }
}

async function _createCategory(dispatch, category) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: category }),
  }
  try {
    const result = await (await fetch(`/new_api/inventories/categories`, requestOptions)).json()
    const payload = result.isSuccess ? parseCategory(result.result) : null
    const error = !result.isSuccess ? result.result : null
    dispatch({ type: CREATE_CATEGORY, payload, error })
    return { isCreate: true, category: payload }
  } catch (error) {
    dispatch({ type: CREATE_CATEGORY, error })
  }
}

async function _updateCategory(dispatch, category) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: { ...category, id: undefined } }),
  }
  try {
    const result = await (await fetch(`/new_api/inventories/categories/${category.id}`, requestOptions)).json()
    const [updated] = result.isSuccess ? result.result : []
    const payload = updated ? parseCategory(updated) : null
    const error = !result.isSuccess ? result.result : null
    dispatch({ type: UPDATE_CATEGORY, payload, error })
    return { category: payload }
  } catch (error) {
    dispatch({ type: UPDATE_CATEGORY, error })
  }
}

export function fetchCategory(categoryId) {
  return async function fetchCategoryThunk(dispatch) {
    if (categoryId === 'new') {
      dispatch({ type: SET_IS_CREATE, payload: true })
      return null
    } else {
      let parsedCategory = null
      try {
        const result = await (await fetch(`/new_api/inventories/categories/${categoryId}`)).json()
        if (result.isSuccess) {
          const [category] = result.result
          parsedCategory = parseCategory(category)
          dispatch({ type: GET_CATEGORY, payload: parsedCategory })
        }
      } catch (err) {
        console.error(err)
      }
      return parsedCategory
    }
  }
}

export function clearCategory(dispatch) {
  dispatch({ type: CLEAR_CATEGORY })
}

// form exports
export function updateGlobalFormFields(fieldValues) {
  return async function updateGlobalFormFieldsThunk(dispatch, getState) {
    const categoriesStore = getState().categories
    const payload = { ...categoriesStore.activeForm.global }

    fieldValues.forEach((fieldValue) => {
      payload[fieldValue.field] = {
        ...categoriesStore.activeForm.global[fieldValue.field],
        value: fieldValue.value,
        data: fieldValue.data,
        isChanged: true,
      }
    })

    dispatch({ type: SET_GLOBAL_FORM, payload })
  }
}

function getDefaultForm() {
  return {
    isCreate: false,
    hasChanges: false,
    isValid: false,
    resetCount: 0,
    global: dataToFormData(getDefaultCategory(), getFields(true)),
  }
}

function isFormValid(globalForm) {
  return !!globalForm.title.value
}

function hasChanges(global) {
  return Object.keys(global).some((key) => global[key].isChanged)
}

export function getTitle(category) {
  return category.title || ''
}
