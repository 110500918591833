
import { buildGetUrl, parse } from 'utils/api'
import { dataToFormData } from 'utils/mapperHelper'
import { safeFetch, safeFetchJson } from 'utils/safeFetch'

import { getFields as getAddressFields } from 'reducers/addresses/shared'

import {
  GET_PLANTS,
  GET_PLANTS_COUNT,
  DELETE_PLANT,
  CREATE_PLANT,
  UPDATE_PLANT,
  SET_GLOBAL_FORM,
  SET_IS_CREATE,
  GET_PLANT,
  RESET_FORM,
  SET_ADDRESS_FORM,
  CLEAR_PLANT,
} from './types'

const dataSetName = 'plant'
const fields = getFields()
const initialState = {
  dataSetName,
  fields,
  plantsCount: 0,
  plants: [],
  activeForm: getDefaultForm(),
  activePlant: getDefaultPlant(),
}

export default function plantsReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
  case GET_PLANTS_COUNT: {
    return {
      ...state,
      plantsCount: payload,
    }
  }
  case GET_PLANTS: {
    return {
      ...state,
      plants: payload,
    }
  }
  case GET_PLANT: {
    return buildPlantState(state, payload)
  }
  case SET_IS_CREATE: {
    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        isCreate: payload,
      },
    }
  }
  case CREATE_PLANT: {
    return buildPlantState(state, payload)
  }
  case UPDATE_PLANT: {
    return buildPlantState(state, payload)
  }
  case SET_GLOBAL_FORM: {
    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        hasChanges: hasChanges(payload, state.activeForm.address),
        isValid: isFormValid(payload),
        global: payload,
      },
    }
  }
  case CLEAR_PLANT: {
    return {
      ...state,
      activePlant: getDefaultPlant(),
      activeForm: getDefaultForm(),
    }
  }
  case SET_ADDRESS_FORM: {
    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        hasChanges: hasChanges(state.activeForm.global, payload),
        address: payload,
      },
    }
  }
  case RESET_FORM: {
    const globalForm = dataToFormData(state.activePlant, getFields(true))
    const addressForm = dataToFormData(state.activePlant.address, getAddressFields(true))
    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        hasChanges: hasChanges(globalForm, addressForm),
        isValid: isFormValid(globalForm),
        resetCount: state.activeForm.resetCount + 1,
        global: globalForm,
        address: addressForm,
      },
    }
  }
  default: {
    return state
  }
  }
}

// plant parsing functions
export function parsePlant(plant) {
  const options = {
    defaultData: getDefaultPlant(),
    fields: initialState.fields,
    dataSetName,
  }
  return parse(plant, options)
}

// plant parsing functions
function buildPlantState(state, payload) {
  if (!payload) {
    return state
  }

  const globalForm = dataToFormData(payload, getFields(true))
  const addressForm = dataToFormData(payload.address, getAddressFields(true))
  return {
    ...state,
    activePlant: payload,
    activeForm: {
      ...state.activeForm,
      hasChanges: hasChanges(globalForm, addressForm),
      isCreate: false,
      isValid: isFormValid(globalForm),
      global: globalForm,
      address: addressForm,
    },
  }
}

// plant exports
export function getFields(editOnly) {
  const editFields = {
    'id': { dataSetName, dbField: 'id', isEdit: false, type: 'id' },
    'name': { dataSetName, dbField: 'name', isEdit: true },
    'code': { dataSetName, dbField: 'code', isEdit: true },
    'companyId': { dataSetName, dbField: 'company_id', isEdit: true, type: 'id' },
    'description': { dataSetName, dbField: 'description', isEdit: true },
    'warehouseIds': { dataSetName, dbField: 'warehouse_ids', isEdit: true },
    'type': { dataSetName, dbField: 'type', isEdit: true },
  }
  if (editOnly) {
    return editFields
  }

  const fields = {
    'createdDate': { dataSetName, dbField: 'created_date', type: 'date' },
    'createdBy': { dataSetName, dbField: 'created_by' },
    'createdById': { dataSetName, dbField: 'created_by_id', type: 'id' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'date' },
    'modifiedBy': { dataSetName, dbField: 'modified_by' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id', type: 'id' },
    'companyName': { dataSetName: 'genCompany', dbField: 'company_name' },
    'companyAddress.id': {
      dataSetName: 'address',
      dataSetAlias: 'company_address',
      dbField: 'id',
      relationEntity: 'addresses',
    },
    'companyAddress.attention': { dataSetName: 'address', dataSetAlias: 'company_address', dbField: 'attention' },
    'companyAddress.street': { dataSetName: 'address', dataSetAlias: 'company_address', dbField: 'address' },
    'companyAddress.street2': { dataSetName: 'address', dataSetAlias: 'company_address', dbField: 'street2' },
    'companyAddress.city': { dataSetName: 'address', dataSetAlias: 'company_address', dbField: 'city' },
    'companyAddress.state': { dataSetName: 'address', dataSetAlias: 'company_address', dbField: 'state' },
    'companyAddress.zip': { dataSetName: 'address', dataSetAlias: 'company_address', dbField: 'zip' },
    'companyAddress.country': { dataSetName: 'address', dataSetAlias: 'company_address', dbField: 'country' },
    'companyAddress.phone': { dataSetName: 'address', dataSetAlias: 'company_address', dbField: 'phone' },
    'address.id': { dataSetName: 'address', dbField: 'id' },
    'address.attention': { dataSetName: 'address', dbField: 'attention' },
    'address.street': { dataSetName: 'address', dbField: 'address' },
    'address.street2': { dataSetName: 'address', dbField: 'street2' },
    'address.city': { dataSetName: 'address', dbField: 'city' },
    'address.state': { dataSetName: 'address', dbField: 'state' },
    'address.zip': { dataSetName: 'address', dbField: 'zip' },
    'address.country': { dataSetName: 'address', dbField: 'country' },
    'address.phone': { dataSetName: 'address', dbField: 'phone' },
  }

  return { ...fields, ...editFields }
}

export function resetForm(dispatch) {
  dispatch({ type: RESET_FORM })
}

export function fetchPlantsCount(data) {
  return async function fetchPlantsCountThunk(dispatch) {
    try {
      const result = await (await safeFetch(buildGetUrl('/new_api/plants/count', data))).json()
      if (result.isSuccess) {
        const count = +result.result[0].count || 0
        dispatch({ type: GET_PLANTS_COUNT, payload: count })
        return count
      }

      return 0
    } catch (err) {
      console.error(err)
      return 0
    }
  }
}

export function fetchPlants(data = {}) {
  return async function fetchPlantsThunk(dispatch) {
    let plants = []

    try {
      const result = await (await safeFetch(buildGetUrl('/new_api/plants', data))).json()
      if (result.isSuccess) {
        plants = result.result.map((plant) => parsePlant(plant))
        dispatch({ type: GET_PLANTS, payload: plants })
      }
    } catch (err) {
      console.error(err)
    }

    return plants
  }
}

export async function _fetchPlants(data = {}) {
  let plants = []

  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/plants', data))).json()
    if (result.isSuccess) {
      plants = result.result.map((plant) => parsePlant(plant))
    }
  } catch (err) {
    console.error(err)
  }

  return plants
}

export function createPlant(plant) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ plant }),
  }
  return async function createPlantThunk(dispatch) {
    try {
      const result = await (await safeFetch(`/new_api/plants`, requestOptions)).json()
      const [created] = result.isSuccess ? result.result : []
      const payload = created ? parsePlant(created) : null
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: CREATE_PLANT, payload, error })

      return payload
    } catch (error) {
      dispatch({ type: CREATE_PLANT, error })
    }
  }
}

export function updatePlant(plant) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ plant }),
  }
  return async function updatePlantThunk(dispatch) {
    try {
      const result = await (await safeFetch(`/new_api/plants/${plant.id}`, requestOptions)).json()
      const [updated] = result.isSuccess ? result.result : []
      const payload = updated ? parsePlant(updated) : null
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: UPDATE_PLANT, payload, error })
      return { isSuccess: result.isSuccess, result }
    } catch (error) {
      dispatch({ type: UPDATE_PLANT, error })
      return { isSuccess: false, result: error }
    }
  }
}

export function deletePlant(plantId) {
  return async function deletePlantThunk(dispatch) {
    try {
      const result = await (await safeFetch(`/new_api/plants/${plantId}`, { method: 'DELETE' })).json()
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: DELETE_PLANT, payload: result.isSuccess, error })
      return result
    } catch (error) {
      dispatch({ type: DELETE_PLANT, error })
    }
  }
}

export function parseDefaultPlantId(plants, resourcePlantId) {
  let defaultPlantId = resourcePlantId

  if (!defaultPlantId && plants.length == 1) {
    defaultPlantId = plants[0].id
  }
  return defaultPlantId
}

export function fetchPlant(plantId) {
  return async function fetchPlantThunk(dispatch) {
    if (plantId === 'new') {
      dispatch({ type: SET_IS_CREATE, payload: true })
      return null
    } else {
      let parsedPlant = null
      try {
        const result = await (await safeFetch(`/new_api/plants/${plantId}`)).json()
        if (result.isSuccess) {
          const [plant] = result.result
          parsedPlant = parsePlant(plant)
          dispatch({ type: GET_PLANT, payload: parsedPlant })
        }
      } catch (err) {
        console.error(err)
      }
      return parsedPlant
    }
  }
}

export async function fetchPlantByIds(ids, data) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson(
    buildGetUrl(`/new_api/plants/${ids}`, data),
  )

  return isSuccess ? result.map((plant) => parsePlant(plant)) : []
}

export function clearPlant(dispatch) {
  dispatch({ type: CLEAR_PLANT })
}

// form exports
export function updateGlobalFormFields(fieldValues) {
  return async function updateGlobalFormFieldsThunk(dispatch, getState) {
    const plantsStore = getState().plants
    const payload = { ...plantsStore.activeForm.global }

    fieldValues.forEach((fieldValue) => {
      payload[fieldValue.field] = {
        ...plantsStore.activeForm.global[fieldValue.field],
        value: fieldValue.value,
        isChanged: true,
      }
    })

    dispatch({ type: SET_GLOBAL_FORM, payload })
  }
}

export function updateAddressForm({ field, formValueData }) {
  return async function updateAddressFormThunk(dispatch, getState) {
    const plantsStore = getState().plants

    const newAddressForm = { ...plantsStore.activeForm.address }

    newAddressForm[field] = formValueData

    dispatch({ type: SET_ADDRESS_FORM, payload: newAddressForm })
  }
}

// form parsing functions

function getDefaultPlant() {
  return parse({}, { fields })
}

function hasChanges(global, address) {
  return Object.keys(global).some((key) => global[key].isChanged) ||
    Object.keys(address).some((key) => address[key].isChanged)
}

function getDefaultForm() {
  return {
    isCreate: false,
    hasChanges: false,
    isValid: false,
    resetCount: 0,
    global: dataToFormData(getDefaultPlant(), getFields(true)),
    address: dataToFormData({}, getAddressFields(true)),
  }
}

function isFormValid(form) {
  return !!form.name.value && !!form.companyId.value && !!form.type.value
}

export function getPlantTitle(plant) {
  return plant.name
}

export function getPlantTypes() {
  return ['plant', 'other']
}
