import { MutableRefObject } from 'react'

import { AppDispatch } from 'store'

import { SET_REF, SET_IS_MOBILE } from './types'

const initialState = {
  refs: {} as Record<string, MutableRefObject<any>>,
  isMobile: false,
}

export default function refsReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
  case SET_REF: {
    const newRefs = { ...state.refs }
    for (const property in payload) {
      if (action.allowReset || !newRefs[property]) newRefs[property] = payload[property]
    }
    return { ...state, refs: newRefs }
  }
  case SET_IS_MOBILE: {
    return { ...state, isMobile: payload }
  }
  default: {
    return state
  }
  }
}

export function setRef(payload: Record<string, MutableRefObject<any>>, allowReset?: boolean) {
  return function setRefThunk(dispatch: AppDispatch) {
    dispatch({ type: SET_REF, payload, allowReset })
  }
}

export function setIsMobile(isMobile: boolean) {
  return function setIsMobileThunk(dispatch: AppDispatch) {
    dispatch({ type: SET_IS_MOBILE, payload: isMobile })
  }
}
