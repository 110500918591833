
import { buildGetUrl, parse } from 'utils/api'
import { safeFetch, parseError } from 'utils/safeFetch'

import {
  GET_SENDERS,
  CREATE_SENDER,
  UPDATE_SENDER,
  DELETE_SENDER,
  REFRESH_DOMAIN,
  SEND_EMAIL,
  VERIFY_DOMAIN,
  CLEAR_SENDERS,
  CHANGE_REGION,
} from './types'

const senderDataSetName = 'sender'
const domainDataSetName = 'domain'
const initialState = {
  senderFields: getSenderFields(),
  senders: [],
  domainFields: getDomainFields(),
}

export default function emailsReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
  case GET_SENDERS: {
    return {
      ...state,
      senders: payload,
    }
  }
  case CREATE_SENDER: {
    let senders = state.senders
    if (payload) {
      senders = [...state.senders, payload]
    }
    return { ...state, senders }
  }
  case UPDATE_SENDER: {
    const senders = state.senders
    const senderIndex = senders.findIndex((sender) => sender.id === payload?.id)
    if (senderIndex > -1) {
      senders[senderIndex] = payload
    }
    return { ...state, senders }
  }
  case DELETE_SENDER: {
    const deletedIds = payload.deleted?.map((deleted) => deleted.id) || []
    return { ...state, senders: state.senders.filter((sender) => !deletedIds.includes(sender.id)) }
  }
  case REFRESH_DOMAIN:
  case VERIFY_DOMAIN: {
    return { ...state, senders: state.senders.map((sender) => {
      if (sender.domainId === payload?.id) {
        sender.domain = payload.domain
        sender.domainStatus = payload.status
        sender.domainDetails = payload.details
      }
      return sender
    }) }
  }
  case CLEAR_SENDERS: {
    return {
      ...state,
      senders: [],
    }
  }
  default: {
    return state
  }
  }
}

export function getSenderFields() {
  return {
    'id': { dataSetName: senderDataSetName, dbField: 'id' },
    'companyId': { dataSetName: senderDataSetName, dbField: 'company_id' },
    'createdById': { dataSetName: senderDataSetName, dbField: 'created_by_id' },
    'modifiedById': { dataSetName: senderDataSetName, dbField: 'modified_by_id' },
    'createdDate': { dataSetName: senderDataSetName, dbField: 'created_date' },
    'modifiedDate': { dataSetName: senderDataSetName, dbField: 'modified_date' },
    'name': { dataSetName: senderDataSetName, dbField: 'name' },
    'email': { dataSetName: senderDataSetName, dbField: 'email_address' },
    'notes': { dataSetName: senderDataSetName, dbField: 'notes' },
    'service': { dataSetName: senderDataSetName, dbField: 'service' },
    'domainId': { dataSetName: senderDataSetName, dbField: 'domain_id' },
    'domain': { dataSetName: domainDataSetName, dbField: 'domain' },
    'domainStatus': { dataSetName: domainDataSetName, dbField: 'status' },
    'domainDetails': { dataSetName: domainDataSetName, dbField: 'verification_details' },
    'region': { dataSetName: domainDataSetName, dbField: 'region' },
  }
}

export function getDomainFields() {
  return {
    'id': { dataSetName: domainDataSetName, dbField: 'id' },
    'domain': { dataSetName: domainDataSetName, dbField: 'domain' },
    'status': { dataSetName: domainDataSetName, dbField: 'status' },
    'details': { dataSetName: domainDataSetName, dbField: 'verification_details' },
    'region': { dataSetName: domainDataSetName, dbField: 'region' },
  }
}

export function fetchSenders(data) {
  return async function fetchSendersThunk(dispatch) {
    let senders = []

    try {
      const result = await (await safeFetch(buildGetUrl('/api/emailing/senders', data))).json()
      if (result.isSuccess) {
        senders = result.result.map((sender) => parseSender(sender))
        dispatch({ type: GET_SENDERS, payload: senders })
      }
    } catch (err) {
      console.error(err)
    }

    return senders
  }
}

export function createSender(sender) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ sender }),
  }

  return async function createSenderThunk(dispatch) {
    try {
      const result = await (await safeFetch(`/api/emailing/senders`, requestOptions)).json()
      const [created] = result.isSuccess ? result.result : []
      const payload = created ? parseSender(created) : null
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: CREATE_SENDER, payload, error })

      return payload
    } catch (error) {
      dispatch({ type: CREATE_SENDER, error })
    }
  }
}

export function updateSender(sender) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ sender }),
  }

  return async function updateSenderThunk(dispatch) {
    try {
      const result = await (await safeFetch(`/api/emailing/senders/${sender.id}`, requestOptions)).json()
      const [updated] = result.isSuccess ? result.result : []
      const payload = updated ? parseSender(updated) : null
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: UPDATE_SENDER, payload, error })

      return payload
    } catch (error) {
      dispatch({ type: UPDATE_SENDER, error })
    }
  }
}

export function deleteSenders(senderIds) {
  return async function deleteSendersThunk(dispatch) {
    try {
      const result = await (await safeFetch(`/api/emailing/senders/${senderIds}`, { method: 'DELETE' })).json()
      const deleted = result.isSuccess ? result.result : []
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: DELETE_SENDER, payload: { deleted, isSuccess: !!result.isSuccess }, error })

      return result
    } catch (error) {
      dispatch({ type: DELETE_SENDER, payload: { deleted: [], isSuccess: false }, error })
    }
  }
}

export function refreshDomain(domainId) {
  return async function refreshDomainThunk(dispatch) {
    try {
      const result = await (await safeFetch(`/api/emailing/domains/${domainId}`)).json()
      const [fetched] = result.isSuccess ? result.result : []
      const payload = fetched ? parseDomain(fetched) : null
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: REFRESH_DOMAIN, payload, error })

      return payload
    } catch (error) {
      dispatch({ type: REFRESH_DOMAIN, error })
    }
  }
}

export function sendEmail(
  senderId,
  sendTo,
  cc,
  bcc,
  subject,
  content,
  filesToUpload = {},
  filesToDownload = [],
  config,
) {
  const body = new FormData()
  body.append('email', JSON.stringify({
    sender_id: senderId,
    to: sendTo.map((to) => ({ email_address: to })),
    cc: cc.map((to) => ({ email_address: to })),
    bcc: bcc.map((to) => ({ email_address: to })),
    subject,
    body: content,
    config,
  }))
  body.append('files_to_download', JSON.stringify(filesToDownload))
  Object.keys(filesToUpload).forEach((fileName) => body.append('files_to_upload', filesToUpload[fileName], fileName))
  const requestOptions = { method: 'POST', body }

  return async function sendEmailThunk(dispatch) {
    try {
      const result = await (await safeFetch(`/api/emailing/emails`, requestOptions)).json()
      const [payload] = result.isSuccess ? result.result : []
      const error = !result.isSuccess ? parseError(result.result) : null

      dispatch({ type: SEND_EMAIL, payload, error })

      return { isSuccess: result.isSuccess, error }
    } catch (error) {
      const parsedError = parseError(error)

      dispatch({ type: SEND_EMAIL, error: parsedError.result })

      return { isSuccess: parsedError.isSuccess, error: parsedError.result }
    }
  }
}

export function verifyDomain(domainId) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  }

  return async function updateSenderThunk(dispatch) {
    try {
      const result = await (await safeFetch(`/api/emailing/domains/${domainId}/verification`, requestOptions)).json()
      const [domain] = result.isSuccess ? result.result : []
      const payload = domain ? parseDomain(domain) : null
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: VERIFY_DOMAIN, payload, error })

      return payload
    } catch (error) {
      dispatch({ type: VERIFY_DOMAIN, error })
    }
  }
}

export function clearSenders(dispatch) {
  dispatch({ type: CLEAR_SENDERS })
}

export function parseSender(sender) {
  const options = {
    defaultData: getDefaultSender(),
    fields: initialState.senderFields,
    dataSetName: senderDataSetName,
  }
  return parse(sender, options)
}

function getDefaultSender() {
  return parse({}, { fields: initialState.senderFields })
}

export function parseDomain(domain) {
  const options = {
    defaultData: getDefaultDomain(),
    fields: initialState.domainFields,
    dataSetName: domainDataSetName,
  }
  return parse(domain, options)
}

function getDefaultDomain() {
  return parse({}, { fields: initialState.domainFields })
}

export function changeRegion(region) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ region }),
  }

  return async function changeRegionThunk(dispatch) {
    try {
      const result = await (await safeFetch(`/api/emailing/domains/switch-region`, requestOptions)).json()
      const [domain] = result.isSuccess ? result.result : []
      const payload = domain ? parseDomain(domain) : null
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: CHANGE_REGION, payload, error })
    } catch (error) {
      dispatch({ type: CHANGE_REGION, error })
    }
  }
}
