export const GET_PLANTS = 'GET_PLANTS'
export const GET_PLANTS_COUNT = 'GET_PLANTS_COUNT'
export const GET_PLANT = 'GET_PLANT'
export const CREATE_PLANT = 'CREATE_PLANT'
export const UPDATE_PLANT = 'UPDATE_PLANT'
export const DELETE_PLANT = 'DELETE_PLANT'

export const SET_ADDRESS_FORM = 'SET_PLANT_ADDRESS_FORM'

export const CLEAR_PLANT = 'CLEAR_PLANT'
export const CLEAR_CREATE_SUCCESS = 'CLEAR_PLANT_CREATE_SUCCESS'
export const CLEAR_UPDATE_SUCCESS = 'CLEAR_PLANT_UPDATE_SUCCESS'
export const CLEAR_DELETE_SUCCESS = 'CLEAR_PLANT_DELETE_SUCCESS'
export const CLEAR_CREATE_ERROR = 'CLEAR_PLANT_CREATE_ERROR'
export const CLEAR_UPDATE_ERROR = 'CLEAR_PLANT_UPDATE_ERROR'
export const CLEAR_DELETE_ERROR = 'CLEAR_PLANT_DELETE_ERROR'

export const SET_IS_CREATE = 'SET_PLANT_IS_CREATE'
export const SET_GLOBAL_FORM = 'SET_PLANT_GLOBAL_FORM'
export const RESET_FORM = 'RESET_PLANT_FORM'
