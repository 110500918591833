// ! TODO (bzoretic): configureStore instead of createStore
import { useCallback } from 'react'
import { TypedUseSelectorHook, useSelector } from 'react-redux'

import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'

import hubRootReducer from './reducers/hubRoot'
import rootReducer from './reducers/root'

const devToolsEnhancer = composeWithDevTools({ trace: true, traceLimit: 25 })
const composedEnhancer = devToolsEnhancer(applyMiddleware(thunkMiddleware))

const store = createStore(rootReducer, composedEnhancer)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export function useAppDispatch() {
  const dispatch = store.dispatch
  return useCallback((event: any) => {
    return dispatch(event)
  }, [dispatch])
}
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store

export const hubStore = createStore(hubRootReducer, composedEnhancer)

export type HubRootState = ReturnType<typeof hubStore.getState>
export type HubAppDispatch = typeof hubStore.dispatch

export function useHubAppDispatch() {
  const dispatch = hubStore.dispatch
  return useCallback((event: any) => {
    return dispatch(event)
  }, [dispatch])
}
export const useHubAppSelector: TypedUseSelectorHook<HubRootState> = useSelector
