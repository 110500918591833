
import moment from 'moment'
import { AppDispatch } from 'store'
import { ApiToSlice, BaseEntityApi, GetFields, Modify } from 'types/slices'

import { buildGetUrl, parse } from 'utils/api'
import { stringIfDefined } from 'utils/defaultValueHelper'
import { Digit, parseNumber } from 'utils/numberParser'
import {
  safeFetch,
  parseError,
  safeFetchJson,
  parsedResultOnSucessOrEmtpy,
  isJob,
} from 'utils/safeFetch'
import {
  convertToBase,
  convertFromBase,
  convertFromDollarPerBase,
} from 'utils/unitConverter'
import {
  convertGrossWeight,
  convertNetWeight,
  convertTareWeight,
  getMeasureUnit,
  getTareUnit,
  getWeightUnit,
  convertUnitWeight,
  getConvertedOnOrderMeasure,
} from 'utils/weightUtils'

import { ReceptionApi } from 'reducers/receptions/receptionType'
import { E, SmartFormOptionObject } from 'reducers/smart-form/smartFormTypes'

import {
  GET_INVENTORIES_COUNT,
  GET_INVENTORIES,
  CREATE_INVENTORIES,
  UPDATE_INVENTORIES,
  WASTE_INVENTORIES,
  DELETE_INVENTORIES,
  CLEAR_INVENTORIES,
} from './types'

type InventoryStatus = 'creating'|'ordered'|'to-order'|'to-return'|'ready'|'quote'|'stand-by'|'done'|'merged'|'returned'

const dataSetName = 'inventory_view'
const outputDataSetName = 'outputDevView'

const initialState = {
  dataSetName,
  fields: getFields(),
  outputFields: getOutputFields(),
  inventoriesCount: 0,
  inventories: [],
}

export default function inventoriesReducer(state = initialState, action: any) {
  const { payload } = action
  switch (action.type) {
  case GET_INVENTORIES_COUNT: {
    return {
      ...state,
      inventoriesCount: payload,
    }
  }
  case GET_INVENTORIES: {
    return {
      ...state,
      inventories: payload,
    }
  }
  case CLEAR_INVENTORIES: {
    return {
      ...state,
      inventories: [],
      inventoriesCount: 0,
    }
  }
  default: {
    return state
  }
  }
}

export type InventoryApi = Modify<{
  material_id: string
  raw_id: string
  instructions: string
  description: string
  serial_number: string
  manufacturer: string
  part_number: string
  revision: string
  planned_unit_cost: number
  tag: string
  status: InventoryStatus
  location_id: string
  template_id: string
  supplier_id: string
  supplier_display_name: string
  client_id: string
  client_company_name: string
  client_display_name: string
  real_unit_cost: number
  invoice_date: Date
  invoice_title: string
  received_date: Date
  receipt_title: string
  receipt_carrier: string
  project_id: string
  treatment_id: string
  initial_measure: number
  current_measure: number
  template_description: string
  project_title: string
  project_display_title: string
  location: string
  level_code: string
  bay_code: string
  aisle_code: string
  zone_code: string
  zone_title: string
  zone_description: string
  warehouse_code: string
  warehouse_title: string
  warehouse_description: string
  material_title: string
  measure_unit: string
  weight_unit: string
  project_type: string
  project_client_address_id: string
  dimension_to_display: string
  template_dimension_to_display: string
  raw_imperial_title: string
  raw_metric_title: string
  treatment_title: string
  location_code: string
  template_part_number: string
  project_number: number
  project_formated_number: string
  project_year: number
  expiration_date: Date
  manufactured_date: Date
  manufactured_ledger_id: string
  due_date: Date
  planned_target_inventory_id: string
  template_sku: string
  template_upc: string
  template_ean: string
  return_number: string
  return_date: Date
  is_bin: boolean
  template_secondary_description: string
  template_long_description_sale: string
  template_long_secondary_description_sale: string
  template_long_description_purchase: string
  template_long_secondary_description_purchase: string
  template_harmonized_system_code_id: string
  template_harmonized_system_code_code: string
  template_harmonized_system_code_description: string
  template_country_of_origin_id: string
  template_country_of_origin_primary_name: string
  template_country_of_origin_secondary_name: string
  secondary_description: string
  category_id: string
  category_title: string
  is_dropship: boolean
  supplier_list_price: number
  supplier_discount: number
  supplier_currency_code: string
  supplier_currency_symbol: string
  supplier_exchange_rate: number
  supplier_part_number: string
  trimmed_instructions: string
  unit_weight: number
  template_is_printable: boolean
  tare_weight: number
  tare_unit: string
  weight_input_is_gross_or_net: boolean
  net_weight: number
  gross_weight: number
  template_manufacturing_order_id: string
  template_group_name: string
  template_type: string
  template_inventory_management_type: string
  template_is_manufactured: boolean
  template_is_purchased: boolean
  template_is_selling: boolean
  template_unit_weight: number
  template_asset_account_id: string
  template_expense_account_id: string
  template_income_account_id: string
  template_target_inventory_mandatory: boolean
  planned_target_inventory_location_code: string
  planned_target_inventory_tag: string
  planned_target_inventory_display_name: string
  plant_id: string
  plant_name: string
  dimension_order: number
  project_client_on_label: boolean
  active_planned_ledger_planned_measure_sum: number
  active_planned_ledger_on_order_measure_sum: number
  active_planned_ledger_reserved_measure_sum: number
  active_planned_ledger_reception_item_id: string
  template_title: string
  template_labels: Record<string, any>[]
  planned_output_id: string
  purchase_order_item_id: string
  purchase_order_id: string
  sales_order_id: string
  reception_item_id: string
  consignment_item_id: string
  other_cost: number
  landed_cost: number
  reception_id: string
  received_datetime: Date
  dropship_address_id: string
  dropship_client_id: string
  origin_output_project_id: string
  reception_checklist_id: string
  tare_type_id: string
  template_main_supplier_purchasing_lead_time: number
  template_main_supplier_part_number: string
  output_measure?: number
  output_measure_unit?: string
  output_produced?: number
  output_batch?: number
  item_qty_planned?: number
  item_last_resource?: string
  item_modified_by?: string
  item_number?: number
  item_title?: string
  item_id?: string
  item_project_title?: string
  negative_measure?: number
  ledger_measure_sum?: number
  inventory_sum_measure?: number
  planned_measure?: number
  consumed_measure?: number
  reference_number?: string
  purchase_order_item_contract_number?: string

  sales_order_customer_id?: string
  sales_order_customer_display_name?: string
}, BaseEntityApi>

export type InventoryExceptions = {
  dimension_to_display: 'dimension'
  template_target_inventory_mandatory: 'isMandatoryTarget'
  template_title: 'item'
  template_sku: 'sku'
  template_upc: 'upc'
  template_ean: 'ean'
  template_group_name: 'groupName',
  template_type: 'type',
  template_inventory_management_type: 'inventoryManagementType',
  template_is_selling: 'isSelling',
  template_is_manufactured: 'isManufactured',
  template_manufacturing_order_id: 'manufacturingOrderId',
  template_is_purchased: 'isPurchased',
  template_is_printable: 'isPrintable',
  template_labels: 'labels',
  material_title: 'material'
  category_title: 'category'
  raw_metric_title: 'rawMetric'
  raw_imperial_title: 'rawImperial'
  treatment_title: 'treatment'
  project_display_title: 'project'
  project_title: 'projectRaw'
  project_formated_number: 'projectFormattedNumber'
  invoice_title: 'invoice'
  receipt_title: 'receptionNumber'
  planned_target_inventory_tag: 'plannedTargetTag'
  planned_target_inventory_display_name: 'plannedTargetDisplayName'
  planned_target_inventory_location_code: 'plannedTargetLocationCode'
  supplier_display_name: 'vendorName'
  supplier_part_number: 'vendorPartNumber'
  supplier_list_price: 'vendorListPrice'
  supplier_currency_code: 'vendorCurrencyCode'
  supplier_currency_symbol: 'vendorCurrencySymbol'
  supplier_discount: 'vendorDiscount'
  supplier_exchange_rate: 'vendorExchangeRate'
  due_date: 'expectedDeliveryDate'
  origin_output_project_id: 'plannedOutputProjectId'
  supplier_id: 'vendorId'
  receipt_carrier: 'receptionCarrier'
  project_client_address_id: 'clientAddressId'
  template_main_supplier_purchasing_lead_time: 'templateSupplierPurchaseLeadTime'
  template_main_supplier_part_number: 'templateSupplierPartNumber'
  active_planned_ledger_on_order_measure_sum: 'onOrderMeasure'
  active_planned_ledger_reserved_measure_sum: 'reservedMeasure'
}

export type Inventory = ApiToSlice<
  Modify<InventoryApi, {
    _measure_unit: string
    _weight_unit: string
    _tare_unit: string
    display_location_tag: string
    converted_current_measure: number
    converted_initial_measure: number
    converted_gross_weight: number
    converted_tare_weight: number
    converted_net_weight: number
    converted_unit_weight: number
    converted_vendor_list_price: number
    converted_on_order_measure: number
    converted_reserved_measure: number
    converted_net_flow_measure: number
    converted_ledger_measure_sum: number
    converted_inventory_sum_measure: number
    converted_planned_measure: number
    converted_consumed_measure: number
    status_order: number
    base_cost: number
    base_planned_unit_cost: number
    base_real_unit_cost: number
    available_measure: number
    receipt_status: string
    total_price: number
    total_cost: number
    converted_negative_measure?: number
  }>,
  InventoryExceptions
>

type MapData = {
  defaultUnits?: {
    qty: string
    weight: string
    length: string
    surface: string
    volume: string
  }
  culture?: string
  measureDigits?: Digit
}

export function getFields(
  dataSetName?: string,
): GetFields<InventoryApi, Inventory, MapData, 'output' | 'item'> {
  dataSetName = dataSetName || 'inventory_view'
  return {
    id: { dataSetName, dbField: 'id', type: 'id' },
    exist: { dataSetName, dbField: 'exist', type: 'boolean' },
    tag: { dataSetName, dbField: 'tag' },
    displayLocationTag: { parseWithParsedData: getDisplayLocationTag },
    status: {
      dataSetName,
      dbField: 'status',
      isEdit: true,
      customEventValueTranslationKey: (value) => `inventories:status.${value ?? 'creating'}`,
    },
    dimension: {
      dataSetName,
      dbField: 'dimension_to_display',
      parse: (inventory) => inventory.dimension_to_display ?? inventory.template_dimension_to_display,
    },
    dimensionOrder: { dataSetName, dbField: 'dimension_order' },
    currentMeasure: {
      dataSetName,
      dbField: 'current_measure',
      type: 'measure',
    },
    convertedCurrentMeasure: {
      dataSetName,
      dbField: 'current_measure',
      parseWithParsedData: parseCurrentMeasure,
    },
    _measureUnit: { dataSetName, dbField: 'measure_unit' },
    measureUnit: {
      dataSetName,
      dbField: 'measure_unit',
      parseWithParsedData: getMeasureUnit,
    },
    createdDate: { dataSetName, dbField: 'created_date', type: 'date' },
    createdBy: { dataSetName, dbField: 'created_by' },
    statusOrder: { parse: (inventory) => getStatusSortOrder(inventory.status) },
    isMandatoryTarget: { dataSetName, dbField: 'template_target_inventory_mandatory' },
    item: { dataSetName, dbField: 'template_title' },
    sku: { dataSetName, dbField: 'template_sku' },
    upc: { dataSetName, dbField: 'template_upc' },
    ean: { dataSetName, dbField: 'template_ean' },
    material: { dataSetName, dbField: 'material_title' },
    category: { dataSetName, dbField: 'category_title' },
    serialNumber: { dataSetName, dbField: 'serial_number' },
    description: { dataSetName, dbField: 'description' },
    secondaryDescription: { dataSetName, dbField: 'secondary_description' },
    rawMetric: { dataSetName, dbField: 'raw_metric_title' },
    rawImperial: { dataSetName, dbField: 'raw_imperial_title' },
    partNumber: { dataSetName, dbField: 'part_number' },
    treatment: { dataSetName, dbField: 'treatment_title' },
    revision: { dataSetName, dbField: 'revision' },
    project: { dataSetName, dbField: 'project_display_title' },
    projectRaw: { dataSetName, dbField: 'project_title' },
    projectFormattedNumber: { dataSetName, dbField: 'project_formated_number' },
    projectType: { dataSetName, dbField: 'project_type' },
    projectYear: { dataSetName, dbField: 'project_year' },
    projectNumber: { dataSetName, dbField: 'project_number' },
    projectClientOnLabel: { dataSetName, dbField: 'project_client_on_label' },
    plantName: { dataSetName, dbField: 'plant_name' },
    basePlannedUnitCost: {
      dataSetName,
      dbField: 'planned_unit_cost',
      type: 'currency',
    },
    plannedUnitCost: {
      dataSetName,
      dbField: 'planned_unit_cost',
      parseWithParsedData: parsePlannedUnitCost,
      type: 'currency',
    },
    baseRealUnitCost: {
      dataSetName,
      dbField: 'real_unit_cost',
      type: 'currency',
    },
    realUnitCost: {
      dataSetName,
      dbField: 'real_unit_cost',
      parseWithParsedData: parseRealUnitCost,
      type: 'currency',
      isEdit: true,
    },
    otherCost: {
      dataSetName,
      dbField: 'other_cost',
      type: 'currency',
      isEdit: true,
    },
    landedCost: {
      dataSetName,
      dbField: 'landed_cost',
      type: 'currency',
      isEdit: true,
    },
    initialMeasure: {
      dataSetName,
      dbField: 'initial_measure',
      type: 'measure',
    },
    convertedInitialMeasure: {
      dataSetName,
      dbField: 'initial_measure',
      parseWithParsedData: parseInitialMeasure,
    },
    _weightUnit: { dataSetName, dbField: 'weight_unit' },
    weightUnit: { dataSetName, dbField: 'weight_unit', parseWithParsedData: getWeightUnit },
    grossWeight: { dataSetName, dbField: 'gross_weight' },
    convertedGrossWeight: {
      dataSetName,
      dbField: 'gross_weight',
      parseWithParsedData: convertGrossWeight,
    },
    tareWeight: {
      dataSetName,
      dbField: 'tare_weight',
      customFormattedValue: (value, { measureDigits, culture, defaultUnits }, entityData) => {
        const parsedNumber = parseNumber(
          _convertTareWeightFromValue(value, entityData, defaultUnits),
          measureDigits,
          culture,
        )

        return `${parsedNumber} ${entityData.weightUnit}`
      },
    },
    _tareUnit: { dataSetName, dbField: 'tare_unit' },
    tareUnit: { dataSetName, dbField: 'tare_unit', parseWithParsedData: getTareUnit },
    convertedTareWeight: {
      dataSetName,
      dbField: 'tare_weight',
      parseWithParsedData: convertTareWeight,
    },
    netWeight: { dataSetName, dbField: 'net_weight' },
    convertedNetWeight: {
      dataSetName,
      dbField: 'net_weight',
      parseWithParsedData: convertNetWeight,
    },
    unitWeight: {
      dataSetName,
      dbField: 'unit_weight',
      type: 'measure',
      customFormattedValue: (value, { measureDigits, culture, defaultUnits }, entityData) => {
        const parsedNumber = parseNumber(
          _parseUnitWeightFromValue(value, entityData, defaultUnits),
          measureDigits,
          culture,
        )

        return `${parsedNumber} ${entityData.weightUnit} / ${entityData.measureUnit}`
      },
    },
    convertedUnitWeight: {
      dataSetName,
      dbField: 'unit_weight',
      parseWithParsedData: convertUnitWeight,
    },
    expirationDate: {
      dataSetName,
      dbField: 'expiration_date',
      type: 'date',
      isTimezoned: false,
    },
    invoiceDate: { dataSetName, dbField: 'invoice_date', type: 'date', isEdit: true, isTimezoned: false },
    invoice: { dataSetName, dbField: 'invoice_title', isEdit: true },
    receivedDate: { dataSetName, dbField: 'received_date', type: 'date', isEdit: true, isTimezoned: false },
    receptionId: { dataSetName, dbField: 'reception_id', type: 'id', relationEntity: 'receptions' },
    receptionItemId: { dataSetName, dbField: 'reception_item_id', type: 'id', relationEntity: 'reception-items' },
    receivedDatetime: {
      dataSetName,
      dbField: 'received_datetime',
      type: 'timestamp',
      isEdit: true,
      customFieldTranslationKey: (t) => t('inventories:inventory.fields.receivedDatetime.longLabel'),
    },
    receptionNumber: { dataSetName, dbField: 'receipt_title', isEdit: true },
    plannedTargetTag: { dataSetName, dbField: 'planned_target_inventory_tag' },
    plannedTargetDisplayName: { dataSetName, dbField: 'planned_target_inventory_display_name' },
    plannedTargetLocationCode: {
      dataSetName,
      dbField: 'planned_target_inventory_location_code',
    },
    manufacturer: { dataSetName, dbField: 'manufacturer' },
    location: { dataSetName, dbField: 'location' },
    locationCode: { dataSetName, dbField: 'location_code' },
    vendorName: { dataSetName, dbField: 'supplier_display_name' },
    vendorPartNumber: { dataSetName, dbField: 'supplier_part_number', isEdit: true },
    vendorListPrice: {
      dataSetName,
      dbField: 'supplier_list_price',
      type: 'currency',
      isEdit: true,
    },
    convertedVendorListPrice: {
      dataSetName,
      dbField: 'supplier_list_price',
      parseWithParsedData: parseListPriceCost,
      isEdit: true,
    },
    vendorCurrencyCode: { dataSetName, dbField: 'supplier_currency_code' },
    vendorCurrencySymbol: { dataSetName, dbField: 'supplier_currency_symbol' },
    vendorDiscount: {
      dataSetName,
      dbField: 'supplier_discount',
      type: 'percentage',
      isEdit: true,
    },
    vendorExchangeRate: {
      dataSetName,
      dbField: 'supplier_exchange_rate',
      type: 'float',
    },
    baseCost: { parseWithParsedData: parseBaseCost },
    modifiedDate: { dataSetName, dbField: 'modified_date', type: 'date' },
    modifiedBy: { dataSetName, dbField: 'modified_by' },
    manufacturedDate: { dataSetName, dbField: 'manufactured_date', type: 'date', isTimezoned: false },
    expectedDeliveryDate: { dataSetName, dbField: 'due_date', type: 'date', isEdit: true, isTimezoned: false },
    returnNumber: { dataSetName, dbField: 'return_number' },
    returnDate: { dataSetName, dbField: 'return_date', type: 'date', isTimezoned: false },
    isDropship: { dataSetName, dbField: 'is_dropship', type: 'boolean', defaultValue: false },
    isBin: { dataSetName, dbField: 'is_bin', type: 'boolean', defaultValue: false },
    groupName: { dataSetName, dbField: 'template_group_name' },
    type: { dataSetName, dbField: 'template_type' },
    inventoryManagementType: { dataSetName, dbField: 'template_inventory_management_type' },
    isSelling: { dataSetName, dbField: 'template_is_selling', type: 'boolean', defaultValue: false },
    isManufactured: { dataSetName, dbField: 'template_is_manufactured', type: 'boolean', defaultValue: false },
    manufacturingOrderId: { dataSetName, dbField: 'template_manufacturing_order_id', type: 'id' },
    isPurchased: { dataSetName, dbField: 'template_is_purchased', type: 'boolean', defaultValue: false },
    isPrintable: { dataSetName, dbField: 'template_is_printable', type: 'boolean', defaultValue: false },
    labels: { dataSetName, dbField: 'template_labels', type: 'array', defaultValue: [] },
    availableMeasure: { parseWithParsedData: parseAvailableMeasure },
    categoryId: { dataSetName, dbField: 'category_id', type: 'id' },
    createdById: { dataSetName, dbField: 'created_by_id', type: 'id' },
    dropshipAddressId: { dataSetName, dbField: 'dropship_address_id', type: 'id', relationEntity: 'addresses' },
    dropshipClientId: { dataSetName, dbField: 'dropship_client_id', type: 'id', relationEntity: 'contacts' },
    locationId: { dataSetName, dbField: 'location_id', type: 'id', relationEntity: 'locations' },
    manufacturedLedgerId: { dataSetName, dbField: 'manufactured_ledger_id', type: 'id' },
    materialId: { dataSetName, dbField: 'material_id', type: 'id' },
    modifiedById: { dataSetName, dbField: 'modified_by_id', type: 'id' },
    plannedOutputId: { dataSetName, dbField: 'planned_output_id', type: 'id' },
    plannedOutputProjectId: { dataSetName, dbField: 'origin_output_project_id', type: 'id' },
    plannedTargetInventoryId: {
      dataSetName,
      dbField: 'planned_target_inventory_id',
      type: 'id',
      relationEntity: 'inventories',
      isEdit: true,
    },
    plantId: { dataSetName, dbField: 'plant_id', type: 'id', relationEntity: 'plants' },
    projectId: { dataSetName, dbField: 'project_id', type: 'id', relationEntity: 'projects' },
    purchaseOrderItemId: { dataSetName, dbField: 'purchase_order_item_id', type: 'id' },
    purchaseOrderId: {
      dataSetName,
      dbField: 'purchase_order_id',
      type: 'id',
      relationEntity: 'purchase-orders',
    },
    salesOrderId: {
      dataSetName,
      dbField: 'sales_order_id',
      type: 'id',
      relationEntity: 'sales-orders',
    },
    rawId: { dataSetName, dbField: 'raw_id', type: 'id' },
    receptionChecklistId: { dataSetName, dbField: 'reception_checklist_id', type: 'id', relationEntity: 'checklists' },
    vendorId: { dataSetName, dbField: 'supplier_id', type: 'id', relationEntity: 'contacts', isEdit: true },
    tareTypeId: { dataSetName, dbField: 'tare_type_id', type: 'id', relationEntity: 'tares' },
    templateId: { dataSetName, dbField: 'template_id', type: 'id' },
    treatmentId: { dataSetName, dbField: 'treatment_id', type: 'id' },
    weightInputIsGrossOrNet: { dataSetName, dbField: 'weight_input_is_gross_or_net', type: 'boolean' },
    instructions: { dataSetName, dbField: 'instructions', type: 'html' },
    trimmedInstructions: { dataSetName, dbField: 'trimmed_instructions', type: 'text', isNotUserFriendly: true },
    receptionCarrier: { dataSetName, dbField: 'receipt_carrier', isEdit: true },
    receiptStatus: {
      isEdit: true,
      parseWithParsedData: (inventory) => {
        const now = moment.utc().startOf('day')
        const dueDate = inventory.expectedDeliveryDate ? moment.utc(inventory.expectedDeliveryDate) : null
        const receivedDate = inventory.receivedDate ? moment.utc(inventory.receivedDate) : null
        let status

        if (dueDate && !receivedDate) {
          if (dueDate < now) {
            status = 'late'
          } else {
            status = 'onTime'
          }
        } else if ((!dueDate && receivedDate) || (dueDate && receivedDate && dueDate >= receivedDate)) {
          status = 'arrived'
        } else if (dueDate && receivedDate && dueDate < receivedDate) {
          status = 'arrivedLate'
        }

        return status
      },
    },

    salesOrderCustomerId: { dataSetName, dbField: 'sales_order_customer_id', type: 'id', relationEntity: 'contacts' },
    salesOrderCustomerDisplayName: { dataSetName, dbField: 'sales_order_customer_display_name' },

    clientId: { dataSetName, dbField: 'client_id' },
    clientAddressId: { dataSetName, dbField: 'project_client_address_id' },
    clientDisplayName: { dataSetName, dbField: 'client_display_name' },
    templateDescription: { dataSetName, dbField: 'template_description' },
    templateLongDescriptionSale: { dataSetName, dbField: 'template_long_description_sale' },
    templateLongSecondaryDescriptionSale: { dataSetName, dbField: 'template_long_secondary_description_sale' },
    templateLongDescriptionPurchase: { dataSetName, dbField: 'template_long_description_purchase' },
    templateLongSecondaryDescriptionPurchase: { dataSetName, dbField: 'template_long_secondary_description_purchase' },
    templateSupplierPurchaseLeadTime: { dataSetName, dbField: 'template_main_supplier_purchasing_lead_time' },
    templateSupplierPartNumber: { dataSetName, dbField: 'template_main_supplier_part_number' },
    templateHarmonizedSystemCodeId: { dataSetName, dbField: 'template_harmonized_system_code_id' },
    templateHarmonizedSystemCodeCode: { dataSetName, dbField: 'template_harmonized_system_code_code' },
    templateHarmonizedSystemCodeDescription: { dataSetName, dbField: 'template_harmonized_system_code_description' },
    templateCountryOfOriginId: { dataSetName, dbField: 'template_country_of_origin_id' },
    templateCountryOfOriginPrimaryName: { dataSetName, dbField: 'template_country_of_origin_primary_name' },
    templateCountryOfOriginSecondaryName: { dataSetName, dbField: 'template_country_of_origin_secondary_name' },

    onOrderMeasure: { dataSetName, dbField: 'active_planned_ledger_on_order_measure_sum', type: 'measure' },
    convertedOnOrderMeasure: {
      dataSetName,
      dbField: 'active_planned_ledger_on_order_measure_sum',
      type: 'measure',
      parseWithParsedData: getConvertedOnOrderMeasure,
    },
    reservedMeasure: { dataSetName, dbField: 'active_planned_ledger_reserved_measure_sum', type: 'measure' },
    convertedReservedMeasure: {
      dataSetName,
      dbField: 'active_planned_ledger_reserved_measure_sum',
      type: 'measure',
      parseWithParsedData: getConvertedReservedMeasure,
    },
    activePlannedLedgerReceptionItemId: {
      dataSetName,
      dbField: 'active_planned_ledger_reception_item_id',
      type: 'id',
      relationEntity: 'reception-items',
    },
    convertedNetFlowMeasure: { parseWithParsedData: getConvertedNetFlowMeasure },
    totalPrice: { parseWithParsedData: (inventory) => {
      return Number(inventory.convertedInitialMeasure) * Number(inventory.realUnitCost)
    } },
    totalCost: { parseWithParsedData: (inventory) => {
      return Number(inventory.realUnitCost) * Number(inventory.vendorExchangeRate)
    } },

    /**
     * planned join fields (returned by back end when plannedJoinItemId is provided)
     */
    outputMeasure: {
      dataSetName: 'outputDevView',
      dataSetAlias: 'output',
      dbField: 'measure',
      type: 'measure',
    },
    outputMeasureUnit: {
      dataSetName: 'outputDevView',
      dataSetAlias: 'output',
      dbField: 'measure_unit',
    },
    outputProduced: {
      dataSetName: 'outputDevView',
      dataSetAlias: 'output',
      dbField: 'produced',
      type: 'measure',
    },
    outputBatch: {
      dataSetName: 'outputDevView',
      dataSetAlias: 'output',
      dbField: 'batch',
      type: 'integer',
    },
    itemQtyPlanned: {
      dataSetName: 'item',
      dbField: 'qty_planned',
      type: 'integer',
    },
    itemLastResource: { dataSetName: 'item', dbField: 'last_resource' },
    itemModifiedBy: { dataSetName: 'item', dbField: 'modified_by' },
    itemNumber: { dataSetName, dbField: 'item_number' },
    itemTitle: { dataSetName, dbField: 'item_title' },
    itemId: { dataSetName, dbField: 'item_id' },
    itemProjectTitle: { dataSetName, dbField: 'item_project_title' },
    consignmentItemId: { dataSetName, dbField: 'consignment_item_id', type: 'id' },
    referenceNumber: { dataSetName, dbField: 'reference_number' },
    purchaseOrderItemContractNumber: { dataSetName, dbField: 'purchase_order_item_contract_number' },

    // ? Those are for warning views
    negativeMeasure: { dataSetName, dbField: 'negative_measure', type: 'measure' },
    convertedNegativeMeasure: {
      dataSetName,
      dbField: 'negative_measure',
      parseWithParsedData: (inventory, options) => parseMeasure(inventory, inventory.negativeMeasure, options),
      type: 'measure',
    },
    ledgerMeasureSum: { dataSetName, dbField: 'ledger_measure_sum', type: 'measure' },
    convertedLedgerMeasureSum: {
      dataSetName,
      dbField: 'ledger_measure_sum',
      type: 'measure',
      parseWithParsedData: (inventory, options) => parseMeasure(inventory, inventory.ledgerMeasureSum, options),
    },
    inventorySumMeasure: { dataSetName, dbField: 'inventory_sum_measure', type: 'measure' },
    convertedInventorySumMeasure: {
      dataSetName,
      dbField: 'inventory_sum_measure',
      type: 'measure',
      parseWithParsedData: (inventory, options) => parseMeasure(inventory, inventory.inventorySumMeasure, options),
    },
    plannedMeasure: { dataSetName, dbField: 'planned_measure', type: 'measure' },
    convertedPlannedMeasure: {
      dataSetName,
      dbField: 'planned_measure',
      type: 'measure',
      parseWithParsedData: (inventory, options) => parseMeasure(inventory, inventory.plannedMeasure, options),
    },
    consumedMeasure: { dataSetName, dbField: 'consumed_measure', type: 'measure' },
    convertedConsumedMeasure: {
      dataSetName,
      dbField: 'consumed_measure',
      type: 'measure',
      parseWithParsedData: (inventory, options) => parseMeasure(inventory, inventory.consumedMeasure, options),
    },
  }
}

export type OutputApi = Modify<{
  item_id: string
  inventory_id: string
  measure: number
  inventory_current_measure: number
  inventory_material_title: string
  inventory_treatment_title: string
  inventory_raw_metric_title: string
  inventory_raw_imperial_title: string
  inventory_part_number: string
  inventory_revision: string
  measure_unit: string
  material_title: string
  is_mandatory: boolean
  template_dimension_to_display: string
  inventory_tag: string
  item_formated_number: string
  item_status: string
  item_title: string
  item_type: string
  item_tag: string
}, BaseEntityApi>

type OutputExceptions = {
  measure_unit: 'unit'
  material_title: 'material'
  inventory_material_title: 'inventoryMaterial'
  inventory_treatment_title: 'inventoryTreatment'
  inventory_raw_metric_title: 'inventoryRawMetric'
  inventory_raw_imperial_title: 'inventoryRawImperial'
  template_dimension_to_display: 'dimension'
  inventory_tag: 'tag'
  item_formated_number: 'cardNumber'
  item_status: 'cardStatus'
  item_title: 'cardTitle'
  item_type: 'cardType'
  item_tag: 'cardTag'
}

export type Output = ApiToSlice<OutputApi, OutputExceptions>

type OutputAcceptedDataSetNames = (
  'inventory' | 'material' | 'inventory_material' | 'inventory_treatment' |
  'inventory_raw' | 'template' | 'item'
)
export function getOutputFields(): GetFields<OutputApi, Output, null, OutputAcceptedDataSetNames> {
  return {
    id: { dataSetName: outputDataSetName, dbField: 'id', type: 'id' },
    itemId: { dataSetName: outputDataSetName, dbField: 'item_id', type: 'id' },
    inventoryId: { dataSetName: outputDataSetName, dbField: 'inventory_id', type: 'id', relationEntity: 'inventories' },
    measure: { dataSetName: outputDataSetName, dbField: 'measure', type: 'measure', defaultValue: 0 },
    isMandatory: { dataSetName: outputDataSetName, dbField: 'is_mandatory', type: 'boolean' },
    inventoryCurrentMeasure: { dataSetName: 'inventory', dbField: 'current_measure', type: 'measure' },
    inventoryPartNumber: { dataSetName: 'inventory', dbField: 'part_number', type: 'string' },
    inventoryRevision: { dataSetName: 'inventory', dbField: 'revision', type: 'string' },
    inventoryMaterial: { dataSetName: 'material', dataSetAlias: 'inventory_material', dbField: 'title' },
    inventoryTreatment: { dataSetName: 'treatment', dataSetAlias: 'inventory_treatment', dbField: 'title' },
    inventoryRawMetric: { dataSetName: 'raw', dataSetAlias: 'inventory_raw', dbField: 'metric_title' },
    inventoryRawImperial: { dataSetName: 'raw', dataSetAlias: 'inventory_raw', dbField: 'imperial_title' },
    unit: { dataSetName: outputDataSetName, dbField: 'measure_unit', parse: getOutputUnit },
    material: { dataSetName: 'templateView', trimAlias: 'material', dbField: 'title' },
    dimension: { dataSetName: 'template', dbField: 'dimension_to_display' },
    tag: { dataSetName: 'inventory', dbField: 'tag' },
    cardNumber: { dataSetName: 'item', dbField: 'formated_number' },
    cardStatus: { dataSetName: 'item', dbField: 'status', type: 'status', dictionaryKey: 'item' },
    cardTitle: { dataSetName: 'item', dbField: 'title' },
    cardType: { dataSetName: 'item', dbField: 'type' },
    cardTag: { dataSetName: 'item', dbField: 'tag' },
  }
}

export function setInventories(inventories: Inventory[]) {
  return async function updateItemsThunk(dispatch: AppDispatch) {
    dispatch({ type: GET_INVENTORIES, payload: inventories })
  }
}

export function parseInventory(
  inventory: InventoryApi,
  defaultUnits?: MapData['defaultUnits'],
  dataSetName?: string,
  options: {
    outPrefix?: string,
    apiPrefix?: string,
    overrideFields?: Partial<GetFields<InventoryApi, Inventory, MapData, 'output' | 'item'>>,
  } = {},
): Inventory {
  const fields = options.overrideFields ?? getFields(dataSetName)
  const _options = {
    defaultData: parse({}, { fields, defaultUnits }),
    fields,
    dataSetName,
    defaultUnits,
    ...options,
  }

  return parse(inventory, _options)
}

export function parseOutput(output: OutputApi, { defaultUnits }: MapData): Output {
  const options = {
    defaultData: parse({}, { fields: initialState.outputFields, defaultUnits }),
    fields: initialState.outputFields,
    dataSetName: outputDataSetName,
    defaultUnits,
  }

  return parse(output, options)
}

const statusSortOrders: InventoryStatus[] = ['creating', 'ordered', 'to-order', 'ready']

export function getStatusSortOrder(status: InventoryStatus) {
  const statusOrder = statusSortOrders.indexOf(status)
  return statusOrder > -1 ? statusOrder : statusSortOrders.length
}

export function getOppositeStatus(status: InventoryStatus): InventoryStatus {
  return status === 'ready' ? 'to-return' : 'ready'
}

export function getOutputUnit(output: any = {}, options: MapData) {
  const dimension = output.template_dimension_to_display
  return output.measure_unit || options?.defaultUnits?.[dimension]
}

export async function fetchInventoryExport(data = {}, mapData = {}, pageSize = 100) {
  const count = await _fetchInventoriesCount(data)
  const page = Math.ceil(count/pageSize)
  const inventories = []

  for (let i = 0; i < page; ++i) {
    const result = await _fetchInventories({ ...data, offset: i*pageSize, topValue: pageSize }, mapData)
    inventories.push(...result)
  }
  return inventories
}

export function fetchInventoriesCount(data = {}) {
  return async function fetchInventoriesCountThunk(dispatch: AppDispatch) {
    const count = await _fetchInventoriesCount(data)
    dispatch({ type: GET_INVENTORIES_COUNT, payload: count })
    return count
  }
}

export async function _fetchInventoriesCount(data = {}) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(parseFetchInventoriesData(data)),
  }

  let count = 0

  try {
    const result = await (await safeFetch('/new_api/inventories/raw-material-count', requestOptions)).json()
    if (result.isSuccess) {
      count = +result.result[0].count || 0
    }
  } catch (err) {
    console.error(err)
  }

  return count
}

export async function _fetchInventories(data: any = {}, mapData: MapData) {
  const offset = data.offset
  const topValue = data.topValue
  const orderByList = JSON.parse(data.orderByList || '[]').map((orderBy: any) => {
    orderBy.dataSetName = data.dataSetName || 'inventory_view'
    return orderBy
  })
  const parsedData = parseFetchInventoriesData(data)
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      ...parsedData,
      offset,
      topValue,
      orderByList,
      dataSetName: data.dataSetName || 'inventory_view',
    }),
  }
  let inventories = []

  try {
    const { isSuccess, result } = await safeFetchJson<InventoryApi>(
      '/new_api/inventories/raw-materials/fetch',
      requestOptions,
    )
    if (isSuccess && !isJob(result)) {
      inventories = result.map((inventory) =>
        parseInventory(inventory, mapData?.defaultUnits),
      )
    }
  } catch (err) {
    console.error(err)
  }

  return inventories
}

export async function fetchInventoryIndex(id: string, data: any) {
  let index = 0

  if (id) {
    try {
      const result = await (await safeFetch(buildGetUrl(`/new_api/inventories/raw-materials/${id}/index`, data))).json()
      if (result.isSuccess) {
        index = +result.result || 0
      }
    } catch (err) {
      console.error(err)
    }
  }

  return index
}

export function fetchInventories(data: any = {}, mapData: any = {}) {
  return async function fetchInventoriesThunk(dispatch: AppDispatch) {
    const inventories = await _fetchInventories(data, mapData)
    if (!mapData.skipDispatch) dispatch({ type: GET_INVENTORIES, payload: inventories })
    return inventories
  }
}

export async function fetchInventoryByIds(
  ids: string[],
  mapData?: MapData,
  data?: any,
  method: string = 'GET',
) {
  if (!ids?.length) return []

  let result
  if (method === 'GET') {
    result = await safeFetchJson(
      buildGetUrl(`/new_api/inventories/raw-materials/${ids}`, data),
    )
  } else if (method === 'POST') {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ids, ...data }),
    }
    result = await (await safeFetch(`/new_api/inventories/raw-materials/fetch`, requestOptions)).json()
  }

  return parsedResultOnSucessOrEmtpy(result, (inventory) =>
    parseInventory(inventory, mapData?.defaultUnits),
  )
}

export async function fetchRelatedCards(id: string) {
  let relatedCards

  if (id) {
    try {
      const { isSuccess, result } = await safeFetchJson(
        `/new_api/inventories/raw-materials/${id}/related-items`,
      )
      if (isSuccess) {
        relatedCards = result
      }
    } catch (err) {}
  }

  return relatedCards || []
}

export async function fetchLedgers(data = {}) {
  let ledgers

  try {
    const { isSuccess, result } = await safeFetchJson(
      buildGetUrl(`/new_api/inventories/consumptions/ledgers`, data),
    )
    if (isSuccess) {
      ledgers = result
    }
  } catch (err) {}

  return ledgers || []
}

export async function fetchInventoryOutputs(data: any, mapData: any = {}) {
  let outputs = []

  try {
    const result = await (
      await safeFetch(buildGetUrl('/new_api/inventories/outputs', data))
    ).json()
    if (result.isSuccess) {
      outputs = result.result.map((output: any) => parseOutput(output, mapData))
    }
  } catch (err) {
    console.error(err)
  }

  return outputs
}

export async function fetchInventoryOutputsById(ids:string[], mapData: any = {}) {
  let outputs = []

  try {
    const result = await (
      await safeFetch(buildGetUrl(`/new_api/inventories/outputs/${ids}`))
    ).json()
    if (result.isSuccess) {
      outputs = result.result.map((output: any) => parseOutput(output, mapData))
    }
  } catch (err) {
    console.error(err)
  }

  return outputs
}

export function createInventories(data: any, nbCopy: number, mapData: MapData) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data, nbCopy }),
  }
  return async function createInventoriesThunk(dispatch: AppDispatch) {
    try {
      const result = await (
        await safeFetch(`/new_api/inventories/raw-materials`, requestOptions)
      ).json()

      const payload = result.isSuccess ?
        result.result.map((inventory: any) =>
          parseInventory(inventory, mapData?.defaultUnits),
        ) :
        []
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: CREATE_INVENTORIES, payload, error })
      return { inventories: payload, isSuccess: result.isSuccess }
    } catch (error) {
      dispatch({ type: CREATE_INVENTORIES, error })
      return { inventories: [], isSuccess: false }
    }
  }
}

export function updateInventories(ids: string[], data: any, mapData: MapData = {}) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data }),
  }
  return async function updateInventoriesThunk(dispatch: AppDispatch) {
    let _error: any
    let _inventories = []
    let _isSuccess = true

    try {
      const { isSuccess, result } = await safeFetchJson<InventoryApi>(
        `/new_api/inventories/raw-materials/${ids}`,
        requestOptions,
      )

      _isSuccess = isSuccess

      if (isSuccess && !isJob(result)) {
        _inventories = result.map((inventory) =>
          parseInventory(inventory, mapData?.defaultUnits),
        )
      } else if (!isSuccess) {
        _error = result
      }
    } catch (error) {
      _isSuccess = false
      _error = error
    }

    dispatch({ type: UPDATE_INVENTORIES, payload: _inventories, error: _error })
    return { inventories: _inventories, isSuccess: _isSuccess }
  }
}

export async function _updateInventories(ids: string[], data: Partial<InventoryApi>[], mapData: MapData = {}) {
  const dataObject = data.map((entity) => {
    return Object.entries(entity).map(([column, value]) => {
      return { column, value }
    })
  })[0] // TODO (slalancette) : The alix-core route only supports one update at a time, change this when needed

  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: dataObject }),
  }

  const response = await safeFetchJson<InventoryApi>(
    `/new_api/inventories/raw-materials/${ids}`,
    requestOptions,
  )

  return parsedResultOnSucessOrEmtpy(response, parseInventory, mapData?.defaultUnits)
}

export async function fetchTransactionCount(ids: string[]) {
  let transactionCount = 0

  try {
    const result = await (
      await safeFetch(`/new_api/inventories/raw-materials/${ids}/ledgers/count`)
    ).json()
    if (result.isSuccess) {
      transactionCount = +result.result[0]?.count || 0
    }
  } catch (error) {
    console.error(error)
  }

  return transactionCount
}

export async function fetchTransactionBatchCount(ids: string[]) {
  let transactionCount = 0
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ids }),
  }

  try {
    const result = await (
      await safeFetch(`/new_api/inventories/raw-materials/batch/ledgers/count`, requestOptions)
    ).json()
    if (result.isSuccess) {
      transactionCount = +result.result[0]?.count || 0
    }
  } catch (error) {
    console.error(error)
  }

  return transactionCount
}

export function wasteInventories(ids: string[]) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: [{ column: 'status', value: 'done' }] }),
  }

  return async function wasteInventoriesThunk(dispatch: AppDispatch) {
    let isSuccess = false

    try {
      const result = await (
        await safeFetch(
          `/new_api/inventories/raw-materials/${ids}`,
          requestOptions,
        )
      ).json()
      isSuccess = !!result.isSuccess
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: WASTE_INVENTORIES, payload: isSuccess, error })
      return result
    } catch (error) {
      dispatch({ type: WASTE_INVENTORIES, payload: false, error })
    }

    return isSuccess
  }
}

export function wasteInventoriesBatch(ids: string[]) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: ids.map((id) => {
      return { id, status: 'done' }
    }) }),
  }

  return async function wasteInventoriesBatchThunk(dispatch: AppDispatch) {
    let isSuccess = false

    try {
      const result = await (
        await safeFetch(
          `/new_api/inventories/raw-materials/batch`,
          requestOptions,
        )
      ).json()
      isSuccess = !!result.isSuccess
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: WASTE_INVENTORIES, payload: isSuccess, error })
      return result
    } catch (error) {
      dispatch({ type: WASTE_INVENTORIES, payload: false, error })
    }

    return { isSuccess }
  }
}

export function deleteInventories(ids: string[]): any {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  }
  return async function deleteInventoriesThunk(dispatch: AppDispatch) {
    try {
      const result = await (
        await safeFetch(
          `/new_api/inventories/raw-materials/${ids}`,
          requestOptions,
        )
      ).json()
      const error = !result.isSuccess ? result.result : null
      dispatch({
        type: DELETE_INVENTORIES,
        payload: !!result.isSuccess,
        error,
      })
      return result
    } catch (error) {
      dispatch({ type: DELETE_INVENTORIES, payload: false, error })
      return { error, isSuccess: false }
    }
  }
}

export function deleteInventoriesBatch(ids: string[]): any {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ids }),
  }
  return async function deleteInventoriesBatchThunk(dispatch: AppDispatch) {
    try {
      const result = await (
        await safeFetch(
          `/new_api/inventories/raw-materials/batch`,
          requestOptions,
        )
      ).json()
      const error = !result.isSuccess ? result.result : null
      dispatch({
        type: DELETE_INVENTORIES,
        payload: !!result.isSuccess,
        error,
      })
      return result
    } catch (error) {
      dispatch({ type: DELETE_INVENTORIES, payload: false, error })
      return { error, isSuccess: false }
    }
  }
}

export async function mergeInventories(masterId: any, mergedIds: any) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      masterId: masterId,
      mergedIds: mergedIds.filter((id) => id != masterId),
    }),
  }

  try {
    return await (
      await safeFetch(
        `/new_api/inventories/raw-materials/merge`,
        requestOptions,
      )
    ).json()
  } catch (error) {
    return parseError(error)
  }
}

export async function splitInventories(splitDict: any) {
  const inventoryId = Object.keys(splitDict)[0]
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: splitDict[inventoryId] }),
  }

  try {
    return await (
      await safeFetch(
        `/new_api/inventories/raw-materials/${inventoryId}/split`,
        requestOptions,
      )
    ).json()
  } catch (error) {
    return parseError(error)
  }
}

export async function returnInventoriesFromCustomer(data: any) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data }),
  }
  try {
    return await(await safeFetch(`/new_api/inventories/raw-materials/return`, requestOptions)).json()
  } catch (error) {
    return { isSuccess: false, result: error }
  }
}

export async function returnInventoriesToVendor(ids: string[], returnNumber: string, returnDate: string) {
  const data = [
    { column: 'status', value: 'returned' },
    { column: 'return_number', value: returnNumber },
    { column: 'return_date', value: returnDate },
  ]
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: data }),
  }

  try {
    return await(await safeFetch(`/new_api/inventories/raw-materials/${ids}`, requestOptions)).json()
  } catch (error) {
    return { isSuccess: false, result: error }
  }
}

export async function returnInventoriesToVendorBatch(ids: string[], returnNumber: string, returnDate: string) {
  const data = ids.map((id) => {
    return { id, status: 'returned', return_number: returnNumber, return_date: returnDate }
  })
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: data }),
  }

  try {
    return await(await safeFetch(`/new_api/inventories/raw-materials/batch`, requestOptions)).json()
  } catch (error) {
    return { isSuccess: false, result: error }
  }
}

export function clearInventories(dispatch: AppDispatch) {
  dispatch({ type: CLEAR_INVENTORIES })
}

function getConvertedReservedMeasure(inventory: Inventory, options: MapData) {
  return +convertFromBase(
    inventory.dimension?.toString(),
    +inventory.reservedMeasure,
    getMeasureUnit(inventory, options),
    true,
  )
}

export function getBaseNetFlow(inventory: Inventory) {
  const currentMeasure = +inventory.currentMeasure || 0
  const reservedMeasure = +inventory.reservedMeasure || 0
  const onOrderMeasure = +inventory.onOrderMeasure || 0
  return currentMeasure - reservedMeasure + onOrderMeasure
}

function getConvertedNetFlowMeasure(inventory: Inventory, options: MapData) {
  const netFlow = getBaseNetFlow(inventory)

  return +convertFromBase(
    inventory.dimension?.toString(),
    netFlow,
    getMeasureUnit(inventory, options),
    true,
  )
}

function parsePlannedUnitCost(inventory: Inventory, options: MapData) {
  const dimension = inventory.dimension
  const plannedUnitCost = +inventory.basePlannedUnitCost || 0
  return convertFromDollarPerBase(
    dimension?.toString(),
    plannedUnitCost,
    getMeasureUnit(inventory, options),
  )
}

function parseRealUnitCost(inventory: Inventory, options: MapData) {
  const dimension = inventory.dimension
  const realUnitCost = Number(inventory.baseRealUnitCost || 0)
  return convertFromDollarPerBase(
    dimension?.toString(),
    realUnitCost,
    getMeasureUnit(inventory, options),
  )
}

function parseInitialMeasure(inventory: Inventory, options: MapData) {
  return +convertFromBase(
    inventory.dimension?.toString(),
    +inventory.initialMeasure,
    getMeasureUnit(inventory, options),
    true,
  )
}

function getDisplayLocationTag(inventory: Inventory) {
  return `${inventory.locationCode ? `${inventory.locationCode} - ` : ''}${inventory.tag}`
}

function parseCurrentMeasure(inventory: Inventory, options: MapData) {
  return +convertFromBase(
    inventory.dimension?.toString(),
    +inventory.currentMeasure,
    getMeasureUnit(inventory, options),
    true,
  )
}

function parseMeasure(inventory, measure, options: MapData) {
  return +convertFromBase(
    inventory.dimension,
    +measure,
    getMeasureUnit(inventory, options),
    true,
  )
}

export function getBaseAvailableMeasure(inventory: Inventory) {
  const currentMeaure = +inventory.currentMeasure || 0
  const activePlannedLedgerMeasure = +inventory.reservedMeasure || 0
  return currentMeaure - activePlannedLedgerMeasure
}

function parseAvailableMeasure(inventory: Inventory, options: MapData) {
  const dimension = inventory.dimension
  return +convertFromBase(
    dimension?.toString(),
    getBaseAvailableMeasure(inventory),
    getMeasureUnit(inventory, options),
    true,
  )
}

function parseBaseCost(inventory: Inventory, options: MapData) {
  const dimension = inventory.dimension
  return convertFromDollarPerBase(
    dimension?.toString(),
    +(inventory.baseRealUnitCost || 0) * +(inventory.vendorExchangeRate || 1),
    getMeasureUnit(inventory, options),
  )
}

function parseListPriceCost(inventory: Inventory, options: MapData) {
  const dimension = inventory.dimension
  return convertFromDollarPerBase(
    dimension?.toString(),
    +inventory.vendorListPrice,
    getMeasureUnit(inventory, options),
  )
}

function _getSingleToBaseOfParsedInventory(inventory: Inventory, defaultUnits: MapData['defaultUnits']) {
  const dimension = inventory.dimension
  const unit = inventory.measureUnit || defaultUnits.weight

  return convertToBase(dimension?.toString(), 1, unit)
}

function _parseUnitWeightFromValue(value: any, inventory: Inventory, defaultUnits: MapData['defaultUnits']) {
  const dimension = inventory.dimension

  if (dimension === 'weight') {
    return 0
  }

  const weightUnit = inventory.weightUnit || defaultUnits.weight
  const singleToBase = _getSingleToBaseOfParsedInventory(inventory, defaultUnits)

  return +convertFromBase('weight', +value, weightUnit, true) * singleToBase
}

function _convertTareWeightFromValue(value: any, inventory: Inventory, defaultUnits: MapData['defaultUnits']) {
  return convertFromBase(
    'weight',
    +value,
    inventory.weightUnit || defaultUnits.weight,
    true,
  )
}

function parseCondition(conditionObj = '[]') {
  return JSON.parse(conditionObj).map((condition: any) => {
    condition.dataSetName = 'inventory_view'
    return condition
  })
}

function parseSelectList(selectList) {
  return selectList.map((columnName: any) => {
    return { column: columnName }
  })
}

function parseSearchColumns(data: any) {
  const columns = data.searchColumns || '[]'
  return JSON.parse(columns).map((column: any) => {
    column.dataSetName = data.dataSetName || 'inventory_view'
    return column
  })
}

function parseFetchInventoriesData(data: any = {}) {
  const conditionObj = parseCondition(data.conditionObj)
  let searchColumns
  if (data.searchColumns) {
    searchColumns = parseSearchColumns(data)
  }
  let selectList
  if (data.selectList) {
    selectList = parseSelectList(data.selectList)
  }

  return {
    ...data,
    conditionObj,
    searchColumns,
    selectList,
    dataSetName: data.dataSetName || 'inventory_view',
  }
}

export function getInventoryTitle(entityData: Inventory) {
  return entityData.tag
}

export function getOutputTitle(entityData: Output) {
  return [entityData.cardNumber, entityData.cardTitle, entityData.tag].filter((str) => !!str).join(' - ')
}

export type InventoryLedgerApi = {
  id: string
  relation_id: string
  inventory_id: InventoryApi['id']
  inventory_measure_unit: InventoryApi['measure_unit']
  inventory_tag: InventoryApi['tag']
  inventory_status: InventoryApi['status']
  reception_id: ReceptionApi['id']
  reception_name: ReceptionApi['name']
  planned_measure: number
  measure: number
  measure_unit: string
  dimension_to_display: string
  name: string
  purchase_order_item_id: string
  consignment_item_id: string
  reception_item_id: string
  status: 'active' | 'resolved' | 'canceled'
  planned_type: (
    'sales' | 'consumption' | 'purchase' | 'loose_demand' | 'loose_supply' | 'on_hand' | 'production' | 'reception'
  )
}

type InventoryLedgerExceptions = {
  inventory_id: 'id'
  id: 'plannedLedgerId'
  planned_measure: 'currentMeasure'
  dimension_to_display: 'dimension'
  inventory_tag: 'tag'
  reception_name: 'receptionNumber'
}

export type InventoryLedger = Modify<ApiToSlice<InventoryLedgerApi, InventoryLedgerExceptions>, {
  activePlannedLedgerReceptionItemId: string | null
}>

export function parseInventoryLedger(inventoryLedger: InventoryLedgerApi, options: MapData): InventoryLedger {
  function getInventoryLedgerUnit(ledger: InventoryLedgerApi, options: MapData) {
    const dimension = ledger?.dimension_to_display
    return ledger?.inventory_measure_unit || options?.defaultUnits?.[dimension]
  }

  const getFields = (): GetFields<InventoryLedgerApi, InventoryLedger, MapData, 'inventory' | 'reception'> => ({
    'plannedLedgerId': { dataSetName: 'plannedLedger', dbField: 'id', type: 'id', relationEntity: 'planned-ledgers' },
    'id': { dataSetName: 'inventory', dbField: 'id', type: 'id', relationEntity: 'inventories' },
    'currentMeasure': {
      dataSetName: 'plannedLedger',
      dbField: 'planned_measure',
      type: 'measure',
      parse: (data) => parsePlannedLedgerCurrentMeasure(data),
    },
    'measureUnit': {
      dataSetName: 'plannedLedger',
      dbField: 'measure_unit',
      parse: (data, options) => data?.measure_unit || getInventoryLedgerUnit(data, options),
    },
    'dimension': { dataSetName: 'plannedLedger', dbField: 'dimension_to_display' },
    'status': { dataSetName: 'plannedLedger', dbField: 'status' },
    'plannedType': { dataSetName: 'plannedLedger', dbField: 'planned_type' },
    'name': { dataSetName: 'plannedLedger', dbField: 'name' },
    'purchaseOrderItemId': {
      dataSetName: 'plannedLedger',
      dbField: 'purchase_order_item_id',
      type: 'id',
      relationEntity: 'purchase-order-items',
    },
    'consignmentItemId': {
      dataSetName: 'plannedLedger',
      dbField: 'consignment_item_id',
      type: 'id',
      relationEntity: 'consignment-items',
    },
    'tag': { dataSetName: 'inventory', dbField: 'tag' },
    'receptionId': { dataSetName: 'reception', dbField: 'id' },
    'receptionNumber': { dataSetName: 'reception', dbField: 'name' },
    'inventoryStatus': { dataSetName: 'inventory', dbField: 'status' },
    'activePlannedLedgerReceptionItemId': {
      type: 'id',
      relationEntity: 'reception-items',
      parse: (data) => data.status === 'active' ? data.reception_item_id : null,
    },
  })

  const _options = {
    ...options,
    dataSetName: 'plannedLedger',
    fields: getFields(),
  }

  return parse(inventoryLedger, _options)
}

function parsePlannedLedgerCurrentMeasure(inventoryLedger: InventoryLedgerApi) {
  return inventoryLedger.status === 'resolved' ? inventoryLedger.measure : inventoryLedger.planned_measure
}

export const getInventoryDropdownOptions = <T extends E = E>({
  getFetchDataColumns,
  mapData,
}: {
  getFetchDataColumns: (currentFormValues: T) => {
    templateId: string,
    plantId: string,
    _includeNullPlant: boolean,
    _includeId?: Inventory['id'],
  },
  mapData: MapData
}): SmartFormOptionObject<T> => {
  return {
    key: 'inventories',
    fetchOnFirstOpen: true,
    fetcher: (fetchData) => _fetchInventories(fetchData, mapData),
    countFetcher: _fetchInventoriesCount,
    indexFetcher: fetchInventoryIndex,
    fields: initialState.fields,
    filterFieldKeys: ['locationCode', 'tag'],
    orderByFieldKeys: [
      {
        key: 'modifiedDate',
        direction: 'DESC',
      },
      {
        key: 'id',
      },
    ],
    isLazy: true,
    getFetchData: (currentFormValues) => {
      const {
        templateId,
        plantId,
        _includeNullPlant,
        _includeId,
      } = getFetchDataColumns(currentFormValues)

      return {
        templateId: stringIfDefined(templateId),
        status: 'ready',
        plantId: plantId,
        _includeNullPlant,
        _includeId,
      }
    },
  }
}

export const receiveActionFields = [
  'id',
  'purchaseOrderItemId',
  'consignmentItemId',
  'status',
  'vendorName',
  'salesOrderCustomerId',
  'vendorId',
  'receptionItemId',
] satisfies (keyof Inventory)[]
export type ReceiveActionInventoryFields = typeof receiveActionFields[number]
export type ReceiveActionInventory = Pick<Inventory, ReceiveActionInventoryFields>

const _actionInventoryFields = [
  'id',
  'status',
  'vendorId',
  'vendorName',
  'invoice',
  'plannedOutputId',
  'purchaseOrderItemId',
  'receptionId',
  'templateId',
  'item',
  'projectId',
  'isPrintable',
  'currentMeasure',
  'initialMeasure',
  'createdDate',
  'dimension',
  'measureUnit',
  'tag',
  'realUnitCost',
  'expectedDeliveryDate',
  'plannedTargetInventoryId',
  'locationId',
  'exist',
  ...receiveActionFields,
] satisfies (keyof Inventory)[]

export const actionInventoryFields = Array.from(new Set(_actionInventoryFields)) satisfies (keyof Inventory)[]

export type ActionInventoryFields = typeof actionInventoryFields[number]
export type ActionInventory = Pick<Inventory, ActionInventoryFields>
