import { ADD_POPUP, REMOVE_POPUP } from './types'

const initialState = {
  popups: [],
}

export default function popupsReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
  case ADD_POPUP: {
    return {
      ...state,
      popups: [
        ...state.popups,
        payload,
      ],
    }
  }
  case REMOVE_POPUP: {
    return {
      ...state,
      popups: state.popups.filter((popup) => popup.id != payload),
    }
  }
  default: {
    return state
  }
  }
}

export function addPopup(popup) {
  return function addPopupThunk(dispatch) {
    dispatch({ type: ADD_POPUP, payload: popup })
  }
}

export function removePopup(popupId) {
  return function removePopupThunk(dispatch) {
    dispatch({ type: REMOVE_POPUP, payload: popupId })
  }
}
