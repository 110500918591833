import { buildGetUrl, parse } from 'utils/api'
import { getFirstString } from 'utils/getFirstOfType'
import { safeFetch, safeFetchJson, objectToInsertData, parsedResultOnSucessOrEmtpy } from 'utils/safeFetch'

import {
  buildReportingTagsFormState,
  buildReportingTagsFormStateWithDeletions,
  buildReportingTagsState,
  getDefaultReportingTagsForm,
  getReportingTagFields,
  parseReportingTag,
} from 'reducers/reporting-tags/reportingTagAssociationSlice'

import {
  GET_CARDS_COUNT,
  GET_CARDS,
  SET_CARD_REPORTING_TAGS_FORM,
  INIT_CARD_REPORTING_TAGS_FORM,
  SET_CARD_REPORTING_TAGS_FORM_SELECTION,
  DELETE_CARD_REPORTING_TAGS_FROM_SELECTION,
} from './types'

const dataSetName = 'item'
const entityName = 'cards'
const stepDataSetName = 'steps'
const attributeDataSetName = 'templateView'

const projectAlias = 'project'
const clientAlias = 'client'

const initialState = {
  dataSetName,
  fields: getFields(),
  cards: [],
  cardsCount: 0,
  reportingTagFields: getReportingTagFields(entityName),
  reportingTagsForm: getDefaultReportingTagsForm(entityName),
}

export default function cardsReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
  case GET_CARDS_COUNT: {
    return {
      ...state,
      cardsCount: payload,
    }
  }
  case GET_CARDS: {
    return {
      ...state,
      cards: payload,
    }
  }
  case SET_CARD_REPORTING_TAGS_FORM: {
    return buildReportingTagsFormState(state, payload)
  }
  case INIT_CARD_REPORTING_TAGS_FORM: {
    return buildReportingTagsState(state, payload, entityName)
  }
  case SET_CARD_REPORTING_TAGS_FORM_SELECTION: {
    return buildReportingTagsFormState(state, { ...state.reportingTagsForm.reportingTags, selections: payload })
  }
  case DELETE_CARD_REPORTING_TAGS_FROM_SELECTION: {
    return buildReportingTagsFormStateWithDeletions(state)
  }
  default: {
    return state
  }
  }
}

function getCostFields() {
  const dataSetName = ''
  return {
    'manufacturingOrderId': { dataSetName, dbField: 'manufacturing_order_id' },
    'currency_code': { dataSetName, dbField: 'sales_currency_code' },
    'unit': { dataSetName, dbField: 'measure_unit' },
    'dimension': { dataSetName, dbField: 'dimension_to_display' },
    // #region plannedCost
    'plannedCost.employee.resultUnitary': { dataSetName, dbField: 'resource_planned_cost_unitary', defaultValue: 0 },
    'plannedCost.employee.result': { dataSetName, dbField: 'resource_planned_cost', defaultValue: 0 },

    'plannedCost.equipment.resultUnitary': { dataSetName, dbField: 'equipment_planned_cost_unitary', defaultValue: 0 },
    'plannedCost.equipment.result': { dataSetName, dbField: 'equipment_planned_cost', defaultValue: 0 },

    'plannedCost.material.resultUnitary': { dataSetName, dbField: 'material_planned_cost_unitary', defaultValue: 0 },
    'plannedCost.material.result': { dataSetName, dbField: 'material_planned_cost', defaultValue: 0 },

    'plannedCost.subcontract.resultUnitary': {
      dataSetName,
      dbField: 'subcontract_planned_cost_unitary',
      defaultValue: 0,
    },
    'plannedCost.subcontract.result': { dataSetName, dbField: 'subcontract_planned_cost', defaultValue: 0 },

    'plannedCost.total.resultUnitary': { dataSetName, dbField: 'planned_cost_price_unitary', defaultValue: 0 },
    'plannedCost.total.result': { dataSetName, dbField: 'planned_cost_price', defaultValue: 0 },
    // #endregion
    // #region realCost
    'realCost.employee.resultUnitary': { dataSetName, dbField: 'resource_real_cost_unitary', defaultValue: 0 },
    'realCost.employee.result': { dataSetName, dbField: 'resource_real_cost', defaultValue: 0 },
    'realCost.employee.variance': { dataSetName, dbField: 'resource_real_cost_var', defaultValue: 0 },
    'realCost.employee.variancePct': { dataSetName, dbField: 'resource_real_cost_var_pct', defaultValue: 0 },

    'realCost.equipment.resultUnitary': { dataSetName, dbField: 'equipment_real_cost_unitary', defaultValue: 0 },
    'realCost.equipment.result': { dataSetName, dbField: 'equipment_real_cost', defaultValue: 0 },
    'realCost.equipment.variance': { dataSetName, dbField: 'equipment_real_cost_var', defaultValue: 0 },
    'realCost.equipment.variancePct': { dataSetName, dbField: 'equipment_real_cost_var_pct', defaultValue: 0 },

    'realCost.material.resultUnitary': { dataSetName, dbField: 'material_real_cost_unitary', defaultValue: 0 },
    'realCost.material.result': { dataSetName, dbField: 'material_real_cost', defaultValue: 0 },
    'realCost.material.variance': { dataSetName, dbField: 'material_real_cost_var', defaultValue: 0 },
    'realCost.material.variancePct': { dataSetName, dbField: 'material_real_cost_var_pct', defaultValue: 0 },

    'realCost.subcontract.resultUnitary': { dataSetName, dbField: 'subcontract_real_cost_unitary', defaultValue: 0 },
    'realCost.subcontract.result': { dataSetName, dbField: 'subcontract_real_cost', defaultValue: 0 },
    'realCost.subcontract.variance': { dataSetName, dbField: 'subcontract_real_cost_var', defaultValue: 0 },
    'realCost.subcontract.variancePct': { dataSetName, dbField: 'subcontract_real_cost_var_pct', defaultValue: 0 },

    'realCost.total.resultUnitary': { dataSetName, dbField: 'real_cost_price_unitary', defaultValue: 0 },
    'realCost.total.result': { dataSetName, dbField: 'real_cost_price', defaultValue: 0 },
    'realCost.total.variance': { dataSetName, dbField: 'real_cost_price_var', defaultValue: 0 },
    'realCost.total.variancePct': { dataSetName, dbField: 'real_cost_price_var_pct', defaultValue: 0 },
    // #endregion
    // #region plannedPrice
    'plannedPrice.employee.resultUnitary': { dataSetName, dbField: 'resource_planned_price_unitary', defaultValue: 0 },
    'plannedPrice.employee.result': { dataSetName, dbField: 'resource_planned_price', defaultValue: 0 },
    'plannedPrice.employee.markup': { dataSetName, dbField: 'resource_time_markup', defaultValue: 0 },
    'plannedPrice.employee.discount': { dataSetName, dbField: 'project_resource_discount', defaultValue: 0 },

    'plannedPrice.equipment.resultUnitary': {
      dataSetName, dbField: 'equipment_planned_price_unitary', defaultValue: 0,
    },
    'plannedPrice.equipment.result': { dataSetName, dbField: 'equipment_planned_price', defaultValue: 0 },
    'plannedPrice.equipment.markup': { dataSetName, dbField: 'equipment_time_markup', defaultValue: 0 },
    'plannedPrice.equipment.discount': { dataSetName, dbField: 'project_equipment_discount', defaultValue: 0 },

    'plannedPrice.material.resultUnitary': { dataSetName, dbField: 'material_planned_price_unitary', defaultValue: 0 },
    'plannedPrice.material.result': { dataSetName, dbField: 'material_planned_price', defaultValue: 0 },
    'plannedPrice.material.markup': { dataSetName, dbField: 'project_markup', defaultValue: 0 },
    'plannedPrice.material.discount': { dataSetName, dbField: 'project_material_discount', defaultValue: 0 },

    'plannedPrice.subcontract.resultUnitary': {
      dataSetName, dbField: 'subcontract_planned_price_unitary', defaultValue: 0,
    },
    'plannedPrice.subcontract.result': { dataSetName, dbField: 'subcontract_planned_price', defaultValue: 0 },
    'plannedPrice.subcontract.markup': { dataSetName, dbField: 'project_markup', defaultValue: 0 },
    'plannedPrice.subcontract.discount': { dataSetName, dbField: 'project_subcontract_discount', defaultValue: 0 },

    'plannedPrice.total.resultUnitary': { dataSetName, dbField: 'planned_selling_price_unitary', defaultValue: 0 },
    'plannedPrice.total.result': { dataSetName, dbField: 'planned_selling_price', defaultValue: 0 },
    // #endregion
    // #region realPrice
    'realPrice.total.resultUnitary': { dataSetName, dbField: 'real_selling_price_unitary', defaultValue: 0 },
    'realPrice.total.result': { dataSetName, dbField: 'real_selling_price', defaultValue: 0 },
    // #endregion
    // #region profit.planned
    'profit.planned.batch': { dataSetName, dbField: 'planned_profit_unitary', defaultValue: 0 },
    'profit.planned.profit': { dataSetName, dbField: 'planned_profit', defaultValue: 0 },
    'profit.planned.margin': { dataSetName, dbField: 'planned_profit_margin', defaultValue: 0 },
    'profit.planned.markup': { dataSetName, dbField: 'planned_profit_markup', defaultValue: 0 },
    // #endregion
    // #region profit.anticipated
    'profit.anticipated.batch': { dataSetName, dbField: 'anticipated_profit_unitary', defaultValue: 0 },
    'profit.anticipated.profit': { dataSetName, dbField: 'anticipated_profit', defaultValue: 0 },
    'profit.anticipated.margin': { dataSetName, dbField: 'anticipated_profit_margin', defaultValue: 0 },
    'profit.anticipated.markup': { dataSetName, dbField: 'anticipated_profit_markup', defaultValue: 0 },
    // #endregion
    // #region profit.real
    'profit.real.batch': { dataSetName, dbField: 'real_profit_unitary', defaultValue: 0 },
    'profit.real.profit': { dataSetName, dbField: 'real_profit', defaultValue: 0 },
    'profit.real.margin': { dataSetName, dbField: 'real_profit_margin', defaultValue: 0 },
    'profit.real.markup': { dataSetName, dbField: 'real_profit_markup', defaultValue: 0 },
    // #endregion
  }
}

export function getFields() {
  return {
    'id': { dataSetName, dbField: 'id' },
    'sku': { dataSetName, dbField: 'sku' },
    'createdDate': { dataSetName, dbField: 'created_date', type: 'timestamp' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'timestamp' },
    'createdBy': { dataSetName, dbField: 'created_by' },
    'modifiedBy': { dataSetName, dbField: 'modified_by' },
    'lastResource': { dataSetName, dbField: 'last_resource' },
    'projectId': { dataSetName: 'projectView', trimAlias: projectAlias, dbField: 'id' },
    'projectTitle': { dataSetName: 'projectView', trimAlias: projectAlias, dbField: 'title' },
    'projectFormattedNumber': { dataSetName: 'projectView', trimAlias: projectAlias, dbField: 'formated_number' },
    'projectYear': { dataSetName: 'projectView', trimAlias: projectAlias, dbField: 'year' },
    'projectClientOnLabel': { dataSetName: 'projectView', trimAlias: projectAlias, dbField: 'client_on_label' },
    'clientAddressId': { dataSetName: 'projectView', trimAlias: projectAlias, dbField: 'client_address_id' },
    'projectNumber': { dataSetName: 'projectView', trimAlias: projectAlias, dbField: 'number', type: 'integer' },
    'projectType': {
      dataSetName: 'projectView',
      trimAlias: projectAlias,
      dbField: 'type',
      type: 'enum',
      values: ['sales', 'standard', 'manufacturing'],
      translationPath: 'cards:projectType',
    },
    'projectOwnerId': { dataSetName: 'projectView', trimAlias: projectAlias, dbField: 'owner_id' },
    'projectOwnerName': { parse: (card) => `${card.project_owner_firstname} ${card.project_owner_lastname}` },
    'projectOwnerEmail': { dataSetName: 'projectView', trimAlias: projectAlias, dbField: 'owner_email' },
    'projectOwnerPhone': { dataSetName: 'projectView', trimAlias: projectAlias, dbField: 'owner_phone' },
    'clientId': { dataSetName: 'projectView', trimAlias: 'client', dbField: 'id', useTrimAliasInField: true },
    'clientDisplayName': {
      dataSetName: 'projectView',
      trimAlias: clientAlias,
      dbField: 'display_name',
      useTrimAliasInField: true,
    },
    'clientLanguage': {
      dataSetName: 'projectView',
      trimAlias: clientAlias,
      dbField: 'language',
      useTrimAliasInField: true,
    },
    'projectPromisedDate': {
      dataSetName: 'projectView',
      trimAlias: projectAlias,
      dbField: 'promised_date',
      type: 'date',
    },
    'salesOrderItemId': { dataSetName, dbField: 'sales_order_item_id' },
    'iconFileName': { dataSetName, dbField: 'icon_filename' },
    'iconLabel': { dataSetName, dbField: 'icon_label' },
    'instructions': { dataSetName, dbField: 'instructions' },
    'formattedNumber': { dataSetName, dbField: 'formated_number' },
    'number': { dataSetName, dbField: 'number' },
    'itemType': {
      dataSetName,
      dbField: 'item_type',
      type: 'enum',
      values: ['so', 'mo'],
      translationPath: 'cards:types',
    },
    'type': { dataSetName, dbField: 'type' },
    'status': {
      dataSetName,
      dbField: 'status',
      type: 'status',
      dictionaryKey: 'card',
      translationPath: 'cards:status',
      values: ['1', '2', '3', '4', '5', '6', '7'],
    },
    'qtyPlanned': { dataSetName, dbField: 'qty_planned' },
    'qtyReal': { dataSetName, dbField: 'qty_real' },
    'tareWeight': { dataSetName, dbField: 'weight_tare' },
    'netWeight': { dataSetName, dbField: 'weight_net' },
    'netWeightUnit': { dataSetName, dbField: 'weight_net_unit' },
    'grossWeightUnit': { dataSetName, dbField: 'weight_gross_unit' },
    'partNumber': { dataSetName, dbField: 'part_number' },
    'revision': { dataSetName, dbField: 'revision' },
    'title': { dataSetName, dbField: 'title' },
    'secondaryTitle': { dataSetName, dbField: 'secondary_title' },
    'shipmentFormattedTitle': { dataSetName, dataSetAlias: 'calculated_shipment', dbField: 'formatted_title' },
    'shipmentId': { dataSetName: 'shipmentView', dbField: 'id', relationEntity: 'shipments', dataSetAlias: 'shipment' },
    'currentStepTitle': { dataSetName: stepDataSetName, dataSetAlias: 'current_step', dbField: 'title' },
    'currentStepWipOf': { dataSetName: stepDataSetName, dataSetAlias: 'current_step', dbField: 'wipof' },
    'currentStepVsId': { dataSetName: stepDataSetName, dataSetAlias: 'current_step', dbField: 'vsid' },
    'currentStepId': { dataSetName: stepDataSetName, dataSetAlias: 'current_step', dbField: 'id' },
    'currentStepStepId': { dataSetName: stepDataSetName, dataSetAlias: 'current_step', dbField: 'stepid' },
    'promisedDate': { dataSetName, dbField: 'promised_date', type: 'date', isTimezoned: false },
    'locationCode': { dataSetName: 'location', dbField: 'code' },
    'tag': { dataSetName, dbField: 'tag' },
    'profit': { dataSetName, dbField: 'profit' },
    'profitByUnit': { dataSetName, dbField: 'profit_by_unit' },
    'grossMargin': { dataSetName, dbField: 'gross_margin' },
    'salesMarkup': { dataSetName, dbField: 'sales_markup' },
    'plantId': { dataSetName, dbField: 'plant_id' },
    'cost': { parse: (value) => parseCost(value.cost) },
    'salesPriceComputMode': { dataSetName, dbField: 'sales_price_compute_mode' },
    'reportingTagsCount': { dataSetName, parse: (card) => card.reportingTags?.length || 0 },
    'reportingTags': {
      dataSetName,
      dbField: 'reportingTags',
      type: 'array',
      parse: (card) =>
        card.reportingTags?.map((reportingTag) => parseReportingTag(entityName, reportingTag)) ?? [],
    },
    'instructions': { dataSetName, dbField: 'instructions' },
    'trimmedInstructions': { dataSetName, dbField: 'trimmed_instructions' },
    'improvementId': { dataSetName, dbField: 'improvement_id' },
    'attributeId': { dataSetName: attributeDataSetName, dbField: 'attribute_id' },
    'attributeDescription': {
      dataSetName: attributeDataSetName,
      dbField: 'attribute_description',
      headerOptions: { language: 'primaryLanguage' },
      customFieldTranslationKey: (t, options) => {
        return t('cards:card.fields.attribute.description', { language: options.primaryLanguage })
      },
    },
    'attributeSecondaryDescription': {
      dataSetName: attributeDataSetName,
      dbField: 'attribute_secondary_description',
      headerOptions: { language: 'secondaryLanguage' },
      customFieldTranslationKey: (t, options) => {
        return t('cards:card.fields.attribute.secondaryDescription', { language: options.secondaryLanguage })
      },
    },
    'attributeOptionId': { dataSetName: attributeDataSetName, dbField: 'attribute_option_id' },
    'attributeOptionDisplayDescription': {
      dataSetName: attributeDataSetName,
      dbField: 'attribute_option_display_description',
      customFieldTranslationKey: (t, options) => {
        return t('cards:card.fields.attribute.option.description', { language: options.primaryLanguage })
      },
    },
    'attributeOptionDisplaySecondaryDescription': {
      dataSetName: attributeDataSetName,
      dbField: 'attribute_option_display_secondary_description',
      customFieldTranslationKey: (t, options) => {
        return t('cards:card.fields.attribute.option.description', { language: options.secondaryLanguage })
      },
    },
    'attributeOptionColor': { dataSetName: attributeDataSetName, dbField: 'attribute_option_color' },
    'sequence': {
      dataSetName,
      dbField: 'sequence',
      type: 'string',
      maxLength: 10,
    },
  }
}

export function fetchCardsCount(data) {
  return async function fetchCardsCountThunk(dispatch) {
    const count = await _fetchCardsCount(data)
    dispatch({ type: GET_CARDS_COUNT, payload: count })
    return count
  }
}

export async function _fetchCardsCount(data) {
  try {
    const result = await(await safeFetch(buildGetUrl('/new_api/items/count', data))).json()
    if (result.isSuccess) {
      const count = +result.result[0].count || 0
      return count
    }

    return 0
  } catch (err) {
    console.error(err)
    return 0
  }
}

export async function moveCardInit(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }
  let contextResult = []
  try {
    const result = await (await safeFetch(`/new_api/items/moves/init`, requestOptions)).json()
    contextResult = result
  } catch (err) {
    console.error(err)
  }
  return contextResult
}

export async function moveCard(data, moveId) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }
  try {
    const result = await (await safeFetch(`/new_api/items/moves/${moveId}`, requestOptions)).json()
    return result
  } catch (err) {
    console.error(err)
  }
}

export async function deleteCards(ids) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  }
  try {
    const result = await (await safeFetch(`/new_api/items/${ids}`, requestOptions)).json()
    return result
  } catch (err) {
    console.error(err)
  }
}

export async function fetchCardsByIds(ids = [], data = {}, mapData = {}) {
  let cards = []

  if (ids.length) {
    try {
      const params = new URLSearchParams()
      params.append('isDetailFetch', 'true')

      const result = await (await safeFetch(`/new_api/items/${ids}?${ params.toString()}`)).json()
      if (result.isSuccess) {
        cards = result.result
          .map((card) => parseCard(card, mapData))
      }
    } catch (err) {
      console.error(err)
    }
  }

  return cards
}

/**
 * @param {any} data
 */
export async function putCard(data) {
  let card = null

  try {
    const response = await safeFetchJson(
      `/new_api/items/${data.id}`,
      {
        method: 'PUT',
        body: JSON.stringify({ data: objectToInsertData(data) }),
        headers: { 'Content-Type': 'application/json' },
      },
    )

    if (response.isSuccess) {
      card = parseCard(response.result[0])
    }
  } catch (err) {
    console.error(err)
  }

  return card
}

export async function fetchCardIndex(id, data = {}) {
  let index = 0

  if (id) {
    try {
      const result = await (await safeFetch(buildGetUrl(`/new_api/items/${id}/index`, data))).json()
      if (result.isSuccess) {
        index = +result.result || 0
      }
    } catch (err) {
      console.error(err)
    }
  }

  return index
}

export async function _fetchCards(data, mapData) {
  let cards = []
  try {
    const { isSuccess, result } = await safeFetchJson(buildGetUrl('/new_api/items', data))
    if (isSuccess) {
      cards = result.map((card) => parseCard(card, mapData))
    }
  } catch (err) {
    console.error(err)
  }
  return cards
}

export function fetchCards(data, mapData) {
  return async function fetchCardsThunk(dispatch) {
    const cards = await _fetchCards(data, mapData)
    dispatch({ type: GET_CARDS, payload: cards })
    return cards
  }
}

export function updateCardsState(cards) {
  return async function updateCardsThunk(dispatch) {
    dispatch({ type: GET_CARDS, payload: cards })
  }
}

export async function updateCards(ids, data, mapData) {
  const dataObject = data.map((entity) => {
    return Object.entries(entity).map(([column, value]) => {
      return { column, value }
    })
  })[0] // TODO (slalancette) : The alix-core route only supports one update at a time, change this when needed

  const response = await safeFetchJson(`/new_api/items/${ids}`, {
    method: 'PUT',
    body: JSON.stringify({ data: dataObject }),
    headers: { 'Content-Type': 'application/json' },
  })

  return parsedResultOnSucessOrEmtpy(response, parseCard, mapData)
}

export function parseCard(card, mapData) {
  const options = {
    ...mapData,
    defaultData: getDefaultCard(),
    fields: initialState.fields,
    dataSetName: dataSetName,
  }

  return parse(card, options)
}

function parseCost(result) {
  return parse(result, { fields: getCostFields() })
}

function getDefaultCard() {
  return parse({}, { fields: initialState.fields })
}

export function getCardtitle(card) {
  return getFirstString(card.title, card.secondaryTitle)
}

/**
 * @returns {import('reducers/smart-form/smartFormTypes').SmartFormOptionObject}
 */
export const getCardDropdownOptions = ({ fetchData = {} } = { fetchData: {} }) => {
  return {
    key: 'cards',
    fetchOnFirstOpen: true,
    fetcher: _fetchCards,
    indexFetcher: fetchCardIndex,
    countFetcher: _fetchCardsCount,
    fields: initialState.fields,

    fetchData: {
      ...fetchData,
      exist: true,
    },
    filterFieldKeys: ['formattedNumber'],
    orderByFieldKeys: [
      {
        key: 'formattedNumber',
        isNullFirst: true,
      },
      {
        key: 'id',
        isNullFirst: true,
      },
    ],
    isLazy: true,
  }
}
