import { ApiToSlice, BaseEntityApi, GetFields, Modify } from 'types/slices'

import { buildGetUrl, parse } from 'utils/api'
import { parseNumber } from 'utils/numberGenerator'
import { parsedResultOnSucessOrEmtpy, safeFetchJson } from 'utils/safeFetch'

const dataSetName = 'equipment'
const fields = getFields()
const initialState = { dataSetName, fields }

export default function equipmentReducer(state = initialState, action: any) {
  switch (action.type) {
  default: {
    return state
  }
  }
}

type MapData = {equipmentsNumberConfig: Record<string, any>}

export type EquipmentApi = Modify<{
  tag: string
  title: string
  number: Record<string, any>
  number_prefix: string
  number_separator1: string
  number_increment: string
  number_separator2: string
  number_suffix: string
}, BaseEntityApi>

export type Equipment = ApiToSlice<Modify<EquipmentApi, {parsed_number: string}>>

export function getFields(): GetFields<EquipmentApi, Equipment, MapData> {
  return {
    'id': { dataSetName, dbField: 'id', isEdit: false },
    'tag': { dataSetName, dbField: 'tag' },
    'title': { dataSetName, dbField: 'title' },
    'parsedNumber': { parse: (equipment, options) => {
      const number = equipment.number ?? {
        prefix: equipment.number_prefix,
        separator1: equipment.number_separator1,
        increment: equipment.number_increment,
        separator2: equipment.number_separator2,
        suffix: equipment.number_suffix,
      }
      return parseNumber('equipments', number, options?.equipmentsNumberConfig)
    } },
  }
}

export async function fetchEquipments(data: any, mapData: MapData): Promise<Equipment[]> {
  let equipments = []
  try {
    const { isSuccess, result } = await safeFetchJson<EquipmentApi>(buildGetUrl('/new_api/equipments', data))
    if (isSuccess && Array.isArray(result)) {
      equipments = result.map((equipment) => parseEquipment(equipment, mapData?.equipmentsNumberConfig))
    }
  } catch (err) {}

  return equipments
}

export function parseEquipment(
  equipment: EquipmentApi,
  equipmentsNumberConfig?: MapData['equipmentsNumberConfig'],
): Equipment {
  const options = {
    defaultData: parse({}, { fields }),
    fields: initialState.fields,
    dataSetName,
    equipmentsNumberConfig,
  }
  return parse(equipment, options)
}

export async function fetchEquipmentsByIds(ids: string[], data: any = {}): Promise<Equipment[]> {
  const response = await safeFetchJson<EquipmentApi>(buildGetUrl(`/new_api/equipments/${ids}`, data))

  return parsedResultOnSucessOrEmtpy(response, (equipment) => parseEquipment(equipment))
}

export function getEquipmentTitle(equipment: Equipment) {
  return equipment.tag
}
