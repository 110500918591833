import { AppDispatch } from 'store'

import { SET_PIN_ERROR, CLEAR_PIN_ERROR } from './types'

const initState = { pinError: null }

export default function resourcesErrorReducer(state = initState, action: any) {
  const { error } = action

  switch (action.type) {
  case SET_PIN_ERROR: {
    return { ...state, pinError: error }
  }
  case CLEAR_PIN_ERROR: {
    return { ...state, pinError: null }
  }
  default:
    return state
  }
}

export function setPinError(dispatch: AppDispatch) {
  dispatch({ type: SET_PIN_ERROR, error: { name: 'ResourcePinError' } })
}

export function clearPinError(dispatch: AppDispatch) {
  dispatch({ type: CLEAR_PIN_ERROR })
}
