import { buildGetUrl, parse } from 'utils/api'
import { safeFetchJson } from 'utils/safeFetch'

import { dataSetName, fields } from './shared'

const initialState = { dataSetName, fields }

export default function addressesReducer(state = initialState, action) {
  switch (action.type) {
  default: {
    return state
  }
  }
}

export function parseAddress(address) {
  const options = {
    defaultData: parse({}, { fields }),
    fields: initialState.fields,
    dataSetName,
  }
  return parse(address, options)
}

export async function fetchAddressByIds(ids, data) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson(
    buildGetUrl(`/new_api/addresses/${ids}`, data),
  )

  return isSuccess ? result.map(parseAddress) : []
}

export function getAddressTitle(address) {
  return formatAddress(address)
}

export function formatAddress(address) {
  const streetLineInfo = []
  if (address.street) {
    streetLineInfo.push(address.street)
  }
  if (address.street2) {
    streetLineInfo.push(address.street2)
  }

  const cityLineInfo = []
  if (address.city) {
    cityLineInfo.push(address.city)
  }
  if (address.state) {
    cityLineInfo.push(address.state)
  }

  const countryLineInfo = []
  if (address.zip) {
    countryLineInfo.push(address.zip)
  }
  if (address.country) {
    countryLineInfo.push(address.country)
  }

  const addressParts = []

  if (address.label) addressParts.push(address.label)
  if (address.companyName) addressParts.push(address.companyName)
  if (address.attention) addressParts.push(address.attention)
  if (streetLineInfo.length) addressParts.push(streetLineInfo.join(' | '))
  if (cityLineInfo.length) addressParts.push(cityLineInfo.join(', '))
  if (countryLineInfo.length) addressParts.push(countryLineInfo.join(', '))
  if (address.phone) addressParts.push(address.phone)

  return addressParts.join(' ')
}
