import { useCallback, useMemo } from 'react'

import { useAppDispatch, useAppSelector } from 'store'

import { useSmartGridContext } from 'components/alix-front/legacy-smart-grid/contexts/SmartGridContext'

import { clearEditColumn, setEditColumn } from 'reducers/smart-grid-inputs/smartGridInputSlice'

export type SmartFormInputProps = {
  rowEntityId: string,
  columnName: string,
}

export const useSmartFormInput = ({
  rowEntityId,
  columnName,
}: SmartFormInputProps) => {
  const { id } = useSmartGridContext()
  const dispatch = useAppDispatch()

  const store = useAppSelector((state) => state.smartGridInputs[id])

  const setFilter = useCallback(() => {
    dispatch(setEditColumn({
      smartGridId: id,
      columnName,
      rowEntityId,
    }))
  }, [columnName, dispatch, id, rowEntityId])

  const clearFilter = useCallback(() => {
    dispatch(clearEditColumn({
      smartGridId: id,
    }))
  }, [dispatch, id])

  const value = useMemo(() => {
    return {
      setFilter,
      clearFilter,
      isEdit: store?.columnName === columnName && store?.rowEntityId === rowEntityId,
    }
  }, [store, rowEntityId, columnName, setFilter, clearFilter])

  return value
}
