import { combineReducers } from 'redux'

import authenticationReducer from './authentication/authenticationSlice'
import authenticationErrorReducer from './authentication/errorSlice'
import clientsSlice from './clients/clientsSlice'
import popupsReducer from './popups/popupsSlice'
import refsReducer from './refs/refsSlice'
import settingsErrorReducer from './settings/errorSlice'
import settingsReducer from './settings/settingsSlice'
import settingsSuccessReducer from './settings/successSlice'

const rootReducer = combineReducers({
  settingsSuccess: settingsSuccessReducer,
  settingsError: settingsErrorReducer,
  settings: settingsReducer,

  popups: popupsReducer,
  refs: refsReducer,

  authentication: authenticationReducer,
  authenticationError: authenticationErrorReducer,

  clients: clientsSlice,
})

export default rootReducer
