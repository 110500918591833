import React, { createContext, useMemo, useContext } from 'react'

type SmartGridContextType = {
  id: string,
}

const Context = createContext<SmartGridContextType | null>(null)

export const useSmartGridContext = () => {
  const context = useContext(Context)

  if (!context) {
    throw new Error('useSmartGridContext must be used within a SmartGridContextProvider')
  }

  return context as SmartGridContextType
}

type Props = {
  id: string,
}

export function SmartGridContext({ children, id }: React.PropsWithChildren<Props>) {
  const value = useMemo(() => ({
    id,
  }), [id])

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  )
};
