import React, { forwardRef } from 'react'
import Moment from 'react-moment'

import moment from 'moment'
import { useAppSelector } from 'store'

type DateColumnProps = {
  date: moment.MomentInput
  hideHours?: boolean
  format?: string
}

type DateColumnRef = {}

const DateColumn = forwardRef<DateColumnRef, DateColumnProps>(({ date, hideHours = false, format }, ref) => {
  const loginUserLanguage = useAppSelector((state) => state.loginUser.language)

  const language = loginUserLanguage || 'fr'
  const _format = format || (language === 'en' ?
    `MMM DD YYYY${hideHours ? '' : ' [at] H:mm A'}` :
    `DD MMM YYYY${hideHours ? '' : ' [à] H[h]mm'}`
  )
  return (date ?
    <Moment
      className="a-smart-grid-default-template"
      date={date}
      locale={`${language}-CA`}
      format={_format}
    /> : null
  )
})

DateColumn.displayName = 'DateColumn'
export default DateColumn
