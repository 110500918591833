import {
  CREATE_INVENTORIES,
  CLEAR_CREATE_ERROR,
  UPDATE_INVENTORIES,
  CLEAR_UPDATE_ERROR,
  WASTE_INVENTORIES,
  CLEAR_WASTE_ERROR,
  DELETE_INVENTORIES,
  CLEAR_DELETE_ERROR,
  ORDER_DELETE_ERROR,
  CLEAR_ORDER_ERROR,
  ORDER_INVENTORIES,
  SET_SCAN_ERROR,
  CLEAR_SCAN_ERROR,
} from './types'

const initState = {
  createError: null,
  updateError: null,
  wasteError: null,
  deleteError: null,
  orderError: null,
  scanError: null,
}

export default function inventoriesErrorReducer(state = initState, action) {
  const { error } = action

  switch (action.type) {
  case CREATE_INVENTORIES: {
    return {
      ...state,
      createError: error,
    }
  }
  case CLEAR_CREATE_ERROR: {
    return {
      ...state,
      createError: null,
    }
  }
  case UPDATE_INVENTORIES: {
    return {
      ...state,
      updateError: error,
    }
  }
  case CLEAR_UPDATE_ERROR: {
    return {
      ...state,
      updateError: null,
    }
  }
  case WASTE_INVENTORIES: {
    return {
      ...state,
      wasteError: error,
    }
  }
  case CLEAR_WASTE_ERROR: {
    return {
      ...state,
      wasteError: null,
    }
  }
  case DELETE_INVENTORIES: {
    return {
      ...state,
      deleteError: error,
    }
  }
  case CLEAR_DELETE_ERROR: {
    return {
      ...state,
      deleteError: null,
    }
  }
  case ORDER_DELETE_ERROR: {
    return {
      ...state,
      orderError: error,
    }
  }
  case ORDER_INVENTORIES: {
    return {
      ...state,
      orderError: error,
    }
  }
  case CLEAR_ORDER_ERROR: {
    return {
      ...state,
      orderError: null,
    }
  }
  case SET_SCAN_ERROR: {
    return {
      ...state,
      scanError: error,
    }
  }
  case CLEAR_SCAN_ERROR: {
    return {
      ...state,
      scanError: null,
    }
  }
  default:
    return state
  }
}

export function clearCreateError(dispatch) {
  dispatch({ type: CLEAR_CREATE_ERROR })
}

export function clearUpdateError(dispatch) {
  dispatch({ type: CLEAR_UPDATE_ERROR })
}

export function clearWasteError(dispatch) {
  dispatch({ type: CLEAR_WASTE_ERROR })
}

export function clearDeleteError(dispatch) {
  dispatch({ type: CLEAR_DELETE_ERROR })
}

export function clearOrderError(dispatch) {
  dispatch({ type: ORDER_DELETE_ERROR })
}

export function setScanError(name, tag) {
  return function setScanErrorThunk(dispatch) {
    dispatch({ type: SET_SCAN_ERROR, error: { error: { name }, tag } })
  }
}

export function clearScanError(dispatch) {
  dispatch({ type: CLEAR_SCAN_ERROR })
}
