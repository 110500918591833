import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch } from 'store'
import { ApiToSlice, BaseEntityApi, GetFields, Modify } from 'types/slices'

import { buildGetUrl, parse } from 'utils/api'
import { isJob, safeFetchJson } from 'utils/safeFetch'

const dataSetName = 'incotermView'

const initialState = {
  dataSetName,
  fields: getFields(),
  incotermsCount: 0,
  incoterms: [],
}

const incotermsSlice = createSlice({
  name: 'incoterms',
  initialState,
  reducers: {
    setIncoterms: (state, action) => {
      state.incoterms = action.payload.data
    },
    setIncotermsCount: (state, action) => {
      state.incotermsCount = action.payload.count
    },
  },
})

export const { setIncoterms, setIncotermsCount } = incotermsSlice.actions

export type IncotermApi = Modify<{
  code: string
  description: string
  display_name: string
}, BaseEntityApi>

export type Incoterm = ApiToSlice<IncotermApi>

type IncotermGetFields = GetFields<IncotermApi, Incoterm>

export function getFields(editOnly = false): IncotermGetFields {
  const editFields: IncotermGetFields = {
    'id': { dataSetName, dbField: 'id', type: 'string' },
    'code': { dataSetName, dbField: 'code', type: 'string', isEdit: true },
    'description': { dataSetName, dbField: 'description', type: 'string', isEdit: true },
  }

  if (editOnly) return editFields

  const fields: IncotermGetFields = {
    'exist': { dataSetName, dbField: 'exist', type: 'boolean' },
    'createdDate': { dataSetName, dbField: 'created_date', type: 'timestamp' },
    'createdBy': { dataSetName, dbField: 'created_by', type: 'string' },
    'createdById': { dataSetName, dbField: 'created_by_id', type: 'string' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'timestamp' },
    'modifiedBy': { dataSetName, dbField: 'modified_by', type: 'string' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id', type: 'string' },
    'displayName': { dataSetName, dbField: 'display_name', type: 'string' },
  }

  return { ...fields, ...editFields }
}

export function getIncotermTitle(incoterm: Incoterm): string {
  return incoterm.displayName
}

export async function fetchIncotermsByIds(ids: string[], data?: Record<string, any>) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson<IncotermApi>(
    buildGetUrl(`/new_api/incoterms/${ids}`, data),
  )

  return isSuccess && !isJob(result) ?
    result.map((incoterm) => parseIncoterm(incoterm)) :
    []
}

export function fetchIncoterms(fetchData?: Record<string, any>) {
  return async function fetchIncotermsThunk(dispatch: AppDispatch) {
    const data = await _fetchIncoterms(fetchData)
    dispatch(setIncoterms({ data }))
    return data
  }
}

export async function _fetchIncoterms(fetchData: Record<string, any>) {
  let incoterms = []

  try {
    const { isSuccess, result } = await safeFetchJson<IncotermApi>(
      buildGetUrl('/new_api/incoterms', fetchData),
    )
    if (isSuccess && !isJob(result)) {
      incoterms = result.map((incoterm) => parseIncoterm(incoterm))
    }
  } catch (err) {
    console.error(err)
  }

  return incoterms
}

export async function _fetchIncotermIndex(id: string, fetchData: Record<string, any>) {
  let index = 0

  if (id) {
    try {
      const result = await safeFetchJson(buildGetUrl(`/new_api/incoterms/${id}/index`, fetchData))
      if (result.isSuccess) {
        index = +result.result || 0
      }
    } catch (err) {
      console.error(err)
    }
  }

  return index
}

export function fetchIncotermsCount(fetchData?: Record<string, any>) {
  return async function fetchIncotermsCountThunk(dispatch: AppDispatch) {
    const count = await _fetchIncotermsCount(fetchData)
    dispatch(setIncotermsCount({ count }))
    return count
  }
}

export async function _fetchIncotermsCount(fetchData?: Record<string, any>) {
  let count = 0

  try {
    const { isSuccess, result } = await safeFetchJson<{count: string}>(
      buildGetUrl('/new_api/incoterms/count', fetchData),
    )
    if (isSuccess && !isJob(result)) {
      count = +result[0].count || 0
    }
  } catch (err) {
    console.error(err)
  }

  return count
}

export function createIncoterm(createData: Partial<IncotermApi>[]) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: createData }),
  }
  return async function createIncotermThunk(dispatch: AppDispatch) {
    try {
      const { isSuccess, result } = await safeFetchJson<IncotermApi, false>(
        '/new_api/incoterms', requestOptions,
      )
      const incoterm = isSuccess && !isJob<IncotermApi, false>(result) ?
        parseIncoterm(result) :
        null
      const error = !isSuccess ? result : null
      return { isSuccess, incoterm, error }
    } catch (error) {
      console.error(error)
      return { isSuccess: false, error }
    }
  }
}

export function deleteIncoterms(ids: string[], isBatch = false) {
  if (!ids?.length) return []

  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: isBatch ? JSON.stringify({ ids }) : undefined,
  }
  const url = isBatch ? '/new_api/incoterms/batch' : `/new_api/incoterms/${ids}`
  return async function deleteIncotermsThunk(dispatch: AppDispatch) {
    try {
      const { isSuccess, result } = await safeFetchJson<IncotermApi, typeof isBatch>(url, requestOptions)
      const error = !isSuccess ? result : null
      const _result = { isSuccess, error, incoterms: undefined, incoterm: undefined }
      if (Array.isArray(result)) {
        _result.incoterms = []
        if (isSuccess && !isJob(result)) {
          _result.incoterms = result.map((incoterm) => parseIncoterm(incoterm))
        }
      } else {
        _result.incoterm = isSuccess && !isJob<IncotermApi, false>(result) ? parseIncoterm(result) : null
      }

      return _result
    } catch (error) {
      console.error(error)
      return { isSuccess: false, error }
    }
  }
}

export async function _updateIncoterm(id: string, updateData: Partial<IncotermApi>) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: updateData }),
  }

  try {
    const { isSuccess, result } = await safeFetchJson<IncotermApi, false>(
      `/new_api/incoterms/${id}`, requestOptions,
    )
    const incoterm = isSuccess && !isJob<IncotermApi, false>(result) ? parseIncoterm(result) : null
    const error = !isSuccess ? result : null
    return { isSuccess, incoterm, error }
  } catch (error) {
    console.error(error)
    return { isSuccess: false, error }
  }
}

export function parseIncoterm(incoterm: IncotermApi): Incoterm {
  const options = {
    defaultData: getDefaultIncoterm(),
    fields: initialState.fields,
    dataSetName: dataSetName,
  }

  return parse(incoterm, options)
}

function getDefaultIncoterm(): Incoterm {
  return parse({}, { fields: initialState.fields })
}

export default incotermsSlice.reducer
