import React, { lazy } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import { Action } from '@reduxjs/toolkit'
import 'moment/locale/en-ca'
import 'moment/locale/fr-ca'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import 'styles/app.css'
import 'styles/primeReactTheme.css'
import 'wdyr'

import 'primereact/resources/primereact.min.css'

/**
 * ? TODO (bzoretic): smart-error and password-requirements are added here to solve the following webpack error:
 * couldn't fulfill desired order of chunk group(s)
 *
 * The error refers to a circular dependency in the css but it seems caused by the if/else in this file.
 * If only the hub app is built and the else part is commented the error disappears
 * If only the app is built and the if part is commented the error disappears
 *
 * For now, when we encounter this error we can force the css order as done here to fix the problem.
 * The downside of this is that the css is imported globally. In my case it was only 251B.
 *
 * Once we swap from CRA, we will have more control over webpack and it's config and we can revisit this solution then.
 */
import 'components/alix-front/legacy-smart-grid/style.css'
import 'components/alix-front/password-requirements/style.css'
import 'components/alix-front/smart-error/style.css'
import 'components/alix-front/smart-status/style.css'

const root = createRoot(document.getElementById('root'))
if (process.env.REACT_APP_BUILD_TARGET === 'hub') {
  const store = require('store').hubStore
  const HubApp = lazy(() => import('components/HubApp'))
  root.render(
    <Provider store={store}>
      <HubApp />
    </Provider>,
  )
} else {
  // TODO (bzoretic): Remove the `as` when we have better types
  const store = require('store').default
  const { fetchLoginUser } = require('reducers/users/login-user/userSlice')
  const App = lazy(() => import('components/App'))
  store.dispatch(fetchLoginUser as unknown as Action)

  root.render(
    <Provider store={store}>
      <App />
    </Provider>,
  )
}
