
import { buildGetUrl, parse } from 'utils/api'
import { dataToFormData } from 'utils/mapperHelper'
import { safeFetch } from 'utils/safeFetch'

import {
  GET_ITEM_GROUPS_COUNT,
  GET_ITEM_GROUPS,
  GET_ITEM_GROUP,
  CLEAR_ITEM_GROUP,
  CREATE_ITEM_GROUP,
  UPDATE_ITEM_GROUP,
  DELETE_ITEM_GROUP,
  SET_IS_CREATE,
  SET_GLOBAL_FORM,
  RESET_FORM,
} from './types'

const dataSetName = 'templateGroup'
const fields = getFields()
const initialState = {
  dataSetName,
  fields,
  itemGroupsCount: 0,
  itemGroups: [],
  activeItemGroup: getDefaultItemGroup(),
  activeForm: getDefaultForm(),
}

export default function itemGroupsReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
  case GET_ITEM_GROUPS_COUNT: {
    return {
      ...state,
      itemGroupsCount: payload,
    }
  }
  case GET_ITEM_GROUPS: {
    return {
      ...state,
      itemGroups: payload,
    }
  }
  case GET_ITEM_GROUP: {
    return buildItemGroupState(state, payload)
  }
  case CREATE_ITEM_GROUP: {
    return buildItemGroupState(state, payload)
  }
  case UPDATE_ITEM_GROUP: {
    return buildItemGroupState(state, payload)
  }
  case CLEAR_ITEM_GROUP: {
    return {
      ...state,
      activeItemGroup: getDefaultItemGroup(),
      activeForm: getDefaultForm(),
    }
  }
  case SET_IS_CREATE: {
    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        isCreate: payload,
      },
    }
  }
  case SET_GLOBAL_FORM: {
    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        hasChanges: hasChanges({ ...state.activeForm, global: payload }),
        isValid: isFormValid(payload),
        global: payload,
      },
    }
  }
  case RESET_FORM: {
    const globalForm = dataToFormData(state.activeItemGroup, getFields(true))

    const newActiveForm = {
      ...state.activeForm,
      isValid: isFormValid(globalForm),
      resetCount: state.activeForm.resetCount + 1,
      global: globalForm,
    }

    return {
      ...state,
      activeForm: {
        ...newActiveForm,
        hasChanges: hasChanges(newActiveForm),
      },
    }
  }
  default: {
    return state
  }
  }
}

// item groups exports
export function getFields(editOnly) {
  const editFields = {
    'id': { dataSetName, dbField: 'id', isEdit: false },
    'name': { dataSetName, dbField: 'name', isEdit: true },
    'type': { dataSetName, dbField: 'type', isEdit: true },
    'isSelling': { dataSetName, dbField: 'is_selling', isEdit: true },
    'isPurchased': { dataSetName, dbField: 'is_purchased', isEdit: true },
    'isManufactured': { dataSetName, dbField: 'is_manufactured', isEdit: true },
    'inventoryManagementType': { dataSetName, dbField: 'inventory_management_type', isEdit: true },
    'assetAccountId': { dataSetName, dbField: 'asset_account_id', isEdit: true },
    'expenseAccountId': { dataSetName, dbField: 'expense_account_id', isEdit: true },
    'incomeAccountId': { dataSetName, dbField: 'income_account_id', isEdit: true },
  }
  if (editOnly) {
    return editFields
  }

  const fields = {
    'createdDate': { dataSetName, dbField: 'created_date' },
    'createdBy': { dataSetName, dbField: 'created_by' },
    'createdById': { dataSetName, dbField: 'created_by_id' },
    'modifiedDate': { dataSetName, dbField: 'modified_date' },
    'modifiedBy': { dataSetName, dbField: 'modified_by' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id' },
    'assetAccount': { parse: (itemGroup) => parseAccountName(itemGroup, 'asset') },
    'expenseAccount': { parse: (itemGroup) => parseAccountName(itemGroup, 'expense') },
    'incomeAccount': { parse: (itemGroup) => parseAccountName(itemGroup, 'income') },
    'linkCount': { parse: (itemGroup = {}) => +itemGroup.link_count || 0 },
  }

  return { ...fields, ...editFields }
}

export function fetchItemGroupsCount(data) {
  return async function fetchItemGroupsCountThunk(dispatch) {
    try {
      const result = await (await safeFetch(buildGetUrl('/new_api/inventories/templates/groups/count', data))).json()
      if (result.isSuccess) {
        const count = +result.result[0].count || 0
        dispatch({ type: GET_ITEM_GROUPS_COUNT, payload: count })
        return count
      }

      return 0
    } catch (err) {
      console.error(err)
      return 0
    }
  }
}

export async function _fetchItemGroups(data = {}) {
  let itemGroups = []
  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/inventories/templates/groups', data))).json()
    if (result.isSuccess) {
      itemGroups = result.result.map((itemGroup) => parseItemGroup(itemGroup))
    }
  } catch (error) {
    console.error(error)
  }
  return itemGroups
}

export function fetchItemGroups(data = {}) {
  return async function fetchItemGroupsThunk(dispatch) {
    const itemGroups = await _fetchItemGroups(data)
    dispatch({ type: GET_ITEM_GROUPS, payload: itemGroups })
    return itemGroups
  }
}

export function fetchItemGroup(itemGroupId) {
  return async function fetchItemGroupThunk(dispatch) {
    if (itemGroupId === 'new') {
      dispatch({ type: SET_IS_CREATE, payload: true })
      return null
    } else {
      let parseItemGroups = null
      try {
        const result = await (await safeFetch(`/new_api/inventories/templates/groups/${itemGroupId}`)).json()
        if (result.isSuccess) {
          const [itemGroup] = result.result
          parseItemGroups = parseItemGroup(itemGroup)
          dispatch({ type: GET_ITEM_GROUP, payload: parseItemGroups })
        }
      } catch (err) {
        console.error(err)
      }
      return parseItemGroups
    }
  }
}

export function createItemGroup(itemGroups) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ itemGroups }),
  }

  return async function createItemGroupThunk(dispatch) {
    try {
      const result = await (await safeFetch(`/new_api/inventories/templates/groups`, requestOptions)).json()
      const [created] = result.isSuccess ? result.result : []
      const payload = created ? parseItemGroup(created) : null
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: CREATE_ITEM_GROUP, payload, error })

      return payload
    } catch (error) {
      dispatch({ type: CREATE_ITEM_GROUP, error })
    }
  }
}

export function updateItemGroup(itemGroup) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ itemGroup }),
  }

  return async function updateItemGroupThunk(dispatch) {
    try {
      const result = await (await safeFetch(
        `/new_api/inventories/templates/groups/${itemGroup.id}`,
        requestOptions,
      )).json()
      const [updated] = result.isSuccess ? result.result : []
      const payload = updated ? parseItemGroup(updated) : null
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: UPDATE_ITEM_GROUP, payload, error })
      return { isSuccess: result.isSuccess, result }
    } catch (error) {
      dispatch({ type: UPDATE_ITEM_GROUP, error })
      return { isSuccess: false, result: error }
    }
  }
}

export function deleteItemGroups(itemGroupIds) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ids: itemGroupIds }),
  }

  return async function deleteItemGroupsThunk(dispatch) {
    try {
      const result = await (await safeFetch(
        `/new_api/inventories/templates/groups/batch`,
        requestOptions,
      )).json()
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: DELETE_ITEM_GROUP, payload: result.isSuccess, error })

      return result
    } catch (error) {
      dispatch({ type: DELETE_ITEM_GROUP, error })
    }
  }
}

export function clearItemGroup(dispatch) {
  dispatch({ type: CLEAR_ITEM_GROUP })
}

// form exports
export function updateGlobalFormFields(fieldValues) {
  return async function updateGlobalFormFieldsThunk(dispatch, getState) {
    const itemGroupsStore = getState().itemGroups
    const payload = { ...itemGroupsStore.activeForm.global }

    fieldValues.forEach((fieldValue) => {
      payload[fieldValue.field] = {
        ...itemGroupsStore.activeForm.global[fieldValue.field],
        value: fieldValue.value,
        isChanged: true,
      }
    })

    dispatch({ type: SET_GLOBAL_FORM, payload })
  }
}

export function resetForm(dispatch) {
  dispatch({ type: RESET_FORM })
}

// item group parsing functions
function buildItemGroupState(state, payload) {
  if (!payload) {
    return state
  }

  const globalForm = dataToFormData(payload, getFields(true))
  const newActiveForm = {
    ...state.activeForm,
    isCreate: false,
    isValid: isFormValid(globalForm),
    global: globalForm,
  }

  return {
    ...state,
    activeItemGroup: payload,
    activeForm: {
      ...newActiveForm,
      hasChanges: hasChanges(newActiveForm),
    },
  }
}

function parseItemGroup(itemGroup = {}, defaultUnits) {
  const options = {
    defaultData: getDefaultItemGroup(),
    fields: initialState.fields,
    dataSetName,
  }
  return parse(itemGroup, options)
}

function parseAccountName(itemGroup, type) {
  const accountCode = itemGroup[`${type}_account_code`]
  const accountName = itemGroup[`${type}_account_name`]
  return `${accountCode ? `[ ${accountCode} ] ` : ''}${accountName || ''}`
}

function hasChanges(form) {
  return Object.keys(form.global).some((key) => form.global[key].isChanged)
}

function getDefaultItemGroup() {
  return parse({}, { fields })
}

function getDefaultForm() {
  return {
    isCreate: false,
    hasChanges: false,
    isValid: false,
    resetCount: 0,
    global: dataToFormData(getDefaultItemGroup(), getFields(true)),
  }
}

function isFormValid(globalForm) {
  return !!globalForm.name.value
}
