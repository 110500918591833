export const GET_CONSIGNMENT_ITEM = 'GET_CONSIGNMENT_ITEM'
export const CREATE_CONSIGNMENT_ITEM = 'CREATE_CONSIGNMENT_ITEM'
export const GET_CONSIGNMENT_ITEMS = 'GET_CONSIGNMENT_ITEMS'
export const UPDATE_CONSIGNMENT_ITEMS = 'UPDATE_CONSIGNMENT_ITEMS'
export const SET_IS_CREATE = 'SET_IS_CREATE'
export const RESET_FORM = 'RESET_FORM'
export const SET_CONSIGNMENT_LINE_ITEMS_FORM_SELECTION = 'SET_CONSIGNMENT_LINE_ITEMS_FORM_SELECTIONS'
export const DUPLICATE_CONSIGNMENT_LINE_ITEMS_FROM_SELECTION = 'DUPLICATE_CONSIGNMENT_LINE_ITEMS_FROM_SELECTION'
export const DELETE_CONSIGNMENT_LINE_ITEMS_FROM_SELECTION = 'DELETE_CONSIGNMENT_LINE_ITEMS_FROM_SELECTION'
export const SET_CONSIGNMENT_LINE_ITEMS_FORM = 'SET_CONSIGNMENT_LINE_ITEMS_FORM'
export const SAVE_CONSIGNMENT_LINE_ITEMS_FORM = 'SAVE_CONSIGNMENT_LINE_ITEMS_FORM'
export const CLEAR_SAVE_CONSIGNMENT_LINE_ITEMS_FORM = 'CLEAR_SAVE_CONSIGNMENT_LINE_ITEMS_FORM'
