import {
  POST_SETTING,
  POST_SECURITY_SETTING,
  CLEAR_SUCCESS_FIELD,
} from './types'

const initState = {
  successField: null,
}

export default function settingsSuccessReducer(state = initState, action) {
  const { payload } = action

  switch (action.type) {
  case POST_SECURITY_SETTING:
  case POST_SETTING: {
    return {
      ...state,
      successField: payload && payload.name ? payload.name : null,
    }
  }
  case CLEAR_SUCCESS_FIELD: {
    return {
      ...state,
      successField: null,
    }
  }
  default:
    return state
  }
}

export function clearSuccessField(dispatch) {
  dispatch({ type: CLEAR_SUCCESS_FIELD })
}
