import { createSlice } from '@reduxjs/toolkit'
import { HubAppDispatch } from 'store'
import { FieldType } from 'types/slices'
import { DefaultRecord } from 'types/utils'

import { hash } from 'components/alix-front/smart-grid/columns/utils'

import { buildGetUrl, parse } from 'utils/api'
import { EntityType } from 'utils/entities'
import { joinOnlyStrings } from 'utils/getFirstOfType'
import { isJob, safeFetchJson } from 'utils/safeFetch'
import { convertFromBase } from 'utils/unitConverter'

import {
  getFields as getTemplateFields,
  parseItem,
} from 'reducers/items/itemsSlice'
import {
  FullProjectPlanning,
  FullProjectPlanningApi,
  ProjectPlanningAnalysisApi,
  ProjectPlanningDetailsApi,
  ProjectPlanningDetailsFields,
  ProjectPlanningFields,
  projectPlanningTemplateApiPrefix,
  projectPlanningTemplateFieldPrefix,
} from 'reducers/project-planning/type'
import { formatFields, getBaseEntityFields } from 'reducers/utils/common'

import { parsePosition } from './utils'

const dataSetName = 'projectPlanningView'
const itemDataSetName = 'templateView'
const detailsDataSetName = 'projectPlanningDetailView'

const convertMeasureCb = <T extends Record<string, any>>(
  field: keyof T,
  parentOptions?: { dimensionKey?: string, unitKey?: string },
) => (data: T, options: any = {}) => {
    const dimensionKey = parentOptions?.dimensionKey || 'template_dimension_to_display'
    const unitKey = parentOptions?.unitKey || 'template_measure_unit'

    return +convertFromBase(
      data[dimensionKey],
      data[field],
      data[unitKey] || options.defaultUnits?.[data[dimensionKey]],
      true,
    )
  }

const baseFields = getBaseEntityFields<ProjectPlanningFields>({ dataSetName })()

const projectPlanningFields: ProjectPlanningFields = {
  ...baseFields,
  templateId: { dataSetName, dbField: 'template_id', type: 'id', relationEntity: 'items' },
  positionColor: { dataSetName, dbField: 'position_color', type: 'string' },
  position: { dataSetName, dbField: 'position', type: 'string', parse: parsePosition },
  baseRecommendation: { dataSetName, dbField: 'recommended', type: 'measure' },
  recommendation: { dataSetName, dbField: 'recommended', type: 'measure', parse: convertMeasureCb('recommended') },
  onOrder: { dataSetName, dbField: 'ordered', type: 'measure', parse: convertMeasureCb('ordered') },
  onHand: { dataSetName, dbField: 'on_hand', type: 'measure', parse: convertMeasureCb('on_hand') },
  otherDemand: { dataSetName, dbField: 'other_demand', type: 'measure', parse: convertMeasureCb('other_demand') },
  netFlow: { dataSetName, dbField: 'net_flow_after', type: 'measure', parse: convertMeasureCb('net_flow_after') },
  projectPlannedDemand: {
    dataSetName,
    dbField: 'project_planned_demand',
    type: 'measure',
    parse: convertMeasureCb('project_planned_demand'),
  },
  projectDemand: { dataSetName, dbField: 'project_demand', type: 'measure', parse: convertMeasureCb('project_demand') },
  projectConsumed: {
    dataSetName,
    dbField: 'project_consumed',
    type: 'measure',
    parse: convertMeasureCb('project_consumed'),
  },
  remainingAfter: {
    dataSetName,
    dbField: 'remaining_after',
    type: 'measure',
    parse: convertMeasureCb('remaining_after'),
  },
  topOfGreen: { dataSetName, dbField: 'top_of_green', type: 'measure', parse: convertMeasureCb('top_of_green') },
  topOfYellow: { dataSetName, dbField: 'top_of_yellow', type: 'measure', parse: convertMeasureCb('top_of_yellow') },
  topOfRed: { dataSetName, dbField: 'top_of_red', type: 'measure', parse: convertMeasureCb('top_of_red') },
}

const itemExtraFields: ProjectPlanningFields = {
  templateId: { dataSetName: itemDataSetName, dbField: 'id', type: 'string' },
  templateTitle: { dataSetName: itemDataSetName, dbField: 'template_title', type: 'string' },
  initialMeasure: { dataSetName: itemDataSetName, dbField: 'initial_measure', type: 'float' },
  unit: { dataSetName: itemDataSetName, dbField: 'measure_unit', type: 'string' },
}

const itemFields = { ...getTemplateFields(), ...itemExtraFields }

// #region Project Planning Details Fields
const convertDetailsQuantity = (data, options: any = {}) => {
  const isNegative = data.planning_type === 'qualified_demand'
  const quantity = +convertFromBase(
    data.dimension, data.measure, data.unit || options.defaultUnits?.[data.dimension], true,
  )

  return isNegative && quantity > 0 ? quantity * -1 : quantity
}

const detailFields: ProjectPlanningDetailsFields = {
  id: {
    parse: (props) => hash(
      Object.keys(props).sort().map((k) => `${k}:${String(props[k])}`).join(';'),
    ),
  },
  date: { dataSetName: detailsDataSetName, dbField: 'planned_date', type: 'date' },
  type: {
    dataSetName: detailsDataSetName,
    dbField: 'planned_type',
    type: 'enum',
    translationPath: 'planning:detailsGrid.typeEnum',
    values: ['on_hand', 'sales', 'production', 'consumption', 'reception'],
  },
  description: { dataSetName: detailsDataSetName, dbField: 'template_description', type: 'string' },
  quantity: {
    dataSetName: detailsDataSetName, dbField: 'measure', type: 'measure',
    parse: convertDetailsQuantity,
  },
  netFlow: {
    dataSetName: detailsDataSetName, dbField: 'cumulative', type: 'measure',
    parse: convertMeasureCb('cumulative', { dimensionKey: 'dimension', unitKey: 'unit' }),
  },
  position: { dataSetName: detailsDataSetName, dbField: 'position', type: 'string' },
  dimension: { dataSetName: detailsDataSetName, dbField: 'dimension', type: 'string' },
  unit: { dataSetName: detailsDataSetName, dbField: 'unit', type: 'string' },
  sku: { dataSetName: detailsDataSetName, dbField: 'sku', type: 'string' },
  planningType: { dataSetName: detailsDataSetName, dbField: 'planning_type', type: 'string' },
  reference: {
    parse: (props: ProjectPlanningDetailsApi) => {
      const type = props.planned_type?.toUpperCase()

      switch (type) {
      case 'ON_HAND': {
        return ''
      }
      case 'SALES': {
        return joinOnlyStrings([props.sales_order_number, props.contact_display_name], ' | ')
      }
      case 'PRODUCTION':
      case 'CONSUMPTION': {
        return joinOnlyStrings([props.item_detailed_number, props.item_title], ' | ')
      }
      case 'RECEPTION': {
        return joinOnlyStrings([props.invoice_title, props.supplier_display_name], ' | ')
      }
      }
    },
  },
  referenceId: {
    parse: (props: ProjectPlanningDetailsApi) => {
      const type = props.planned_type?.toUpperCase()

      switch (type) {
      case 'ON_HAND': {
        return ''
      }
      case 'SALES': {
        return props.sales_order_id
      }
      case 'PRODUCTION':
      case 'CONSUMPTION': {
        return props.item_id
      }
      case 'RECEPTION': {
        if (props.sales_order_id) {
          return props.sales_order_id
        }
        return props.purchase_order_id
      }
      }
    },
  },
  referenceType: {
    parse: (props: ProjectPlanningDetailsApi) => {
      const type = props.planned_type?.toUpperCase()

      switch (type) {
      case 'ON_HAND': {
        return 'inventory'
      }
      case 'SALES': {
        return 'sales_orders'
      }
      case 'PRODUCTION':
      case 'CONSUMPTION': {
        return 'cards'
      }
      case 'RECEPTION': {
        if (props.sales_order_id) {
          return 'sales_orders'
        }
        return 'purchase_orders'
      }
      }
    },
  },
  templateTitle: { dataSetName: detailsDataSetName, dbField: 'template_title', type: 'string' },
}
// #endregion

const initialState = {
  dataSetName,
  fields: getProjectPlanningFields(),
  itemFields,
  detailsFields: getProjectPlanningDetailFields(),
  count: 0,
  data: [],
  detailsData: [],
  detailsCount: 0,
}

export function getProjectPlanningFields() {
  const options: Partial<FieldType> = {
    dataSetName,
    useTrimAliasInField: true,
  }

  return {
    ...formatFields(
      itemFields,
      projectPlanningTemplateFieldPrefix,
      projectPlanningTemplateApiPrefix,
      options,
    ),
    ...baseFields,
    ...projectPlanningFields,
  }
}

export function getProjectPlanningDetailFields() {
  return detailFields
}

const slice = createSlice({
  name: 'project-planning',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload.data
    },
    setCount: (state, action) => {
      state.count = action.payload.count
    },
    setDetailsData: (state, action) => {
      state.detailsData = action.payload.data
    },
    setDetailsCount: (state, action) => {
      state.detailsCount = action.payload.count
    },
  },
})

export const { setData, setCount, setDetailsData, setDetailsCount } = slice.actions

export function fetchProjectPlanningCount(fetchData?: Record<string, any>) {
  return async function fetchProjectPlanningCountThunk(dispatch: HubAppDispatch) {
    const count = await _fetchProjectPlanningCount(fetchData)

    dispatch(setCount({ count }))

    return count
  }
}

function getConditionObj(fetchData?: Record<string, any>) {
  const conditionObj = []

  if (fetchData.conditionObj) {
    conditionObj.push(
      ...JSON.parse(fetchData.conditionObj),
    )
  }

  return JSON.stringify(conditionObj)
}

export function clearProjectPlanning(dispatch) {
  dispatch(setData({ data: [] }))
  dispatch(setCount({ count: 0 }))
}

export function fetchProjectPlanning(fetchData?: Record<string, any>, mapData = {}) {
  return async function fetchProjectPlanningThunk(dispatch: HubAppDispatch) {
    const data = await _fetchProjectPlanning(fetchData, mapData)

    dispatch(setData({ data }))

    return data
  }
}

export async function _fetchProjectPlanning(fetchData: Record<string, any> = {}, mapData = {}) {
  let data = []

  try {
    const { isSuccess, result } = await safeFetchJson<FullProjectPlanningApi, true>(
      buildGetUrl('/new_api/projects/planning', {
        ...fetchData,
        conditionObj: getConditionObj(fetchData),
      }),
    )
    if (isSuccess && !isJob(result)) {
      data = result.map((projectPlanning) => parseProjectPlanning(projectPlanning, mapData))
    }
  } catch (err) {
    console.error(err)
  }

  return data
}

export async function _fetchProjectPlanningCount(fetchData: Record<string, any> = {}) {
  let count = 0

  try {
    const { isSuccess, result } = await safeFetchJson<{count: string}, true>(
      buildGetUrl('/new_api/projects/planning/count', {
        ...fetchData,
        conditionObj: getConditionObj(fetchData),
      }),
    )
    if (isSuccess && !isJob(result)) {
      count = +result[0].count || 0
    }
  } catch (err) {
    console.error(err)
  }

  return count
}

export async function fetchProjectPlanningAnalysis(
  fetchData: Record<string, any> = {},
): Promise<ProjectPlanningAnalysisApi> {
  let data: ProjectPlanningAnalysisApi = {}

  try {
    const { isSuccess, result } = await safeFetchJson<ProjectPlanningAnalysisApi, false>(
      buildGetUrl('/new_api/projects/planning/analysis', fetchData),
    )
    if (isSuccess && !isJob<ProjectPlanningAnalysisApi, false>(result)) {
      data = result
    }
  } catch (err) {
    console.error(err)
  }

  return data
}

export async function runReport(plantId: string, projectId: string, cardId?: string) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ plantId, projectId, cardId }),
  }

  try {
    return await safeFetchJson('/new_api/projects/planning/report', requestOptions)
  } catch (err) {
    console.error(err)
  }
}

const getMapData = (mapData: DefaultRecord = {}) => {
  return {
    ...mapData,
    debug: true,

    fields: initialState.fields,
    dataSetName: dataSetName,
    defaultUnits: mapData?.defaultUnits,
    isPrimaryLanguage: mapData?.isPrimaryLanguage,
    isDocumentPrimaryLanguage: mapData?.isDocumentPrimaryLanguage,
    taxDict: mapData?.taxDict,
    priceMaxDigits: mapData?.priceMaxDigits,
    measureMaxDigits: mapData?.measureMaxDigits,

    vendorDetails: mapData.vendorDetails || {},
    customerDetails: mapData.customerDetails || {},
    preferredCompany: mapData.preferredCompany || {},
  } as const
}

export function parseProjectPlanning(data: FullProjectPlanningApi, mapData: DefaultRecord = {}) {
  const options = getMapData(mapData)

  const item = parseItem({ ...data }, {
    ...options,
    apiPrefix: `${projectPlanningTemplateApiPrefix}_`,
    outPrefix: `${projectPlanningTemplateFieldPrefix}`,
    skipOutPrefixCamelCaseKeys: true,
  }, initialState.itemFields)

  const projectPlanning = parse({ ...data }, {
    ...options,
    fields: projectPlanningFields,
  })

  return { ...item, ...projectPlanning }
}

export const ProjectPlanningEntity: EntityType<FullProjectPlanning> = {
  translationFile: 'projectPlanning',
  coreRelationName: 'projectPlanning',
  getFields: getProjectPlanningFields,
  relationTranslationFiles: [
    'items',
    'projectPlanning',
  ],
  // Entity is not used in events, no need for these fields
  namespace: () => '',
  fetcher: async() => [],
  getTitle: () => '',
  getDimension: (data, _, field) => {
    switch (field.dataSetAlias) {
    case projectPlanningTemplateApiPrefix:
      return data.templateDimensionToDisplay
    default:
      return null
    }
  },
  getUnit: (data, _, field) => {
    switch (field.dataSetAlias) {
    case projectPlanningTemplateApiPrefix:
      return data.templateDimensionToDisplay === 'weight' ?
        data.templateMeasureUnit : data.templateWeightUnit
    default:
      return null
    }
  },
  parser: parseProjectPlanning,
}

// #region Project Planning Details
const parseProjectPlanningDetail = (detail: ProjectPlanningDetailsApi, mapData: any = {}) => {
  const entityFields = getProjectPlanningDetailFields()

  const option = {
    fields: entityFields,
    dataSetName: detailsDataSetName,
    defaultData: parse({}, { fields: entityFields }),
    defaultUnits: mapData.defaultUnits,
  }

  return parse(detail, option)
}

export function fetchProjectPlanningDetailData(fetchData?: Record<string, any>, mapData = {}) {
  return async function fetchProjectPlanningDetailDataThunk(dispatch: HubAppDispatch) {
    const data = await _fetchPlanningDetailData(fetchData, mapData)

    dispatch(setDetailsData({ data }))

    return data
  }
}

export async function _fetchPlanningDetailData(fetchData: Record<string, any> = {}, mapData = {}) {
  let data = []

  try {
    const { isSuccess, result } = await safeFetchJson<ProjectPlanningDetailsApi, true>(
      buildGetUrl('/new_api/projects/planning/details', {
        ...fetchData,
        conditionObj: getConditionObj(fetchData),
      }),
    )
    if (isSuccess && !isJob(result)) {
      data = result.map((projectPlanningDetail) => parseProjectPlanningDetail(projectPlanningDetail, mapData))
    }
  } catch (err) {
    console.error(err)
  }

  return data
}

export function fetchProjectPlanningDetailCount(fetchData?: Record<string, any>) {
  return async function fetchProjectPlanningDetailCountThunk(dispatch: HubAppDispatch) {
    const count = await _fetchProjectPlanningDetailCount(fetchData)

    dispatch(setDetailsCount({ count }))

    return count
  }
}

export async function _fetchProjectPlanningDetailCount(fetchData: Record<string, any>) {
  let count = 0

  try {
    const { isSuccess, result } = await safeFetchJson<{count: string}, true>(
      buildGetUrl('/new_api/projects/planning/details/count', {
        ...fetchData,
        conditionObj: getConditionObj(fetchData),
      }),
    )
    if (isSuccess && !isJob(result)) {
      count = +result[0].count || 0
    }
  } catch (err) {
    console.error(err)
  }

  return count
}
// #endregion

export default slice.reducer
