import { buildGetUrl, parse } from 'utils/api'
import { EntityName } from 'utils/entities'
import { dataToFormData, formDataToArray } from 'utils/mapperHelper'
import { isJob, safeFetchJson } from 'utils/safeFetch'

import {
  DELETE_CARD_REPORTING_TAGS_FROM_SELECTION,
  INIT_CARD_REPORTING_TAGS_FORM,
  SET_CARD_REPORTING_TAGS_FORM,
  SET_CARD_REPORTING_TAGS_FORM_SELECTION,
} from 'reducers/cards/types'
import {
  DELETE_ITEM_REPORTING_TAGS_FROM_SELECTION,
  INIT_ITEM_REPORTING_TAGS_FORM,
  SET_ITEM_REPORTING_TAGS_FORM,
  SET_ITEM_REPORTING_TAGS_FORM_SELECTION,
} from 'reducers/items/types'
import {
  DELETE_PO_ITEM_REPORTING_TAGS_FROM_SELECTION,
  SET_PO_ITEM_REPORTING_TAGS_FORM,
  SET_PO_ITEM_REPORTING_TAGS_FORM_SELECTION,
  INIT_PO_ITEM_REPORTING_TAGS_FORM,
} from 'reducers/purchase-orders/types/actions'
import {
  DELETE_SO_ITEM_REPORTING_TAGS_FROM_SELECTION,
  SET_SO_ITEM_REPORTING_TAGS_FORM,
  SET_SO_ITEM_REPORTING_TAGS_FORM_SELECTION,
  INIT_SO_ITEM_REPORTING_TAGS_FORM,
} from 'reducers/sales-orders/types/actions'
import { SavableLineItemsForm } from 'reducers/smart-form/smartFormTypes'
export type AssociatedEntityName = Extract<
  EntityName,
  'purchase-order-items' | 'sales-order-items' | 'items' | 'cards'
>
export const salesOrderItemReportingTagDataSetName = 'salesOrderItemReportingTag'
export const purchaseOrderItemReportingTagDataSetName = 'purchaseOrderItemReportingTag'
export const itemReportingTagDataSetName = 'templateReportingTag'
export const cardReportingTagDataSetName = 'itemReportingTag'

type ReportingTagDataSetName =
  typeof salesOrderItemReportingTagDataSetName |
  typeof purchaseOrderItemReportingTagDataSetName |
  typeof itemReportingTagDataSetName |
  typeof cardReportingTagDataSetName

type DispatchKeyDict = {
  updateForm: string,
  setSelection: string,
  deleteFromSelection: string,
  initForm: string,
}

type AssociatedEntityNameDispatchKeyDict = Record<AssociatedEntityName, DispatchKeyDict>

const associatedEntityNameDispatchKeyDict: AssociatedEntityNameDispatchKeyDict = {
  'purchase-order-items': {
    updateForm: SET_PO_ITEM_REPORTING_TAGS_FORM,
    setSelection: SET_PO_ITEM_REPORTING_TAGS_FORM_SELECTION,
    deleteFromSelection: DELETE_PO_ITEM_REPORTING_TAGS_FROM_SELECTION,
    initForm: INIT_PO_ITEM_REPORTING_TAGS_FORM,
  },
  'sales-order-items': {
    updateForm: SET_SO_ITEM_REPORTING_TAGS_FORM,
    setSelection: SET_SO_ITEM_REPORTING_TAGS_FORM_SELECTION,
    deleteFromSelection: DELETE_SO_ITEM_REPORTING_TAGS_FROM_SELECTION,
    initForm: INIT_SO_ITEM_REPORTING_TAGS_FORM,
  },
  'items': {
    updateForm: SET_ITEM_REPORTING_TAGS_FORM,
    setSelection: SET_ITEM_REPORTING_TAGS_FORM_SELECTION,
    deleteFromSelection: DELETE_ITEM_REPORTING_TAGS_FROM_SELECTION,
    initForm: INIT_ITEM_REPORTING_TAGS_FORM,
  },
  'cards': {
    updateForm: SET_CARD_REPORTING_TAGS_FORM,
    setSelection: SET_CARD_REPORTING_TAGS_FORM_SELECTION,
    deleteFromSelection: DELETE_CARD_REPORTING_TAGS_FROM_SELECTION,
    initForm: INIT_CARD_REPORTING_TAGS_FORM,
  },
}

function _getDataSetName(
  associatedEntityName: AssociatedEntityName,
): ReportingTagDataSetName {
  switch (associatedEntityName) {
  case 'purchase-order-items':
    return purchaseOrderItemReportingTagDataSetName
  case 'sales-order-items':
    return salesOrderItemReportingTagDataSetName
  case 'items':
    return itemReportingTagDataSetName
  case 'cards':
    return cardReportingTagDataSetName
  default:
    throw new Error(`Invalid associated entity: ${associatedEntityName}`)
  }
}

function _getAssociatedEntityStore(associatedEntityName: AssociatedEntityName, state) {
  switch (associatedEntityName) {
  case 'purchase-order-items':
    return state.purchaseOrders
  case 'sales-order-items':
    return state.salesOrders
  case 'items':
    return state.items
  case 'cards':
    return state.cards
  default:
    throw new Error(`Invalid associated entity: ${associatedEntityName}`)
  }
}

export function getReportingTagsForm(associatedEntityName: AssociatedEntityName, state) {
  return _getAssociatedEntityStore(associatedEntityName, state).reportingTagsForm
}

export function getReportingTagsFieldFromStore(associatedEntityName: AssociatedEntityName, state) {
  return _getAssociatedEntityStore(associatedEntityName, state).reportingTagFields
}

export function getReportingTagFields(
  associatedEntityName: AssociatedEntityName,
  editOnly?: boolean,
): Record<string, any> {
  const dataSetName = _getDataSetName(associatedEntityName)
  const sharedFields = _getReportingTagSharedFields(dataSetName, editOnly)
  const entityFields = _getReportingTagEntityFields(associatedEntityName, editOnly)
  return { ...sharedFields, ...entityFields }
}

function _getReportingTagSharedFields(dataSetName: ReportingTagDataSetName, editOnly?: boolean): Record<string, any> {
  const fields = {
    id: { dataSetName, dbField: 'id', type: 'string', isEdit: false },
    reportingTagId: { dataSetName, dbField: 'reporting_tag_id', type: 'string', isEdit: false },
    reportingTagName: { dataSetName, dbField: 'reporting_tag_name', type: 'string', isEdit: false },
    reportingTagOptionId: {
      dataSetName,
      dbField: 'reporting_tag_option_id',
      type: 'id',
      relationEntity: 'reporting-tag-options',
      isEdit: true,
    },
    reportingTagOptionName: { dataSetName, dbField: 'reporting_tag_option_name', type: 'string', isEdit: false },
    zohoBooksReportingTagId: { dataSetName, dbField: 'zoho_books_reporting_tag_id', type: 'string', isEdit: false },
    zohoBooksReportingTagOptionId: {
      dataSetName,
      dbField: 'zoho_books_reporting_tag_option_id',
      type: 'string',
      isEdit: false,
    },
  }

  let fieldsToReturn = Object.keys(fields)
  if (editOnly) {
    fieldsToReturn = Object.keys(fields).filter((key) => fields[key].isEdit)
    fieldsToReturn.push(...['id', 'reportingTagId', 'reportingTagName', 'reportingTagOptionName'])
  }

  return fieldsToReturn.reduce((acc, key) => ({ ...acc, [key]: fields[key] }), {})
}

function _getReportingTagEntityFields(
  associatedEntityName: AssociatedEntityName,
  editOnly?: boolean,
): Record<string, any> {
  switch (associatedEntityName) {
  case 'purchase-order-items':
    return getPurchaseOrderItemReportingTagFields(editOnly)
  case 'sales-order-items':
    return getSalesOrderItemReportingTagFields(editOnly)
  case 'items':
    return getItemReportingTagFields(editOnly)
  case 'cards':
    return getCardReportingTagFields(editOnly)
  default:
    throw new Error(`Invalid associated entity: ${associatedEntityName}`)
  }
}

export function getPurchaseOrderItemReportingTagFields(editOnly?: boolean) {
  const dataSetName = _getDataSetName('purchase-order-items')

  const fields = {
    purchaseOrderItemId: { dataSetName, dbField: 'purchase_order_item_id', type: 'string', isEdit: false },
  }

  let fieldsToReturn = Object.keys(fields)
  if (editOnly) {
    fieldsToReturn = Object.keys(fields).filter((key) => fields[key].isEdit)
    fieldsToReturn.push(...['purchaseOrderItemId'])
  }

  return fieldsToReturn.reduce((acc, key) => ({ ...acc, [key]: fields[key] }), {})
}

export function getSalesOrderItemReportingTagFields(editOnly?: boolean) {
  const dataSetName = _getDataSetName('sales-order-items')

  const fields = {
    salesOrderItemId: { dataSetName, dbField: 'sales_order_item_id', type: 'string', isEdit: false },
  }

  let fieldsToReturn = Object.keys(fields)
  if (editOnly) {
    fieldsToReturn = Object.keys(fields).filter((key) => fields[key].isEdit)
    fieldsToReturn.push(...['salesOrderItemId'])
  }

  return fieldsToReturn.reduce((acc, key) => ({ ...acc, [key]: fields[key] }), {})
}

export function getItemReportingTagFields(editOnly?: boolean) {
  const dataSetName = _getDataSetName('items')

  const fields = {
    templateId: { dataSetName, dbField: 'template_id', type: 'string', isEdit: false },
  }

  let fieldsToReturn = Object.keys(fields)
  if (editOnly) {
    fieldsToReturn = Object.keys(fields).filter((key) => fields[key].isEdit)
    fieldsToReturn.push(...['templateId'])
  }

  return fieldsToReturn.reduce((acc, key) => ({ ...acc, [key]: fields[key] }), {})
}

export function getCardReportingTagFields(editOnly?: boolean) {
  const dataSetName = _getDataSetName('cards')

  const fields = {
    itemId: { dataSetName, dbField: 'item_id', type: 'string', isEdit: false },
  }

  let fieldsToReturn = Object.keys(fields)
  if (editOnly) {
    fieldsToReturn = Object.keys(fields).filter((key) => fields[key].isEdit)
    fieldsToReturn.push(...['itemId'])
  }

  return fieldsToReturn.reduce((acc, key) => ({ ...acc, [key]: fields[key] }), {})
}

export function parseReportingTag(
  associatedEntityName: AssociatedEntityName,
  reportingTag: Record<string, any>,
  mapData: Record<string, any> = {},
) {
  const dataSetName = _getDataSetName(associatedEntityName)
  const fields = getReportingTagFields(associatedEntityName)
  const options = {
    ...mapData,
    defaultData: parse({}, { fields }),
    fields,
    dataSetName,
  }

  return parse(reportingTag, options)
}

export function getReportingTagsFormValueDict(reportingTagsForm): any[] {
  return formDataToArray(getReportingTagsFormItems(reportingTagsForm), false, false, true)
}

export function getReportingTagsFormItems(reportingTagsForm): any[] {
  const deletionIds = Object.keys(reportingTagsForm.deletions || {})
  const insertions = Object.keys(reportingTagsForm.insertions || {})
    .filter((id) => !deletionIds.includes(id))
    .map((id) => reportingTagsForm.insertions[id])
  const updates = Object.keys(reportingTagsForm.updates || {})
    .filter((id) => !deletionIds.includes(id))
    .map((id) => reportingTagsForm.updates[id])

  return [...insertions, ...updates]
}

export function formatReportingTagsForSaving(
  associatedEntityName: AssociatedEntityName,
  reportingTags,
): SavableLineItemsForm {
  const fields = getReportingTagFields(associatedEntityName)
  const convertedUpdates = formDataToArray(
    reportingTags.updates,
    true,
    true,
    true,
    true,
    fields,
  )
  const updatesWithChanges = convertedUpdates.filter((update) =>
    Object.keys(update).length > 1, // check if update contains more fields than just 'id'
  )

  return {
    insertions: formDataToArray(
      reportingTags.insertions,
      true,
      true,
      false,
      true,
      fields,
    ),
    updates: updatesWithChanges,
    deletions: Object.values(reportingTags.deletions),
  }
}

export function getDefaultReportingTagsForm(associatedEntityName: AssociatedEntityName) {
  return {
    isCreate: false,
    hasChanges: false,
    isValid: false,
    resetCount: 0,
    reportingTags: _buildInitialReportingTagsFormState(associatedEntityName),
  }
}

export function buildReportingTagsState(state, payload, associatedEntityName: AssociatedEntityName) {
  if (!payload) {
    return state
  }

  const reportingTags = _buildInitialReportingTagsFormState(associatedEntityName, payload)

  const newReportingTagsForm = {
    ...state.reportingTagsForm,
    isCreate: false,
    isValid: isReportingTagsFormValid(reportingTags),
    reportingTags,
  }

  return {
    ...state,
    reportingTagsForm: {
      ...newReportingTagsForm,
      hasChanges: hasReportingTagsChanges(newReportingTagsForm),
    },
  }
}

export function buildReportingTagsFormState(state, reportingTags) {
  const newReportingTagsForm = {
    ...state.reportingTagsForm,
    isValid: isReportingTagsFormValid(reportingTags),
    reportingTags,
  }
  return {
    ...state,
    reportingTagsForm: {
      ...newReportingTagsForm,
      hasChanges: hasReportingTagsChanges(newReportingTagsForm),
    },
  }
}

export function buildReportingTagsFormStateWithDeletions(state) {
  const newInsertions = { ...state.reportingTagsForm.reportingTags.insertions }
  const newDeletions = { ...state.reportingTagsForm.reportingTags.deletions }
  state.reportingTagsForm.reportingTags.selections.forEach((selection) => {
    if (newInsertions[selection.id]) {
      delete newInsertions[selection.id]
    } else {
      newDeletions[selection.id] = selection
    }
  })

  return buildReportingTagsFormState(state, {
    ...state.reportingTagsForm.reportingTags,
    selections: [],
    insertions: newInsertions,
    deletions: newDeletions,
  })
}

function _buildInitialReportingTagsFormState(associatedEntityName: AssociatedEntityName, reportingTags = []) {
  return {
    selections: [],
    insertions: {},
    updates: _buildReportingTagsFormData(associatedEntityName, reportingTags),
    deletions: {},
  }
}

function _buildReportingTagsFormData(associatedEntityName: AssociatedEntityName, reportingTags) {
  const formData = {}

  reportingTags.forEach((reportingTag) => {
    formData[reportingTag.id] = dataToFormData(reportingTag, getReportingTagFields(associatedEntityName, true), false)
    formData[reportingTag.id].isGlobalChange = false
  })

  return formData
}

function hasReportingTagsChanges(form) {
  return Object.keys(form.reportingTags.updates).some((key) => form.reportingTags.updates[key].isGlobalChange) ||
    Object.keys(form.reportingTags.insertions).length > 0 ||
    Object.keys(form.reportingTags.deletions).length > 0
}

function isReportingTagsFormValid(reportingTagsForm) {
  const reportingTags = getReportingTagsFormItems(reportingTagsForm)
  return reportingTags.every((reportingTag) => (
    reportingTag.reportingTagId.value &&
    reportingTag.reportingTagOptionId.value
  ))
}

export function updateReportingTagsForm(newReportingTagsForm, associatedEntityName: AssociatedEntityName) {
  return async function updateLineItemsFormThunk(dispatch) {
    dispatch({
      type: associatedEntityNameDispatchKeyDict[associatedEntityName].updateForm,
      payload: newReportingTagsForm,
    })
  }
}

export function initReportingTagsForm(reportingTags, associatedEntityName: AssociatedEntityName) {
  return async function initReportingTagsFormThunk(dispatch) {
    dispatch({
      type: associatedEntityNameDispatchKeyDict[associatedEntityName].initForm,
      payload: reportingTags,
    })
  }
}

export function deleteReportingTagsFromSelection(associatedEntityName: AssociatedEntityName) {
  return async function deleteReportingTagsFromSelectionThunk(dispatch, getState) {
    dispatch({ type: associatedEntityNameDispatchKeyDict[associatedEntityName].deleteFromSelection })
    return getReportingTagsForm(associatedEntityName, getState())
  }
}

export function updateReportingTagsFormSelections(selections, associatedEntityName: AssociatedEntityName) {
  return async function updateLineItemsFormSelectionsThunk(dispatch) {
    dispatch({
      type: associatedEntityNameDispatchKeyDict[associatedEntityName].setSelection,
      payload: selections,
    })
  }
}

export function getReportingTagAssociationTitle(association) {
  return association.reportingTagName
}

async function fetchAssociatedReportingTagsByIds(entityName: AssociatedEntityName, ids: []) {
  if (ids.length === 0) return []

  let url = ''
  switch (entityName) {
  case 'purchase-order-items':
    url = `/new_api/purchase-orders/items/reporting-tags/`
    break
  case 'sales-order-items':
    url = `/new_api/sales-orders/items/reporting-tags/`
    break
  case 'items':
    url = `/new_api/inventories/templates/reporting-tags/`
    break
  case 'cards':
    throw new Error(`Not implemented for entity: ${entityName}`)
  }

  url += ids.join(',')

  const { isSuccess, result } = await safeFetchJson(
    buildGetUrl(url, { includeDeleted: true }))

  return isSuccess && !isJob(result) ? result.map((tag) => parseReportingTag(entityName, tag)) : []
}

export async function fetchItemReportingTagsByIds(ids: []) {
  return fetchAssociatedReportingTagsByIds('items', ids)
}

export async function fetchPurchaseOrderItemsReportingTagsByIds(ids: []) {
  return fetchAssociatedReportingTagsByIds('purchase-order-items', ids)
}

export async function fetchSalesOrderItemsReportingTagsByIds(ids: []) {
  return fetchAssociatedReportingTagsByIds('sales-order-items', ids)
}
