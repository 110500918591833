import { unit as evaluateUnit, pow, abs } from 'mathjs'

const translatorDict = {
  't': 'tonne',
  'st': 'ton',
  'po': 'in',
  'arch': 'in',
  'pi': 'ft',
  'po2': 'sqin',
  'pi2': 'sqft',
  'po3': 'cuin',
  'pmp': 'cuin',
  'pi3': 'cuft',
  'tasse': 'cup',
  'pinte': 'pt',
  'oz': { volume: 'floz' },
}

export const units = {
  qty: ['un'],
  weight: ['mg', 'g', 'kg', 't', 'oz', 'lb', 'st'],
  length: ['mm', 'cm', 'm', 'po', 'pi', 'arch', 'yd'],
  surface: ['mm2', 'cm2', 'm2', 'po2', 'pi2'],
  volume: ['mm3', 'cm3', 'm3', 'L', 'mL', 'tasse', 'oz', 'pinte', 'gal', 'po3', 'pi3', 'pmp'],
}

function _convertFromBase(
  dimension: string,
  measure: string | number = 0,
  unit: string,
  baseUnit?: string,
  isDollar?: boolean,
  forceNumeric?: boolean,
): string | number {
  if (!dimension || !unit) return +measure

  const _baseUnit = baseUnit ?? _getBaseUnits()[dimension]
  const _measure = _parseMeasure(measure, unit, true, isDollar)
  if (dimension === 'qty') return _measure

  const _unit = _translateUnit(dimension, unit)
  const evaluatedMeasure = +evaluateUnit(_measure, isDollar ? `${_baseUnit}^-1` : _baseUnit)
    .to(isDollar ? `${_unit}^-1` : _unit)
    .toNumber()
    .toPrecision(15)

  if (!forceNumeric) return formatMeasure(evaluatedMeasure, unit)
  return evaluatedMeasure
}

export function convertFromBase(
  dimension: string,
  measure: string | number,
  unit: string,
  forceNumeric: boolean,
  baseUnit?: string,
): string | number {
  return _convertFromBase(dimension, measure, unit, baseUnit, false, forceNumeric)
}

export function convertFromDollarPerBase(
  dimension: string,
  measure: string | number,
  unit: string,
  baseUnit?: string,
): number {
  return +_convertFromBase(dimension, measure, unit, baseUnit, true, true)
}

function _convertToBase(
  dimension: string,
  measure: string | number = 0,
  unit: string,
  baseUnit?: string,
  isDollar = false,
): number {
  if (!dimension || !unit) return +measure

  const _baseUnit = baseUnit ?? _getBaseUnits()[dimension]

  const numericMeasure = _parseMeasure(measure, unit, false, isDollar)
  if (dimension === 'qty') return numericMeasure

  const _unit = _translateUnit(dimension, unit)
  return +evaluateUnit(numericMeasure, isDollar ? `${_unit}^-1` : _unit)
    .to(isDollar ? `${_baseUnit}^-1` : _baseUnit)
    .toNumber()
    .toPrecision(15)
}

export function convertToBase(
  dimension: string,
  measure: string | number = 0,
  unit: string,
  baseUnit?: string,
): number {
  return _convertToBase(dimension, measure, unit, baseUnit)
}

export function convertToDollarPerBase(
  dimension: string,
  measure: string | number = 0,
  unit: string,
  baseUnit?: string,
): number {
  return _convertToBase(dimension, measure, unit, baseUnit, true)
}

function _parseMeasure(measure: string | number, unit: string, isFrom: boolean, isDollar: boolean) {
  let _measure = +measure || 0

  if (unit === 'arch' && !isFrom) {
    _measure = _convertArchToInch(measure)
  } else if (unit === 'pmp') {
    if ((isFrom && !isDollar) || (!isFrom && isDollar)) _measure /= 144
    else _measure *= 144
  }

  return _measure
}

function _translateUnit(dimension: string, unit: string): string {
  const translation = translatorDict[unit]
  if (typeof translation === 'string') return translation
  if (typeof translation?.[dimension] === 'string') return translation[dimension]
  return unit
}

function _getBaseUnits() {
  return {
    qty: 'un',
    weight: 'g',
    length: 'mm',
    surface: 'mm2',
    volume: 'mm3',
    time: 'second',
  }
}

export function formatMeasure(measure: number, unit: string): number | string {
  switch (unit) {
  case 'arch': {
    const isNegative = measure < 0
    const _measure = Math.abs(measure)
    const leftOver = _measure % 12 % 1
    const fraction = _getFractionFromLeftOver(leftOver)
    const feet = `${Math.floor(_measure / 12)}'`
    const inches = `${Math.floor(_measure % 12)} ${fraction}`.trim()

    return `${isNegative ? '- ' : ''}${feet}-${inches}"`
  }
  default: {
    return measure
  }
  }
}

function _getFractionFromLeftOver(leftOver: number): string {
  let bestFit = [0, 0]
  let bestScore = _getL1Error(0, leftOver)

  let score: number
  for (let n = 0; n < 5; n++) {
    for (let x = 0; x < +pow(2, n); x++) {
      score = _getL1Error(leftOver, x / +pow(2, n))
      if (score < bestScore) {
        bestScore = score
        bestFit = [x, n]
      }
    }
  }

  let fraction = ''
  if (bestFit[0]) {
    fraction = `${bestFit[0]}/${pow(2, bestFit[1])}`
  }

  return fraction
}

function _getL1Error(x: number, y: number) {
  return abs(x - y)
}

function _convertArchToInch(archValue: string | number) {
  const _archValue = archValue.toString()
  const tokenList = _archTokenizer(_archValue)
  const tokenDict = _archTokenListToDict(tokenList)
  const toInch = tokenDict.ft * 12 + tokenDict.in + tokenDict.fr
  return _archValue.startsWith('-') ? toInch * -1 : toInch
}

function _archTokenizer(string: string): string[] {
  const tokenList = []
  const archTokenList = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.', ',', '/', '\'', '"']

  let char: string
  let token = ''
  for (let i = 0; i<string.length; i++) {
    char = string[i]
    if (!archTokenList.includes(char)) {
      if (token.length) {
        tokenList.push(token)
        token = ''
      }
    } else {
      token += char
    }
  }
  if (token.length) tokenList.push(token)
  return tokenList
}

function _archTokenListToDict(tokenList: string[]) {
  const tokenDict = { ft: 0, in: 0, fr: 0 }
  let nbUnitMarked = 0

  tokenList.forEach((token) => {
    if (token.includes('\'')) {
      tokenDict.ft = parseInt(token)
      nbUnitMarked += 1
    } else if (token.includes('/') || token.includes('.') || token.includes(',')) {
      tokenDict.fr = _fractionToFloat(token)
      nbUnitMarked += 1
    } else if (token.includes('"')) {
      tokenDict.in = parseInt(token)
      nbUnitMarked += 1
    }
  })

  if (nbUnitMarked != tokenList.length) {
    if (tokenList.length === 3) {
      if (!tokenDict.ft) tokenDict.ft = parseInt(tokenList[0])
      if (!tokenDict.in) tokenDict.in = parseInt(tokenList[1])
      if (!tokenDict.fr) tokenDict.fr = _fractionToFloat(tokenList[2])
    } else if (tokenList.length === 2) {
      if (!tokenList[1].includes('/') && !tokenList[1].includes('.') && !tokenList[1].includes(',')) {
        if (!tokenDict.ft) tokenDict.ft = parseInt(tokenList[0])
        if (!tokenDict.in) tokenDict.in = parseInt(tokenList[1])
      } else {
        if (!tokenDict.in) tokenDict.in = parseInt(tokenList[0])
        if (!tokenDict.fr) tokenDict.fr = _fractionToFloat(tokenList[1])
      }
    } else if (!tokenDict.in) {
      tokenDict.in = _fractionToFloat(tokenList[0])
    }
  }
  return tokenDict
}

function _fractionToFloat(string: string) {
  let value: number
  string = string.replace(',', '.')

  if (string.includes('/')) {
    const valList = string.split('/')
    value = parseFloat(valList[0]) / parseFloat(valList[1])
  } else {
    value = parseFloat(string)
  }

  return value
}
