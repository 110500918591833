import { createSlice } from '@reduxjs/toolkit'

import { prefixItem } from 'components/alix-front/smart-grid/columns/utils'

import { buildGetUrl } from 'utils/api'
import { filterObjectKeys } from 'utils/mapperHelper'
import { safeFetchJson } from 'utils/safeFetch'

import { _fetchItemByIds } from 'reducers/items/itemsSlice'

import {
  parsePurchaseOrder,
  getFields as getPurchaseOrderFields,
  lineItemFields,
  defaultPurchaseOrderMapData,
  parsePurchaseOrderLineItem,
} from './shared'
import { UPDATE_CREATING_PURCHASE_ORDER_ITEMS, UPDATE_PURCHASE_ORDER_ITEMS } from './types/actions'

const initialState = {
  data: [],
  count: 0,
  fields: lineItemFields,
}

export const _prefixPurchaseOrderItem = '$item$'

export const prefixPurchaseOrderItem = (item) => prefixItem(item, _prefixPurchaseOrderItem)

export const prefixPurchaseOrder = (item) => prefixItem(item, 'purchaseOrder')

export function getFieldsJoinedToPurchaseOrder() {
  const poFields = getPurchaseOrderFields()

  const merged = {
    ...poFields,
    ...prefixPurchaseOrderItem(initialState.fields),
    id: initialState.fields.id,
    purchaseOrderId: poFields.id,
  }

  return merged
}

export function parseJoinedToPurchaseOrder(purchaseOrder = {}, mapData = {}) {
  const isPrimaryLanguage = mapData.primaryLanguage === purchaseOrder.supplier_language
  const documentLanguage = purchaseOrder.config?.language ?
    `${purchaseOrder.config.language}-CA` :
    purchaseOrder.supplier_language
  const isDocumentPrimaryLanguage = mapData.primaryLanguage === documentLanguage

  const parsedPurchaseOrder = parsePurchaseOrder(purchaseOrder, mapData)
  const item = filterObjectKeys(purchaseOrder, 'purchaseorderitemview_')

  const parsedItem = parsePurchaseOrderLineItem(
    item, mapData.defaultUnits, isPrimaryLanguage, isDocumentPrimaryLanguage,
  )

  const merged = {
    ...parsedPurchaseOrder,
    ...prefixPurchaseOrderItem(parsedItem),
    id: parsedItem.id,
    purchaseOrderId: parsedPurchaseOrder.id,
  }

  return merged
}

const purchaseOrderItemsSlice = createSlice({
  name: 'purchaseOrderItems',
  initialState,
  reducers: {
    setPurchaseOrderItems: (state, action) => {
      state.data = action.payload.data
    },
    setPurchaseOrderCount: (state, action) => {
      state.count = action.payload.count
    },
  },
})

export const { setPurchaseOrderItems, setPurchaseOrderCount } = purchaseOrderItemsSlice.actions

export function fetchPurchaseOrderItems(data = {}, mapData = {}) {
  return async function(dispatch) {
    const result = await fetchPurchaseOrderItemByIds(data.purchaseOrderItemIds, mapData)

    dispatch({ type: UPDATE_PURCHASE_ORDER_ITEMS, payload: result })
    return result
  }
}

export async function fetchPurchaseOrderItemByIds(ids, data = {}) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson(
    buildGetUrl(`/new_api/purchase-orders/items/${ids.join(',')}`, data),
  )

  return isSuccess ? result.map((lineItem) => parsePurchaseOrderLineItem(lineItem)) : []
}

export function updateCreatingPurchaseOrderItems(templateIds) {
  return async function(dispatch, getState) {
    if (!templateIds?.length) return []
    const result = await _fetchItemByIds(templateIds)
    const companyInfo = getState().companies
    const accountingSettings = getState().settings.global.accounting_accounts
    const accountingAccountConfig = accountingSettings?.[companyInfo?.preferredCompany?.id] || {}

    dispatch({ type: UPDATE_CREATING_PURCHASE_ORDER_ITEMS, payload: result, config: accountingAccountConfig })
  }
}

const _getDefaultFetchMapData = () => ({
  joinToPurchaseOrderItems: true,
  excludeItems: true,
  ...defaultPurchaseOrderMapData,
})

export function _fetchPurchaseOrderItems(data = {}, mapData = {}) {
  return async function(dispatch) {
    const { isSuccess, result } = await safeFetchJson(
      buildGetUrl(
        `/new_api/purchase-orders`,
        {
          ...data,
          ..._getDefaultFetchMapData(),
        },
      ),
    )

    const formattedData = isSuccess ? result.map((lineItem) => parseJoinedToPurchaseOrder(lineItem, mapData)) : []

    dispatch(setPurchaseOrderItems({ data: formattedData }))

    return formattedData
  }
}

const getCount = (result) => {
  result = Array.isArray(result) ? result[0] : result

  return result?.count || 0
}

export function _fetchPurchaseOrderItemCount(data = {}) {
  return async function(dispatch) {
    const { isSuccess, result } = await safeFetchJson(
      buildGetUrl(
        `/new_api/purchase-orders/count`,
        {
          ...data,
          ..._getDefaultFetchMapData(),
        },
      ),
    )

    const count = isSuccess ? getCount(result) : 0

    dispatch(setPurchaseOrderCount({ count }))

    return count
  }
}

export default purchaseOrderItemsSlice.reducer
