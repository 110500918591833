import { createSlice } from '@reduxjs/toolkit'

import { buildGetUrl } from 'utils/api'
import { safeFetchJson } from 'utils/safeFetch'

import { getLineItemFields, parseJoinedShipmentItem, parseShipmentItem } from './shipmentsSlice'

const initialState = {
  data: [],
  count: 0,
  fields: getLineItemFields(),
}

const shipmentsItemsSlice = createSlice({
  name: 'shipmentLineItems',
  initialState,
  reducers: {
    setShipmentItems: (state, action) => {
      state.data = action.payload.data
    },
    setShipmentItemsCount: (state, action) => {
      state.count = action.payload.count
    },
  },
})

export async function fetchShipmentItemsbyIds(ids, data) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson(`/new_api/shipments/line-items/${ids}`)

  return isSuccess ? result.map((shipmentItem) => parseShipmentItem(shipmentItem, data)) : []
}

export const { setShipmentItems, setShipmentItemsCount } = shipmentsItemsSlice.actions

export function fetchShipmentItems(data = {}, mapData = {}) {
  return async function(dispatch) {
    const { isSuccess, result } = await safeFetchJson(
      buildGetUrl(`/new_api/shipments/line-items/`, data),
    )

    return isSuccess ? result.map((lineItem) => parseShipmentItem(lineItem, mapData)) : []
  }
}

const getCount = (result) => {
  result = Array.isArray(result) ? result[0] : result

  return result?.count || 0
}

export function fetchShipmentItemsCount(data = {}) {
  return async function(dispatch) {
    const { isSuccess, result } = await safeFetchJson(
      buildGetUrl(`/new_api/shipments/line-items/count`, data),
    )

    return isSuccess ? getCount(result) : 0
  }
}

export function _fetchShipmentsItemsJoined(data = {}, mapData = {}) {
  return async function(dispatch) {
    const { isSuccess, result } = await safeFetchJson(
      buildGetUrl(`/new_api/shipments/`, {
        ...data,
        joinToShipmentItems: true,
      }),
    )

    const parsedData = isSuccess ? result.map((shipment) => parseJoinedShipmentItem(shipment, mapData)) : []

    dispatch(setShipmentItems({ data: parsedData }))

    return parsedData
  }
}

export function _fetchShipmentItemsJoinedCount(data = {}) {
  return async function(dispatch) {
    const { isSuccess, result } = await safeFetchJson(
      buildGetUrl(`/new_api/shipments/count`, {
        ...data,
        joinToShipmentItems: true,
      }),
    )

    const count = isSuccess ? getCount(result) : 0

    dispatch(setShipmentItemsCount({ count }))

    return count
  }
}

export default shipmentsItemsSlice.reducer
