export const GET_COST = 'GET_COST'
export const GET_ITEM_COST = 'GET_ITEM_COST'
export const ERROR_GET_COST = 'ERROR_GET_COST'
export const ERROR_GET_ITEM_COST = 'ERROR_GET_ITEM_COST'
export const CLEAR_GET_ITEM_COST = 'CLEAR_ERROR_GET_ITEM_COST'
export const CLEAR_GET_COST = 'CLEAR_ERROR_GET_COST'

export const GET_CONSUMPTION_PLANNED_PRICES = 'GET_CONSUMPTION_PLANNED_PRICES'
export const ERROR_GET_CONSUMPTION_PLANNED_PRICES = 'ERROR_GET_CONSUMPTION_PLANNED_PRICES'
export const CLEAR_GET_CONSUMPTION_PLANNED_PRICES = 'CLEAR_ERROR_GET_CONSUMPTION_PLANNED_PRICES'
