
import { buildGetUrl, parse } from 'utils/api'
import { dataToFormData, objectToInsertData } from 'utils/mapperHelper'
import { safeFetch, safeFetchJson } from 'utils/safeFetch'

import {
  GET_TARES_TYPES_COUNT,
  GET_TARE_TYPE,
  GET_TARE_TYPES,
  CREATE_TARE_TYPE,
  UPDATE_TARE_TYPE,
  SET_IS_CREATE,
  RESET_FORM,
  CLEAR_TARE_TYPE,
  SET_GLOBAL_FORM,
  DELETE_TARE_TYPE,
} from './types'

const dataSetName = 'tare'
const fields = getFields()
const initialState = {
  dataSetName,
  fields,
  tares: [],
  activeTare: getDefaultTare(),
  activeForm: getDefaultForm(),
}

export default function taresReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
  case GET_TARES_TYPES_COUNT: {
    return {
      ...state,
      taresCount: payload,
    }
  }
  case GET_TARE_TYPE: {
    return buildTareState(state, payload)
  }
  case GET_TARE_TYPES: {
    return {
      ...state,
      tares: payload,
    }
  }
  case CREATE_TARE_TYPE: {
    return buildTareState(state, payload)
  }
  case UPDATE_TARE_TYPE: {
    return buildTareState(state, payload)
  }
  case SET_IS_CREATE: {
    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        isCreate: payload,
      },
    }
  }
  case RESET_FORM: {
    const globalForm = dataToFormData(state.activeTare, getFields(true))

    const newActiveForm = {
      ...state.activeForm,
      isValid: isFormValid(globalForm),
      resetCount: state.activeForm.resetCount +1,
      global: globalForm,
    }

    return {
      ...state,
      activeForm: {
        ...newActiveForm,
        hasChanges: hasChanges(newActiveForm),
      },
    }
  }
  case CLEAR_TARE_TYPE: {
    return {
      ...state,
      activeTare: getDefaultTare(),
      activeForm: getDefaultForm(),
    }
  }
  case SET_GLOBAL_FORM: {
    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        hasChanges: hasChanges({ ...state.activeForm, global: payload }),
        isValid: isFormValid(payload),
        global: payload,
      },
    }
  }
  default:
    return state
  }
}

export function getFields(editOnly) {
  const editFields = {
    'id': { dataSetName, dbField: 'id', isEdit: false },
    'name': { dataSetName, dbField: 'name', isEdit: true },
    'weight': { dataSetName, dbField: 'weight', isEdit: true, formDefaultValue: 0 },
    'weightUnit': { dataSetName, dbField: 'weight_unit', isEdit: true },
  }

  if (editOnly) {
    return editFields
  }

  const fields = {
    'createdDate': { dataSetName, dbField: 'created_date', type: 'date' },
    'createdBy': { dataSetName, dbField: 'created_by', type: 'string' },
    'createdById': { dataSetName, dbField: 'created_by_id', type: 'id' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'date' },
    'modifiedBy': { dataSetName, dbField: 'modified_by', type: 'string' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id', type: 'id' },
  }

  return { ...fields, ...editFields }
}

export async function fetchTaresByIds(ids, data) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson(
    buildGetUrl(`/new_api/tares/${ids}`, data),
  )

  return isSuccess ? result.map((item) => parseTare(item)) : []
}

export function getTareTitle(tare) {
  return tare.name
}

export function fetchTares(data = {} ) {
  return async function fetchTaresThunk(dispatch) {
    const tares = await _fetchTares(data)
    dispatch({ type: GET_TARE_TYPES, payload: tares })
    return tares
  }
}

export async function _fetchTares(data = {}) {
  let tares = []

  try {
    const result = await(await safeFetch(buildGetUrl(`/new_api/tares`, data))).json()
    if (result.isSuccess) {
      tares = result.result.map((tare) => parseTare(tare))
    }
  } catch (error) {
    console.error(error)
  }

  return tares
}

export function fetchTare(tareId) {
  return async function fetchTareThunk(dispatch) {
    if (tareId === 'new') {
      dispatch({ type: SET_IS_CREATE, payload: true })
      return null
    } else {
      let parsedTare = null
      try {
        const result = await(await safeFetch(`/new_api/tares/${tareId}`)).json()
        if (result.isSuccess) {
          const [tare] = result.result
          parsedTare = parseTare(tare)
          dispatch({ type: GET_TARE_TYPE, payload: parsedTare })
        }
      } catch (error) {
        console.error(error)
      }
      return parsedTare
    }
  }
}

export function fetchTaresCount(data) {
  return async function fetchTaresCountThunk(dispatch) {
    const count = await _fetchTaresCount(data)
    dispatch({ type: GET_TARES_TYPES_COUNT, payload: count })
    return count
  }
}

export async function _fetchTaresCount(data) {
  let count = 0

  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/tares/count', data))).json()
    if (result.isSuccess) {
      count = +result.result[0].count || 0
    }
  } catch (err) {
    console.error(err)
  }

  return count
}

export function resetForm(dispatch) {
  dispatch({ type: RESET_FORM })
}

export function clearTare(dispatch) {
  dispatch({ type: CLEAR_TARE_TYPE })
}

export function createTare(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: objectToInsertData(data) }),
  }

  return async function createTareThunk(dispatch) {
    try {
      const result = await (await safeFetch(`/new_api/tares`, requestOptions)).json()
      const payload = result.isSuccess ? parseTare(result.result) : null
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: CREATE_TARE_TYPE, payload, error })

      return payload
    } catch (error) {
      dispatch({ type: CREATE_TARE_TYPE, error })
    }
  }
}

export function updateTare(data) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: objectToInsertData(data) }),
  }

  return async function updateTareThunk(dispatch) {
    try {
      const result = await (await safeFetch(`/new_api/tares/${data.id}`, requestOptions)).json()
      const [updated] = result.isSuccess ? result.result : []
      const payload = updated ? parseTare(updated) : null
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: UPDATE_TARE_TYPE, payload, error })
    } catch (error) {
      dispatch({ type: UPDATE_TARE_TYPE, error })
    }
  }
}

export function deleteTares(taresIds) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  }

  return async function deleteTaresThunk(dispatch) {
    try {
      const result = await (await safeFetch(`/new_api/tares/${taresIds}`, requestOptions)).json()
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: DELETE_TARE_TYPE, payload: result.isSuccess, error })

      return result
    } catch (error) {
      dispatch({ type: DELETE_TARE_TYPE, error })
    }
  }
}

export function updateGlobalFormFields(fieldValues) {
  return async function updateGlobalFormFieldsThunk(dispatch, getState) {
    const taresStore = getState().tares
    const payload = { ...taresStore.activeForm.global }

    fieldValues.forEach((fieldValue) => {
      payload[fieldValue.field] = {
        ...taresStore.activeForm.global[fieldValue.field],
        value: fieldValue.value,
        data: fieldValue.data,
        isChanged: true,
      }
    })

    dispatch({ type: SET_GLOBAL_FORM, payload })
  }
}

export function parseTare(tare = {}) {
  const options = {
    defaultData: getDefaultTare(),
    fields: initialState.fields,
    dataSetName,
  }

  return parse(tare, options)
}

function getDefaultTare() {
  return parse({}, { fields })
}

function getDefaultForm() {
  return {
    isCreate: false,
    hasChanges: false,
    isValid: false,
    resetCount: 0,
    global: dataToFormData(getDefaultTare(), getFields(true)),
  }
}

function buildTareState(state, payload) {
  if (!payload) {
    return state
  }

  const globalForm = dataToFormData(payload, getFields(true))
  const newActiveForm = {
    ...state.activeForm,
    isValid: isFormValid(globalForm),
    global: globalForm,
  }
  return {
    ...state,
    activeTare: payload,
    activeForm: {
      ...newActiveForm,
      hasChanges: hasChanges(newActiveForm),
    },
  }
}

function isFormValid(globalForm) {
  return !!globalForm.name.value && !!globalForm.weightUnit.value
}

function hasChanges(form) {
  return Object.keys(form.global).some((key) => form.global[key].isChanged)
}

