
import { buildGetUrl, parse } from 'utils/api'
import { safeFetch, safeFetchJson } from 'utils/safeFetch'
import { convertToBase } from 'utils/unitConverter'

import {
  GET_BILLS_COUNT,
  GET_BILLS,
  CLEAR_BILLS,
  GET_BILL_ITEMS_COUNT,
  GET_BILL_ITEMS,
  CLEAR_BILL_ITEMS,
} from './types'

const dataSetName = 'billView'
const itemDataSetName = 'billItemView'
const initialState = {
  dataSetName,
  fields: getFields(),
  bills: [],
  billsCount: 0,
  itemFields: getItemFields(),
  billItemsCount: 0,
  billItems: [],
}

export default function billsReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
  case GET_BILLS_COUNT: {
    return {
      ...state,
      billsCount: payload,
    }
  }
  case GET_BILLS: {
    return {
      ...state,
      bills: payload,
    }
  }
  case CLEAR_BILLS: {
    return {
      ...state,
      billsCount: 0,
      bills: [],
    }
  }
  case GET_BILL_ITEMS_COUNT: {
    return {
      ...state,
      billItemsCount: payload,
    }
  }
  case GET_BILL_ITEMS: {
    return {
      ...state,
      billItems: payload,
    }
  }
  case CLEAR_BILL_ITEMS: {
    return {
      ...state,
      billItemsCount: 0,
      billItems: [],
    }
  }
  default: {
    return state
  }
  }
}

export function getFields() {
  return {
    'id': { dataSetName, dbField: 'id', type: 'id' },
    'createdDate': { dataSetName, dbField: 'created_date', type: 'date' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'date' },
    'createdBy': { dataSetName, dbField: 'created_by' },
    'createdById': { dataSetName, dbField: 'created_by_id', type: 'id' },
    'modifiedBy': { dataSetName, dbField: 'modified_by' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id', type: 'id' },
    'companyId': { dataSetName, dbField: 'company_id', type: 'id' },
    'vendorId': { dataSetName, dbField: 'vendor_id', type: 'id', relationEntity: 'contacts' },
    'shipToAddressId': { dataSetName, dbField: 'ship_to_address_id', type: 'id', relationEntity: 'addresses' },
    'billToAddressId': { dataSetName, dbField: 'bill_to_address_id', type: 'id', relationEntity: 'addresses' },
    'name': { dataSetName, dbField: 'name' },
    'notes': { dataSetName, dbField: 'notes' },
    'referenceNumber': { dataSetName, dbField: 'reference_number' },
    'billDate': { dataSetName, dbField: 'bill_date', type: 'date' },
    'status': {
      dataSetName,
      dbField: 'status',
      customEventValueTranslationKey: (value) => `bills:status.${value ?? 'open'}`,
    },
    'paymentTerms': { dataSetName, dbField: 'payment_terms' },
    'subject': { dataSetName, dbField: 'subject' },
    'termsAndConditions': { dataSetName, dbField: 'terms_and_conditions' },
    'currencyId': { dataSetName, dbField: 'currency_id', type: 'id' },
    'exchangeRate': { dataSetName, dbField: 'exchange_rate' },
    'externalId': { dataSetName, dbField: 'external_id', type: 'id' },
    'config': { dataSetName, dbField: 'config', type: 'json' },
    'dueDate': { dataSetName, dbField: 'due_date', type: 'date' },
    'isOverdue': { dataSetName, dbField: 'is_overdue', type: 'boolean' },
    'vendorDisplayName': { dataSetName, dbField: 'vendor_display_name' },
    'vendorCompanyName': { dataSetName, dbField: 'vendor_company_name' },
    'currencyCode': { dataSetName, dbField: 'currency_code' },
    'currencySymbol': { dataSetName, dbField: 'currency_symbol' },
  }
}

export function getItemFields() {
  return {
    'id': { dataSetName: itemDataSetName, dbField: 'id', type: 'id' },
    'billId': { dataSetName: itemDataSetName, dbField: 'bill_id', type: 'id', relationEntity: 'bills' },
    'billName': { dataSetName: itemDataSetName, dbField: 'bill_name' },
    'billStatus': { dataSetName: itemDataSetName, dbField: 'bill_status' },
    'billIsOverdue': { dataSetName: itemDataSetName, dbField: 'bill_is_overdue', type: 'boolean' },
    'billExternalId': { dataSetName: itemDataSetName, dbField: 'bill_external_id', type: 'id' },
    'modifiedDate': { dataSetName: itemDataSetName, dbField: 'modified_date', type: 'date' },
    'modifiedBy': { dataSetName: itemDataSetName, dbField: 'modified_by' },
    'modifiedById': { dataSetName: itemDataSetName, dbField: 'modified_by_id', type: 'id' },
    'billed': { dataSetName: itemDataSetName, dbField: 'measure', type: 'measure' },
    'purchaseOrderMeasure': { dataSetName: itemDataSetName, dbField: 'purchase_order_measure', type: 'measure' },
    'purchaseOrderBilled': { dataSetName: itemDataSetName, dbField: 'purchase_order_billed_count' },
    'unit': { parse: getUnit },
    'dimension': { dataSetName: itemDataSetName, dbField: 'dimension_to_display' },
    'templateInventoryManagementType': {
      dataSetName: itemDataSetName,
      dbField: 'template_inventory_management_type',
      type: 'string',
    },
    'conversionFactor': { dataSetName: itemDataSetName, dbField: 'conversion_factor', parse: getConversionFactor },
    'unitCost': { dataSetName: itemDataSetName, dbField: 'unit_cost', type: 'currency' },
    'accountId': {
      dataSetName: itemDataSetName,
      dbField: 'account_id',
      type: 'id',
      relationEntity: 'chart-of-accounts',
    },
  }
}

export function fetchBillsCount(data) {
  return async function fetchBillsCountThunk(dispatch) {
    try {
      const result = await (await safeFetch(buildGetUrl('/new_api/bills/count', data))).json()
      if (result.isSuccess) {
        const count = +result.result[0].count || 0
        dispatch({ type: GET_BILLS_COUNT, payload: count })
        return count
      }

      return 0
    } catch (err) {
      console.error(err)
      return 0
    }
  }
}

export function fetchBills(data) {
  return async function fetchBillsThunk(dispatch) {
    let bills = []

    try {
      const result = await (await safeFetch(buildGetUrl('/new_api/bills', data))).json()
      if (result.isSuccess) {
        bills = result.result.map((bill) => parseBill(bill))
        dispatch({ type: GET_BILLS, payload: bills })
      }
    } catch (err) {
      console.error(err)
    }

    return bills
  }
}

export async function fetchBillsbyIds(ids, data) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson(
    buildGetUrl(`/new_api/bills/${ids}`, data),
  )

  return isSuccess ? result.map((bill) => parseBill(bill)) : []
}

export function getBillTitle(bill) {
  return bill.name
}

export async function updateBills(bills) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ bills }),
  }

  try {
    return (await safeFetch(`/api/integrations/bills/batch`, requestOptions)).json()
  } catch (error) {
    console.error(error)
  }
}

export function clearBills(dispatch) {
  dispatch({ type: CLEAR_BILLS })
}

export function fetchBillItemsCount(data) {
  return async function fetchBillItemsCountThunk(dispatch) {
    try {
      const result = await (await safeFetch(buildGetUrl('/new_api/bills/items/count', data))).json()
      if (result.isSuccess) {
        const count = +result.result[0].count || 0
        dispatch({ type: GET_BILL_ITEMS_COUNT, payload: count })
        return count
      }

      return 0
    } catch (err) {
      console.error(err)
      return 0
    }
  }
}

export function fetchBillItems(data, mapData) {
  return async function fetchBillItemsThunk(dispatch) {
    let billItems = []

    try {
      const result = await (await safeFetch(buildGetUrl('/new_api/bills/items', data))).json()
      if (result.isSuccess) {
        billItems = result.result.map((billItem) => parseBillItem(billItem, mapData))
        dispatch({ type: GET_BILL_ITEMS, payload: billItems })
      }
    } catch (err) {
      console.error(err)
    }

    return billItems
  }
}

export function clearBillItems(dispatch) {
  dispatch({ type: CLEAR_BILL_ITEMS })
}

export function parseBill(bill) {
  const options = {
    defaultData: getDefaultBill(),
    fields: initialState.fields,
    dataSetName,
  }
  return parse(bill, options)
}

function parseBillItem(billItem, mapData) {
  const options = {
    defaultData: getDefaultBillItem(),
    fields: initialState.itemFields,
    dataSetName: itemDataSetName,
    defaultUnits: mapData.defaultUnits,
  }
  return parse(billItem, options)
}

function getUnit(billItem, options = {}) {
  const defaultUnits = options.defaultUnits || {}
  return billItem.measure_unit || defaultUnits[billItem.dimension_to_display]
}

function getDefaultBill() {
  return parse({}, { fields: initialState.fields })
}

function getDefaultBillItem() {
  return parse({}, { fields: initialState.itemFields })
}

function getConversionFactor(lineItem, options = {}, isInitial = false) {
  if (isInitial) {
    return +lineItem.conversion_factor || 0
  }

  const dimension = lineItem.dimension_to_display
  const templateUnit = getTemplateUnit(lineItem, options)

  return +lineItem.conversion_factor > 0 ? +lineItem.conversion_factor : convertToBase(dimension, 1, templateUnit)
}

function getTemplateUnit(lineItem = {}, options = {}) {
  const dimension = lineItem.dimension_to_display
  const defaultUnits = options.defaultUnits || {}

  return lineItem.measure_unit || defaultUnits[dimension]
}
