export const GET_ATTRIBUTES = 'GET_ATTRIBUTES'
export const GET_ATTRIBUTES_COUNT = 'GET_ATTRIBUTES_COUNT'
export const GET_ATTRIBUTE_OPTIONS = 'GET_ATTRIBUTE_OPTIONS'
export const GET_ATTRIBUTE_OPTIONS_COUNT = 'GET_ATTRIBUTE_OPTIONS_COUNT'

export const CREATE_ATTRIBUTE = 'CREATE_ATTRIBUTE'
export const DELETE_ATTRIBUTE = 'DELETE_ATTRIBUTE'
export const UPDATE_ATTRIBUTE = 'UPDATE_ATTRIBUTE'

export const CREATE_OPTION = 'CREATE_OPTION'
export const DELETE_OPTION = 'DELETE_OPTION'
export const UPDATE_OPTION = 'UPDATE_OPTION'
