import { v4 as uuid } from 'uuid'

import {
  handleTemplateSupplierPriceUpdates,
  handleTemplateSupplierPricesFetch,
} from 'components/alix-front/purchase-orders/purchase-order/overview/utils'

import { buildGetUrl } from 'utils/api'
import { calculateCostWithDiscount } from 'utils/costCalculator'
import { isDateValid } from 'utils/dataUtils'
import { dateToStringWithoutTimezone } from 'utils/dateParser'
import { getFirstString } from 'utils/getFirstOfType'
import { dataToFormData, buildDictionary, mergeUnchangedFormData } from 'utils/mapperHelper'
import { safeFetch, safeFetchJson, parseError } from 'utils/safeFetch'

import { getFields as getAddressFields } from 'reducers/addresses/shared'
import { fetchContactDetails } from 'reducers/contacts/contactsSlice'
import { parseCurrency, fetchCurrencies as _fetchCurrencies } from 'reducers/currencies/currenciesSlice'
import { _fetchItemByIds as fetchItemTemplateByIds } from 'reducers/items/itemsSlice'
import { fetchPlantByIds } from 'reducers/plants/plantsSlice'
import {
  buildReportingTagsFormState,
  buildReportingTagsState,
  buildReportingTagsFormStateWithDeletions,
  formatReportingTagsForSaving,
  getDefaultReportingTagsForm,
  getReportingTagFields,
} from 'reducers/reporting-tags/reportingTagAssociationSlice'

import {
  fields,
  lineItemFields,
  dataSetName,
  defaultPurchaseOrderMapData,
  getDefaultPurchaseOrder,
  getFields,
  parsePurchaseOrder,
  getInventoryCount,
  getReceivingStatus,
  getLineItemFields,
  parsePurchaseOrderLineItem,
} from './shared'
import {
  GET_PURCHASE_ORDERS_COUNT,
  GET_PURCHASE_ORDERS,
  GET_PURCHASE_ORDER,
  CLEAR_PURCHASE_ORDER,
  DUPLICATE_PURCHASE_ORDER,
  CREATE_PURCHASE_ORDER,
  UPDATE_PURCHASE_ORDER,
  DELETE_PURCHASE_ORDER,
  GET_CURRENCIES,
  SET_IS_CREATE,
  SET_GLOBAL_FORM,
  SET_VENDOR_DETAILS,
  SET_BILL_TO_FORM,
  SET_SHIP_TO_FORM,
  SET_LINE_ITEM_MEASURE,
  SET_LINE_ITEM_INVENTORIES,
  SET_LINE_ITEMS_FORM,
  SET_LINE_ITEMS_FORM_SELECTIONS,
  DUPLICATE_LINE_ITEMS_FROM_SELECTION,
  DELETE_LINE_ITEMS_FROM_SELECTION,
  RESET_FORM,
  CLEAR_SELECTIONS,
  SET_PO_ITEM_REPORTING_TAGS_FORM,
  UPDATE_PURCHASE_ORDER_ITEMS,
  UPDATE_CREATING_PURCHASE_ORDER_ITEMS,
  INIT_PO_ITEM_REPORTING_TAGS_FORM,
  DELETE_PO_ITEM_REPORTING_TAGS_FROM_SELECTION,
  SET_PO_ITEM_REPORTING_TAGS_FORM_SELECTION,
} from './types/actions'

const itemEntityName = 'purchase-order-items'

const initialState = {
  dataSetName,
  fields,
  itemFields: lineItemFields,
  reportingTagFields: getReportingTagFields(itemEntityName),
  purchaseOrdersCount: 0,
  purchaseOrders: [],
  activePurchaseOrder: getDefaultPurchaseOrder(),
  activeForm: getDefaultForm(),
  reportingTagsForm: getDefaultReportingTagsForm(itemEntityName),
  currencies: [],
  baseCurrency: {},
  duplicatePurchaseOrder: null,
}

export default function purchaseOrdersReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
  case GET_PURCHASE_ORDERS_COUNT: {
    return {
      ...state,
      purchaseOrdersCount: payload,
    }
  }
  case GET_PURCHASE_ORDERS: {
    return {
      ...state,
      purchaseOrders: payload,
    }
  }
  case GET_PURCHASE_ORDER: {
    return buildPurchaseOrderState(state, payload)
  }
  case CREATE_PURCHASE_ORDER: {
    return buildPurchaseOrderState(state, payload)
  }
  case UPDATE_PURCHASE_ORDER: {
    return buildPurchaseOrderState(state, payload)
  }
  case CLEAR_PURCHASE_ORDER: {
    return {
      ...state,
      activePurchaseOrder: getDefaultPurchaseOrder(),
      activeForm: getDefaultForm(),
    }
  }
  case DUPLICATE_PURCHASE_ORDER: {
    return {
      ...state,
      duplicatePurchaseOrder: JSON.parse(JSON.stringify({ ...state.activePurchaseOrder, status: 'draft' })),
    }
  }
  case GET_CURRENCIES: {
    const baseCurrency = payload.find((currency) => currency.isBase)
    let currencyInfo = state.activeForm.currencyInfo
    if (!currencyInfo.currencyCode) {
      currencyInfo = parseCurrency(
        state.activePurchaseOrder,
        state.activeForm.global.vendorId.details,
        baseCurrency,
        state.activeForm.isCreate,
      )
    }

    return {
      ...state,
      currencies: payload,
      baseCurrency,
      activeForm: {
        ...state.activeForm,
        currencyInfo,
      },
    }
  }
  case SET_IS_CREATE: {
    const globalForm = state.duplicatePurchaseOrder ?
      dataToFormData(state.duplicatePurchaseOrder, getFields(true, true), true) :
      state.activeForm.global

    globalForm.orderDate = {
      ...globalForm.orderDate,
      value: dateToStringWithoutTimezone(undefined, true),
      isChanged: true,
    }

    const lineItemsForm = state.duplicatePurchaseOrder ?
      buildInitialLineItemsFormState(state.duplicatePurchaseOrder.lineItems, true) :
      state.activeForm.lineItems

    const billToForm = state.duplicatePurchaseOrder ? dataToFormData(
      state.duplicatePurchaseOrder.billTo,
      getAddressFields(true),
      true,
    ) : state.activeForm.billTo

    const shipToForm = state.duplicatePurchaseOrder ? dataToFormData(
      state.duplicatePurchaseOrder.shipTo,
      getAddressFields(true),
      true,
    ) : state.activeForm.shipTo

    const currencyInfo = state.duplicatePurchaseOrder ? parseCurrency(
      state.duplicatePurchaseOrder,
      undefined,
      state.baseCurrency,
    ) : getDefaultCurrencyInfo()

    const activePurchaseOrder = state.duplicatePurchaseOrder ? {
      ...state.duplicatePurchaseOrder,
      subtotal: getSubtotalFromForm(lineItemsForm),
    } : state.activePurchaseOrder

    const newActiveForm = {
      ...state.activeForm,
      isCreate: payload,
      global: globalForm,
      lineItems: lineItemsForm,
      billTo: billToForm,
      shipTo: shipToForm,
      isValid: isFormValid(activePurchaseOrder, globalForm, lineItemsForm),
      currencyInfo,
    }

    return {
      ...state,
      duplicatePurchaseOrder: null,
      activePurchaseOrder,
      activeForm: {
        ...newActiveForm,
        hasChanges: hasChanges(newActiveForm),
      },
    }
  }
  case SET_GLOBAL_FORM: {
    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        hasChanges: hasChanges({ ...state.activeForm, global: payload }),
        isValid: isFormValid(state.activePurchaseOrder, payload, state.activeForm.lineItems),
        global: payload,
      },
    }
  }
  case SET_VENDOR_DETAILS: {
    const vendorDetails = payload.vendorDetails
    const currencyInfo = parseCurrency(
      state.activePurchaseOrder,
      vendorDetails,
      state.baseCurrency,
      state.activeForm.isCreate,
    )

    const newGlobalForm = {
      ...state.activeForm.global,
      vendorId: {
        ...state.activeForm.global.vendorId,
        value: vendorDetails.id,
        isChanged: true,
        details: vendorDetails,
      },
    }
    const newLineItemsForm = {
      ...state.activeForm.lineItems,
      insertions: payload.insertions,
    }
    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        hasChanges: hasChanges({ ...state.activeForm, global: newGlobalForm, lineItems: newLineItemsForm }),
        isValid: isFormValid(state.activePurchaseOrder, newGlobalForm, newLineItemsForm),
        global: newGlobalForm,
        lineItems: newLineItemsForm,
        currencyInfo,
      },
    }
  }
  case SET_BILL_TO_FORM: {
    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        hasChanges: hasChanges({ ...state.activeForm, billTo: payload }),
        billTo: payload,
      },
    }
  }
  case SET_SHIP_TO_FORM: {
    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        hasChanges: hasChanges({ ...state.activeForm, shipTo: payload }),
        shipTo: payload,
      },
    }
  }
  case SET_LINE_ITEM_MEASURE: {
    const newLineItems = [...state.activePurchaseOrder.lineItems]
    const lineItemIndex = newLineItems.findIndex((lineItem) => lineItem.id === payload.id)
    newLineItems[lineItemIndex].measure = payload.measure

    const newLineForm = { ...state.activeForm.lineItems }
    newLineForm.updates[payload.id].measure = {
      ...newLineForm.updates[payload.id].measure,
      value: payload.measure,
      isChanged: false,
    }

    return {
      ...state,
      activePurchaseOrder: {
        ...state.activePurchaseOrder,
        lineItems: newLineItems,
        lineItemDict: buildDictionary(newLineItems, 'id'),
      },
      activeForm: {
        ...state.activeForm,
        hasChanges: hasChanges({ ...state.activeForm, lineItems: newLineForm }),
        isValid: isFormValid(state.activePurchaseOrder, state.activeForm.global, newLineForm),
        lineItems: newLineForm,
      },
    }
  }
  case SET_LINE_ITEM_INVENTORIES: {
    const newLineItems = []
    let purchaseOrderInventories = []
    state.activePurchaseOrder.lineItems.forEach((lineItem, index) => {
      const inventories = payload[lineItem.id] || lineItem.inventories

      newLineItems[index] = {
        ...lineItem,
        inventories,
        receivingStatus: getReceivingStatus(inventories),
        inventoryCount: getInventoryCount(inventories),
      }

      purchaseOrderInventories = [...purchaseOrderInventories, ...inventories]
    })

    const newPurchaseOrderReceivingStatus = getReceivingStatus(purchaseOrderInventories)
    const newPurchaseOrders = state.purchaseOrders
    const activePurchaseOrderIndex = newPurchaseOrders.findIndex((po) => po.id === state.activePurchaseOrder.id)
    newPurchaseOrders[activePurchaseOrderIndex] = {
      ...newPurchaseOrders[activePurchaseOrderIndex],
      receivingStatus: newPurchaseOrderReceivingStatus,
    }

    return {
      ...state,
      purchaseOrders: newPurchaseOrders,
      activePurchaseOrder: {
        ...state.activePurchaseOrder,
        receivingStatus: newPurchaseOrderReceivingStatus,
        lineItems: newLineItems,
        lineItemDict: buildDictionary(newLineItems, 'id'),
      },
    }
  }
  case SET_LINE_ITEMS_FORM: {
    return buildLineItemsFormState(state, payload)
  }
  case SET_LINE_ITEMS_FORM_SELECTIONS: {
    return buildLineItemsFormState(state, { ...state.activeForm.lineItems, selections: payload })
  }
  case DUPLICATE_LINE_ITEMS_FROM_SELECTION: {
    const newInsertions = { ...state.activeForm.lineItems.insertions }
    let highestRankValue = Math.max(
      ...Object.keys(state.activeForm.lineItems.updates)
        .map((id) => state.activeForm.lineItems.updates[id].rank.value),
    )
    const itemFields = getLineItemFields(false, true)
    state.activeForm.lineItems.selections.forEach((selection) => {
      highestRankValue = highestRankValue + 1
      const newInsertionId = uuid()
      newInsertions[newInsertionId] = dataToFormData(
        { ...selection, id: newInsertionId, rank: highestRankValue, receivedCount: 0 },
        itemFields,
        true,
      )

      const toDuplicate = (
        state.activeForm.lineItems.insertions[selection.id] ||
        state.activeForm.lineItems.updates[selection.id] ||
        {}
      )
      Object.keys(toDuplicate)
        .filter((key) => toDuplicate[key].isChanged)
        .forEach((key) => {
          newInsertions[newInsertionId][key] = { ...toDuplicate[key] }
        })
    })
    return buildLineItemsFormState(state, { ...state.activeForm.lineItems, selections: [], insertions: newInsertions })
  }
  case DELETE_LINE_ITEMS_FROM_SELECTION: {
    const newInsertions = { ...state.activeForm.lineItems.insertions }
    const newDeletions = { ...state.activeForm.lineItems.deletions }
    state.activeForm.lineItems.selections.forEach((selection) => {
      if (newInsertions[selection.id]) {
        delete newInsertions[selection.id]
      } else {
        newDeletions[selection.id] = selection
      }
    })

    return buildLineItemsFormState(state, {
      ...state.activeForm.lineItems,
      selections: [],
      insertions: newInsertions,
      deletions: newDeletions,
    })
  }
  case RESET_FORM: {
    const globalForm = dataToFormData(
      state.activeForm.isCreate ? getDefaultPurchaseOrder() : state.activePurchaseOrder,
      getFields(true),
    )
    const lineItemsForm = buildInitialLineItemsFormState(
      state.activeForm.isCreate ? undefined : state.activePurchaseOrder.lineItems,
    )
    const billToForm = dataToFormData(
      state.activeForm.isCreate ? {} : state.activePurchaseOrder.billTo,
      getAddressFields(true),
    )

    const shipToForm = dataToFormData(
      state.activeForm.isCreate ? {} : state.activePurchaseOrder.shipTo,
      getAddressFields(true),
    )

    const currencyInfo = state.activeForm.isCreate ? getDefaultCurrencyInfo() : parseCurrency(
      state.activePurchaseOrder,
      globalForm.vendorId.details,
      state.baseCurrency,
      state.activeForm.isCreate,
    )
    const subtotal = getSubtotalFromForm(lineItemsForm)

    if (state.activeForm.isCreate) {
      globalForm.orderDate = {
        ...globalForm.orderDate,
        value: dateToStringWithoutTimezone(undefined, true),
        isChanged: true,
      }
    }

    const newActiveForm = {
      ...state.activeForm,
      isValid: isFormValid(state.activePurchaseOrder, globalForm, lineItemsForm),
      resetCount: state.activeForm.resetCount + 1,
      global: globalForm,
      lineItems: lineItemsForm,
      billTo: billToForm,
      shipTo: shipToForm,
      currencyInfo,
    }

    return {
      ...state,
      activePurchaseOrder: {
        ...state.activePurchaseOrder,
        subtotal,
      },
      activeForm: {
        ...newActiveForm,
        hasChanges: hasChanges(newActiveForm),
      },
    }
  }
  case CLEAR_SELECTIONS: {
    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        lineItems: {
          ...state.activeForm.lineItems,
          selections: [],
        },
      },
    }
  }

  case UPDATE_PURCHASE_ORDER_ITEMS: {
    const formLineItems = { ...state.activeForm.lineItems.updates }
    const purchaseOrderLineItems = state.activePurchaseOrder.lineItems.map((lineItem) => ({ ...lineItem }))

    payload.forEach((payloadLineItem) => {
      if (formLineItems[payloadLineItem.id]) {
        formLineItems[payloadLineItem.id] = dataToFormData(
          payloadLineItem,
          getLineItemFields(true, false), false,
        )
      }
      const lineItemIndex = purchaseOrderLineItems.findIndex((item) => item.id === payloadLineItem.id)
      if (lineItemIndex > -1) {
        purchaseOrderLineItems[lineItemIndex] = payloadLineItem
      }
    })

    return {
      ...state,
      activeForm: { ...state.activeForm, lineItems: { ...state.activeForm.lineItems, updates: formLineItems } },
      activePurchaseOrder: { ...state.activePurchaseOrder, lineItems: purchaseOrderLineItems },
    }
  }

  case UPDATE_CREATING_PURCHASE_ORDER_ITEMS: {
    const updatedInsertions = {}
    const lineItemFieldKeys = Object.keys(lineItemFields)
    const accountingAccountConfig = action.config

    payload.forEach((item) => {
      const targetInsertions = Object.values(state.activeForm.lineItems.insertions).filter((insertion) => {
        return insertion.templateId.value === item.id
      })

      targetInsertions.forEach((currentInsertion) => {
        const lineItemData = templateToLineItem(
          currentInsertion.id.value,
          item,
          lineItemFieldKeys,
          currentInsertion.rank.value,
          false,
          accountingAccountConfig,
        )

        const templateToLineItemFormData = dataToFormData(lineItemData, lineItemFields)

        updatedInsertions[currentInsertion.id.value] = mergeUnchangedFormData(
          currentInsertion,
          templateToLineItemFormData,
        )

        updatedInsertions[currentInsertion.id.value].unitCost = {
          ...currentInsertion.unitCost,
          value: calculateCostWithDiscount(
            updatedInsertions[currentInsertion.id.value].unitCostWithoutDiscount.value,
            updatedInsertions[currentInsertion.id.value].discount.value,
            false,
          ),
        }
      })
    })

    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        lineItems: {
          ...state.activeForm.lineItems,
          insertions: updatedInsertions,
        },
      },
    }
  }
  case SET_PO_ITEM_REPORTING_TAGS_FORM: {
    return buildReportingTagsFormState(state, payload)
  }
  case INIT_PO_ITEM_REPORTING_TAGS_FORM: {
    return buildReportingTagsState(state, payload, itemEntityName)
  }
  case SET_PO_ITEM_REPORTING_TAGS_FORM_SELECTION: {
    return buildReportingTagsFormState(state, { ...state.reportingTagsForm.reportingTags, selections: payload })
  }
  case DELETE_PO_ITEM_REPORTING_TAGS_FROM_SELECTION: {
    return buildReportingTagsFormStateWithDeletions(state)
  }
  default: {
    return state
  }
  }
}

// purchase orders exports
export async function fetchNextNumber(quantity = 1) {
  let nextNumber = ''

  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/purchase-orders/next-number', { quantity }))).json()
    if (result.isSuccess) {
      nextNumber = result.result
    }
  } catch (err) {
    console.error(err)
  }

  return nextNumber
}

export function fetchPurchaseOrdersCount(data) {
  return async function fetchPurchaseOrdersCountThunk(dispatch) {
    try {
      const result = await (await safeFetch(buildGetUrl(
        '/new_api/purchase-orders/count',
        { ...data, ...defaultPurchaseOrderMapData },
      ))).json()
      if (result.isSuccess) {
        const count = +result.result[0].count || 0
        dispatch({ type: GET_PURCHASE_ORDERS_COUNT, payload: count })
        return count
      }

      return 0
    } catch (err) {
      console.error(err)
      return 0
    }
  }
}

export function fetchPurchaseOrders(data = {}, mapData = {}) {
  return async function fetchPurchaseOrdersThunk(dispatch) {
    let purchaseOrders = []

    try {
      const result = await (await safeFetch(buildGetUrl('/new_api/purchase-orders', {
        ...data,
        excludeItems: true,
        calculateStatuses: true,
      }))).json()
      if (result.isSuccess) {
        purchaseOrders = result.result.map((purchaseOrder) => parsePurchaseOrder(purchaseOrder, mapData))
        dispatch({
          type: GET_PURCHASE_ORDERS,
          payload: purchaseOrders,
        })
      }
    } catch (err) {
      console.error(err)
    }

    return purchaseOrders
  }
}

export function fetchPurchaseOrder(purchaseOrderId, mapData, fetchTemplateSupplierPrices = false) {
  return async function fetchPurchaseOrderThunk(dispatch) {
    if (purchaseOrderId === 'new') {
      dispatch({ type: SET_IS_CREATE, payload: true })
      return null
    } else {
      let parsedPurchaseOrder = null
      try {
        const result = await (await safeFetch(buildGetUrl(
          `/new_api/purchase-orders/${purchaseOrderId}`,
          { calculateStatuses: true, fetchTemplateSupplierPrices },
        ))).json()
        if (result.isSuccess) {
          const [purchaseOrder] = result.result
          parsedPurchaseOrder = parsePurchaseOrder(purchaseOrder, mapData)
          parsedPurchaseOrder.vendorDetails = (await fetchContactDetails([parsedPurchaseOrder.vendorId]))[0]

          dispatch({ type: GET_PURCHASE_ORDER, payload: parsedPurchaseOrder })
        }
      } catch (err) {
        console.error(err)
      }
      return parsedPurchaseOrder
    }
  }
}

export async function fetchPurchaseOrderByIds(ids, data) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson(
    buildGetUrl(`/new_api/purchase-orders/${ids.join(',')}`, data),
  )

  return isSuccess ? result.map((item) => parsePurchaseOrder(item, data)) : []
}

/**
 * @param {Record<string, any>} purchaseOrder
 * @param {Record<string, any>} mapData
 * @param {import('types/report').RefreshReportData} refreshReportData
 */
export function createPurchaseOrder(purchaseOrder, mapData = {}, refreshReportData) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ purchaseOrder, refreshReportData }),
  }

  return async function createPurchaseOrderThunk(dispatch) {
    try {
      const result = await (await safeFetch(`/new_api/purchase-orders`, requestOptions)).json()
      const [created] = result.isSuccess ? result.result : []
      const payload = created ? parsePurchaseOrder(created, mapData) : null
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: CREATE_PURCHASE_ORDER, payload, error })

      return { payload, error, isSuccess: result.isSuccess }
    } catch (error) {
      dispatch({ type: CREATE_PURCHASE_ORDER, error })
      return { error, isSuccess: false }
    }
  }
}

export function saveReportingTags(purchaseOrderItemId, purchaseOrderId, mapData) {
  return async function saveReportingTagsThunk(dispatch, getState) {
    const reportingTagsForm = getState().purchaseOrders.reportingTagsForm
    const formattedReportingTags = formatReportingTagsForSaving(itemEntityName, reportingTagsForm.reportingTags)

    const purchaseOrderItem = {
      id: purchaseOrderItemId,
      purchase_order_id: purchaseOrderId,
      reportingTags: formattedReportingTags,
    }

    return _updatePurchaseOrderItem(dispatch, purchaseOrderItem, mapData)
  }
}

async function _updatePurchaseOrderItem(dispatch, purchaseOrderItem, mapData) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ purchaseOrderItem }),
  }

  try {
    const result = await (await safeFetch(
      `/new_api/purchase-orders/items/${purchaseOrderItem.id}`,
      requestOptions,
    )).json()
    const [updated] = result.isSuccess ? result.result : []
    const payload = updated ? [{
      ...parsePurchaseOrderLineItem(
        updated,
        mapData?.defaultUnits,
        mapData?.isPrimaryLanguage,
        mapData?.isDocumentPrimaryLanguage,
      ),
    }] : []
    const error = !result.isSuccess ? result.result : null
    dispatch({ type: UPDATE_PURCHASE_ORDER_ITEMS, payload, error })

    return { isSuccess: result.isSuccess, result: payload }
  } catch (error) {
    dispatch({ type: UPDATE_PURCHASE_ORDER_ITEMS, error })
    return { isSuccess: false, result: error }
  }
}

export function updatePurchaseOrder(purchaseOrder, mapData = {}) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ purchaseOrder }),
  }

  return async function updatePurchaseOrderThunk(dispatch, getState) {
    try {
      const result = await (await safeFetch(
        buildGetUrl(`/new_api/purchase-orders/${purchaseOrder.id}`, { fetchTemplateSupplierPrices: true })
        , requestOptions,
      )).json()
      const [updated] = result.isSuccess ? result.result : []
      const payload = updated ? parsePurchaseOrder(updated, mapData) : null
      const error = !result.isSuccess ? result.result : null

      dispatch({ type: UPDATE_PURCHASE_ORDER, payload, error })
      return { isSuccess: result.isSuccess, result }
    } catch (error) {
      dispatch({ type: UPDATE_PURCHASE_ORDER, error })
      return { isSuccess: false, result: error }
    }
  }
}

export function duplicatePurchaseOrder(dispatch) {
  dispatch({ type: DUPLICATE_PURCHASE_ORDER })
}

export function issuePurchaseOrder(purchaseOrderId, mapData = {}) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  }

  return async function issuePurchaseOrderThunk(dispatch) {
    try {
      const result = await (await safeFetch(`/new_api/purchase-orders/${purchaseOrderId}/issue`, requestOptions)).json()
      const [updated] = result.isSuccess ? result.result : []
      const payload = updated ? parsePurchaseOrder(updated, mapData) : null
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: UPDATE_PURCHASE_ORDER, payload, error })
    } catch (error) {
      dispatch({ type: UPDATE_PURCHASE_ORDER, error })
    }
  }
}

export function closePurchaseOrder(purchaseOrderId, mapData = {}) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  }

  return async function closePurchaseOrderThunk(dispatch) {
    try {
      const result = await (await safeFetch(`/new_api/purchase-orders/${purchaseOrderId}/close`, requestOptions)).json()
      const [updated] = result.isSuccess ? result.result : []
      const payload = updated ? parsePurchaseOrder(updated, mapData) : null
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: UPDATE_PURCHASE_ORDER, payload, error })
    } catch (error) {
      dispatch({ type: UPDATE_PURCHASE_ORDER, error })
    }
  }
}

export function cancelPurchaseOrder(purchaseOrderId, mapData = {}) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  }

  return async function cancelPurchaseOrderThunk(dispatch) {
    try {
      const result = await (
        await safeFetch(
          `/new_api/purchase-orders/${purchaseOrderId}/cancel`,
          requestOptions)
      ).json()
      const [updated] = result.isSuccess ? result.result : []
      const payload = updated ? parsePurchaseOrder(updated, mapData) : null
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: UPDATE_PURCHASE_ORDER, payload, error })
    } catch (error) {
      dispatch({ type: UPDATE_PURCHASE_ORDER, error })
    }
  }
}

export function reopenPurchaseOrder(purchaseOrderId, mapData = {}) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  }

  return async function reopenPurchaseOrderThunk(dispatch) {
    try {
      const result = await (
        await safeFetch(
          `/new_api/purchase-orders/${purchaseOrderId}/reopen`
          , requestOptions)
      ).json()
      const [updated] = result.isSuccess ? result.result : []
      const payload = updated ? parsePurchaseOrder(updated, mapData) : null
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: UPDATE_PURCHASE_ORDER, payload, error })
    } catch (error) {
      dispatch({ type: UPDATE_PURCHASE_ORDER, error })
    }
  }
}

export function deletePurchaseOrder(purchaseOrderId) {
  return async function deletePurchaseOrderThunk(dispatch) {
    try {
      const result = await (await safeFetch(`/new_api/purchase-orders/${purchaseOrderId}`, { method: 'DELETE' })).json()
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: DELETE_PURCHASE_ORDER, payload: result.isSuccess, error })

      return result
    } catch (error) {
      dispatch({ type: DELETE_PURCHASE_ORDER, error })
    }
  }
}

export function clearPurchaseOrder(dispatch) {
  dispatch({ type: CLEAR_PURCHASE_ORDER })
}

export async function adjustLineItemInventories(lineItem, splitDict, mergeDict, deltaDict) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ lineItem, splitDict, mergeDict, deltaDict }),
  }

  try {
    return await (await safeFetch(`/new_api/purchase-orders/items/inventories/adjust`, requestOptions)).json()
  } catch (error) {
    return parseError(error)
  }
}

// currency exports
export function fetchCurrencies() {
  return async function fetchCurrenciesThunk(dispatch) {
    const currencies = await _fetchCurrencies()
    dispatch({ type: GET_CURRENCIES, payload: currencies })
  }
}

// form exports
export function updateGlobalFormFields(fieldValues) {
  return async function updateGlobalFormFieldsThunk(dispatch, getState) {
    const purchaseOrdersStore = getState().purchaseOrders
    const payload = { ...purchaseOrdersStore.activeForm.global }

    fieldValues.forEach((fieldValue) => {
      payload[fieldValue.field] = {
        ...purchaseOrdersStore.activeForm.global[fieldValue.field],
        value: fieldValue.value,
        isChanged: !!fields[fieldValue.field]?.isEdit,
      }
    })

    dispatch({ type: SET_GLOBAL_FORM, payload })
  }
}

export function updateVendorDetails(id, itemData = {}) {
  return async function updateVendorDetailsThunk(dispatch) {
    const [vendorDetails] = await fetchContactDetails([id])

    const currentInsertions = Object.values(itemData.insertions || {})
    const itemIds = currentInsertions.map((insertion) => insertion.templateId.value)
    const itemDict = buildDictionary(
      await fetchItemTemplateByIds(itemIds, { ...itemData.mapData, vendorDetails }),
      'id',
    )

    const lineItemFieldKeys = Object.keys(lineItemFields)
    const newInsertions = {}

    const {
      supplierTemplatePricesByTemplateId,
      prices,
    } = await handleTemplateSupplierPricesFetch({
      supplierId: id,
      templateId: currentInsertions.map((insertion) => insertion.templateId.value),
    })

    currentInsertions.forEach((currentInsertion) => {
      const relatedItem = itemDict[currentInsertion.templateId.value]

      const templateVendorPrices = supplierTemplatePricesByTemplateId[currentInsertion.templateId.value] || []

      const lineItemData = templateToLineItem(
        currentInsertion.id.value,
        relatedItem,
        lineItemFieldKeys,
        currentInsertion.rank.value,
      )

      lineItemData.templateVendorPrices = templateVendorPrices
      const toBaseMeasure = (+lineItemData.measure || 0)*lineItemData.conversionFactor

      handleTemplateSupplierPriceUpdates({
        lineItemData,
        baseMeasure: toBaseMeasure,
        prices,
      })

      newInsertions[currentInsertion.id.value] = dataToFormData(lineItemData, lineItemFields)
      newInsertions[currentInsertion.id.value].templateId.isChanged = true
      newInsertions[currentInsertion.id.value].rank.isChanged = true
    })

    dispatch({ type: SET_VENDOR_DETAILS, payload: { vendorDetails, insertions: newInsertions } })
  }
}

export async function bill(data, lineItems, companyId) {
  try {
    if (!data.selectedBill.isCreate) {
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ bill: { line_items: { insertions: lineItems } } }),
      }

      return await (await safeFetch(`/api/integrations/bills/${data.selectedBill.id}`, requestOptions)).json()
    } else {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          bill: {
            company_id: companyId,
            name: data.billNumber,
            is_external: data.pushToZohoBooks,
            bill_date: data.billDate.toUTCString(),
            line_items: lineItems,
          },
        }),
      }

      return await (await safeFetch(`/api/integrations/bills`, requestOptions)).json()
    }
  } catch (error) {
    console.error(error)
    return error
  }
}

export function templateToLineItem(
  id,
  item,
  lineItemFieldKeys,
  rank,
  removeFieldsAfterMap = false,
  companyAccountingAccountsSettings = {},
) {
  const fieldMapper = {
    name: 'descriptionToVendorLanguage',
    dimension: 'dimension',
    unit: 'measureUnit',
    measure: ['measure', 'purchaseMoq'],
    unitCost: 'unitCost',
    unitCostWithoutDiscount: 'vendorListPrice',
    discount: 'vendorDiscount',
    conversionFactor: 'conversionFactor',
    initialConversionFactor: 'initialConversionFactor',
    templateUnit: 'initialMeasureUnit',
    partNumber: 'partNumber',
    notes: 'longDescriptionToVendorLanguage',
    expectedDeliveryDate: 'expectedDeliveryDate',
    currencyCode: 'currencyCode',
    currencySymbol: 'currencySymbol',
    currencyId: 'currencyId',
    accountId: companyAccountingAccountsSettings.use_asset_account_on_purchase &&
      item.inventoryManagementType === 'quantity_and_value' ?
      'assetAccountId' : 'expenseAccountId',
  }

  const fieldsToRemove = []
  const lineItemData = { id, rank }
  Object.keys(fieldMapper).forEach((field) => {
    const mapper = fieldMapper[field]
    if (removeFieldsAfterMap) {
      if (Array.isArray(mapper)) {
        fieldsToRemove.push(...mapper)
      } else {
        fieldsToRemove.push(mapper)
      }
    }
    lineItemData[field] = item[Array.isArray(mapper) ? mapper.find((m) => item[m]) : mapper] ?? null
  })
  Object.keys(item).forEach((key) => {
    const templateDataKey = `template${key.charAt(0).toUpperCase() + key.slice(1)}`
    if (lineItemFieldKeys.includes(templateDataKey)) {
      lineItemData[templateDataKey] = item[key]
      if (removeFieldsAfterMap) {
        fieldsToRemove.push(key)
      }
    }
  })
  fieldsToRemove.forEach((fieldToRemove) => delete item[fieldToRemove])

  return lineItemData
}

export function setLineItemMeasure(measureUpdate) {
  return async function setLineItemMeasureThunk(dispatch) {
    dispatch({ type: SET_LINE_ITEM_MEASURE, payload: measureUpdate })
  }
}

export function setLineItemInventories(newLineItemInventoriesDict) {
  return async function setLineItemInventoriesThunk(dispatch) {
    dispatch({ type: SET_LINE_ITEM_INVENTORIES, payload: newLineItemInventoriesDict })
  }
}

export function updateLineItemsForm(newLineItemsForm) {
  return async function updateLineItemsFormThunk(dispatch) {
    dispatch({ type: SET_LINE_ITEMS_FORM, payload: newLineItemsForm })
  }
}

export function updateLineItemsFormSelections(selections) {
  return async function updateLineItemsFormSelectionsThunk(dispatch) {
    dispatch({ type: SET_LINE_ITEMS_FORM_SELECTIONS, payload: selections })
  }
}

export function duplicateFromSelection(dispatch) {
  dispatch({ type: DUPLICATE_LINE_ITEMS_FROM_SELECTION })
}

export function deleteFromSelection(dispatch) {
  dispatch({ type: DELETE_LINE_ITEMS_FROM_SELECTION })
}

export function updateBillToForm(newBillToForm) {
  return async function updateBillToFormThunk(dispatch) {
    dispatch({ type: SET_BILL_TO_FORM, payload: newBillToForm })
  }
}

export function updateBillToFormFromId(id) {
  return async function updateBillToFormFromIdThunk(dispatch) {
    const [plant] = await fetchPlantByIds([id])
    dispatch({ type: SET_BILL_TO_FORM, payload: dataToFormData(plant.companyAddress, getAddressFields(true), true) })
  }
}

export function updateShipToForm(newShipToForm) {
  return async function updateShipToFormThunk(dispatch) {
    dispatch({ type: SET_SHIP_TO_FORM, payload: newShipToForm })
  }
}

export function updateShipToFormFromId(id, isDropship = true, isPlant = false) {
  return async function updateShipToFormFromIdThunk(dispatch) {
    let shipAddress
    if (isPlant && !isDropship) {
      const [plant] = await fetchPlantByIds([id])
      shipAddress = plant.address
    } else if (isDropship && !isPlant) {
      const [dropshipClient] = await fetchContactDetails([id])
      shipAddress = dropshipClient.shippingAddress
    }

    if (shipAddress) {
      dispatch({ type: SET_SHIP_TO_FORM, payload: dataToFormData(shipAddress, getAddressFields(true), true) })
    }
  }
}

export function updateGlobalFormPoInfo(poInfo) {
  return async function updateGlobalFormPoInfoThunk(dispatch, getState) {
    const _form = getState().purchaseOrders.activeForm
    const newNotes = _form.global.notes.value || poInfo?.value?.notes || ''
    const newTermsAndConditions = _form.global.termsAndConditions.value ||
      poInfo?.value?.termsAndConditions ||
      ''
    const payload = {
      ..._form.global,
      notes: {
        ..._form.global.notes,
        value: newNotes,
        isChanged: _form.isCreate ||
          newNotes != _form.global.notes.value,
      },
      termsAndConditions: {
        ..._form.global.termsAndConditions,
        value: newTermsAndConditions,
        isChanged: _form.isCreate ||
          newTermsAndConditions != _form.global.termsAndConditions.value,
      },
    }
    dispatch({ type: SET_GLOBAL_FORM, payload })
  }
}

export function resetForm(dispatch) {
  dispatch({ type: RESET_FORM })
}

function parseObjectToDB(object, fields) {
  return Object.keys(object).reduce((acc, key) => ({
    ...acc,
    [fields[key].dbField]: object[key],
  }), {})
}

export function splitLineItems(constants, line, items, mapData) {
  return async function splitLineItemsThunk(dispatch) {
    const itemsFields = getLineItemFields(false)

    const body = {
      purchaseOrderId: constants.purchaseOrderId,
      fromLineItem: parseObjectToDB(line, itemsFields),
      newLineItems: items.map((item) => parseObjectToDB(item, itemsFields)),
    }

    const { isSuccess, result } = await safeFetchJson('/new_api/purchase-orders/items/split', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })

    if (isSuccess) {
      dispatch({ type: CLEAR_SELECTIONS })
      dispatch({ type: UPDATE_PURCHASE_ORDER, payload: parsePurchaseOrder(result[0], mapData) })
    }

    return { isSuccess, result }
  }
}

// purchase order parsing functions
function buildPurchaseOrderState(state, payload) {
  if (!payload) {
    return state
  }

  const globalForm = dataToFormData(payload, getFields(true))
  const lineItemsForm = buildInitialLineItemsFormState(payload.lineItems)
  const billToForm = dataToFormData(payload.billTo, getAddressFields(true))
  const shipToForm = dataToFormData(payload.shipTo, getAddressFields(true))

  const newActiveForm = {
    ...state.activeForm,
    isCreate: false,
    isValid: isFormValid(payload, globalForm, lineItemsForm),
    global: globalForm,
    lineItems: lineItemsForm,
    billTo: billToForm,
    shipTo: shipToForm,
    currencyInfo: parseCurrency(payload, globalForm.vendorId.details, state.baseCurrency, false),
  }

  return {
    ...state,
    activePurchaseOrder: {
      ...payload,
      vendorDetails: payload.vendorDetails && payload.vendorDetails.id ?
        payload.vendorDetails :
        state.activePurchaseOrder.vendorDetails,
    },
    activeForm: {
      ...newActiveForm,
      hasChanges: hasChanges(newActiveForm),
    },
  }
}

// form parsing functions
function getSubtotalFromForm(lineItemsForm) {
  return getLineItemsFormItems(lineItemsForm).reduce((acc, lineItem) => {
    return acc + +((+lineItem.unitCost.value || 0) * (+lineItem.measure.value || 0)).toFixed(2)
  }, 0)
}

function getLineItemsFormItems(lineItemsForm) {
  const deletionIds = Object.keys(lineItemsForm.deletions)
  const insertions = Object.values(lineItemsForm.insertions)
  const updates = Object.keys(lineItemsForm.updates)
    .filter((id) => !deletionIds.includes(id))
    .map((id) => lineItemsForm.updates[id])

  return [...insertions, ...updates]
}

function buildLineItemsFormState(state, lineItems) {
  const subtotal = getSubtotalFromForm(lineItems)

  return {
    ...state,
    activePurchaseOrder: {
      ...state.activePurchaseOrder,
      subtotal,
    },
    activeForm: {
      ...state.activeForm,
      hasChanges: hasChanges({ ...state.activeForm, lineItems }),
      isValid: isFormValid(state.activePurchaseOrder, state.activeForm.global, lineItems),
      lineItems,
    },
  }
}

function buildInitialLineItemsFormState(lineItems, isDuplicate = false) {
  return {
    selections: [],
    insertions: isDuplicate ? buildLineItemsFormData(lineItems, true) : {},
    updates: !isDuplicate ? buildLineItemsFormData(lineItems) : {},
    deletions: {},
  }
}

function buildLineItemsFormData(lineItems = [], isDuplicate = false) {
  const formData = {}

  lineItems.forEach((lineItem) => {
    formData[lineItem.id] = dataToFormData(lineItem, getLineItemFields(!isDuplicate, isDuplicate), isDuplicate)
    formData[lineItem.id].isGlobalChange = isDuplicate
  })

  return formData
}

function hasChanges(form) {
  return Object.keys(form.global).some((key) => form.global[key].isChanged) ||
    Object.keys(form.billTo).some((key) => form.billTo[key].isChanged) ||
    Object.keys(form.shipTo).some((key) => form.shipTo[key].isChanged) ||
    Object.keys(form.lineItems.updates).some((key) => form.lineItems.updates[key].isGlobalChange) ||
    Object.keys(form.lineItems.insertions).length > 0 ||
    Object.keys(form.lineItems.deletions).length > 0
}

function getDefaultForm() {
  return {
    isCreate: false,
    hasChanges: false,
    isValid: false,
    resetCount: 0,
    global: dataToFormData(getDefaultPurchaseOrder(), getFields(true)),
    lineItems: buildInitialLineItemsFormState(),
    billTo: dataToFormData({}, getAddressFields(true)),
    shipTo: dataToFormData({}, getAddressFields(true)),
    currencyInfo: getDefaultCurrencyInfo(),
  }
}

function getDefaultCurrencyInfo() {
  return { exchangeRate: 1 }
}

function isFormValid(purchaseOrder, globalForm, lineItemsForm) {
  const lineItemsFormItems = getLineItemsFormItems(lineItemsForm)

  return !!globalForm.vendorId.value && lineItemsFormItems.length > 0 &&
          (!purchaseOrder.status || purchaseOrder.status === 'draft' || !!Date.parse(globalForm.orderDate.value)) &&
          !!globalForm.plantId.value &&
          (!globalForm.isDropship.value || (!!globalForm.isDropship.value && globalForm.dropshipClientId.value)) &&
          isDateValid(globalForm.orderDate.value)
}

export function getPurchaseOrderItemTitle(entityData) {
  return getFirstString(
    entityData.name,
    entityData.templateTitle,
    entityData.materialTitle,
    `[${entityData.id}]`,
  )
}

export function getPurchaseOrderTitle(entityData) {
  return entityData.poNumber
}
