import moment from 'moment'

export const addTimeToDate = (date: Date, seconds: number) => {
  return moment(date).add(seconds, 'seconds').format('YYYY-MM-DD')
}

export const isDateValid = (date: Date | null | string): boolean => {
  if (date === null || date === '') {
    return false
  }

  date = new Date(date)

  return !isNaN(date.getTime()) && date.toString() !== 'Invalid Date'
}

