import React, { forwardRef } from 'react'

type ErrorWrapperProps = {
  active?: boolean
  message?: string
  children: React.ReactNode
}

export type ErrorWrapperRef = {}

const ErrorWrapper = forwardRef<ErrorWrapperRef, ErrorWrapperProps>(({ active, message, children }, ref) => {
  return (
    <>
      {children}
      <div
        className="a-error"
        data-is-error={!!active}
      >
        {message || ''}
      </div>
    </>
  )
})

ErrorWrapper.displayName = 'ErrorWrapper'
export default ErrorWrapper
