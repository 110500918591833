import {
  POST_SETTING,
  CLEAR_SETTING_ERROR,
} from './types'

const initState = {
  settingError: {
    error: null,
    field: null,
  },
}

export default function settingsErrorReducer(state = initState, action) {
  const { error, field } = action

  switch (action.type) {
  case POST_SETTING: {
    return {
      ...state,
      settingError: { error, field },
    }
  }
  case CLEAR_SETTING_ERROR: {
    return {
      ...state,
      settingError: { ...initState.settingError },
    }
  }
  default:
    return state
  }
}

export function clearSettingError(dispatch) {
  dispatch({ type: CLEAR_SETTING_ERROR })
}
