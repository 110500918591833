
import { HubAppDispatch, HubRootState } from 'store'

import { delayPromise } from 'utils/fetchLoad'
import { safeFetch, safeFetchJson, isError } from 'utils/safeFetch'

import {
  SET_UID,
  SET_EMAIL_STATE,
  SET_PASSWORD_STATE,
  SET_EMAIL_NOT_FOUND_ERROR,
  SET_PASSWORD_RESET_ERROR,
  SET_PASSWORD_CREDS_ERROR,
  SET_EMAIL_NOT_ACTIVATED_ERROR,
  SET_EMAIL_UNKOWN_ERROR,
  SET_DEFAULT_LOGIN_DISABLED_ERROR,
} from './types'

type EmailState = { email: string, isValid: boolean }

type PasswordState = { password: string }

type State = { uid: string, email: EmailState, password: PasswordState }

export const initialState: State = {
  uid: null,
  email: { email: '', isValid: false },
  password: { password: '' },
}

export default function authenticationReducer(state = initialState, action) {
  const { payload } = action

  switch (action.type) {
  case SET_UID: {
    return { ...state, uid: payload as String }
  }
  case SET_EMAIL_STATE: {
    return { ...state, email: { ...state.email, ...payload as EmailState } }
  }
  case SET_PASSWORD_STATE: {
    return { ...state, password: { ...state.password, ...payload as PasswordState } }
  }
  default: {
    return state
  }
  }
}

export function assertUsername(useCase: string) {
  return async function assertUsernameThunk(dispatch: HubAppDispatch, getState: () => HubRootState) {
    const { uid, email: { email } } = getState().authentication
    const { isSuccess, result } = await delayPromise(
      (await safeFetch(`/sign-in/${uid}/assert-username`, {
        method: 'post',
        body: JSON.stringify({ username: email, useCase }),
        headers: { 'Content-Type': 'application/json' },
      })).json(),
    )

    if (!isSuccess) {
      if ( result?.message?.includes('User not found')) {
        dispatch({ type: SET_EMAIL_NOT_FOUND_ERROR })
      } else if (result?.message?.includes('Default login disabled')) {
        dispatch({ type: SET_DEFAULT_LOGIN_DISABLED_ERROR })
      } else dispatch({ type: SET_EMAIL_UNKOWN_ERROR })
    } else if (result?.redirectTo) window.location.href = result.redirectTo
    else dispatch({ type: SET_EMAIL_STATE, payload: { isValid: true } })
  }
}

export async function forgotPassword(dispatch: HubAppDispatch, getState: () => HubRootState): Promise<boolean> {
  const { uid, email: { email } } = getState().authentication
  const { isSuccess, result } = await delayPromise(safeFetchJson(`/sign-in/${uid}/forgot-password`, {
    method: 'post',
    body: JSON.stringify({ username: email }),
    headers: { 'Content-Type': 'application/json' },
  }))

  if (!isSuccess && isError(result)) {
    dispatch({ type: SET_EMAIL_STATE, payload: { isValid: false } })
    if (result.message === 'User not found') dispatch({ type: SET_EMAIL_NOT_ACTIVATED_ERROR })
    else dispatch({ type: SET_EMAIL_UNKOWN_ERROR })
  }

  return isSuccess
}

export function passwordReset(digest: string, password: string) {
  return async function passwordResetThunk(dispatch: HubAppDispatch, getState: () => HubRootState): Promise<unknown> {
    const { uid } = getState().authentication
    const { isSuccess, result } = await delayPromise(safeFetchJson(`/sign-in/${uid}/password/confirm`, {
      method: 'post',
      body: JSON.stringify({ digest, password }),
      headers: { 'Content-Type': 'application/json' },
    }))
    if (!isSuccess) dispatch({ type: SET_PASSWORD_RESET_ERROR })
    return result
  }
}

export async function signIn(dispatch: HubAppDispatch, getState: () => HubRootState): Promise<unknown> {
  const { uid, email: { email }, password: { password } } = getState().authentication
  const { isSuccess, result } = await delayPromise(safeFetchJson(`/sign-in/${uid}/login`, {
    method: 'post',
    body: JSON.stringify({ username: email, password }),
    headers: { 'Content-Type': 'application/json' },
  }))
  if (!isSuccess) {
    dispatch({ type: SET_EMAIL_STATE, payload: { isValid: false } })
    dispatch({ type: SET_PASSWORD_STATE, payload: { password: '' } })
    dispatch({ type: SET_PASSWORD_CREDS_ERROR })
  }
  return result
}

type SignUpFormType = {
  firstname: string
  lastname: string
  company: string
  email: string
  password: string
  language: string
  isCommunications: boolean
  phone?: string
  employeeCount?: string
}
export function signUp(form: SignUpFormType) {
  return async function signUpThunk(_: HubAppDispatch, getState: () => HubRootState): Promise<unknown> {
    const { uid } = getState().authentication
    const result = await delayPromise(safeFetchJson(`/sign-in/${uid}/sign-up`, {
      method: 'post',
      body: JSON.stringify(form),
      headers: { 'Content-Type': 'application/json' },
    }))
    return result
  }
}

type SignUpStateResult = {
  state: string
  returnTo: string
  isSuccess: boolean
}
export async function getSignUpState(uid: string): Promise<SignUpStateResult> {
  const result = { state: '', returnTo: '', isSuccess: false }

  try {
    const _result = await (await safeFetch(`/sign-in/${uid}/sign-up/state`)).json()
    result.state = _result.result?.state
    result.returnTo = _result.result?.returnTo
    result.isSuccess = _result.isSuccess
  } catch (err) {
    result.state = 'error'
  }

  return result
}

export function setUid(payload: string) {
  return function setEmailStateThunk(dispatch: HubAppDispatch) {
    dispatch({ type: SET_UID, payload })
  }
}

export function setEmailState(payload: Partial<EmailState>) {
  return function setEmailStateThunk(dispatch: HubAppDispatch) {
    dispatch({ type: SET_EMAIL_STATE, payload })
  }
}

export function setPasswordState(payload: Partial<PasswordState>) {
  return function setPasswordStateThunk(dispatch: HubAppDispatch) {
    dispatch({ type: SET_PASSWORD_STATE, payload })
  }
}
