import { buildGetUrl, parse } from 'utils/api'
import { safeFetchJson } from 'utils/safeFetch'

const dataSetName = 'contactPerson'
const fields = getFields()
const initialState = { dataSetName, fields }

export default function contactPeopleReducer(state = initialState, action) {
  switch (action.type) {
  default: {
    return state
  }
  }
}

export function getFields(editOnly) {
  const editFields = {
    id: { dataSetName, dbField: 'id', isEdit: false },
    firstName: { dataSetName, dbField: 'first_name', isEdit: true },
    lastName: { dataSetName, dbField: 'last_name', isEdit: true },
    email: { dataSetName, dbField: 'email', isEdit: true },
    phone: { dataSetName, dbField: 'phone', isEdit: true },
    mobile: { dataSetName, dbField: 'mobile', isEdit: true },
    department: { dataSetName, dbField: 'department', isEdit: true },
    skype: { dataSetName, dbField: 'skype', isEdit: true },
    designation: { dataSetName, dbField: 'designation', isEdit: true },
    isPrimaryContact: { dataSetName, dbField: 'is_primary_contact', isEdit: true, type: 'boolean' },
    isInvoice: { dataSetName, dbField: 'is_invoice', isEdit: true, type: 'boolean' },
  }

  if (editOnly) {
    return editFields
  }
  const fields = {
    fullName: { parse: (contactPerson) =>
      `${contactPerson?.first_name || ''} ${contactPerson?.last_name || ''}`.trim(),
    },
    createdDate: { dataSetName, dbField: 'created_date' },
    modifiedDate: { dataSetName, dbField: 'modified_date' },
    contactId: { dataSetName, dbField: 'contact_id', relationEntity: 'contacts', type: 'id' },
  }

  return { ...fields, ...editFields }
}

export async function fetchContactPersonByIds(ids, data) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson(
    buildGetUrl(`/new_api/contacts/contact-persons/${ids}`, data),
  )

  return isSuccess ? result.map((item) => parseContactPerson(item)) : []
}

export function parseContactPerson(contactPerson) {
  const options = {
    defaultData: parse({}, { fields }),
    fields: initialState.fields,
    dataSetName,
  }
  return parse(contactPerson, options)
}

export function getContactPersonTitle(contactPerson) {
  return `${contactPerson?.firstName || ''} ${contactPerson?.lastName || ''}`.trim()
}

/**
 * @param {string} contactId
 * @param {string} contactPersonId
 */
export async function _setContactPrimaryPerson(contactId, contactPersonId) {
  const response = await safeFetchJson(`/api/integrations/contacts/${contactId}`, {
    method: 'PUT',
    body: JSON.stringify({
      contact: {
        contactPersons: {
          updates: [{ id: contactPersonId, is_primary_contact: true }],
        },
      },
    }),
    headers: { 'Content-Type': 'application/json' },
  })

  return response
}
