import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch } from 'store'
import { ApiToSlice, BaseEntityApi, GetFields, Modify } from 'types/slices'

import { buildGetUrl, parse } from 'utils/api'
import { isJob, safeFetchJson } from 'utils/safeFetch'

const dataSetName = 'itemStepView'

const initialState = {
  dataSetName,
  fields: getFields(),
  cardSteps: [],
}

const cardStepsSlice = createSlice({
  name: 'cardSteps',
  initialState,
  reducers: {
    setCardSteps: (state, action) => {
      state.cardSteps = action.payload.data
    },
  },
})

export const { setCardSteps } = cardStepsSlice.actions

export type CardStepApi = Modify<{
  step_id: number
  item_id: string
  status: 1 | 2 | 3 | 4 | 5
  step_is_wip: boolean
  planned_worktime: number
  planned_equipment_time: number
  checklist_id: string
  is_planned_split: boolean
  is_constant_time: boolean
  planned_slot_id: string
  total_planned_worktime: number
  total_planned_equipment_time: number
  pass_count: number
  vs_id: string
  vs_rank: number
  vs_color: string
  step_stepid: string
  step_stepcode: string
  step_title: string
  step_iswip: boolean
  step_wipof: string
  step_isworktimeauto: boolean
  step_isshipment: boolean
  step_rank: number
  step_is_validation: boolean
  step_ismanualranking: boolean
  checklist_template_id: string
  created_from_id: string
  instructions: string
  trimmed_instructions: string
  display_title: string
  item_current_step_id: number
}, BaseEntityApi>

export type CardStep = ApiToSlice<CardStepApi, {
  step_stepid: 'stepStepId'
}>

type CardStepGetFields = GetFields<CardStepApi, CardStep>

export function getFields(editOnly = false): CardStepGetFields {
  const editFields: CardStepGetFields = {
    'id': { dataSetName, dbField: 'id', type: 'string' },
    'instructions': { dataSetName, dbField: 'instructions', type: 'string', isEdit: true },
    'trimmedInstructions': { dataSetName, dbField: 'trimmed_instructions', type: 'string', isEdit: true },
  }

  if (editOnly) return editFields

  const fields: CardStepGetFields = {
    'exist': { dataSetName, dbField: 'exist', type: 'boolean' },
    'createdDate': { dataSetName, dbField: 'created_date', type: 'timestamp' },
    'createdBy': { dataSetName, dbField: 'created_by', type: 'string' },
    'createdById': { dataSetName, dbField: 'created_by_id', type: 'string' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'timestamp' },
    'modifiedBy': { dataSetName, dbField: 'modified_by', type: 'string' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id', type: 'string' },
    'itemCurrentStepId': { dataSetName, dbField: 'item_current_step_id', type: 'integer' },
    'stepTitle': { dataSetName, dbField: 'step_title', type: 'string' },
    'stepId': { dataSetName, dbField: 'step_id', type: 'integer' },
    'stepStepId': { dataSetName, dbField: 'step_stepid', type: 'integer' },
    'vsId': { dataSetName, dbField: 'vs_id', type: 'integer' },
    'displayTitle': { dataSetName, dbField: 'display_title', type: 'string' },
  }

  return { ...fields, ...editFields }
}

export function getCardStepTitle(cardStep: CardStep): string {
  return cardStep.displayTitle
}

export async function fetchCardStepById(id: string, data?: Record<string, any>) {
  if (!id) return []

  const { isSuccess, result } = await safeFetchJson<CardStepApi>(
    buildGetUrl(`/new_api/items/steps/${id}`, data),
  )

  return isSuccess && !isJob(result) ?
    result.map((cardStep) => parseCardStep(cardStep)) :
    []
}

export function fetchCardSteps(fetchData?: Record<string, any>) {
  return async function fetchCardStepsThunk(dispatch: AppDispatch) {
    const data = await _fetchCardSteps(fetchData)
    dispatch(setCardSteps({ data }))
    return data
  }
}

export async function _fetchCardSteps(fetchData: Record<string, any>) {
  let cardSteps = []

  try {
    const { isSuccess, result } = await safeFetchJson<CardStepApi>(
      buildGetUrl('/new_api/items/steps', fetchData),
    )
    if (isSuccess && !isJob(result)) {
      cardSteps = result.map((cardStep) => parseCardStep(cardStep))
    }
  } catch (err) {
    console.error(err)
  }

  return cardSteps
}

export async function updateCardStep(id: string, updateData: Partial<CardStepApi>) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: Object.keys(updateData).map((column) => ({ column, value: updateData[column] })) }),
  }

  try {
    const { isSuccess, result } = await safeFetchJson<CardStepApi, false>(
      `/new_api/items/steps/${id}`, requestOptions,
    )
    const cardStep = isSuccess && !isJob<CardStepApi, false>(result) ?
      parseCardStep(result) :
      null
    const error = !isSuccess ? result : null
    return { isSuccess, cardStep, error }
  } catch (error) {
    console.error(error)
    return { isSuccess: false, error }
  }
}

export function parseCardStep(cardStep: CardStepApi): CardStep {
  const options = {
    defaultData: getDefaultCardStep(),
    fields: initialState.fields,
    dataSetName: dataSetName,
  }

  return parse(cardStep, options)
}

function getDefaultCardStep(): CardStep {
  return parse({}, { fields: initialState.fields })
}

export default cardStepsSlice.reducer
