import { ApiToSlice, BaseEntityApi, GetFields, Modify } from 'types/slices'

import { joinOnlyStrings } from 'utils/getFirstOfType'

export const dataSetName = 'address'

export const fields = getFields()

export type AddressApi = Modify<{
  attention: string
  address: string
  street2: string
  city: string
  state: string
  zip: string
  country: string
  phone: string
  contact_id: string
}, BaseEntityApi>

type Exceptions = {address: 'street'}

export type Address = ApiToSlice<
  Modify<AddressApi, {address_line_2: string, address_line_3: string, full_address: string}>,
  Exceptions
>

export function getFields(editOnly?: boolean): GetFields<AddressApi, Address> {
  const editFields: GetFields<AddressApi, Address> = {
    id: { dataSetName, dbField: 'id', isEdit: false, type: 'id' },
    attention: { dataSetName, dbField: 'attention', isEdit: true },
    street: { dataSetName, dbField: 'address', isEdit: true },
    street2: { dataSetName, dbField: 'street2', isEdit: true },
    city: { dataSetName, dbField: 'city', isEdit: true },
    state: { dataSetName, dbField: 'state', isEdit: true },
    zip: { dataSetName, dbField: 'zip', isEdit: true },
    country: { dataSetName, dbField: 'country', isEdit: true },
    phone: { dataSetName, dbField: 'phone', isEdit: true },
  }
  if (editOnly) {
    return editFields
  }

  const fields: GetFields<AddressApi, Address> = {
    createdDate: { dataSetName, dbField: 'created_date', type: 'date' },
    createdBy: { dataSetName, dbField: 'created_by' },
    createdById: { dataSetName, dbField: 'created_by_id', type: 'id' },
    modifiedDate: { dataSetName, dbField: 'modified_date', type: 'date' },
    modifiedBy: { dataSetName, dbField: 'modified_by' },
    modifiedById: { dataSetName, dbField: 'modified_by_id', type: 'id' },
    contactId: { dataSetName, dbField: 'contact_id', type: 'id', relationEntity: 'contacts' },
    addressLine2: { parse: (address) => joinOnlyStrings([address?.city, address?.state], ',').trim() },
    addressLine3: { parse: (address) => joinOnlyStrings([address?.zip, address?.country], ',').trim() },
    fullAddress: { parse: (address) => joinOnlyStrings([
      address?.address, address?.city, address?.state, address?.zip, address?.country,
    ], ', ').trim() },
  }

  return { ...fields, ...editFields }
}
