export const GET_VIEW = 'GET_VIEW'
export const CLEAR_VIEW = 'CLEAR_VIEW'

export const CREATE_VIEW = 'CREATE_VIEW'
export const UPDATE_VIEW = 'UPDATE_VIEW'
export const UPDATE_MODEL = 'UPDATE_VIEW_MODEL'
export const DELETE_VIEW = 'DELETE_VIEW'

export const CLEAR_CREATE_SUCCESS = 'CLEAR_VIEW_CREATE_SUCCESS'
export const CLEAR_UPDATE_SUCCESS = 'CLEAR_VIEW_UPDATE_SUCCESS'
export const CLEAR_DELETE_SUCCESS = 'CLEAR_VIEW_DELETE_SUCCESS'
export const CLEAR_CREATE_ERROR = 'CLEAR_VIEW_CREATE_ERROR'
export const CLEAR_UPDATE_ERROR = 'CLEAR_VIEW_UPDATE_ERROR'
export const CLEAR_MODEL_UPDATE_ERROR = 'CLEAR_VIEW_MODEL_UPDATE_ERROR'
export const CLEAR_DELETE_ERROR = 'CLEAR_VIEW_DELETE_ERROR'

export const SET_IS_CREATE = 'SET_VIEW_IS_CREATE'
export const SET_GLOBAL_FORM = 'SET_VIEW_GLOBAL_FORM'
export const SET_CONFIG_FORM = 'SET_VIEW_CONFIG_FORM'
export const RESET_FORM = 'RESET_VIEW_FORM'
