
import { buildGetUrl, parse } from 'utils/api'
import { dataToFormData, objectToInsertData, formKeyDataToObject } from 'utils/mapperHelper'
import { safeFetchJson } from 'utils/safeFetch'

import {
  GET_MATERIALS,
  GET_MATERIALS_COUNT,
  DELETE_MATERIAL,
  CREATE_MATERIAL,
  UPDATE_MATERIAL,
  SET_GLOBAL_FORM,
  SET_IS_CREATE,
  GET_MATERIAL,
  RESET_FORM,
  CLEAR_MATERIAL,
} from './types'

const dataSetName = 'material'
const fields = getFields()
const initialState = {
  dataSetName,
  fields,
  materialsCount: 0,
  materials: [],
  activeForm: getDefaultForm(),
  activeMaterial: getDefaultMaterial(),
}

export default function materialsReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
  case GET_MATERIALS_COUNT: {
    return {
      ...state,
      materialsCount: payload,
    }
  }
  case GET_MATERIALS: {
    return {
      ...state,
      materials: payload,
    }
  }
  case GET_MATERIAL: {
    return buildMaterialState(state, payload)
  }
  case SET_IS_CREATE: {
    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        isCreate: payload,
      },
    }
  }
  case CREATE_MATERIAL: {
    return buildMaterialState(state, payload)
  }
  case UPDATE_MATERIAL: {
    return buildMaterialState(state, payload)
  }
  case SET_GLOBAL_FORM: {
    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        hasChanges: hasChanges({ ...state.activeForm, global: payload }),
        isValid: isFormValid(payload),
        global: payload,
      },
    }
  }
  case CLEAR_MATERIAL: {
    return {
      ...state,
      activeMaterial: getDefaultMaterial(),
      activeForm: getDefaultForm(),
    }
  }
  case RESET_FORM: {
    const globalForm = dataToFormData(state.activeMaterial, getFields(true))

    const newActiveForm = {
      ...state.activeForm,
      isValid: isFormValid(globalForm),
      resetCount: state.activeForm.resetCount +1,
      global: globalForm,
    }

    return {
      ...state,
      activeForm: {
        ...newActiveForm,
        hasChanges: hasChanges(newActiveForm),
      },
    }
  }
  default: {
    return state
  }
  }
}

export function parseMaterial(material) {
  const options = {
    defaultData: getDefaultMaterial(),
    fields: initialState.fields,
    dataSetName,
  }
  return parse(material, options)
}

export function getFields(editOnly) {
  const editFields = {
    'id': { dataSetName, dbField: 'id', isEdit: false },
    'title': { dataSetName, dbField: 'title', isEdit: true },
    'code': { dataSetName, dbField: 'code', isEdit: true },
  }
  if (editOnly) {
    return editFields
  }

  const fields = {
    'createdDate': { dataSetName, dbField: 'created_date', type: 'date' },
    'createdBy': { dataSetName, dbField: 'created_by' },
    'createdById': { dataSetName, dbField: 'created_by_id' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'date' },
    'modifiedBy': { dataSetName, dbField: 'modified_by' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id' },
    'privateTag': { dataSetName, dbField: 'private_tag' },
  }

  return { ...fields, ...editFields }
}

function buildMaterialState(state, payload) {
  if (!payload) {
    return state
  }

  const globalForm = dataToFormData(payload, getFields(true))
  const newActiveForm = {
    ...state.activeForm,
    isCreate: false,
    isValid: isFormValid(globalForm),
    global: globalForm,
  }

  return {
    ...state,
    activeMaterial: payload,
    activeForm: {
      ...newActiveForm,
      hasChanges: hasChanges(newActiveForm),
    },
  }
}

export function resetForm(dispatch) {
  dispatch({ type: RESET_FORM })
}

export function fetchMaterialsCount(data) {
  return async function fetchMaterialsCountThunk(dispatch) {
    try {
      const result = await (await fetch(buildGetUrl(
        '/new_api/inventories/materials/count',
        { ...data },
      ))).json()
      if (result.isSuccess) {
        const count = +result.result[0].count || 0
        dispatch({ type: GET_MATERIALS_COUNT, payload: count })
        return count
      }

      return 0
    } catch (err) {
      console.error(err)
    }
    return 0
  }
}

export function fetchMaterials(data) {
  return async function fetchMaterialsThunk(dispatch) {
    let materials = []
    try {
      const result = await (await fetch(buildGetUrl(
        '/new_api/inventories/materials',
        { ...data },
      ))).json()
      if (result.isSuccess) {
        materials = result.result.map((material) => parseMaterial(material))
        dispatch({ type: GET_MATERIALS, payload: materials })
      }
    } catch (err) {
      console.error(err)
    }

    return materials
  }
}

export async function fetchMaterialsByIds(ids = []) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson(`/new_api/inventories/materials/${ids}`)

  return isSuccess ? result.map((material) => parseMaterial(material)) : []
}

export function deleteMaterial(materialId) {
  return async function deleteMaterialThunk(dispatch) {
    try {
      const result = await (await fetch(`/new_api/inventories/materials/${materialId}`, { method: 'DELETE' })).json()
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: DELETE_MATERIAL, payload: result.isSuccess, error })
      return result
    } catch (error) {
      dispatch({ type: DELETE_MATERIAL, error })
    }
  }
}

function getDefaultMaterial() {
  return parse({}, { fields })
}

export function saveMaterial(setPage) {
  return async function saveMaterialThunk(dispatch, getState) {
    const materialsStore = getState().materials
    const globalFormData = formKeyDataToObject(materialsStore.activeForm.global)
    if (materialsStore.activeForm.isCreate) {
      setPage((page) => ({ ...page, isCreating: true }))
      return _createMaterial(dispatch, globalFormData)
    } else {
      const material = {
        id: materialsStore.activeMaterial.id,
        ...globalFormData,
      }
      return _updateMaterial(dispatch, material)
    }
  }
}

async function _createMaterial(dispatch, material) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: objectToInsertData(material) }),
  }
  try {
    const result = await (await fetch(`/new_api/inventories/materials`, requestOptions)).json()
    const payload = result.isSuccess ? parseMaterial(result.result) : null
    const error = !result.isSuccess ? result.result : null
    dispatch({ type: CREATE_MATERIAL, payload, error })
    return { isCreate: true, material: payload }
  } catch (error) {
    dispatch({ type: CREATE_MATERIAL, error })
  }
}

async function _updateMaterial(dispatch, material) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: objectToInsertData(material) }),
  }
  try {
    const result = await (await fetch(`/new_api/inventories/materials/${material.id}`, requestOptions)).json()
    const [updated] = result.isSuccess ? result.result : []
    const payload = updated ? parseMaterial(updated) : null
    const error = !result.isSuccess ? result.result : null
    dispatch({ type: UPDATE_MATERIAL, payload, error })
    return { material: payload }
  } catch (error) {
    dispatch({ type: UPDATE_MATERIAL, error })
  }
}

export function fetchMaterial(materialId) {
  return async function fetchMaterialThunk(dispatch) {
    if (materialId === 'new') {
      dispatch({ type: SET_IS_CREATE, payload: true })
      return null
    } else {
      let parsedMaterial = null
      try {
        const result = await (await fetch(`/new_api/inventories/materials/${materialId}`)).json()
        if (result.isSuccess) {
          const [material] = result.result
          parsedMaterial = parseMaterial(material)
          dispatch({ type: GET_MATERIAL, payload: parsedMaterial })
        }
      } catch (err) {
        console.error(err)
      }
      return parsedMaterial
    }
  }
}

export function clearMaterial(dispatch) {
  dispatch({ type: CLEAR_MATERIAL })
}

export function updateGlobalFormFields(fieldValues) {
  return async function updateGlobalFormFieldsThunk(dispatch, getState) {
    const materialsStore = getState().materials
    const payload = { ...materialsStore.activeForm.global }

    fieldValues.forEach((fieldValue) => {
      payload[fieldValue.field] = {
        ...materialsStore.activeForm.global[fieldValue.field],
        value: fieldValue.value,
        data: fieldValue.data,
        isChanged: true,
      }
    })

    dispatch({ type: SET_GLOBAL_FORM, payload })
  }
}

function getDefaultForm() {
  return {
    isCreate: false,
    hasChanges: false,
    isValid: false,
    resetCount: 0,
    global: dataToFormData(getDefaultMaterial(), getFields(true)),
  }
}

function isFormValid(globalForm) {
  return !!globalForm.title.value
}

function hasChanges(form) {
  return Object.keys(form.global).some((key) => form.global[key].isChanged)
}

export function getTitle(material) {
  if (!material) return ''

  return material.title
}

