import { ApiToSlice, GetFields, Modify } from 'types/slices'

import { buildGetUrl, parse } from 'utils/api'
import { safeFetchJson } from 'utils/safeFetch'

const dataSetName = 'customField'
const fields = getFields()
const initialState = { dataSetName, fields }

export default function customFieldReducer(state = initialState, action) {
  switch (action.type) {
  default: {
    return state
  }
  }
}

export type CustomFieldApi = {
  id: string
  name: string
  value: string
  type: string
  custom_field_definition_name: string
  custom_field_definition_config: {
    step_id?: number,
    value?: any,
  },
}

export type CustomField = ApiToSlice<Modify<CustomFieldApi, {
  custom_field_definition_config: {
    stepId: number,
    value: any,
  }
}>>

export function getFields(): GetFields<CustomFieldApi, CustomField> {
  return {
    id: { dataSetName, dbField: 'id', isEdit: false },
    name: { dataSetName, dbField: 'name', isEdit: true },
    value: { dataSetName, dbField: 'value', isEdit: true },
    type: { dataSetName, dbField: 'type', isEdit: true },
    customFieldDefinitionName: { dataSetName, dbField: 'custom_field_definition_name', isEdit: true },
    customFieldDefinitionConfig: {
      dataSetName,
      dbField: 'custom_field_definition_config',
      isEdit: true,
      type: 'json',
      parse: (customField) => {
        return {
          stepId: customField?.custom_field_definition_config?.step_id,
          value: customField?.custom_field_definition_config?.value,
        }
      },
    },
  }
}

export async function fetchCustomFields(data) {
  let customFields = []
  try {
    const { isSuccess, result } = await safeFetchJson<CustomFieldApi>(buildGetUrl('/new_api/custom-fields', data))
    if (isSuccess && Array.isArray(result)) {
      customFields = result.map((item) => parseCustomField(item))
    }
  } catch (err) {}

  return customFields
}

export function parseCustomField(customField: CustomFieldApi): CustomField {
  const options = {
    defaultData: parse({}, { fields }),
    fields: initialState.fields,
    dataSetName,
  }
  return parse(customField, options)
}
