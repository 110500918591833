import { planningTypes as types } from './types'

const initState = {
  planningError: null,
  detailsError: null,
}

export default function planningErrorReducer(state = initState, action) {
  const { error } = action

  switch (action.type) {
  case types.GET_PLANNING: {
    return {
      ...state,
      planningError: error,
    }
  }
  default:
    return state
  }
}
