
import { buildGetUrl, parse } from 'utils/api'
import { safeFetch } from 'utils/safeFetch'

import { GET_STEPS } from './types'

const dataSetName = 'steps'
const slotsDataSetName = 'slots'
const slotEquipmentDataSetName = 'slotsEquipment'
export const initialState = {
  dataSetName,
  fields: getFields(),
  steps: [],
  slotsFields: getSlotsFields(),
  slots: [],
  slotsDataSetName,
}

export default function stepsReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
  case GET_STEPS: {
    return {
      ...state,
      steps: payload,
    }
  }
  default: {
    return state
  }
  }
}

export function getSlotsFields() {
  return {
    'id': { slotsDataSetName, dbField: 'id' },
    'step_id': { slotsDataSetName, dbField: 'step_id' },
    'displayName': { slotsDataSetName, dbField: 'name' },
    'isAvailable': { slotsDataSetName, dbField: 'isAvailable' },
    'itemCount': { slotsDataSetName, dbField: 'item_count' },
    'maxItemCount': { slotsDataSetName, dbField: 'max_item_count' },
    'equipments': { slotsDataSetName, dbField: 'equipments' },
  }
}

export function getSlotsEquipmentFields() {
  return {
    'id': { dataSetName: slotEquipmentDataSetName, dbField: 'id' },
    'slotId': { dataSetName: slotEquipmentDataSetName, dbField: 'slot_id' },
    'equipmentId': { dataSetName: 'equipment', dbField: 'id' },
    'equipmentTitle': { dataSetName: 'equipment', dbField: 'title' },
    'resourceCount': { dataSetName: slotEquipmentDataSetName, dbField: 'resourceCount' },
  }
}

export function getFields() {
  return {
    'id': { dataSetName, dbField: 'id' },
    'valuestreamId': { dataSetName, dbField: 'vsid' },
    'stepId': { dataSetName, dbField: 'stepid' },
    'stepCode': { dataSetName, dbField: 'stepcode' },
    'number': { dataSetName, dbField: 'number' },
    'title': { dataSetName, dbField: 'title' },
    'color': { dataSetName, dbField: 'color' },
    'isOperational': { dataSetName, dbField: 'isoperational' },
    'isWip': { dataSetName, dbField: 'iswip' },
    'wipOf': { dataSetName, dbField: 'wipof' },
    'closingStep': { dataSetName, dbField: 'closingstep' },
    'openingStep': { dataSetName, dbField: 'openingstep' },
    'sellingPrice': { dataSetName, dbField: 'sellingprice_calcul' },
    'resourceSellingPrice': { dataSetName, dbField: 'sellingprice_ressources' },
    'equipmentSellingPrice': { dataSetName, dbField: 'sellingprice_equipement' },
    'maxNbItems': { dataSetName, dbField: 'max_nb_items' },
    'nbItemsThreshold': { dataSetName, dbField: 'threshold_nb_items' },
    'isManualRanking': { dataSetName, dbField: 'ismanualranking' },
    'isMultiselect': { dataSetName, dbField: 'ismultiselect' },
    'isWorkTimeAuto': { dataSetName, dbField: 'isworktimeauto' },
    'isShipment': { dataSetName, dbField: 'isshipment' },
    'isPacemaker': { dataSetName, dbField: 'is_pacemaker' },
    'defaultCardTab': { dataSetName, dbField: 'default_card_tab' },
    'rank': { dataSetName, dbField: 'rank' },
    'isFinishedGood': { dataSetName, dbField: 'is_finished_good' },
    'isValidation': { dataSetName, dbField: 'is_validation' },
    'isInspection': { dataSetName, dbField: 'is_inspection' },
    'maxResourceTime': { dataSetName, dbField: 'max_res_time' },
    'maxEquipmentTime': { dataSetName, dbField: 'max_eq_time' },
    'maxResourceTimeUnit': { dataSetName, dbField: 'max_res_time_unit' },
    'maxEquipmentTimeUnit': { dataSetName, dbField: 'max_eq_time_unit' },
    'isMaxResourceTimeLock': { dataSetName, dbField: 'is_max_res_time_lock' },
    'isMaxEquipmentTimeLock': { dataSetName, dbField: 'is_max_eq_time_lock' },
    'isMaxNbItemsLock': { dataSetName, dbField: 'is_max_nb_items_lock' },
    'isOpenOnMove': { dataSetName, dbField: 'is_open_on_move' },
    'isRework': { dataSetName, dbField: 'is_rework' },
    'resourceCostRate': { dataSetName, dbField: 'resource_cost_rate' },
    'equipmentCostRate': { dataSetName, dbField: 'equipment_cost_rate' },
    'valuestream.id': { dataSetName: 'valuestreams', dbField: 'id', lookupInOptions: 'valuestream' },
    'valuestream.rank': { dataSetName: 'valuestreams', dbField: 'rank', lookupInOptions: 'valuestream' },
    'cardCount': { parse: ((step) => step.card_count) },
    'displayTitle': { parse: (step = {}) => {
      return `${step.stepcode ? `${step.stepcode} - ` : ''}${step.title}`.trim()
    } },
  }
}

export async function _fetchSteps(data) {
  let steps = []
  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/steps', data))).json()
    if (result.isSuccess) {
      steps = result.result.map((step) => parseStep(step))
    }
  } catch (error) {
    console.error(error)
  }
  return steps
}

export function fetchSteps() {
  return async function fetchStepsThunk(dispatch) {
    const steps = await _fetchSteps()
    dispatch({ type: GET_STEPS, payload: steps })
  }
}

export async function _fetchSlots(data) {
  let slots = []
  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/slots', data))).json()
    if (result.isSuccess) {
      slots = result.result.map((slot) => parseSlot(slot))
    }
  } catch (error) {
    console.error(error)
  }
  return slots
}

export async function fetchSlotsResources(data) {
  let resources = []
  try {
    const response = await (await safeFetch(buildGetUrl(`/new_api/slots/resources`, data))).json()
    if (response.isSuccess) {
      resources = response.result
    }
  } catch (error) {
    console.error(error)
  }
  return resources
}

export async function fetchSlotsEquipment(data) {
  let slots = []
  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/slots/equipments', data))).json()
    if (result.isSuccess) {
      slots = result.result.map((slot) => parseSlotEquipment(slot)).sort((item1, item2) => {
        let order = 0
        const aTitle = item1.equipmentTitle.toUpperCase()
        const bTitle = item2.equipmentTitle.toUpperCase()
        if (aTitle > bTitle) order = 1
        else if (aTitle < bTitle) order = -1
        return order
      })
    }
  } catch (error) {
    console.error(error)
  }
  return slots
}

export async function loginSlot(slotId, resourceId) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      data: [
        { column: 'resource_id', value: resourceId },
        { column: 'slot_id', value: slotId },
      ],
    }),
  }

  try {
    const result = await (await safeFetch('/new_api/slots/resources', requestOptions)).json()
    return result.isSuccess
  } catch (error) {
    console.error(error)
    return false
  }
}

export function parseStep(step) {
  const options = {
    defaultData: getDefaultStep(),
    fields: initialState.fields,
    dataSetName,
    valuestream: step.valuestream || {},
  }

  return parse(step, options)
}

function parseSlot(slot) {
  const options = {
    defaultData: getDefaultSlot(),
    fields: initialState.slotsFields,
    slotsDataSetName,
  }

  return parse(slot, options)
}

function parseSlotEquipment(slotEquipment) {
  const options = {
    fields: getSlotsEquipmentFields(),
    slotsDataSetName,
  }

  return parse(slotEquipment, options)
}

function getDefaultSlot() {
  return parse({}, { fields: initialState.slotsFields })
}

function getDefaultStep() {
  return parse({}, { fields: initialState.fields })
}
