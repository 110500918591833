
import { buildGetUrl, parse } from 'utils/api'
import { dataToFormData, objectToInsertData, formKeyDataToObject } from 'utils/mapperHelper'
import { safeFetchJson } from 'utils/safeFetch'

import {
  GET_RAWS,
  GET_RAWS_COUNT,
  DELETE_RAW,
  CREATE_RAW,
  UPDATE_RAW,
  SET_GLOBAL_FORM,
  SET_IS_CREATE,
  GET_RAW,
  RESET_FORM,
  CLEAR_RAW,
} from './types'

const dataSetName = 'raw'
const fields = getFields()
const initialState = {
  dataSetName,
  fields,
  rawsCount: 0,
  raws: [],
  activeForm: getDefaultForm(),
  activeRaw: getDefaultRaw(),
}

export default function rawsReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
  case GET_RAWS_COUNT: {
    return {
      ...state,
      rawsCount: payload,
    }
  }
  case GET_RAWS: {
    return {
      ...state,
      raws: payload,
    }
  }
  case GET_RAW: {
    return buildRawState(state, payload)
  }
  case SET_IS_CREATE: {
    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        isCreate: payload,
      },
    }
  }
  case CREATE_RAW: {
    return buildRawState(state, payload)
  }
  case UPDATE_RAW: {
    return buildRawState(state, payload)
  }
  case SET_GLOBAL_FORM: {
    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        hasChanges: hasChanges(payload),
        isValid: isFormValid(payload),
        global: payload,
      },
    }
  }
  case CLEAR_RAW: {
    return {
      ...state,
      activeRaw: getDefaultRaw(),
      activeForm: getDefaultForm(),
    }
  }
  case RESET_FORM: {
    const globalForm = dataToFormData(state.activeRaw, getFields(true))

    const newActiveForm = {
      ...state.activeForm,
      isValid: isFormValid(globalForm),
      resetCount: state.activeForm.resetCount +1,
      global: globalForm,
    }

    return {
      ...state,
      activeForm: {
        ...newActiveForm,
        hasChanges: hasChanges(newActiveForm),
      },
    }
  }
  default: {
    return state
  }
  }
}

export function parseRaw(raw) {
  const options = {
    defaultData: getDefaultRaw(),
    fields: initialState.fields,
    dataSetName,
  }
  return parse(raw, options)
}

export function getFields(editOnly) {
  const editFields = {
    'id': { dataSetName, dbField: 'id', isEdit: false },
    'imperialTitle': { dataSetName, dbField: 'imperial_title', isEdit: true },
    'metricTitle': { dataSetName, dbField: 'metric_title', isEdit: true },
    'title': { parse: (raw) => {
      if (raw.imperial_title && raw.metric_title) {
        return `${raw.imperial_title} | ${raw.metric_title}`
      } else if (raw.imperial_title) {
        return raw.imperial_title
      } else if (raw.metric_title) {
        return raw.metric_title
      }

      return ''
    } },
  }
  if (editOnly) {
    return editFields
  }

  const fields = {
    'createdDate': { dataSetName, dbField: 'created_date', type: 'date' },
    'createdBy': { dataSetName, dbField: 'created_by' },
    'createdById': { dataSetName, dbField: 'created_by_id' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'date' },
    'modifiedBy': { dataSetName, dbField: 'modified_by' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id' },
    'privateTag': { dataSetName, dbField: 'private_tag' },
  }

  return { ...fields, ...editFields }
}

function buildRawState(state, payload) {
  if (!payload) {
    return state
  }

  const globalForm = dataToFormData(payload, getFields(true))
  const newActiveForm = {
    ...state.activeForm,
    isCreate: false,
    isValid: isFormValid(globalForm),
    global: globalForm,
  }

  return {
    ...state,
    activeRaw: payload,
    activeForm: {
      ...newActiveForm,
      hasChanges: hasChanges(newActiveForm),
    },
  }
}

export function resetForm(dispatch) {
  dispatch({ type: RESET_FORM })
}

export function fetchRawsCount(data) {
  return async function fetchRawsCountThunk(dispatch) {
    try {
      const result = await (await fetch(buildGetUrl(
        '/new_api/inventories/raws/count',
        { ...data },
      ))).json()
      if (result.isSuccess) {
        const count = +result.result[0].count || 0
        dispatch({ type: GET_RAWS_COUNT, payload: count })
        return count
      }
    } catch (err) {
      console.error(err)
    }
    return 0
  }
}

export function fetchRaws(data) {
  return async function fetchRawsThunk(dispatch) {
    let raws = []
    try {
      const result = await (await fetch(buildGetUrl(
        '/new_api/inventories/raws',
        { ...data },
      ))).json()
      if (result.isSuccess) {
        raws = result.result.map((raw) => parseRaw(raw))
        dispatch({ type: GET_RAWS, payload: raws })
      }
    } catch (err) {
      console.error(err)
    }

    return raws
  }
}

export async function fetchRawsByIds(ids = []) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson(`/new_api/inventories/raws/${ids}`)

  return isSuccess ? result.map((raw) => parseRaw(raw)) : []
}

export function deleteRaw(rawId) {
  return async function deleteRawThunk(dispatch) {
    try {
      const result = await (await fetch(`/new_api/inventories/raws/${rawId}`, { method: 'DELETE' })).json()
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: DELETE_RAW, payload: result.isSuccess, error })
      return result
    } catch (error) {
      dispatch({ type: DELETE_RAW, error })
    }
  }
}

function getDefaultRaw() {
  return parse({}, { fields })
}

export function fetchRaw(rawId) {
  return async function fetchRawThunk(dispatch) {
    if (rawId === 'new') {
      dispatch({ type: SET_IS_CREATE, payload: true })
      return null
    } else {
      let parsedRaw = null
      try {
        const result = await (await fetch(`/new_api/inventories/raws/${rawId}`)).json()
        if (result.isSuccess) {
          const [raw] = result.result
          parsedRaw = parseRaw(raw)
          dispatch({ type: GET_RAW, payload: parsedRaw })
        }
      } catch (err) {
        console.error(err)
      }
      return parsedRaw
    }
  }
}

export function clearRaw(dispatch) {
  dispatch({ type: CLEAR_RAW })
}

// form exports
export function saveRaw(setPage) {
  return async function saveRawThunk(dispatch, getState) {
    const rawsStore = getState().raws
    const globalFormData = formKeyDataToObject(rawsStore.activeForm.global)
    if (rawsStore.activeForm.isCreate) {
      setPage((page) => ({ ...page, isCreating: true }))
      return _createRaw(dispatch, globalFormData )
    } else {
      const raw = {
        id: rawsStore.activeRaw.id,
        ...globalFormData,
      }
      return _updateRaw(dispatch, raw)
    }
  }
}

async function _createRaw(dispatch, raw) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: objectToInsertData(raw) }),
  }
  try {
    const result = await (await fetch(`/new_api/inventories/raws`, requestOptions)).json()
    const payload = result.isSuccess ? parseRaw(result.result) : null
    const error = !result.isSuccess ? result.result : null
    dispatch({ type: CREATE_RAW, payload, error })
    return { isCreate: true, raw: payload }
  } catch (error) {
    dispatch({ type: CREATE_RAW, error })
  }
}

async function _updateRaw(dispatch, raw) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: objectToInsertData({ ...raw, id: undefined }) }),
  }
  try {
    const result = await (await fetch(`/new_api/inventories/raws/${raw.id}`, requestOptions)).json()
    const [updated] = result.isSuccess ? result.result : []
    const payload = updated ? parseRaw(updated) : null
    const error = !result.isSuccess ? result.result : null
    dispatch({ type: UPDATE_RAW, payload, error })
    return { raw: payload }
  } catch (error) {
    dispatch({ type: UPDATE_RAW, error })
  }
}

export function updateGlobalFormFields(fieldValues) {
  return async function updateGlobalFormFieldsThunk(dispatch, getState) {
    const rawsStore = getState().raws
    const payload = { ...rawsStore.activeForm.global }

    fieldValues.forEach((fieldValue) => {
      payload[fieldValue.field] = {
        ...rawsStore.activeForm.global[fieldValue.field],
        value: fieldValue.value,
        data: fieldValue.data,
        isChanged: true,
      }
    })

    dispatch({ type: SET_GLOBAL_FORM, payload })
  }
}

function getDefaultForm() {
  return {
    isCreate: false,
    hasChanges: false,
    isValid: false,
    resetCount: 0,
    global: dataToFormData(getDefaultRaw(), getFields(true)),
  }
}

function isFormValid(globalForm) {
  return !!globalForm.imperialTitle.value
}

function hasChanges(global) {
  return Object.keys(global).some((key) => global[key].isChanged)
}

export function getRawTitle(raw) {
  if (!raw) return ''

  if (raw.imperialTitle && raw.metricTitle) {
    return `${raw.imperialTitle} | ${raw.metricTitle}`
  }

  return (raw.imperialTitle || '') + (raw.metricTitle || '')
}
