
import { buildGetUrl, parse } from 'utils/api'
import { dataToFormData } from 'utils/mapperHelper'
import { safeFetch, safeFetchJson } from 'utils/safeFetch'

import {
  GET_CONTACT_CATEGORIES_COUNT,
  GET_CONTACT_CATEGORIES,
  GET_CONTACT_CATEGORY,
  CLEAR_CONTACT_CATEGORY,
  CREATE_CONTACT_CATEGORY,
  UPDATE_CONTACT_CATEGORY,
  DELETE_CONTACT_CATEGORY,
  SET_IS_CREATE,
  SET_GLOBAL_FORM,
  RESET_FORM,
} from './types'

const dataSetName = 'contactCategory'
const fields = getFields()
const initialState = {
  dataSetName,
  fields,
  contactCategoriesCount: 0,
  contactCategories: [],
  activeContactCategory: getDefaultContactCategory(),
  activeForm: getDefaultForm(),
}

export default function contactCategoriesReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
  case GET_CONTACT_CATEGORIES_COUNT: {
    return {
      ...state,
      contactCategoriesCount: payload,
    }
  }
  case GET_CONTACT_CATEGORIES: {
    return {
      ...state,
      contactCategories: payload,
    }
  }
  case GET_CONTACT_CATEGORY: {
    return buildContactCategoryState(state, payload)
  }
  case CREATE_CONTACT_CATEGORY: {
    return buildContactCategoryState(state, payload)
  }
  case UPDATE_CONTACT_CATEGORY: {
    return buildContactCategoryState(state, payload)
  }
  case CLEAR_CONTACT_CATEGORY: {
    return {
      ...state,
      activeContactCategory: getDefaultContactCategory(),
      activeForm: getDefaultForm(),
    }
  }
  case SET_IS_CREATE: {
    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        isCreate: payload,
      },
    }
  }
  case SET_GLOBAL_FORM: {
    return {
      ...state,
      activeForm: {
        ...state.activeForm,
        hasChanges: hasChanges({ ...state.activeForm, global: payload }),
        isValid: isFormValid(payload),
        global: payload,
      },
    }
  }
  case RESET_FORM: {
    const globalForm = dataToFormData(state.activeContactCategory, getFields(true))

    const newActiveForm = {
      ...state.activeForm,
      isValid: isFormValid(globalForm),
      resetCount: state.activeForm.resetCount +1,
      global: globalForm,
    }

    return {
      ...state,
      activeForm: {
        ...newActiveForm,
        hasChanges: hasChanges(newActiveForm),
      },
    }
  }
  default: {
    return state
  }
  }
}

// contact categories exports
export function getFields(editOnly) {
  const editFields = {
    'id': { dataSetName, dbField: 'id', isEdit: false },
    'name': { dataSetName, dbField: 'name', isEdit: true },
  }
  if (editOnly) {
    return editFields
  }

  const fields = {
    'isExist': { dataSetName, dbField: 'exist' },
    'cid': { dataSetName, dbField: 'cid' },
    'createdDate': { dataSetName, dbField: 'created_date', type: 'date' },
    'createdById': { dataSetName, dbField: 'created_by_id' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'date' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id' },
    'modifiedBy': { dataSetName, dbField: 'modified_by' },
  }
  return { ...fields, ...editFields }
}

export function fetchContactCategoriesCount(data) {
  return async function fetchContactCategoriesCountThunk(dispatch) {
    try {
      const result = await (await safeFetch(buildGetUrl('/new_api/contacts/categories/count', data))).json()
      if (result.isSuccess) {
        const count = +result.result[0].count || 0
        dispatch({ type: GET_CONTACT_CATEGORIES_COUNT, payload: count })
        return count
      }

      return 0
    } catch (err) {
      console.error(err)
      return 0
    }
  }
}

export async function _fetchContactCategories(data = {}, callBackOnSuccess = undefined) {
  let contactCategories = []

  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/contacts/categories', data))).json()
    if (result.isSuccess) {
      contactCategories = result.result.map((contactCategory) => parseContactCategory(contactCategory))

      if (callBackOnSuccess) {
        callBackOnSuccess(contactCategories)
      }
    }
  } catch (err) {
    console.error(err)
  }

  return contactCategories
}

export async function fetchContactCategoriesByIds(ids, data) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson(
    buildGetUrl(`/new_api/contacts/categories/${ids}`, data),
  )

  return isSuccess ? result.map((contactCategory) => parseContactCategory(contactCategory)) : []
}

export function getContactCategoryTitle(contactCategory) {
  return contactCategory.name
}

export function fetchContactCategories(data = {}) {
  return async function fetchContactCategoriesThunk(dispatch) {
    return _fetchContactCategories(
      data,
      (contegories) => dispatch({ type: GET_CONTACT_CATEGORIES, payload: contegories }),
    )
  }
}

export function fetchContactCategory(contactCategoryId) {
  return async function fetchContactCategoryThunk(dispatch) {
    if (contactCategoryId === 'new') {
      dispatch({ type: SET_IS_CREATE, payload: true })
      return null
    } else {
      let parsedContactCategory = null
      try {
        const result = await (await safeFetch(`/new_api/contacts/categories/${contactCategoryId}`)).json()
        if (result.isSuccess) {
          const [contactCategory] = result.result
          parsedContactCategory = parseContactCategory(contactCategory)
          dispatch({ type: GET_CONTACT_CATEGORY, payload: parsedContactCategory })
        }
      } catch (err) {
        console.error(err)
      }
      return parsedContactCategory
    }
  }
}

export function createContactCategory(contactCategory) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-type': 'application/json' },
    body: JSON.stringify({ contactCategory }),
  }
  return async function createContactCategoryThunk(dispatch) {
    try {
      const result = await (await safeFetch(`/new_api/contacts/categories`, requestOptions)).json()
      const [created] = result.isSuccess ? result.result : []
      const payload = created ? parseContactCategory(created) : null
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: CREATE_CONTACT_CATEGORY, payload, error })

      return payload
    } catch (error) {
      dispatch({ type: CREATE_CONTACT_CATEGORY, error })
    }
  }
}

export function updateContactCategory(contactCategory) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ contactCategory }),
  }
  return async function updateContactCategoryThunk(dispatch) {
    try {
      const result = await (await safeFetch(
        `/new_api/contacts/categories/${contactCategory.id}`,
        requestOptions,
      )).json()
      const [updated] = result.isSuccess ? result.result : []
      const payload = updated ? parseContactCategory(updated) : null
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: UPDATE_CONTACT_CATEGORY, payload, error })
      return { isSuccess: result.isSuccess, result }
    } catch (error) {
      dispatch({ type: UPDATE_CONTACT_CATEGORY, error })
      return { isSuccess: false, result: error }
    }
  }
}

export function deleteContactCategories(contactCategoryIds) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  }
  return async function deleteContactCategoriesThunk(dispatch) {
    try {
      const result = await (await safeFetch(
        `/new_api/contacts/categories/${contactCategoryIds}`,
        requestOptions,
      )).json()
      const error = result.isSuccess ? null : result.result
      dispatch({ type: DELETE_CONTACT_CATEGORY, payload: result.isSuccess, error })

      return result
    } catch (error) {
      dispatch({ type: DELETE_CONTACT_CATEGORY, error })
    }
  }
}

export function clearContactCategory(dispatch) {
  dispatch({ type: CLEAR_CONTACT_CATEGORY })
}

// form exports
export function updateGlobalFormFields(fieldValues) {
  return async function updateGlobalFormFieldsThunk(dispatch, getState) {
    const contactCategoriesStore = getState().contactCategories
    const payload = { ...contactCategoriesStore.activeForm.global }

    fieldValues.forEach((fieldValue) => {
      payload[fieldValue.field] = {
        ...contactCategoriesStore.activeForm.global[fieldValue.field],
        value: fieldValue.value,
        isChanged: true,
      }
    })

    dispatch({ type: SET_GLOBAL_FORM, payload })
  }
}

export function resetForm(dispatch) {
  dispatch({ type: RESET_FORM })
}

// contact category parsing functions
function buildContactCategoryState(state, payload) {
  if (!payload) {
    return state
  }

  const globalForm = dataToFormData(payload, getFields(true))
  const newActiveForm = {
    ...state.activeForm,
    isCreate: false,
    isValid: isFormValid(globalForm),
    global: globalForm,
  }

  return {
    ...state,
    activeContactCategory: payload,
    activeForm: {
      ...newActiveForm,
      hasChanges: hasChanges(newActiveForm),
    },
  }
}

export function parseContactCategory(contactCategory = {}, defaultUnits) {
  const options = {
    defaultData: getDefaultContactCategory(),
    fields: initialState.fields,
    dataSetName,
  }
  return parse(contactCategory, options)
}

function hasChanges(form) {
  return Object.keys(form.global).some((key) => form.global[key].isChanged)
}

function getDefaultContactCategory() {
  return parse({}, { fields })
}

function getDefaultForm() {
  return {
    isCreate: false,
    hasChanges: false,
    isValid: false,
    resetCount: 0,
    global: dataToFormData(getDefaultContactCategory(), getFields(true)),
  }
}

function isFormValid(globalForm) {
  return !!globalForm.name.value
}
