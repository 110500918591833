/**
 * Return the result of the first function called with value or the value.
 *
 * @param {*[]} functions
 * @param {*} value
 * @return {*}
 */
export const callFirstFonction = (functions, value) => {
  const firstFunction = functions.find((f) => typeof f === 'function')
  return firstFunction ? firstFunction(value) : value
}

/**
 * @param {any[]} functions
 * @param {any} functionParams
 * @param {any} fallback
 * @param {function} [callback]
 * @return {any}
 */
export const callFirstFonctionOrFallback = (functions, functionParams, fallback, callback) => {
  const firstFunction = functions.find((f) => typeof f === 'function')

  if (firstFunction) {
    return firstFunction(functionParams)
  }

  if (typeof callback === 'function') callback()

  return fallback
}

/**
 * Return the first string in the fallbacks or an empty string.
 *
 * @param {(any)[]} fallbacks
 * @return {string}
 */
export const getFirstString = (...fallbacks) => {
  return fallbacks.find((fallback) => typeof fallback === 'string') ?? ''
}

export const getFirstNotNull = (...fallbacks) => {
  return fallbacks.find((fallback) => fallback != undefined || fallback != null ) ?? null
}

/**
 * Return the first truthy value in the fallbacks or the last fallback or null.
 *
 * @param  {...any} fallbacks
 * @returns {any}
 */
export const getFirstTruthy = (...fallbacks) => {
  return fallbacks.find((fallback) => fallback) ?? (fallbacks.length ? fallbacks[fallbacks.length - 1] : null)
}

export const joinOnlyStrings = (values, separator = ',') => {
  return values.filter((v) => typeof v === 'string' && v.trim()).join(separator)
}

/**
 * @param {object[]} objects - Array of objects to check
 * @param {string} key - Key to check
 * @param {*} [value] - If not provided, the value will be the value of the first object
 * @returns {boolean} - True if all objects have the same value for the key, false otherwise
 */
export const checkEveryObjectsEqualsForKey = (objects, key, value) => {
  if (!Array.isArray(objects) || objects.length === 0) return true

  const _value = value ?? objects[0][key]

  return objects.every((object) => object[key] === _value)
}
