import { AppDispatch } from 'store'
import { ApiToSlice, GetFields } from 'types/slices'

import { buildGetUrl, parse } from 'utils/api'
import { safeFetch } from 'utils/safeFetch'

import {
  DELETE_DASHBOARD,
  DRAG_DASHBOARDS,
  GET_DASHBOARDS,
  GET_DASHBOARDS_COUNT,
  SET_DASHBOARDS,
} from './types'

const dataSetName = 'dashboard'

const initialState = {
  dataSetName,
  fields: getFields(),
  dashboardsCount: 0,
  dashboards: [],
}

export default function dashboardsReducer(state = initialState, action: any) {
  const { payload } = action
  switch (action.type) {
  case GET_DASHBOARDS:
  case SET_DASHBOARDS:
    return {
      ...state,
      dashboards: payload,
    }
  case GET_DASHBOARDS_COUNT:
    return {
      ...state,
      dashboardsCount: payload,
    }
  default:
    return state
  }
}

export type MapData = {
  userGroup: string
}

export type DashboardApi = {
  id: string
  rank: number
  name: string
  url: string
  iconClass: string
  securityGroups: string[],
  exist: boolean,
}

export type Dashboard = ApiToSlice<DashboardApi>
type DashboardGetFields = GetFields<DashboardApi, Dashboard, MapData>

export function getFields(editOnly = false): DashboardGetFields {
  const editFields: DashboardGetFields = {
    'id': {
      type: 'string',
      dbField: 'id',
      dataSetName,
    },
    'name': {
      type: 'string',
      dbField: 'name',
      isEdit: true,
      dataSetName,
    },
    'url': {
      type: 'string',
      dbField: 'url',
      isEdit: true,
      dataSetName,
    },
    'iconClass': {
      type: 'string',
      dbField: 'iconClass',
      isEdit: true,
      dataSetName,
    },
    'securityGroups': {
      type: 'enum',
      dbField: 'securityGroups',
      isEdit: true,
      dataSetName,
      parse: (data, { userGroup }) => {
        if (userGroup === 'Support') return data.securityGroups ?? []
        return data.securityGroups?.filter((group) => group !== 'Support') ?? []
      },
    },
    'exist': {
      type: 'boolean',
      dataSetName,
      dbField: 'exist',
    },
  }

  if (editOnly) return editFields

  const fields: DashboardGetFields = {
    'rank': {
      type: 'string',
      dbField: 'rank',
      dataSetName,
    },
  }
  return { ...fields, ...editFields }
}

export async function fetchSecurityGroups() {
  let securityGroups = []
  try {
    const result = await (await safeFetch('/new_api/users/groups')).json()

    if (result.isSuccess) {
      securityGroups = result.result.map((group) => ({
        id: group.id,
        value: group.name,
        name: group.name,
      }))
    }
  } catch (error) {
    console.error(error)
  }
  return securityGroups
}

export function getDashboardTitle(dashboard: Dashboard) {
  return dashboard.name
}

export function fetchDashboards( data?: Record<string, any>, mapData?: MapData) {
  return async function fetchDashboardsThunk(dispatch: AppDispatch) {
    const dashboards = await _fetchDashboards(data, mapData)
    dispatch({ type: GET_DASHBOARDS, payload: dashboards })
    return dashboards
  }
}

export async function fetchDashboardsByIds(ids: (string|number)[], mapData?: MapData) {
  if (!ids?.length) return []
  const { isSuccess, result } = await (await safeFetch(buildGetUrl(`/new_api/dashboards/${ids}`))).json()
  return isSuccess ? result.map((dashboard: Dashboard) => parseDashboard(dashboard, mapData)) : []
}

export async function _fetchDashboards(data: Record<string, any>, mapData?: MapData) {
  let dashboards = []
  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/dashboards', data))).json()
    if (result.isSuccess) {
      dashboards = result.result.map((dashboard: Dashboard) => parseDashboard(dashboard, mapData))
        .sort((a: any, b: any) => a.rank - b.rank)
    }
  } catch (error) {
    console.error(error)
  }
  return dashboards
}

export function parseDashboard(dashboard: Dashboard, mapData?: MapData) {
  const options = {
    defaultData: getDefaultDashboard(),
    fields: initialState.fields,
    dataSetName,
    ...mapData,
  }

  return parse(dashboard, options)
}

function getDefaultDashboard() {
  return parse({}, { fields: initialState.fields })
}

export function deleteDashboards(ids: string[]) {
  if (!ids?.length) return []

  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  }
  return async function deleteDashboardsThunk(dispatch: AppDispatch) {
    const result = await (await safeFetch(`/new_api/dashboards/${ids}`, requestOptions)).json()
    try {
      const payload = result.isSuccess ? ids : null
      dispatch({ type: DELETE_DASHBOARD, payload })
      return { isSuccess: result.isSuccess }
    } catch (error) {
      console.error(error)
      dispatch({ type: DELETE_DASHBOARD, error })
      return { isSuccess: false }
    }
  }
}

export function dragDashboard(update: { id: string, oldRank: number, newRank: number }) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ update }),
  }

  return async function dragDashboardThunk(dispatch: AppDispatch) {
    try {
      const result = await (await safeFetch('/new_api/dashboards/drag', requestOptions)).json()
      const payload = result.isSuccess ? result.result : null
      const error = !result.isSuccess ? result.error : null
      dispatch({ type: DRAG_DASHBOARDS, payload, error })
      return { isSuccess: result.isSuccess, result: payload }
    } catch (error) {
      console.error(error)
      dispatch({ type: DRAG_DASHBOARDS, error })
      return { isSuccess: false, result: null }
    }
  }
}
