import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch } from 'store'
import { ApiToSlice, BaseEntityApi, GetFields, Modify } from 'types/slices'

import { buildGetUrl, parse } from 'utils/api'
import { isJob, safeFetchJson } from 'utils/safeFetch'

const dataSetName = 'countryOfOrigin'

const initialState = {
  dataSetName,
  fields: getFields(),
  countryOfOriginsCount: 0,
  countryOfOrigins: [],
}

const countryOfOriginsSlice = createSlice({
  name: 'countryOfOrigins',
  initialState,
  reducers: {
    setCountryOfOrigins: (state, action) => {
      state.countryOfOrigins = action.payload.data
    },
    setCountryOfOriginsCount: (state, action) => {
      state.countryOfOriginsCount = action.payload.count
    },
  },
})

export const { setCountryOfOrigins, setCountryOfOriginsCount } = countryOfOriginsSlice.actions

export type MapData = {
  primaryLanguage?: string
  secondaryLanguage?: string
}

export type CountryOfOriginApi = Modify<{
  primary_name: string
  secondary_name: string
}, BaseEntityApi>

type Exception = {primary_name: 'name'}

export type CountryOfOrigin = ApiToSlice<CountryOfOriginApi, Exception>

type CountryOfOriginGetFields = GetFields<CountryOfOriginApi, CountryOfOrigin, MapData>

export function getFields(editOnly = false): CountryOfOriginGetFields {
  const editFields: CountryOfOriginGetFields = {
    'id': { dataSetName, dbField: 'id', type: 'string' },
    'name': {
      dataSetName,
      dbField: 'primary_name',
      type: 'string',
      isEdit: true,
      headerOptions: { language: 'primaryLanguage' },
      customFieldTranslationKey: (t, options) => t(
        'countryOfOrigins:countryOfOrigin.fields.name.label',
        { language: options?.primaryLanguage },
      ),
    },
    'secondaryName': {
      dataSetName,
      dbField: 'secondary_name',
      type: 'string',
      isEdit: true,
      headerOptions: { language: 'secondaryLanguage' },
      customFieldTranslationKey: (t, options) => t(
        'countryOfOrigins:countryOfOrigin.fields.secondaryName.label',
        { language: options?.secondaryLanguage },
      ),
    },
  }

  if (editOnly) return editFields

  const fields: CountryOfOriginGetFields = {
    'exist': { dataSetName, dbField: 'exist', type: 'boolean' },
    'createdDate': { dataSetName, dbField: 'created_date', type: 'timestamp' },
    'createdBy': { dataSetName, dbField: 'created_by', type: 'string' },
    'createdById': { dataSetName, dbField: 'created_by_id', type: 'string' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'timestamp' },
    'modifiedBy': { dataSetName, dbField: 'modified_by', type: 'string' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id', type: 'string' },
  }

  return { ...fields, ...editFields }
}

export function getCountryOfOriginTitle(countryOfOrigin: CountryOfOrigin): string {
  return countryOfOrigin.name
}

export async function fetchCountryOfOriginsByIds(ids: string[], mapData?: MapData, data?: Record<string, any>) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson<CountryOfOriginApi>(
    buildGetUrl(`/new_api/country-of-origins/${ids}`, data),
  )

  return isSuccess && !isJob(result) ?
    result.map((countryOfOrigin) => parseCountryOfOrigin(countryOfOrigin, mapData)) :
    []
}

export function fetchCountryOfOrigins(mapData: MapData, fetchData?: Record<string, any>) {
  return async function fetchCountryOfOriginsThunk(dispatch: AppDispatch) {
    const data = await _fetchCountryOfOrigins(fetchData, mapData)
    dispatch(setCountryOfOrigins({ data }))
    return data
  }
}

export async function _fetchCountryOfOrigins(fetchData: Record<string, any>, mapData: MapData) {
  let countryOfOrigins = []

  try {
    const { isSuccess, result } = await safeFetchJson<CountryOfOriginApi>(
      buildGetUrl('/new_api/country-of-origins', fetchData),
    )
    if (isSuccess && !isJob(result)) {
      countryOfOrigins = result.map((countryOfOrigin) => parseCountryOfOrigin(countryOfOrigin, mapData))
    }
  } catch (err) {
    console.error(err)
  }

  return countryOfOrigins
}

export function fetchCountryOfOriginsCount(fetchData?: Record<string, any>) {
  return async function fetchCountryOfOriginsCountThunk(dispatch: AppDispatch) {
    const count = await _fetchCountryOfOriginsCount(fetchData)
    dispatch(setCountryOfOriginsCount({ count }))
    return count
  }
}

export async function _fetchCountryOfOriginsCount(fetchData?: Record<string, any>) {
  let count = 0

  try {
    const { isSuccess, result } = await safeFetchJson<{count: string}>(
      buildGetUrl('/new_api/country-of-origins/count', fetchData),
    )
    if (isSuccess && !isJob(result)) {
      count = +result[0].count || 0
    }
  } catch (err) {
    console.error(err)
  }

  return count
}

export function createCountryOfOrigin(createData: Partial<CountryOfOriginApi>[], mapData: MapData) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: createData }),
  }
  return async function createCountryOfOriginsThunk(dispatch: AppDispatch) {
    try {
      const { isSuccess, result } = await safeFetchJson<CountryOfOriginApi, false>(
        '/new_api/country-of-origins', requestOptions,
      )
      const countryOfOrigin = isSuccess && !isJob<CountryOfOriginApi, false>(result) ?
        parseCountryOfOrigin(result, mapData) :
        null
      const error = !isSuccess ? result : null
      return { isSuccess, countryOfOrigin, error }
    } catch (error) {
      console.error(error)
      return { isSuccess: false, error }
    }
  }
}

export function deleteCountryOfOrigins(ids: string[], mapData: MapData, isBatch = false) {
  if (!ids?.length) return []

  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: isBatch ? JSON.stringify({ ids }) : undefined,
  }
  const url = isBatch ? '/new_api/country-of-origins/batch' : `/new_api/country-of-origins/${ids}`
  return async function deleteCountryOfOriginsThunk(dispatch: AppDispatch) {
    try {
      const { isSuccess, result } = await safeFetchJson<CountryOfOriginApi, typeof isBatch>(url, requestOptions)
      const error = !isSuccess ? result : null
      const _result = { isSuccess, error, countryOfOrigins: undefined, countryOfOrigin: undefined }
      if (Array.isArray(result)) {
        _result.countryOfOrigins = []
        if (isSuccess && !isJob(result)) {
          _result.countryOfOrigins = result.map((countryOfOrigin) => parseCountryOfOrigin(countryOfOrigin, mapData))
        }
      } else {
        _result.countryOfOrigin = isSuccess && !isJob<CountryOfOriginApi, false>(result) ?
          parseCountryOfOrigin(result, mapData) :
          null
      }

      return _result
    } catch (error) {
      console.error(error)
      return { isSuccess: false, error }
    }
  }
}

export async function _updateCountryOfOrigin(id: string, updateData: Partial<CountryOfOriginApi>, mapData: MapData) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: updateData }),
  }

  try {
    const { isSuccess, result } = await safeFetchJson<CountryOfOriginApi, false>(
      `/new_api/country-of-origins/${id}`, requestOptions,
    )
    const countryOfOrigin = isSuccess && !isJob<CountryOfOriginApi, false>(result) ?
      parseCountryOfOrigin(result, mapData) :
      null
    const error = !isSuccess ? result : null
    return { isSuccess, countryOfOrigin, error }
  } catch (error) {
    console.error(error)
    return { isSuccess: false, error }
  }
}

export function parseCountryOfOrigin(countryOfOrigin: CountryOfOriginApi, mapData: MapData): CountryOfOrigin {
  const options = {
    defaultData: getDefaultCountryOfOrigin(),
    fields: initialState.fields,
    dataSetName: dataSetName,
    ...mapData,
  }

  return parse(countryOfOrigin, options)
}

function getDefaultCountryOfOrigin(): CountryOfOrigin {
  return parse({}, { fields: initialState.fields })
}

export default countryOfOriginsSlice.reducer
