import { AppDispatch } from 'store'

import {
  SET_IS_PASSWORD_SUCCESS,
  PUT_LOGIN_USER_PASSWORD,
  SET_IS_EMAIL_SUCCESS,
  PUT_LOGIN_USER_EMAIL,
} from './types'

const initState = { isPasswordSuccess: null, isEmailSuccess: null }

export default function loginUserSuccessReducer(state = initState, action) {
  const { isSuccess, error } = action

  switch (action.type) {
  case PUT_LOGIN_USER_PASSWORD: {
    return {
      ...state,
      isPasswordSuccess: !error,
    }
  }
  case SET_IS_PASSWORD_SUCCESS: {
    return {
      ...state,
      isPasswordSuccess: isSuccess,
    }
  }
  case PUT_LOGIN_USER_EMAIL: {
    return {
      ...state,
      isEmailSuccess: !error,
    }
  }
  case SET_IS_EMAIL_SUCCESS: {
    return {
      ...state,
      isEmailSuccess: isSuccess,
    }
  }
  default:
    return state
  }
}

export function setIsPasswordSuccess(isSuccess: boolean) {
  return async function(dispatch: AppDispatch) {
    dispatch({ type: SET_IS_PASSWORD_SUCCESS, isSuccess })
  }
}

export function setIsEmailSuccess(isSuccess: boolean) {
  return async function(dispatch: AppDispatch) {
    dispatch({ type: SET_IS_EMAIL_SUCCESS, isSuccess })
  }
}
