export const GET_CATEGORIES = 'GET_CATEGORIES'
export const GET_CATEGORIES_COUNT = 'GET_CATEGORIES_COUNT'
export const GET_CATEGORY = 'GET_CATEGORY'
export const CREATE_CATEGORY = 'CREATE_CATEGORY'
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'

export const CLEAR_CATEGORY = 'CLEAR_CATEGORY'
export const CLEAR_CREATE_SUCCESS = 'CLEAR_CATEGORY_CREATE_SUCCESS'
export const CLEAR_UPDATE_SUCCESS = 'CLEAR_CATEGORY_UPDATE_SUCCESS'
export const CLEAR_DELETE_SUCCESS = 'CLEAR_CATEGORY_DELETE_SUCCESS'
export const CLEAR_CREATE_ERROR = 'CLEAR_CATEGORY_CREATE_ERROR'
export const CLEAR_UPDATE_ERROR = 'CLEAR_CATEGORY_UPDATE_ERROR'
export const CLEAR_DELETE_ERROR = 'CLEAR_CATEGORY_DELETE_ERROR'

export const SET_IS_CREATE = 'SET_CATEGORY_IS_CREATE'
export const SET_GLOBAL_FORM = 'SET_CATEGORY_GLOBAL_FORM'
export const RESET_FORM = 'RESET_CATEGORY_FORM'
