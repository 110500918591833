import { parse, buildGetUrl } from 'utils/api'
import { safeFetch } from 'utils/safeFetch'

const dataSetName = 'currency'
const fields = getFields()
const initialState = { dataSetName, fields }

export default function currenciesReducer(state = initialState, action) {
  switch (action.type) {
  default: {
    return state
  }
  }
}

// currencies exports
export function getFields() {
  return {
    id: { dataSetName, dbField: 'id' },
    exist: { dataSetName, dbField: 'exist' },
    name: { dataSetName, dbField: 'currency_name' },
    code: { dataSetName, dbField: 'currency_code' },
    symbol: { dataSetName, dbField: 'currency_symbol' },
    precision: { dataSetName, dbField: 'price_precision' },
    isBase: { dataSetName, dbField: 'is_base_currency' },
    exchangeRate: { dataSetName, dbField: 'exchange_rate', defaultValue: 1 },
  }
}

export function getDefaultCurrency() {
  return parse({}, { fields })
}

export function parseCurrency(item, formCurrency = {}, baseCurrency = {}, isFormCurrency) {
  return {
    currencyCode: (isFormCurrency ? formCurrency.currencyCode : item.currencyCode) || baseCurrency.code,
    currencySymbol: (isFormCurrency ? formCurrency.currencySymbol : item.currencySymbol) || baseCurrency.symbol,
    exchangeRate: (isFormCurrency ? formCurrency.exchangeRate : item.exchangeRate) || baseCurrency.exchangeRate || 1,
  }
}

export async function fetchCurrencies(data) {
  let currencies = []

  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/accounting/currencies', data))).json()
    if (result.isSuccess) {
      currencies = result.result.map((currency) => _parseCurrency(currency))
    }
  } catch (err) {
    console.error(err)
  }

  return currencies
}

// parsing functions
function _parseCurrency(currency) {
  return parse(currency, { fields: initialState.fields, dataSetName })
}
