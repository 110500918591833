import { HubAppDispatch } from 'store'

import {
  SET_EMAIL_NOT_FOUND_ERROR,
  CLEAR_EMAIL_NOT_FOUND_ERROR,
  SET_EMAIL_NOT_ACTIVATED_ERROR,
  CLEAR_EMAIL_NOT_ACTIVATED_ERROR,
  SET_EMAIL_UNKOWN_ERROR,
  CLEAR_EMAIL_UNKOWN_ERROR,
  SET_EMAIL_REQUIRED_ERROR,
  CLEAR_EMAIL_REQUIRED_ERROR,
  SET_PASSWORD_REQUIRED_ERROR,
  CLEAR_PASSWORD_REQUIRED_ERROR,
  SET_PASSWORD_RESET_ERROR,
  CLEAR_PASSWORD_RESET_ERROR,
  SET_PASSWORD_CREDS_ERROR,
  CLEAR_PASSWORD_CREDS_ERROR,
  SET_DEFAULT_LOGIN_DISABLED_ERROR,
  CLEAR_DEFAULT_LOGIN_DISABLED_ERROR,
} from './types'

const initState = {
  email: { required: 0, notFound: 0, notActivated: 0, unkownError: 0, loginDisabled: 0 },
  password: { required: 0, reset: 0, creds: 0 },
}

export default function authenticationErrorReducer(state = initState, action) {
  switch (action.type) {
  case SET_EMAIL_NOT_FOUND_ERROR: {
    return { ...state, email: { ...state.email, notFound: state.email.notFound + 1 } }
  }
  case CLEAR_EMAIL_NOT_FOUND_ERROR: {
    return { ...state, email: { ...state.email, notFound: 0 } }
  }
  case SET_DEFAULT_LOGIN_DISABLED_ERROR: {
    return { ...state, email: { ...state.email, loginDisabled: state.email.loginDisabled + 1 } }
  }
  case CLEAR_DEFAULT_LOGIN_DISABLED_ERROR: {
    return { ...state, email: { ...state.email, loginDisabled: 0 } }
  }
  case SET_EMAIL_NOT_ACTIVATED_ERROR: {
    return { ...state, email: { ...state.email, notActivated: state.email.notActivated + 1 } }
  }
  case CLEAR_EMAIL_NOT_ACTIVATED_ERROR: {
    return { ...state, email: { ...state.email, notActivated: 0 } }
  }
  case SET_EMAIL_UNKOWN_ERROR: {
    return { ...state, email: { ...state.email, unkownError: state.email.unkownError + 1 } }
  }
  case CLEAR_EMAIL_UNKOWN_ERROR: {
    return { ...state, email: { ...state.email, unkownError: 0 } }
  }
  case SET_EMAIL_REQUIRED_ERROR: {
    return { ...state, email: { ...state.email, required: state.email.required + 1 } }
  }
  case CLEAR_EMAIL_REQUIRED_ERROR: {
    return { ...state, email: { ...state.email, required: 0 } }
  }
  case SET_PASSWORD_REQUIRED_ERROR: {
    return { ...state, password: { ...state.password, required: state.password.required + 1 } }
  }
  case CLEAR_PASSWORD_REQUIRED_ERROR: {
    return { ...state, password: { ...state.password, required: 0 } }
  }
  case SET_PASSWORD_RESET_ERROR: {
    return { ...state, password: { ...state.password, reset: state.password.reset + 1 } }
  }
  case CLEAR_PASSWORD_RESET_ERROR: {
    return { ...state, password: { ...state.password, reset: 0 } }
  }
  case SET_PASSWORD_CREDS_ERROR: {
    return { ...state, password: { ...state.password, creds: state.password.creds + 1 } }
  }
  case CLEAR_PASSWORD_CREDS_ERROR: {
    return { ...state, password: { ...state.password, creds: 0 } }
  }
  default:
    return state
  }
}

export function setEmailRequiredError(dispatch: HubAppDispatch) {
  dispatch({ type: SET_EMAIL_REQUIRED_ERROR })
}

export function clearEmailRequiredError(dispatch: HubAppDispatch) {
  dispatch({ type: CLEAR_EMAIL_REQUIRED_ERROR })
}

export function clearEmailNotFoundError(dispatch: HubAppDispatch) {
  dispatch({ type: CLEAR_EMAIL_NOT_FOUND_ERROR })
}

export function clearEmailNotActivatedError(dispatch: HubAppDispatch) {
  dispatch({ type: CLEAR_EMAIL_NOT_ACTIVATED_ERROR })
}

export function clearEmailUnkownError(dispatch: HubAppDispatch) {
  dispatch({ type: CLEAR_EMAIL_UNKOWN_ERROR })
}

export function setPasswordRequiredError(dispatch: HubAppDispatch) {
  dispatch({ type: SET_PASSWORD_REQUIRED_ERROR })
}

export function clearPasswordRequiredError(dispatch: HubAppDispatch) {
  dispatch({ type: CLEAR_PASSWORD_REQUIRED_ERROR })
}

export function clearPasswordResetError(dispatch: HubAppDispatch) {
  dispatch({ type: CLEAR_PASSWORD_RESET_ERROR })
}

export function clearPasswordCredsError(dispatch: HubAppDispatch) {
  dispatch({ type: CLEAR_PASSWORD_CREDS_ERROR })
}
