import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch } from 'store'
import { ApiToSlice, BaseEntityApi, GetFields, Modify } from 'types/slices'

import { buildGetUrl, parse } from 'utils/api'
import { isJob, safeFetchJson } from 'utils/safeFetch'

const dataSetName = 'harmonizedSystemCode'

const initialState = {
  dataSetName,
  fields: getFields(),
  harmonizedSystemCodesCount: 0,
  harmonizedSystemCodes: [],
}

const harmonizedSystemCodesSlice = createSlice({
  name: 'harmonizedSystemCodes',
  initialState,
  reducers: {
    setHarmonizedSystemCodes: (state, action) => {
      state.harmonizedSystemCodes = action.payload.data
    },
    setHarmonizedSystemCodesCount: (state, action) => {
      state.harmonizedSystemCodesCount = action.payload.count
    },
  },
})

export const { setHarmonizedSystemCodes, setHarmonizedSystemCodesCount } = harmonizedSystemCodesSlice.actions

export type HarmonizedSystemCodeApi = Modify<{
  code: string
  description: string
}, BaseEntityApi>

export type HarmonizedSystemCode = ApiToSlice<HarmonizedSystemCodeApi>

type HarmonizedSystemCodeGetFields = GetFields<HarmonizedSystemCodeApi, HarmonizedSystemCode>

export function getFields(editOnly = false): HarmonizedSystemCodeGetFields {
  const editFields: HarmonizedSystemCodeGetFields = {
    'id': { dataSetName, dbField: 'id', type: 'string' },
    'code': { dataSetName, dbField: 'code', type: 'string', isEdit: true },
    'description': { dataSetName, dbField: 'description', type: 'string', isEdit: true },
  }

  if (editOnly) return editFields

  const fields: HarmonizedSystemCodeGetFields = {
    'exist': { dataSetName, dbField: 'exist', type: 'boolean' },
    'createdDate': { dataSetName, dbField: 'created_date', type: 'timestamp' },
    'createdBy': { dataSetName, dbField: 'created_by', type: 'string' },
    'createdById': { dataSetName, dbField: 'created_by_id', type: 'string' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'timestamp' },
    'modifiedBy': { dataSetName, dbField: 'modified_by', type: 'string' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id', type: 'string' },
  }

  return { ...fields, ...editFields }
}

export function getHarmonizedSystemCodeTitle(harmonizedSystemCode: HarmonizedSystemCode): string {
  return harmonizedSystemCode.code
}

export async function fetchHarmonizedSystemCodesByIds(ids: string[], data?: Record<string, any>) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson<HarmonizedSystemCodeApi>(
    buildGetUrl(`/new_api/harmonized-system-codes/${ids}`, data),
  )

  return isSuccess && !isJob(result) ?
    result.map((harmonizedSystemCode) => parseHarmonizedSystemCode(harmonizedSystemCode)) :
    []
}

export function fetchHarmonizedSystemCodes(fetchData?: Record<string, any>) {
  return async function fetchHarmonizedSystemCodesThunk(dispatch: AppDispatch) {
    const data = await _fetchHarmonizedSystemCodes(fetchData)
    dispatch(setHarmonizedSystemCodes({ data }))
    return data
  }
}

export async function _fetchHarmonizedSystemCodes(fetchData: Record<string, any>) {
  let harmonizedSystemCodes = []

  try {
    const { isSuccess, result } = await safeFetchJson<HarmonizedSystemCodeApi>(
      buildGetUrl('/new_api/harmonized-system-codes', fetchData),
    )
    if (isSuccess && !isJob(result)) {
      harmonizedSystemCodes = result.map((harmonizedSystemCode) => parseHarmonizedSystemCode(harmonizedSystemCode))
    }
  } catch (err) {
    console.error(err)
  }

  return harmonizedSystemCodes
}

export function fetchHarmonizedSystemCodesCount(fetchData?: Record<string, any>) {
  return async function fetchHarmonizedSystemCodesCountThunk(dispatch: AppDispatch) {
    const count = await _fetchHarmonizedSystemCodesCount(fetchData)
    dispatch(setHarmonizedSystemCodesCount({ count }))
    return count
  }
}

export async function _fetchHarmonizedSystemCodesCount(fetchData?: Record<string, any>) {
  let count = 0

  try {
    const { isSuccess, result } = await safeFetchJson<{count: string}>(
      buildGetUrl('/new_api/harmonized-system-codes/count', fetchData),
    )
    if (isSuccess && !isJob(result)) {
      count = +result[0].count || 0
    }
  } catch (err) {
    console.error(err)
  }

  return count
}

export function createHarmonizedSystemCode(createData: Partial<HarmonizedSystemCodeApi>[]) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: createData }),
  }
  return async function createHarmonizedSystemCodeThunk(dispatch: AppDispatch) {
    try {
      const { isSuccess, result } = await safeFetchJson<HarmonizedSystemCodeApi, false>(
        '/new_api/harmonized-system-codes', requestOptions,
      )
      const harmonizedSystemCode = isSuccess && !isJob<HarmonizedSystemCodeApi, false>(result) ?
        parseHarmonizedSystemCode(result) :
        null
      const error = !isSuccess ? result : null
      return { isSuccess, harmonizedSystemCode, error }
    } catch (error) {
      console.error(error)
      return { isSuccess: false, error }
    }
  }
}

export function deleteHarmonizedSystemCodes(ids: string[], isBatch = false) {
  if (!ids?.length) return []

  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: isBatch ? JSON.stringify({ ids }) : undefined,
  }
  const url = isBatch ? '/new_api/harmonized-system-codes/batch' : `/new_api/harmonized-system-codes/${ids}`
  return async function deleteHarmonizedSystemCodesThunk(dispatch: AppDispatch) {
    try {
      const { isSuccess, result } = await safeFetchJson<HarmonizedSystemCodeApi, typeof isBatch>(url, requestOptions)
      const error = !isSuccess ? result : null
      const _result = { isSuccess, error, harmonizedSystemCodes: undefined, harmonizedSystemCode: undefined }
      if (Array.isArray(result)) {
        _result.harmonizedSystemCodes = []
        if (isSuccess && !isJob(result)) {
          _result.harmonizedSystemCodes = result.map(
            (harmonizedSystemCode) => parseHarmonizedSystemCode(harmonizedSystemCode),
          )
        }
      } else {
        _result.harmonizedSystemCode = isSuccess && !isJob<HarmonizedSystemCodeApi, false>(result) ?
          parseHarmonizedSystemCode(result) :
          null
      }

      return _result
    } catch (error) {
      console.error(error)
      return { isSuccess: false, error }
    }
  }
}

export async function _updateHarmonizedSystemCode(id: string, updateData: Partial<HarmonizedSystemCodeApi>) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: updateData }),
  }

  try {
    const { isSuccess, result } = await safeFetchJson<HarmonizedSystemCodeApi, false>(
      `/new_api/harmonized-system-codes/${id}`, requestOptions,
    )
    const harmonizedSystemCode = isSuccess && !isJob<HarmonizedSystemCodeApi, false>(result) ?
      parseHarmonizedSystemCode(result) :
      null
    const error = !isSuccess ? result : null
    return { isSuccess, harmonizedSystemCode, error }
  } catch (error) {
    console.error(error)
    return { isSuccess: false, error }
  }
}

export function parseHarmonizedSystemCode(harmonizedSystemCode: HarmonizedSystemCodeApi): HarmonizedSystemCode {
  const options = {
    defaultData: getDefaultHarmonizedSystemCode(),
    fields: initialState.fields,
    dataSetName: dataSetName,
  }

  return parse(harmonizedSystemCode, options)
}

function getDefaultHarmonizedSystemCode(): HarmonizedSystemCode {
  return parse({}, { fields: initialState.fields })
}

export default harmonizedSystemCodesSlice.reducer
