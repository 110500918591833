import { AppDispatch } from 'store'

import {
  SET_SCAN_ERROR,
  CLEAR_SCAN_ERROR,
} from './types'

const initState = {
  scanError: null,
}

export default function cardsErrorReducer(state = initState, action) {
  const { error } = action

  switch (action.type) {
  case SET_SCAN_ERROR: {
    return {
      ...state,
      scanError: error,
    }
  }
  case CLEAR_SCAN_ERROR: {
    return {
      ...state,
      scanError: null,
    }
  }
  default:
    return state
  }
}

export function setScanError(tag: string) {
  return function setScanErrorThunk(dispatch: AppDispatch) {
    dispatch({ type: SET_SCAN_ERROR, error: { error: { name: 'CardScanError' }, tag } })
  }
}

export function clearScanError(dispatch: AppDispatch) {
  dispatch({ type: CLEAR_SCAN_ERROR })
}
