import { v4 as uuid } from 'uuid'

import { buildGetUrl, parse } from 'utils/api'
import { safeFetch } from 'utils/safeFetch'

import {
  GET_EVENTS_COUNT,
  GET_EVENTS,
  CLEAR_EVENTS,
  SET_CONSTANTS,
} from './types'

const dataSetName = 'event'
const fields = getFields()
const initialState = {
  dataSetName,
  fields,
  eventsCount: 0,
  events: [],
  eventEntityfields: {},
  nameSpaces: [],
  entityName: '',
}

export default function eventsReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
  case GET_EVENTS_COUNT: {
    return {
      ...state,
      eventsCount: payload,
    }
  }
  case GET_EVENTS: {
    const { events: newPageEvents, page } = payload

    // If we fetched a page that we already had, we do nothing.
    if (page < state.events.length) {
      return state
    }

    return {
      ...state,
      events: [
        ...state.events,
        {
          id: uuid(),
          events: newPageEvents,
        },
      ],
    }
  }
  case CLEAR_EVENTS: {
    return {
      ...state,
      events: [],
      eventsCount: 0,
    }
  }
  case SET_CONSTANTS: {
    return {
      ...state,
      ...payload,
    }
  }
  default: {
    return state
  }
  }
}

export function getFields() {
  return {
    'id': { dataSetName, dbField: 'id' },
    'createdDate': { dataSetName, dbField: 'created_date' },
    'createdBy': { dataSetName, dbField: 'created_by' },
    'createdById': { dataSetName, dbField: 'created_by_id' },
    'userId': { dataSetName, dbField: 'user_id' },
    'entityId': { dataSetName, dbField: 'entity_id' },
    'entityIdInt': { dataSetName, dbField: 'entity_id_int' },
    'entityName': { dataSetName, dbField: 'entity_name' },
    'rank': { dataSetName, dbField: 'rank' },
    'type': { dataSetName, dbField: 'event_type' },
    'transactionId': { dataSetName, dbField: 'transaction_id' },
    'isTransactionLink': { dataSetName, dbField: 'is_transaction_link' },
    'details': { dataSetName, dbField: 'details' },
    'transactionEntityName': { dataSetName: 'transactionEvent', dbField: 'entity_name', dataSetAlias: 'transaction' },
    'transactionDetails': { dataSetName, dbField: 'transactionDetails', parse: (event) => {
      return (event.transactionDetails || []).map((detail) => parseEvent(detail))
    } },
  }
}

export function fetchEventsCount(data) {
  return async function fetchEventsCountThunk(dispatch) {
    try {
      const result = await (await safeFetch(buildGetUrl('/new_api/events/count', data))).json()
      if (result.isSuccess) {
        const count = +result.result[0].count || 0
        dispatch({ type: GET_EVENTS_COUNT, payload: count })
        return count
      }

      return 0
    } catch (err) {
      console.error(err)
      return 0
    }
  }
}

export function fetchEvents(data, handleEventsFiltering, page) {
  return async function fetchEventsThunk(dispatch) {
    let events = []

    try {
      const result = await (await safeFetch(buildGetUrl('/new_api/events', data))).json()
      if (result.isSuccess) {
        events = handleEventsFiltering(
          result.result.map((event) => parseEvent(event)),
        )
        dispatch({ type: GET_EVENTS, payload: { events, page } })
      }
    } catch (err) {
      console.error(err)
    }

    return events
  }
}

export function parseEvent(event) {
  const options = {
    defaultData: getDefaultEvent(),
    fields: initialState.fields,
    dataSetName,
  }
  return parse(event, options)
}

function getDefaultEvent() {
  return parse({}, { fields })
}

export function clearEvents(dispatch) {
  dispatch({ type: CLEAR_EVENTS })
}
