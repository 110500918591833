import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type SmartGridId = string;

type SliceState = Record<
  SmartGridId,
  {
    rowEntityId: string;
    columnName: string;
  } | null
>;

const initialState: SliceState = {}

const slice = createSlice({
  name: 'smart-grid-inputs',
  initialState,
  reducers: {
    setEditColumn: (
      state,
      action: PayloadAction<{
        smartGridId: string;
        rowEntityId: string;
        columnName: string;
      }>,
    ) => {
      const { smartGridId, columnName, rowEntityId } = action.payload

      state[smartGridId] = {
        rowEntityId,
        columnName,
      }

      return state
    },
    clearEditColumn: (state, action: PayloadAction<{ smartGridId: string }>) => {
      const { smartGridId } = action.payload

      state[smartGridId] = null

      return state
    },
    clearSmartGrid: (state, action: PayloadAction<{ smartGridId: string }>) => {
      const { smartGridId } = action.payload

      state[smartGridId] = null

      return state
    },
  },
})

export const {
  setEditColumn,
  clearEditColumn,
  clearSmartGrid,
} = slice.actions

export default slice.reducer
