
import { buildGetUrl, parse } from 'utils/api'
import { getFirstString } from 'utils/getFirstOfType'
import { safeFetch, safeFetchJson } from 'utils/safeFetch'

import {
  GET_INVOICES_COUNT,
  GET_INVOICES,
  CLEAR_INVOICES,
  GET_INVOICE_ITEMS_COUNT,
  GET_INVOICE_ITEMS,
  CLEAR_INVOICE_ITEMS,
} from './types'

const dataSetName = 'invoiceView'
const itemDataSetName = 'invoiceItemView'
const initialState = {
  dataSetName,
  fields: getFields(),
  invoices: [],
  invoicesCount: 0,
  itemFields: getItemFields(),
  invoiceItemsCount: 0,
  invoiceItems: [],
}

export default function invoicesReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
  case GET_INVOICES_COUNT: {
    return {
      ...state,
      invoicesCount: payload,
    }
  }
  case GET_INVOICES: {
    return {
      ...state,
      invoices: payload,
    }
  }
  case CLEAR_INVOICES: {
    return {
      ...state,
      invoicesCount: 0,
      invoices: [],
    }
  }
  case GET_INVOICE_ITEMS_COUNT: {
    return {
      ...state,
      invoiceItemsCount: payload,
    }
  }
  case GET_INVOICE_ITEMS: {
    return {
      ...state,
      invoiceItems: payload,
    }
  }
  case CLEAR_INVOICE_ITEMS: {
    return {
      ...state,
      invoiceItemsCount: 0,
      invoiceItems: [],
    }
  }
  default: {
    return state
  }
  }
}

export function getFields() {
  return {
    'id': { dataSetName, dbField: 'id', type: 'id' },
    'createdDate': { dataSetName, dbField: 'created_date', type: 'date' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'date' },
    'createdBy': { dataSetName, dbField: 'created_by' },
    'createdById': { dataSetName, dbField: 'created_by_id', type: 'id' },
    'modifiedBy': { dataSetName, dbField: 'modified_by' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id', type: 'id' },
    'companyId': { dataSetName, dbField: 'company_id', type: 'id' },
    'customerId': { dataSetName, dbField: 'customer_id', type: 'id' },
    'shipToAddressId': { dataSetName, dbField: 'ship_to_address_id', type: 'id', relationEntity: 'addresses' },
    'billToAddressId': { dataSetName, dbField: 'bill_to_address_id', type: 'id', relationEntity: 'addresses' },
    'name': { dataSetName, dbField: 'name' },
    'notes': { dataSetName, dbField: 'notes', type: 'text' },
    'referenceNumber': { dataSetName, dbField: 'reference_number' },
    'invoiceDate': { dataSetName, dbField: 'invoice_date', type: 'date', isTimezoned: false },
    'status': {
      dataSetName,
      dbField: 'status',
      customEventValueTranslationKey: (value) => `invoices:status.${value ?? 'draft'}`,
    },
    'paymentTerms': { dataSetName, dbField: 'payment_terms' },
    'subject': { dataSetName, dbField: 'subject' },
    'termsAndConditions': { dataSetName, dbField: 'terms_and_conditions', type: 'text' },
    'currencyId': { dataSetName, dbField: 'currency_id', type: 'id' },
    'exchangeRate': { dataSetName, dbField: 'exchange_rate', type: 'currency' },
    'externalId': { dataSetName, dbField: 'external_id', type: 'id' },
    'config': { dataSetName, dbField: 'config', type: 'json' },
    'dueDate': { dataSetName, dbField: 'due_date', type: 'date', isTimezoned: false },
    'isOverdue': { dataSetName, dbField: 'is_overdue', type: 'boolean' },
    'clientCompanyName': { dataSetName, dbField: 'client_company_name' },
    'clientDisplayName': { dataSetName, dbField: 'client_display_name' },
    'currencyCode': { dataSetName, dbField: 'currency_code' },
    'currencySymbol': { dataSetName, dbField: 'currency_symbol' },
  }
}

export function getItemFields() {
  return {
    'id': { dataSetName: itemDataSetName, dbField: 'id', type: 'id' },
    'invoiceId': { dataSetName: itemDataSetName, dbField: 'invoice_id', type: 'id', relationEntity: 'invoices' },
    'invoiceName': { dataSetName: itemDataSetName, dbField: 'invoice_name' },
    'invoiceStatus': { dataSetName: itemDataSetName, dbField: 'invoice_status' },
    'invoiceIsOverdue': { dataSetName: itemDataSetName, dbField: 'invoice_is_overdue', type: 'boolean' },
    'invoiceExternalId': { dataSetName: itemDataSetName, dbField: 'invoice_external_id', type: 'id' },
    'modifiedDate': { dataSetName: itemDataSetName, dbField: 'modified_date', type: 'date' },
    'modifiedBy': { dataSetName: itemDataSetName, dbField: 'modified_by' },
    'modifiedById': { dataSetName: itemDataSetName, dbField: 'modified_by_id', type: 'id' },
    'invoiced': { dataSetName: itemDataSetName, dbField: 'measure', type: 'measure' },
    'salesOrderMeasure': { dataSetName: itemDataSetName, dbField: 'sales_order_measure', type: 'measure' },
    'salesOrderInvoiced': { dataSetName: itemDataSetName, dbField: 'sales_order_invoiced_count', type: 'measure' },
    'salesOrderShipped': { dataSetName: itemDataSetName, dbField: 'sales_order_shipped_count', type: 'measure' },
    'unit': { parse: getUnit },
    'dimension': { dataSetName: itemDataSetName, dbField: 'dimension_to_display' },
    'incomeAccountId': {
      dataSetName: itemDataSetName,
      dbField: 'income_account_id',
      type: 'id',
      relationEntity: 'chart-of-accounts',
    },
  }
}

export function fetchInvoicesCount(data) {
  return async function fetchInvoicesCountThunk(dispatch) {
    try {
      const result = await (await safeFetch(buildGetUrl('/new_api/invoices/count', data))).json()
      if (result.isSuccess) {
        const count = +result.result[0].count || 0
        dispatch({ type: GET_INVOICES_COUNT, payload: count })
        return count
      }

      return 0
    } catch (err) {
      console.error(err)
      return 0
    }
  }
}

export function fetchInvoices(data) {
  return async function fetchInvoicesThunk(dispatch) {
    let invoices = []

    try {
      const result = await (await safeFetch(buildGetUrl('/new_api/invoices', data))).json()
      if (result.isSuccess) {
        invoices = result.result.map((invoice) => parseInvoice(invoice))
        dispatch({ type: GET_INVOICES, payload: invoices })
      }
    } catch (err) {
      console.error(err)
    }

    return invoices
  }
}

export async function fetchInvoicesByIds(ids, data) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson(
    buildGetUrl(`/new_api/invoices/${ids}`, data),
  )

  return isSuccess ? result.map((invoice) => parseInvoice(invoice)) : []
}

export function getInvoiceTitle(invoice) {
  return getFirstString(
    invoice.name,
    invoice.referenceNumber,
  )
}

export async function updateInvoices(invoices) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ invoices }),
  }

  try {
    return (await safeFetch(`/api/integrations/invoices/batch`, requestOptions)).json()
  } catch (error) {
    console.error(error)
  }
}

export function clearInvoices(dispatch) {
  dispatch({ type: CLEAR_INVOICES })
}

export function fetchInvoiceItemsCount(data) {
  return async function fetchInvoiceItemsCountThunk(dispatch) {
    try {
      const result = await (await safeFetch(buildGetUrl('/new_api/invoices/items/count', data))).json()
      if (result.isSuccess) {
        const count = +result.result[0].count || 0
        dispatch({ type: GET_INVOICE_ITEMS_COUNT, payload: count })
        return count
      }

      return 0
    } catch (err) {
      console.error(err)
      return 0
    }
  }
}

export function fetchInvoiceItems(data, mapData) {
  return async function fetchInvoiceItemsThunk(dispatch) {
    let invoiceItems = []

    try {
      const result = await (await safeFetch(buildGetUrl('/new_api/invoices/items', data))).json()
      if (result.isSuccess) {
        invoiceItems = result.result.map((invoiceItem) => parseInvoiceItem(invoiceItem, mapData))
        dispatch({ type: GET_INVOICE_ITEMS, payload: invoiceItems })
      }
    } catch (err) {
      console.error(err)
    }

    return invoiceItems
  }
}

export function clearInvoiceItems(dispatch) {
  dispatch({ type: CLEAR_INVOICE_ITEMS })
}

export function parseInvoice(invoice) {
  const options = {
    defaultData: getDefaultInvoice(),
    fields: initialState.fields,
    dataSetName,
  }
  return parse(invoice, options)
}

function parseInvoiceItem(invoiceItem, mapData) {
  const options = {
    defaultData: getDefaultInvoiceItem(),
    fields: initialState.itemFields,
    dataSetName: itemDataSetName,
    defaultUnits: mapData.defaultUnits,
  }
  return parse(invoiceItem, options)
}

function getUnit(invoiceItem, options = {}) {
  const defaultUnits = options.defaultUnits || {}
  return invoiceItem.measure_unit || defaultUnits[invoiceItem.dimension_to_display]
}

function getDefaultInvoice() {
  return parse({}, { fields: initialState.fields })
}

function getDefaultInvoiceItem() {
  return parse({}, { fields: initialState.itemFields })
}
