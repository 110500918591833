import {
  CREATE_SENDER,
  CLEAR_SENDER_CREATE_ERROR,
  UPDATE_SENDER,
  CLEAR_SENDER_UPDATE_ERROR,
  DELETE_SENDER,
  CLEAR_SENDER_DELETE_ERROR,
  REFRESH_DOMAIN,
  CLEAR_DOMAIN_REFRESH_ERROR,
  VERIFY_DOMAIN,
  CLEAR_DOMAIN_VERIFY_ERROR,
} from './types'

const initState = {
  createSenderError: null,
  updateSenderError: null,
  deleteSenderError: null,
  refreshDomainError: null,
  verifyDomainError: null,
}

export default function emailsErrorReducer(state = initState, action) {
  const { error } = action

  switch (action.type) {
  case CREATE_SENDER: {
    return {
      ...state,
      createSenderError: error,
    }
  }
  case CLEAR_SENDER_CREATE_ERROR: {
    return {
      ...state,
      createSenderError: null,
    }
  }
  case UPDATE_SENDER: {
    return {
      ...state,
      updateSenderError: error,
    }
  }
  case CLEAR_SENDER_UPDATE_ERROR: {
    return {
      ...state,
      updateSenderError: null,
    }
  }
  case DELETE_SENDER: {
    return {
      ...state,
      deleteSenderError: error,
    }
  }
  case CLEAR_SENDER_DELETE_ERROR: {
    return {
      ...state,
      deleteSenderError: null,
    }
  }
  case REFRESH_DOMAIN: {
    return {
      ...state,
      refreshDomainError: error,
    }
  }
  case CLEAR_DOMAIN_REFRESH_ERROR: {
    return {
      ...state,
      refreshDomainError: null,
    }
  }
  case VERIFY_DOMAIN: {
    return {
      ...state,
      verifyDomainError: error,
    }
  }
  case CLEAR_DOMAIN_VERIFY_ERROR: {
    return {
      ...state,
      verifyDomainError: null,
    }
  }
  default:
    return state
  }
}

export function clearCreateSenderError(dispatch) {
  dispatch({ type: CLEAR_SENDER_CREATE_ERROR })
}

export function clearUpdateSenderError(dispatch) {
  dispatch({ type: CLEAR_SENDER_UPDATE_ERROR })
}

export function clearDeleteSenderError(dispatch) {
  dispatch({ type: CLEAR_SENDER_DELETE_ERROR })
}

export function clearRefreshDomainError(dispatch) {
  dispatch({ type: CLEAR_DOMAIN_REFRESH_ERROR })
}

export function clearVerifyDomainError(dispatch) {
  dispatch({ type: CLEAR_DOMAIN_VERIFY_ERROR })
}
