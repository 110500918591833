import {
  DELETE_PROJECTS,
} from './types'

const initState = {}

export default function itemsErrorReducer(state = initState, action) {
  const { error } = action

  switch (action.type) {
  case DELETE_PROJECTS: {
    return {
      ...state,
      deleteError: error,
    }
  }
  default:
    return state
  }
}
