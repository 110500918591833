export const GET_TREATMENTS = 'GET_TREATMENTS'
export const GET_TREATMENTS_COUNT = 'GET_TREATMENTS_COUNT'
export const GET_TREATMENT = 'GET_TREATMENT'
export const CREATE_TREATMENT = 'CREATE_TREATMENT'
export const UPDATE_TREATMENT = 'UPDATE_TREATMENT'
export const DELETE_TREATMENT = 'DELETE_TREATMENT'

export const CLEAR_TREATMENT = 'CLEAR_TREATMENT'
export const CLEAR_CREATE_SUCCESS = 'CLEAR_TREATMENT_CREATE_SUCCESS'
export const CLEAR_UPDATE_SUCCESS = 'CLEAR_TREATMENT_UPDATE_SUCCESS'
export const CLEAR_DELETE_SUCCESS = 'CLEAR_TREATMENT_DELETE_SUCCESS'
export const CLEAR_CREATE_ERROR = 'CLEAR_TREATMENT_CREATE_ERROR'
export const CLEAR_UPDATE_ERROR = 'CLEAR_TREATMENT_UPDATE_ERROR'
export const CLEAR_DELETE_ERROR = 'CLEAR_TREATMENT_DELETE_ERROR'

export const SET_IS_CREATE = 'SET_TREATMENT_IS_CREATE'
export const SET_GLOBAL_FORM = 'SET_TREATMENT_GLOBAL_FORM'
export const RESET_FORM = 'RESET_TREATMENT_FORM'
