type NumberType = 'tools' | 'equipments'

interface NumberConfig {
  'tools-increment'?: number
  'tools-suffix'?: number
  'equipments-increment'?: number
  'equipments-suffix'?: number
}

interface NumberGenerator {
  type: NumberType
  number: Record<string, any>
  numberConfig: NumberConfig
}

export function parseNumber(type: NumberType, number: Record<string, any>, config) {
  const numberGenerator = {
    type,
    number,
    numberConfig: {
      [`${type}-increment`]: 5,
      [`${type}-suffix`]: 5,
    },
  } as NumberGenerator
  _setIncrementLength(numberGenerator, config)

  let result = ''
  if (_isNumberSet(number)) {
    const prefix = number.prefix
    const separator1 = number.separator1
    const increment = number.increment.toString()
      .padStart(numberGenerator.numberConfig[`${numberGenerator.type}-increment`], '0')
    const separator2 = number.separator2
    const suffix = number.suffix
    result = `${prefix}${separator1}${increment}${separator2}${suffix}`
  }
  return result
}

function hasIncrement(increment: any) {
  return !isNaN(parseInt(increment))
}

function _isNumberSet(number: Record<string, any>) {
  return hasIncrement(number.increment) || number.prefix || number.suffix || number.separator1 || number.separator2
}

function _setIncrementLength(numberGenerator: NumberGenerator, config) {
  const _hasIncrement = hasIncrement(config?.increment)
  if (_hasIncrement && config?.suffix) {
    numberGenerator.numberConfig[`${numberGenerator.type}-increment`] = config.increment
    numberGenerator.numberConfig[`${numberGenerator.type}-suffix`] = config.suffix
  } else if (_hasIncrement) {
    numberGenerator.numberConfig[`${numberGenerator.type}-increment`] = config.increment
    numberGenerator.numberConfig[`${numberGenerator.type}-suffix`] = config.increment
  } else if (config?.suffix) {
    numberGenerator.numberConfig[`${numberGenerator.type}-increment`] = config.suffix
    numberGenerator.numberConfig[`${numberGenerator.type}-suffix`] = config.suffix
  }
}
