import {
  CREATE_TREATMENT,
  CLEAR_CREATE_ERROR,
  UPDATE_TREATMENT,
  DELETE_TREATMENT,
  CLEAR_UPDATE_ERROR,
  CLEAR_DELETE_ERROR,
} from './types'

const initState = {
  createError: null,
  updateError: null,
  deleteError: null,
}

export default function treatmentsErrorReducer(state = initState, action) {
  const { error } = action

  switch (action.type) {
  case CREATE_TREATMENT: {
    return {
      ...state,
      createError: error,
    }
  }
  case CLEAR_CREATE_ERROR: {
    return {
      ...state,
      createError: null,
    }
  }
  case UPDATE_TREATMENT: {
    return {
      ...state,
      updateError: error,
    }
  }
  case DELETE_TREATMENT: {
    return {
      ...state,
      deleteError: error,
    }
  }
  case CLEAR_UPDATE_ERROR: {
    return {
      ...state,
      updateError: null,
    }
  }
  case CLEAR_DELETE_ERROR: {
    return {
      ...state,
      deleteError: null,
    }
  }
  default:
    return state
  }
}

export function clearCreateError(dispatch) {
  dispatch({ type: CLEAR_CREATE_ERROR })
}

export function clearUpdateError(dispatch) {
  dispatch({ type: CLEAR_UPDATE_ERROR })
}

export function clearDeleteError(dispatch) {
  dispatch({ type: CLEAR_DELETE_ERROR })
}
