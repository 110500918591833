export const GET_ITEM_GROUPS_COUNT = 'GET_ITEM_GROUPS_COUNT'
export const GET_ITEM_GROUPS = 'GET_ITEM_GROUPS'
export const GET_ITEM_GROUP = 'GET_ITEM_GROUP'
export const CREATE_ITEM_GROUP = 'CREATE_ITEM_GROUP'
export const UPDATE_ITEM_GROUP = 'UPDATE_ITEM_GROUP'
export const DELETE_ITEM_GROUP = 'DELETE_ITEM_GROUP'

export const CLEAR_ITEM_GROUP = 'CLEAR_ITEM_GROUP'
export const CLEAR_CREATE_SUCCESS = 'CLEAR_ITEM_GROUP_CREATE_SUCCESS'
export const CLEAR_UPDATE_SUCCESS = 'CLEAR_ITEM_GROUP_UPDATE_SUCCESS'
export const CLEAR_DELETE_SUCCESS = 'CLEAR_ITEM_GROUP_DELETE_SUCCESS'
export const CLEAR_CREATE_ERROR = 'CLEAR_ITEM_GROUP_CREATE_ERROR'
export const CLEAR_UPDATE_ERROR = 'CLEAR_ITEM_GROUP_UPDATE_ERROR'
export const CLEAR_DELETE_ERROR = 'CLEAR_ITEM_GROUP_DELETE_ERROR'

export const SET_IS_CREATE = 'SET_ITEM_GROUP_IS_CREATE'
export const SET_GLOBAL_FORM = 'SET_ITEM_GROUP_GLOBAL_FORM'
export const RESET_FORM = 'RESET_ITEM_GROUP_FORM'
