export const GET_LOGIN_USER = 'GET_LOGIN_USER'

export const SET_RULES = 'SET_LOGIN_USER_RULES'

export const SET_LANGUAGE_ERROR = 'SET_LANGUAGE_ERROR'
export const SET_PASSWORD_ERROR = 'SET_PASSWORD_ERROR'
export const SET_IS_PASSWORD_SUCCESS = 'SET_IS_PASSWORD_SUCCESS'
export const SET_EMAIL_ERROR = 'SET_EMAIL_ERROR'
export const SET_IS_EMAIL_SUCCESS = 'SET_IS_EMAIL_SUCCESS'

export const PUT_LOGIN_USER_LANGUAGE = 'PUT_LOGIN_USER_LANGUAGE'
export const PUT_LOGIN_USER_PASSWORD = 'PUT_LOGIN_USER_PASSWORD'
export const PUT_LOGIN_USER_EMAIL = 'PUT_LOGIN_USER_EMAIL'
