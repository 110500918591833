import { ApiToSlice, BaseEntityApi, GetFields, Modify } from 'types/slices'

import { buildGetUrl, parse } from 'utils/api'
import { parsedResultOnSucessOrEmtpy, safeFetchJson } from 'utils/safeFetch'

const dataSetName = 'timeEntries'

export type TimeEntryApi = Modify<{
  ismanualentry: boolean
  resourceid: string
  shift_id: string
  work_time_entry_id: string
  approvedby: string
  break_time_minutes: number
  typeofentry: number
  break_type: 'break' | 'diner' | 'default'
  comment: string
  timezone: string
  adjustedclockin: Date
  adjustedclockout: Date
  approveddate: Date
  clockin: Date
  clockout: Date
  entrydate: Date
  shiftend: Date
  shiftstart: Date
}, BaseEntityApi>

type Exceptions = {resourceid: 'resourceId'}

export type TimeEntry = ApiToSlice<TimeEntryApi, Exceptions>

export function getFields(): GetFields<TimeEntryApi, TimeEntry> {
  return {
    'exist': { dataSetName, dbField: 'exist', type: 'boolean' },
    'ismanualentry': { dataSetName, dbField: 'ismanualentry', type: 'boolean', isNotUserFriendly: true },

    'cid': { dataSetName, dbField: 'cid', type: 'id', isNotUserFriendly: true },
    'createdById': { dataSetName, dbField: 'created_by_id', type: 'id', isNotUserFriendly: true },
    'id': { dataSetName, dbField: 'id', type: 'id', isNotUserFriendly: true },
    'modifiedById': { dataSetName, dbField: 'modified_by_id', type: 'id', isNotUserFriendly: true },
    'resourceId': { dataSetName, dbField: 'resourceid', type: 'id', relationEntity: 'resources' },
    'shiftId': { dataSetName, dbField: 'shift_id', type: 'id', relationEntity: 'shifts' },
    'workTimeEntryId': {
      dataSetName,
      dbField: 'work_time_entry_id',
      type: 'id',
      relationEntity: 'time-entries',
      isNotUserFriendly: true,
    },

    'approvedby': { dataSetName, dbField: 'approvedby', type: 'id', relationEntity: 'resources' },
    'breakTimeMinutes': { dataSetName, dbField: 'break_time_minutes', type: 'integer' },
    'typeofentry': { dataSetName, dbField: 'typeofentry', type: 'integer', isNotUserFriendly: true },

    'breakType': {
      dataSetName,
      dbField: 'break_type',
      type: 'string',
      customEventValueTranslationKey: (value) => `timeEntries:timeEntry.breakTypes.${value ?? 'default'}`,
    },
    'comment': { dataSetName, dbField: 'comment', type: 'string' },
    'createdBy': { dataSetName, dbField: 'created_by', type: 'string', isNotUserFriendly: true },
    'modifiedBy': { dataSetName, dbField: 'modified_by', type: 'string', isNotUserFriendly: true },
    'timezone': { dataSetName, dbField: 'timezone', type: 'string', isNotUserFriendly: true },

    'adjustedclockin': { dataSetName, dbField: 'adjustedclockin', type: 'date', isNotUserFriendly: true },
    'adjustedclockout': { dataSetName, dbField: 'adjustedclockout', type: 'date', isNotUserFriendly: true },
    'approveddate': { dataSetName, dbField: 'approveddate', type: 'date', isNotUserFriendly: true },
    'clockin': { dataSetName, dbField: 'clockin', type: 'timestamp' },
    'clockout': { dataSetName, dbField: 'clockout', type: 'timestamp' },
    'createdDate': { dataSetName, dbField: 'created_date', type: 'date', isNotUserFriendly: true },
    'entrydate': { dataSetName, dbField: 'entrydate', type: 'date', isNotUserFriendly: true },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'date', isNotUserFriendly: true },
    'shiftend': { dataSetName, dbField: 'shiftend', type: 'date', isNotUserFriendly: true },
    'shiftstart': { dataSetName, dbField: 'shiftstart', type: 'date', isNotUserFriendly: true },
  }
}

const initialState = {
  fields: getFields(),
}

export function parseTimeEntry(timeEntry) {
  const fields = initialState.fields

  const options = {
    fields: fields,
    dataSetName,
  }
  return parse(timeEntry, options)
}

export async function fetchTimeEntriesByIds(ids, data = {}) {
  const response = await safeFetchJson(buildGetUrl(`/new_api/resources/time-entries/${ids}`, data))

  return parsedResultOnSucessOrEmtpy(response, parseTimeEntry)
}

export function getTitle(timeEntry, _, t) {
  return t?.(`timeEntries:timeEntry.breakTypes.${timeEntry.breakType}`) ?? timeEntry.breakType
}
