import {
  CLEAR_CUSTOM_IDENTITY_PROVIDER_CREATE_ERROR,
  CLEAR_CUSTOM_IDENTITY_PROVIDER_DELETE_ERROR,
  CLEAR_CUSTOM_IDENTITY_PROVIDER_UPDATE_ERROR,
  CREATE_CUSTOM_IDENTITY_PROVIDER,
  DELETE_CUSTOM_IDENTITY_PROVIDER,
  UPDATE_CUSTOM_IDENTITY_PROVIDER,
} from './types'

const initState = {
  createError: null,
  updateError: null,
  deleteError: null,
}

export default function customIdentityProviderErrorReducer(state = initState, action) {
  const { error } = action
  switch (action.type) {
  case CREATE_CUSTOM_IDENTITY_PROVIDER: {
    return { ...state, createError: error }
  }
  case CLEAR_CUSTOM_IDENTITY_PROVIDER_CREATE_ERROR: {
    return { ...state, createError: null }
  }
  case UPDATE_CUSTOM_IDENTITY_PROVIDER: {
    return { ...state, updateError: error }
  }
  case CLEAR_CUSTOM_IDENTITY_PROVIDER_UPDATE_ERROR: {
    return { ...state, updateError: null }
  }
  case DELETE_CUSTOM_IDENTITY_PROVIDER: {
    return { ...state, deleteError: error }
  }
  case CLEAR_CUSTOM_IDENTITY_PROVIDER_DELETE_ERROR: {
    return { ...state, deleteError: null }
  }
  default:
    return state
  }
}

export function clearCustomIdentityProviderCreateError(dispatch) {
  dispatch({ type: CLEAR_CUSTOM_IDENTITY_PROVIDER_CREATE_ERROR })
}

export function clearCustomIdentityProviderUpdateError(dispatch) {
  dispatch({ type: CLEAR_CUSTOM_IDENTITY_PROVIDER_UPDATE_ERROR })
}

export function clearCustomIdentityProviderDeleteError(dispatch) {
  dispatch({ type: CLEAR_CUSTOM_IDENTITY_PROVIDER_DELETE_ERROR })
}
