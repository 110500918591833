export const GET_CONTACT_CATEGORIES_COUNT = 'GET_CONTACT_CATEGORIES_COUNT'
export const GET_CONTACT_CATEGORIES = 'GET_CONTACT_CATEGORIES'
export const GET_CONTACT_CATEGORY = 'GET_CONTACT_CATEGORY'
export const CREATE_CONTACT_CATEGORY = 'CREATE_CONTACT_CATEGORY'
export const UPDATE_CONTACT_CATEGORY = 'UPDATE_CONTACT_CATEGORY'
export const DELETE_CONTACT_CATEGORY = 'DELETE_CONTACT_CATEGORY'

export const CLEAR_CONTACT_CATEGORY = 'CLEAR_CONTACT_CATEGORY'
export const CLEAR_CREATE_SUCCESS = 'CLEAR_CONTACT_CATEGORY_CREATE_SUCCESS'
export const CLEAR_UPDATE_SUCCESS = 'CLEAR_CONTACT_CATEGORY_UPDATE_SUCCESS'
export const CLEAR_DELETE_SUCCESS = 'CLEAR_CONTACT_CATEGORY_DELETE_SUCCESS'
export const CLEAR_CREATE_ERROR = 'CLEAR_CONTACT_CATEGORY_CREATE_ERROR'
export const CLEAR_UPDATE_ERROR = 'CLEAR_CONTACT_CATEGORY_UPDATE_ERROR'
export const CLEAR_DELETE_ERROR = 'CLEAR_CONTACT_CATEGORY_DELETE_ERROR'

export const SET_IS_CREATE = 'SET_CONTACT_CATEGORY_IS_CREATE'
export const SET_GLOBAL_FORM = 'SET_CONTACT_CATEGORY_GLOBAL_FORM'
export const RESET_FORM = 'RESET_CONTACT_CATEGORY_FORM'
