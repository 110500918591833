import { ApiToSlice, GetFields, Modify } from 'types/slices'
import { PrefixedRecord } from 'types/utils'

import { ItemApi } from 'reducers/items/apiType'

export const projectPlanningTemplateApiPrefix = 'template'

export const projectPlanningTemplateFieldPrefix = '$template$.'

export type ProjectPlanningApi = {
  id: string,
  exist: boolean,
  cid: string,
  created_date: Date,
  created_by: string,
  created_by_id: string,
  template_id: string,
  template_template_title: string,
  template_measure_unit: string,
  template_initial_measure: number,
  recommended: number,
  ordered: number,
  on_hand: number,
  other_demand: number,
  net_flow_after: number,
  project_planned_demand: number,
  project_demand: number,
  project_consumed: number,
  remaining_after: number,
  top_of_green: number,
  top_of_yellow: number,
  top_of_red: number,
  position: number,
  position_color: 'green' | 'yellow' | 'red',
}
type Exceptions = {
  template_template_title: 'templateTitle',
  template_measure_unit: 'unit',
  template_initial_measure: 'initialMeasure',
  recommended: 'baseRecommendation',
}

export type ProjectPlanning = Modify<ApiToSlice<ProjectPlanningApi, Exceptions>, {
  recommendation: number,
  onOrder: number,
  onHand: number,
  otherDemand: number,
  netFlow: number,
  projectPlannedDemand: number,
  projectDemand: number,
  projectConsumed: number,
  remainingAfter: number,
  topOfGreen: number,
  topOfYellow: number,
  topOfRed: number,
  position: string,
}>
export type ProjectPlanningFields = GetFields<ProjectPlanningApi, ProjectPlanning>

export type FullProjectPlanningApi = ProjectPlanningApi &
  PrefixedRecord<ItemApi, `${typeof projectPlanningTemplateApiPrefix}_`>

export type FullProjectPlanning = ApiToSlice<FullProjectPlanningApi>

export type ProjectPlanningAnalysisApi = {
  [itemId: string]: { template_id: string, red_zone_date: string }
}

// #region Project Planning Details
export type ProjectPlanningDetailsApi = {
  cid: string,
  planned_date: Date,
  planned_type: string,
  template_title: string,
  template_description: string,
  measure: number,
  cumulative: number,
  position: string,
  dimension: string,
  unit: string,
  sku: string,
  planning_type: string,
  sales_order_id: string,
  sales_order_number: string,
  purchase_order_id: string,
  invoice_title: string,
  supplier_display_name: string,
  item_id: string,
  item_title: string,
  item_detailed_number: string,
  contact_display_name: string,
}
type ProjectPlanningDetailsExceptions = {
  planned_date: 'date',
  planned_type: 'type',
  template_description: 'description',
  measure: 'quantity',
  cumulative: 'netFlow',
}

export type ProjectPlanningDetails = ApiToSlice<Modify<ProjectPlanningDetailsApi, {
  id: number,
  reference: number,
  referenceId: string,
  referenceType: string,
}>, ProjectPlanningDetailsExceptions>
export type ProjectPlanningDetailsFields = GetFields<ProjectPlanningDetailsApi, ProjectPlanningDetails>
// #endregion
