
import { buildGetUrl, parse } from 'utils/api'
import { parseError, safeFetchJson } from 'utils/safeFetch'

import { GET_CONSUMPTION_PLANNED_PRICES, GET_COST, GET_ITEM_COST } from './types'

export const apiCostUrlBuilder = (id) => `/new_api/projects/${id}/cost`

const dataSetName = ''

export function getFields() {
  return {
    'manufacturingOrderId': { dataSetName, dbField: 'manufacturing_order_id' },
    'currency_code': { dataSetName, dbField: 'sales_currency_code' },
    'unit': { dataSetName, dbField: 'so_item_measure_unit' },
    'dimension': { dataSetName, dbField: 'so_item_dimension_to_display' },
    // #region plannedCost
    'plannedCost.employee.resultUnitary': { dataSetName, dbField: 'resource_planned_cost_unitary', defaultValue: 0 },
    'plannedCost.employee.result': { dataSetName, dbField: 'resource_planned_cost', defaultValue: 0 },

    'plannedCost.equipment.resultUnitary': { dataSetName, dbField: 'equipment_planned_cost_unitary', defaultValue: 0 },
    'plannedCost.equipment.result': { dataSetName, dbField: 'equipment_planned_cost', defaultValue: 0 },

    'plannedCost.material.resultUnitary': { dataSetName, dbField: 'material_planned_cost_unitary', defaultValue: 0 },
    'plannedCost.material.result': { dataSetName, dbField: 'material_planned_cost', defaultValue: 0 },

    'plannedCost.subcontract.resultUnitary': {
      dataSetName,
      dbField: 'subcontract_planned_cost_unitary',
      defaultValue: 0,
    },
    'plannedCost.subcontract.result': { dataSetName, dbField: 'subcontract_planned_cost', defaultValue: 0 },

    'plannedCost.total.resultUnitary': { dataSetName, dbField: 'planned_cost_price_unitary', defaultValue: 0 },
    'plannedCost.total.result': { dataSetName, dbField: 'planned_cost_price', defaultValue: 0 },
    // #endregion
    // #region realCost
    'realCost.employee.resultUnitary': { dataSetName, dbField: 'resource_real_cost_unitary', defaultValue: 0 },
    'realCost.employee.result': { dataSetName, dbField: 'resource_real_cost', defaultValue: 0 },
    'realCost.employee.variance': { dataSetName, dbField: 'resource_real_cost_var', defaultValue: 0 },
    'realCost.employee.variancePct': { dataSetName, dbField: 'resource_real_cost_var_pct', defaultValue: 0 },

    'realCost.equipment.resultUnitary': { dataSetName, dbField: 'equipment_real_cost_unitary', defaultValue: 0 },
    'realCost.equipment.result': { dataSetName, dbField: 'equipment_real_cost', defaultValue: 0 },
    'realCost.equipment.variance': { dataSetName, dbField: 'equipment_real_cost_var', defaultValue: 0 },
    'realCost.equipment.variancePct': { dataSetName, dbField: 'equipment_real_cost_var_pct', defaultValue: 0 },

    'realCost.material.resultUnitary': { dataSetName, dbField: 'material_real_cost_unitary', defaultValue: 0 },
    'realCost.material.result': { dataSetName, dbField: 'material_real_cost', defaultValue: 0 },
    'realCost.material.variance': { dataSetName, dbField: 'material_real_cost_var', defaultValue: 0 },
    'realCost.material.variancePct': { dataSetName, dbField: 'material_real_cost_var_pct', defaultValue: 0 },

    'realCost.subcontract.resultUnitary': { dataSetName, dbField: 'subcontract_real_cost_unitary', defaultValue: 0 },
    'realCost.subcontract.result': { dataSetName, dbField: 'subcontract_real_cost', defaultValue: 0 },
    'realCost.subcontract.variance': { dataSetName, dbField: 'subcontract_real_cost_var', defaultValue: 0 },
    'realCost.subcontract.variancePct': { dataSetName, dbField: 'subcontract_real_cost_var_pct', defaultValue: 0 },

    'realCost.total.resultUnitary': { dataSetName, dbField: 'real_cost_price_unitary', defaultValue: 0 },
    'realCost.total.result': { dataSetName, dbField: 'real_cost_price', defaultValue: 0 },
    'realCost.total.variance': { dataSetName, dbField: 'real_cost_price_var', defaultValue: 0 },
    'realCost.total.variancePct': { dataSetName, dbField: 'real_cost_price_var_pct', defaultValue: 0 },
    // #endregion
    // #region plannedPrice
    'plannedPrice.employee.resultUnitary': { dataSetName, dbField: 'resource_planned_price_unitary', defaultValue: 0 },
    'plannedPrice.employee.result': { dataSetName, dbField: 'resource_planned_price', defaultValue: 0 },
    'plannedPrice.employee.markup': { dataSetName, dbField: 'resource_time_markup', defaultValue: 0 },
    'plannedPrice.employee.discount': { dataSetName, dbField: 'project_resource_discount', defaultValue: 0 },

    'plannedPrice.equipment.resultUnitary': {
      dataSetName, dbField: 'equipment_planned_price_unitary', defaultValue: 0,
    },
    'plannedPrice.equipment.result': { dataSetName, dbField: 'equipment_planned_price', defaultValue: 0 },
    'plannedPrice.equipment.markup': { dataSetName, dbField: 'equipment_time_markup', defaultValue: 0 },
    'plannedPrice.equipment.discount': { dataSetName, dbField: 'project_equipment_discount', defaultValue: 0 },

    'plannedPrice.material.resultUnitary': { dataSetName, dbField: 'material_planned_price_unitary', defaultValue: 0 },
    'plannedPrice.material.result': { dataSetName, dbField: 'material_planned_price', defaultValue: 0 },
    'plannedPrice.material.markup': { dataSetName, dbField: 'project_markup', defaultValue: 0 },
    'plannedPrice.material.discount': { dataSetName, dbField: 'project_material_discount', defaultValue: 0 },

    'plannedPrice.subcontract.resultUnitary': {
      dataSetName, dbField: 'subcontract_planned_price_unitary', defaultValue: 0,
    },
    'plannedPrice.subcontract.result': { dataSetName, dbField: 'subcontract_planned_price', defaultValue: 0 },
    'plannedPrice.subcontract.markup': { dataSetName, dbField: 'project_markup', defaultValue: 0 },
    'plannedPrice.subcontract.discount': { dataSetName, dbField: 'project_subcontract_discount', defaultValue: 0 },

    'plannedPrice.total.resultUnitary': { dataSetName, dbField: 'planned_selling_price_unitary', defaultValue: 0 },
    'plannedPrice.total.result': { dataSetName, dbField: 'planned_selling_price', defaultValue: 0 },
    // #endregion
    // #region realPrice
    'realPrice.total.resultUnitary': { dataSetName, dbField: 'real_selling_price_unitary', defaultValue: 0 },
    'realPrice.total.result': { dataSetName, dbField: 'real_selling_price', defaultValue: 0 },
    // #endregion
    // #region profit.planned
    'profit.planned.batch': { dataSetName, dbField: 'planned_profit_unitary', defaultValue: 0 },
    'profit.planned.profit': { dataSetName, dbField: 'planned_profit', defaultValue: 0 },
    'profit.planned.margin': { dataSetName, dbField: 'planned_profit_margin', defaultValue: 0 },
    'profit.planned.markup': { dataSetName, dbField: 'planned_profit_markup', defaultValue: 0 },
    // #endregion
    // #region profit.anticipated
    'profit.anticipated.batch': { dataSetName, dbField: 'anticipated_profit_unitary', defaultValue: 0 },
    'profit.anticipated.profit': { dataSetName, dbField: 'anticipated_profit', defaultValue: 0 },
    'profit.anticipated.margin': { dataSetName, dbField: 'anticipated_profit_margin', defaultValue: 0 },
    'profit.anticipated.markup': { dataSetName, dbField: 'anticipated_profit_markup', defaultValue: 0 },
    // #endregion
    // #region profit.real
    'profit.real.batch': { dataSetName, dbField: 'real_profit_unitary', defaultValue: 0 },
    'profit.real.profit': { dataSetName, dbField: 'real_profit', defaultValue: 0 },
    'profit.real.margin': { dataSetName, dbField: 'real_profit_margin', defaultValue: 0 },
    'profit.real.markup': { dataSetName, dbField: 'real_profit_markup', defaultValue: 0 },
    // #endregion
  }
}

export function getConsumptionPlannedFields() {
  return {
    'id': { dataSetName, dbField: 'template_id' },
    'templateTitle': { dataSetName, dbField: 'template_title' },
    'measure': { dataSetName, dbField: 'measure' },
    'unitSellingPrice': { dataSetName, dbField: 'unit_selling_price' },
  }
}

function parseCost(result) {
  return parse(result, { fields: getFields() })
}

function parseConsumptionPlannedPrice(result) {
  return parse(result, { fields: getConsumptionPlannedFields() })
}

const initialState = {
  data: [],
  itemCost: [],
  consumptionPlannedPrices: [],
}

export default function itemsReducer(state = initialState, action) {
  const { payload } = action

  switch (action.type) {
  case GET_COST: {
    return {
      ...state,
      data: payload,
    }
  }
  case GET_ITEM_COST: {
    return {
      ...state,
      itemCost: payload,
    }
  }
  case GET_CONSUMPTION_PLANNED_PRICES: {
    return {
      ...state,
      consumptionPlannedPrices: payload,
    }
  }
  default: {
    return state
  }
  }
}

export function fetchCost({ projectId }) {
  return async function fetchItemsThunk(dispatch) {
    try {
      const { isSuccess, result } = await safeFetchJson(buildGetUrl(apiCostUrlBuilder(projectId)))

      const payload = isSuccess ? result.map((result) => parseCost(result)): []
      const error = !isSuccess ? result : null
      dispatch({ type: GET_COST, payload, error })
      return result
    } catch (error) {
      dispatch({ type: GET_COST, error: parseError(error) })
    }
  }
}

export function fetchItemCost(data) {
  return async function fetchItemsThunk(dispatch) {
    try {
      const { isSuccess, result } = await safeFetchJson(buildGetUrl('/new_api/items/cost', data))

      const payload = isSuccess ? result.map((result) => parseCost(result)) : []
      const error = !isSuccess ? result : null
      dispatch({ type: GET_ITEM_COST, payload, error })
      return payload
    } catch (error) {
      dispatch({ type: GET_ITEM_COST, error: parseError(error) })
    }
  }
}

export function fetchConsumptionPlannedPrices(data) {
  return async function fetchConsumptionPlannedPricesThunk(dispatch) {
    try {
      const { isSuccess, result } = await safeFetchJson(
        buildGetUrl('/new_api/items/cost/consumption-planned-prices', data),
      )

      const payload = isSuccess ? result.map((result) => parseConsumptionPlannedPrice(result)) : []
      const error = !isSuccess ? result : null
      dispatch({ type: GET_CONSUMPTION_PLANNED_PRICES, payload, error })
      return result
    } catch (error) {
      dispatch({ type: GET_CONSUMPTION_PLANNED_PRICES, error: parseError(error) })
    }
  }
}
