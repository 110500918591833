import { createSlice } from '@reduxjs/toolkit'
import { ApiToSlice, BaseEntityApi, GetFields, Modify } from 'types/slices'

import { buildGetUrl, parse } from 'utils/api'
import { isJob, safeFetchJson } from 'utils/safeFetch'

const dataSetName = 'shipmentListView'

const initialState = {
  dataSetName,
  fields: getFields(),
  shipmentListsCount: 0,
  sipmentLists: [],
}

const slice = createSlice({
  name: 'shipmentLists',
  initialState,
  reducers: {
    setShipmentLists: (state, action) => {
      state.sipmentLists = action.payload.data
    },
    setShipmentListsCount: (state, action) => {
      state.shipmentListsCount = action.payload.count
    },
  },
})

export const { setShipmentLists, setShipmentListsCount } = slice.actions

export type MapData = {
  shipmentId: string
  number: string
}

export type ShipmentListApi = Modify<{
  shipment_id: string
  number: string
  ship_to_address: string
  ship_to_attention: string
  ship_to_street2: string
  ship_to_city: string
  ship_to_state: string
  ship_to_zip: string
  ship_to_country: string
  ship_to_phone: string
}, BaseEntityApi>

type Exceptions = {ship_to_address: 'shipToStreet'}

export type ShipmentList = ApiToSlice<Modify<{trimmed_number: number}, ShipmentListApi>, Exceptions>

type ShipmentListGetFields = GetFields<ShipmentListApi, ShipmentList, MapData>

export function getFields(): ShipmentListGetFields {
  const editFields: ShipmentListGetFields = {
    'id': { dataSetName, dbField: 'id', type: 'string' },
    'shipmentId': {
      dataSetName,
      dbField: 'shipment_id',
      type: 'string',
      isEdit: true,
      relationEntity: 'shipments',
    },
    'number': {
      dataSetName,
      dbField: 'number',
      type: 'string',
      isEdit: false,
    },
    'trimmedNumber': {
      parse: (data) => +(data.number?.split('-')?.[1]),
      isEdit: false,
    },
    'shipToAttention': { dataSetName, dbField: 'ship_to_attention' },
    'shipToStreet': { dataSetName, dbField: 'ship_to_address' },
    'shipToStreet2': { dataSetName, dbField: 'ship_to_street2' },
    'shipToCity': { dataSetName, dbField: 'ship_to_city' },
    'shipToState': { dataSetName, dbField: 'ship_to_state' },
    'shipToZip': { dataSetName, dbField: 'ship_to_zip' },
    'shipToCountry': { dataSetName, dbField: 'ship_to_country' },
    'shipToPhone': { dataSetName, dbField: 'ship_to_phone' },
  }

  return editFields
}

export function getShipmentListTitle(shipmentList: ShipmentList): string {
  return shipmentList.number
}

export async function fetchShipmentListsByIds(ids: string[], mapData?: MapData, data?: Record<string, any>) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson<ShipmentListApi>(
    buildGetUrl(`/new_api/shipments/lists/${ids}`, data),
  )

  return isSuccess && !isJob(result) ?
    result.map((shipmentList) => parseShipmentList(shipmentList, mapData)) :
    []
}

export function parseShipmentList(shipmentList: ShipmentListApi, mapData: MapData): ShipmentList {
  const options = {
    defaultData: getDefaultShipmentList(),
    fields: initialState.fields,
    dataSetName: dataSetName,
    ...mapData,
  }

  return parse(shipmentList, options)
}

function getDefaultShipmentList(): ShipmentList {
  return parse({}, { fields: initialState.fields })
}

export default slice.reducer
