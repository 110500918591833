export const GET_SETTINGS = 'GET_SETTINGS'
export const POST_SETTING = 'POST_SETTING'
export const GET_SECURITY_SETTINGS = 'GET_SECURITY_SETTINGS'
export const POST_SECURITY_SETTING = 'POST_SECURITY_SETTING'
export const CLEAR_SUCCESS_FIELD = 'CLEAR_SUCCESS_FIELD'
export const CLEAR_SETTING_ERROR = 'CLEAR_SETTING_ERROR'

export const GET_CLIENT_SOCKET = 'GET_CLIENT_SOCKET'
export const GET_APP_SETTINGS = 'GET_APP_SETTINGS'
export const GET_REACT_VERSION = 'GET_REACT_VERSION'
export const GET_REACT_HUB_VERSION = 'GET_REACT_HUB_VERSION'
export const GET_FRONT_URL = 'GET_FRONT_URL'
export const GET_MENU_ITEMS = 'GET_MENU_ITEMS'
export const GET_MY_ORGANIZATIONS = 'GET_MY_ORGANIZATIONS'
export const GET_SETTING_MENU_ITEMS = 'GET_SETTING_MENU_ITEMS'
export const GET_CUSTOMER_PORTAL_VERSION = 'GET_CUSTOMER_PORTAL_VERSION'
export const GET_PRINTERS = 'GET_PRINTERS'
