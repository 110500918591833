import { buildGetUrl, parse } from 'utils/api'
import { safeFetch } from 'utils/safeFetch'

const dataSetName = 'warehouse'
const fields = getFields()
const initialState = { dataSetName, fields }

export default function warehousesReducer(state = initialState, action) {
  switch (action.type) {
  default: {
    return state
  }
  }
}

// warehouses exports
export function getFields() {
  return {
    id: { dataSetName, dbField: 'id' },
    exist: { dataSetName, dbField: 'exist' },
    title: { dataSetName, dbField: 'title' },
  }
}

export function getDefaultWarehouse() {
  return parse({}, { fields })
}

export async function fetchWarehouses(data = {}) {
  let warehouses = []

  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/locations/warehouses', data))).json()
    if (result.isSuccess) {
      warehouses = result.result.map((warehouse) => parseWarehouse(warehouse))
    }
  } catch (err) {
    console.error(err)
  }
  return warehouses
}

// parsing functions
function parseWarehouse(warehouse) {
  const options = {
    defaultData: parse({}, { fields }),
    fields: initialState.fields,
    dataSetName,
  }
  return parse(warehouse, options)
}
