
import { buildGetUrl } from 'utils/api'
import { safeFetch } from 'utils/safeFetch'

import { GET_ZOHO_BOOKS_INTEGRATION } from './types'

const initialState = {
  zohoBooksIntegration: {},
}

export default function integrationsReducer(state = initialState, action) {
  const { payload } = action

  switch (action.type) {
  case GET_ZOHO_BOOKS_INTEGRATION: {
    return {
      ...state,
      zohoBooksIntegration: payload,
    }
  }
  default: {
    return state
  }
  }
}

export async function fetchIntegrations(type) {
  let integration
  try {
    const result = await (await safeFetch(buildGetUrl(`/api/integrations/${type}`))).json()
    if (result.isSuccess) {
      integration = result.result[0]
    }
  } catch (err) {
    console.error(err)
  }

  return integration
}

export function fetchZohoBooksIntegrations() {
  return async function fetchZohoBooksIntegrationsThunk(dispatch) {
    const zohoBooksIntegration = await fetchIntegrations('zoho-books')
    dispatch({ type: GET_ZOHO_BOOKS_INTEGRATION, payload: zohoBooksIntegration })
  }
}

