import moment from 'moment'

/**
 * Convert a moment date to standard ALIX api string with timezone
 * @param date
 * @param timezone Should be moment readable timezone ie: Z, ZZ, +0000, -0400
 * @returns {string}
 */
export function dateToTimezoneString(date = moment(), timezone = 'ZZ'): string {
  if (timezone != 'ZZ') {
    timezone = `[Z][${timezone}]`
  }
  return date.format(`YYYY-MM-DD HH:mm:ss.SSS${timezone}`)
}

export function dateToStringWithoutTimezone(date = moment(), isMidnight?: boolean, hoursFormat?: string): string {
  const hours = hoursFormat ?? (isMidnight ? '[00:00:00]' : 'HH:mm:ss.SSS')
  return date.format(`YYYY-MM-DD ${hours}`)
}

export function dateToString(
  date = moment(),
  isTimezoned = true,
  options?: {
    timezone?: string,
    isMidnight?: boolean
    hoursFormat?: string
  },
): string {
  return isTimezoned ?
    dateToTimezoneString(date, options?.timezone) :
    dateToStringWithoutTimezone(date, options?.isMidnight, options?.hoursFormat)
}

interface ToDisplayOptions {
  format?: string
  language?: string
  showTime?: boolean
  culture?: string
}
export function dateToDisplay(date = moment(), options: ToDisplayOptions): string {
  const timeFormat = options.showTime ? ' HH:mm' : ''

  const format = (options?.format || (options?.language === 'en' ? 'MMMM Do YYYY' : 'DD MMMM YYYY')) + timeFormat

  if (options.culture) {
    date = date.locale(options.culture)
  }

  return date.format(format)
}

export function addToDate(date: Date, type: 'day' | 'week' | 'month', quantity: number): Date {
  if (type === 'day') {
    date.setDate(date.getDate() + quantity)
  } else if (type === 'week') {
    date.setDate(date.getDate() + quantity * 7)
  } else if (type === 'month') {
    date.setMonth(date.getMonth() + quantity)
  }
  return date
}

export function getNow(isMidnight: boolean = false): Date {
  const now = new Date()
  if (!isMidnight) return now

  return new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0)
}
