export const GET_SENDERS = 'GET_SENDERS'

export const CREATE_SENDER = 'CREATE_SENDER'
export const UPDATE_SENDER = 'UPDATE_SENDER'
export const DELETE_SENDER = 'DELETE_SENDER'
export const REFRESH_DOMAIN = 'REFRESH_DOMAIN'
export const SEND_EMAIL = 'SEND_EMAIL'
export const VERIFY_DOMAIN = 'VERIFY_DOMAIN'
export const CHANGE_REGION = 'CHANGE_REGION'

export const CLEAR_SENDERS = 'CLEAR_SENDERS'
export const CLEAR_SENDER_CREATE_SUCCESS = 'CLEAR_SENDER_CREATE_SUCCESS'
export const CLEAR_SENDER_UPDATE_SUCCESS = 'CLEAR_SENDER_UPDATE_SUCCESS'
export const CLEAR_SENDER_DELETE_SUCCESS = 'CLEAR_SENDER_DELETE_SUCCESS'
export const CLEAR_DOMAIN_REFRESH_SUCCESS = 'CLEAR_DOMAIN_REFRESH_SUCCESS'
export const CLEAR_DOMAIN_VERIFY_SUCCESS = 'CLEAR_DOMAIN_VERIFY_SUCCESS'
export const CLEAR_SENDER_CREATE_ERROR = 'CLEAR_SENDER_CREATE_ERROR'
export const CLEAR_SENDER_UPDATE_ERROR = 'CLEAR_SENDER_UPDATE_ERROR'
export const CLEAR_SENDER_DELETE_ERROR = 'CLEAR_SENDER_DELETE_ERROR'
export const CLEAR_DOMAIN_REFRESH_ERROR = 'CLEAR_DOMAIN_REFRESH_ERROR'
export const CLEAR_DOMAIN_VERIFY_ERROR = 'CLEAR_DOMAIN_VERIFY_ERROR'
