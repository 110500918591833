export const GET_ITEMS_COUNT = 'GET_ITEMS_COUNT'
export const GET_ITEMS = 'GET_ITEMS'
export const DELETE_ITEM = 'DELETE_ITEM'
export const CREATE_ITEM = 'CREATE_ITEM'
export const COPY_ITEM = 'COPY_ITEM'
export const UPDATE_ITEM = 'UPDATE_ITEM'

export const CLEAR_ITEMS = 'CLEAR_ITEMS'
export const CLEAR_DELETE_SUCCESS = 'CLEAR_DELETE_ITEM_SUCCESS'
export const CLEAR_DELETE_ERROR = 'CLEAR_DELETE_ITEM_ERROR'
export const CLEAR_CREATE_SUCCESS = 'CLEAR_CREATE_ITEM_SUCCESS'
export const CLEAR_CREATE_ERROR = 'CLEAR_CREATE_ITEM_ERROR'
export const CLEAR_COPY_SUCCESS = 'CLEAR_COPY_ITEM_SUCCESS'
export const CLEAR_COPY_ERROR = 'CLEAR_COPY_ITEM_ERROR'
export const CLEAR_UPDATE_ERROR = 'CLEAR_UPDATE_ITEM_ERROR'
export const CLEAR_UPDATE_SUCCESS = 'CLEAR_UPDATE_ITEM_SUCCESS'

export const SET_ITEM_FORM = 'SET_ITEM_FORM'

export const SET_ITEM_REPORTING_TAGS_FORM = 'SET_ITEM_REPORTING_TAGS_FORM'
export const INIT_ITEM_REPORTING_TAGS_FORM = 'INIT_ITEM_REPORTING_TAGS_FORM'
export const DELETE_ITEM_REPORTING_TAGS_FROM_SELECTION =
  'DELETE_ITEM_REPORTING_TAGS_FROM_SELECTION'
export const SET_ITEM_REPORTING_TAGS_FORM_SELECTION =
  'SET_ITEM_REPORTING_TAGS_FORM_SELECTION'
