import { FieldType, GetFields } from 'types/slices'

import { parse } from 'utils/api'
import { capitalize } from 'utils/history'
import { calculate } from 'utils/numberParser'
import { convertFromBase } from 'utils/unitConverter'
import { getMeasureUnit } from 'utils/weightUtils'

import { parseCheckList } from 'reducers/checklists/checkListsSlice'
import { parseCustomField } from 'reducers/custom-fields/customFieldsSlice'
import {
  getFields as getInventoryFields,
  parseInventory,
} from 'reducers/inventories/inventoriesSlice'
import { getDisplayTitleFromApi as getLocationDisplayTitleFromApi } from 'reducers/locations/locationsSlice'
import { getDisplayTitleFromApi as getProjectDisplayTitleFromApi } from 'reducers/projects/projectsSlice'
import {
  columnFromReception,
  columnsFromInventory,
  inventoryFieldPrefix,
  Reception,
  ReceptionApi,
  receptionFieldPrefix,
  FullReceptionItem,
  FullReceptionItemApi,
  ReceptionMapData,
  ReceptionStatus,
  ApiKeysFromInventory,
  ApiKeysFromReception,
  inventoryApiPrefix,
  receptionApiPrefix,
  WeighType,
} from 'reducers/receptions/receptionType'
import {
  filterFields,
  formatFields,
  FormatFieldsOptions,
} from 'reducers/utils/common'
import { getBaseEntityFields } from 'reducers/utils/common'

export const receptionDataSetName = 'receptionView'
export const receptionItemDataSetName = 'receptionItemView'

export type ReceptionItemMapData = {
  defaultUnits?: {
    qty: string
    weight: string
    length: string
    surface: string
    volume: string
  }
  isTimeOnReceptionDate?: boolean
  primaryLanguage?: string
  secondaryLanguage?: string
};

const inventoryFields = getInventoryFields()
const receptionFields = getFields()

const overrideField = {
  dataSetName: receptionItemDataSetName,
}

const usedInventoryFields = filterFields<
    typeof inventoryFields,
    ApiKeysFromInventory
  >(inventoryFields, columnsFromInventory, overrideField, [
    'id',
    'invoice',
    'serialNumber',
    'projectId',
    'plannedTargetInventoryId',
    'locationId',
    'invoiceDate',
    'vendorPartNumber',
  ])

const usedReceptionFields = filterFields<
    typeof receptionFields,
    ApiKeysFromReception
  >(receptionFields, columnFromReception, overrideField, [])

function _getWeightFields(type: WeighType): ReceptionGetFields {
  const capitalizedType = capitalize(type)

  return {
    [`${type}IsManual`]: {
      dataSetName: receptionDataSetName,
      dbField: `${type}_is_manual`,
      type: 'boolean',
    },
    [`${type}IsCalculated`]: {
      dataSetName: receptionDataSetName,
      dbField: `${type}_is_calculated`,
      type: 'boolean',
    },
    [`${type}WeightReadingRegisterId`]: {
      dataSetName: receptionDataSetName,
      dbField: `${type}_weight_reading_register_id`,
      type: 'id',
      relationEntity: 'readings-register',
    },
    [`${type}WeightManualReadingRegisterId`]: {
      dataSetName: receptionDataSetName,
      dbField: `${type}_weight_manual_reading_register_id`,
      type: 'id',
      relationEntity: 'readings-register',
    },
    [`display${capitalizedType}RegisterId`]: {
      dataSetName: receptionDataSetName,
      dbField: `display_${type}_register_id`,
      type: 'id',
      relationEntity: 'readings-register',
    },
    [`display${capitalizedType}RegisterName`]: {
      dataSetName: receptionDataSetName,
      dbField: `display_${type}_register_name`,
    },
    [`display${capitalizedType}RegisterReadingDate`]: {
      dataSetName: receptionDataSetName,
      dbField: `display_${type}_register_reading_date`,
      type: 'timestamp',
    },
    [`display${capitalizedType}RegisterCreatedById`]: {
      dataSetName: receptionDataSetName,
      dbField: `display_${type}_register_created_by_id`,
      type: 'id',
      relationEntity: 'readings-register',
    },
    [`display${capitalizedType}RegisterCreatedBy`]: {
      dataSetName: receptionDataSetName,
      dbField: `display_${type}_register_created_by`,
    },
    [`display${capitalizedType}RegisterMeasure`]: {
      dataSetName: receptionDataSetName,
      dbField: `display_${type}_register_measure`,
      type: 'measure',
    },
    [`display${capitalizedType}RegisterStart`]: {
      dataSetName: receptionDataSetName,
      dbField: `display_${type}_register_start`,
      type: 'timestamp',
    },
    [`display${capitalizedType}RegisterEnd`]: {
      dataSetName: receptionDataSetName,
      dbField: `display_${type}_register_end`,
      type: 'timestamp',
    },
    [`oldScale${capitalizedType}RegisterId`]: {
      dataSetName: receptionDataSetName,
      dbField: `old_scale_${type}_register_id`,
      type: 'id',
      relationEntity: 'readings-register',
    },
    [`oldScale${capitalizedType}RegisterName`]: {
      dataSetName: receptionDataSetName,
      dbField: `old_scale_${type}_register_name`,
    },
    [`oldScale${capitalizedType}RegisterReadingDate`]: {
      dataSetName: receptionDataSetName,
      dbField: `old_scale_${type}_register_reading_date`,
      type: 'timestamp',
    },
    [`oldScale${capitalizedType}RegisterCreatedById`]: {
      dataSetName: receptionDataSetName,
      dbField: `old_scale_${type}_register_created_by_id`,
      type: 'id',
      relationEntity: 'readings-register',
    },
    [`oldScale${capitalizedType}RegisterCreatedBy`]: {
      dataSetName: receptionDataSetName,
      dbField: `old_scale_${type}_register_created_by`,
    },
    [`oldScale${capitalizedType}RegisterMeasure`]: {
      dataSetName: receptionDataSetName,
      dbField: `old_scale_${type}_register_measure`,
      type: 'measure',
    },
  }
}

export const lineItemInitialState = {
  data: [],
  count: 0,
  fields: getLineItemFields(),
}

export const initialState = {
  dataSetName: receptionDataSetName,
  fields: getFields(),
  itemFields: getLineItemFields(),
  receptionsCount: 0,
  receptions: [],
}

function getDefaultReceptionItem() {
  return parse({}, { fields: lineItemInitialState.fields })
}

export function parseReceptionItem(
  item: FullReceptionItemApi,
  mapData: ReceptionItemMapData,
  onlyParseAvailableFields = false,
): FullReceptionItem {
  const options = {
    defaultData: getDefaultReceptionItem(),
    fields: lineItemInitialState.fields,
    dataSetName: receptionItemDataSetName,
    defaultUnits: mapData?.defaultUnits,
    onlyParseAvailableFields,
    isTimeOnReceptionDate: mapData?.isTimeOnReceptionDate,
  }

  const inventory = parseInventory(
    { ...item } as any,
    options.defaultUnits,
    options.dataSetName,
    {
      apiPrefix: inventoryApiPrefix,
      outPrefix: inventoryFieldPrefix,
      overrideFields: usedInventoryFields,
    },
  )

  const reception = parseReception({ ...item } as any,
    options,
    options.dataSetName,
    {
      apiPrefix: receptionApiPrefix,
      outPrefix: receptionFieldPrefix,
      overrideFields: usedReceptionFields,
    })

  const receptionItem = parse({ ...item } as any, {
    ...options,
    fields: receptionItemFields(),
  })

  return {
    ...receptionItem,
    ...reception,
    ...inventory,
  }
}

function getConvertedMeasure(receptionItem: FullReceptionItem, options = {}) {
  return +convertFromBase(
    receptionItem.dimension?.toString(),
    +receptionItem.partialValueRaw,
    getMeasureUnit(
      {
        _measureUnit: receptionItem.measureUnit,
        dimension: receptionItem.dimension,
      },
      options,
    ),
    true,
  )
}

export type ReceptionItemFields = GetFields<FullReceptionItemApi, FullReceptionItem>;

function receptionItemFields(): ReceptionItemFields {
  const baseFields = getBaseEntityFields<ReceptionItemFields>({
    dataSetName: receptionItemDataSetName,
  })()

  return {
    ...baseFields,
    dimension: {
      dataSetName: receptionItemDataSetName,
      dbField: 'dimension',
      type: 'string',
    },

    templateId: {
      dataSetName: receptionItemDataSetName,
      dbField: 'template_id',
      type: 'id',
      relationEntity: 'items',
    },
    templateSku: {
      dataSetName: receptionItemDataSetName,
      dbField: 'template_sku',
      type: 'string',
    },
    templateTargetInventoryMandatory: {
      dataSetName: receptionItemDataSetName,
      dbField: 'template_target_inventory_mandatory',
      type: 'boolean',
    },
    templateTitle: {
      dataSetName: receptionItemDataSetName,
      dbField: 'template_title',
      type: 'string',
    },
    inventoryCustomFields: {
      parse: (receptionItem) => (receptionItem.inventory_custom_fields ?? []).map((c) => parseCustomField(c)),
    },
    measureUnit: {
      dataSetName: receptionItemDataSetName,
      dbField: 'measure_unit',
      type: 'string',
    },
    projectTitle: {
      dataSetName: receptionItemDataSetName,
      dbField: 'project_title',
      type: 'string',
    },
    projectFormatedNumber: {
      dataSetName: receptionItemDataSetName,
      dbField: 'project_formated_number',
      type: 'string',
    },
    projectDisplayTitle: {
      parse: (data) => getProjectDisplayTitleFromApi({
        title: data.project_title,
        formated_number: data.project_formated_number,
      }),
    },
    locationDisplayTitle: {
      parse: (data) => getLocationDisplayTitleFromApi({
        title: data.location_title,
        code: data.location_code,
      }),
    },
    plannedLedgerId: {
      dataSetName: receptionItemDataSetName,
      dbField: 'planned_ledger_id',
      type: 'id',
      relationEntity: 'planned-ledgers',
      isEdit: true,
    },
    plannedLedgerMeasure: {
      dataSetName: receptionItemDataSetName,
      dbField: 'planned_ledger_measure',
      type: 'measure',
    },
    plannedLedgerPlannedMeasure: {
      dataSetName: receptionItemDataSetName,
      dbField: 'planned_ledger_planned_measure',
      type: 'measure',
    },

    tareWeight: {
      dataSetName: receptionItemDataSetName,
      dbField: 'tare_weight',
      type: 'measure',
      isEdit: true,
    },
    tareUnit: {
      dataSetName: receptionItemDataSetName,
      dbField: 'tare_unit',
      type: 'string',
      isEdit: true,
    },
    tareTypeId: {
      dataSetName: receptionItemDataSetName,
      dbField: 'tare_type_id',
      type: 'id',
      relationEntity: 'tares',
      isEdit: true,
    },
    unitWeight: {
      dataSetName: receptionItemDataSetName,
      dbField: 'unit_weight',
      type: 'measure',
      isEdit: true,
    },
    partialValueRaw: {
      dataSetName: receptionItemDataSetName,
      dbField: 'partial_value_raw',
      type: 'measure',
      isEdit: true,
    },
    // TODO (odeschenes): Add the `relationEntity` when we start using the `template-suppliers` entity
    templateSupplierId: {
      dataSetName: receptionItemDataSetName,
      dbField: 'template_supplier_id',
      type: 'id',
    },
    templateSupplierCustomUnit: {
      dataSetName: receptionItemDataSetName,
      dbField: 'template_supplier_custom_unit',
      type: 'string',
    },
    templateSupplierConversionFactor: {
      dataSetName: receptionItemDataSetName,
      dbField: 'template_supplier_conversion_factor',
      type: 'float',
    },
    purchaseOrderItemId: {
      dataSetName: receptionItemDataSetName,
      dbField: 'purchase_order_item_id',
      type: 'id',
      relationEntity: 'purchase-order-items',
    },
    consignmentItemId: {
      dataSetName: receptionItemDataSetName,
      dbField: 'consignment_item_id',
      type: 'id',
      relationEntity: 'consignment-items',
    },
    purchaseOrderId: {
      dataSetName: receptionItemDataSetName,
      dbField: 'purchase_order_id',
      type: 'id',
      relationEntity: 'purchase-orders',
    },
    salesOrderId: {
      dataSetName: receptionItemDataSetName,
      dbField: 'sales_order_id',
      type: 'id',
      relationEntity: 'sales-orders',
    },

    vendorDisplayName: {
      dataSetName: receptionItemDataSetName,
      dbField: 'vendor_display_name',
    },
    vendorLanguage: {
      dataSetName: receptionItemDataSetName,
      dbField: 'vendor_language',
    },

    isPartial: {
      dataSetName: receptionItemDataSetName,
      dbField: 'is_partial',
      type: 'boolean',
      isEdit: true,
    },
    weightInputIsGrossOrNet: {
      dataSetName: receptionItemDataSetName,
      dbField: 'weight_input_is_gross_or_net',
      type: 'boolean',
      isEdit: true,
    },

    readingRegisterIds: {
      dataSetName: receptionItemDataSetName,
      dbField: 'reading_register_ids',
      type: 'array',
      isEdit: true,
      parse: () => [],
    },
    locationTitle: {
      dataSetName: receptionItemDataSetName,
      dbField: 'location_title',
      type: 'string',
    },
    convertedMeasure: {
      parseWithParsedData: getConvertedMeasure,
      type: 'measure',
    },
    plannedLedgerPartialReceiptId: {
      dataSetName: receptionItemDataSetName,
      dbField: 'planned_ledger_partial_receipt_id',
      type: 'id',
      relationEntity: 'planned-ledgers',
      isNotUserFriendly: true,
    },

    netWeight: {
      parse: (data) => {
        let _netWeight = +data.partial_value_raw || 0

        if (data.dimension !== 'weight') {
          _netWeight = calculate('*', [_netWeight, +data.unit_weight || 0])
        }

        return _netWeight
      },
    },
    grossWeight: {
      parseWithParsedData: (data) => {
        return calculate('+', [+data.netWeight || 0, +data.tareWeight || 0])
      },
    },

    groupName: {
      dataSetName: receptionItemDataSetName,
      dbField: 'group_name',
    },
    materialTitle: {
      dataSetName: receptionItemDataSetName,
      dbField: 'material_title',
    },
    categoryTitle: {
      dataSetName: receptionItemDataSetName,
      dbField: 'category_title',
    },
    rawImperialTitle: {
      dataSetName: receptionItemDataSetName,
      dbField: 'raw_imperial_title',
    },
    rawMetricTitle: {
      dataSetName: receptionItemDataSetName,
      dbField: 'raw_metric_title',
    },
    treatmentTitle: {
      dataSetName: receptionItemDataSetName,
      dbField: 'treatment_title',
    },
  } satisfies ReceptionItemFields
}

export function getLineItemFields() {
  const dataSetName = receptionItemDataSetName

  const defaultFieldInfo: Partial<FieldType> = {
    dataSetName,
    useTrimAliasInField: true,
  }

  const options: FormatFieldsOptions = {
    isCamelCase: true,
  }

  const inventoryFields = formatFields(
    usedInventoryFields,
    inventoryFieldPrefix,
    inventoryFieldPrefix,
    defaultFieldInfo,
    options,
  )

  const receptionFields = formatFields(
    usedReceptionFields,
    receptionFieldPrefix,
    receptionFieldPrefix,
    defaultFieldInfo,
    options,
  )

  return {
    ...receptionItemFields(),
    ...inventoryFields,
    ...receptionFields,
  }
}

function parseReceptionDate(
  reception: ReceptionApi,
  options: ReceptionMapData,
) {
  return options?.isTimeOnReceptionDate ?
    reception.receipt_datetime :
    reception.receipt_posting_date
}

export type ReceptionGetFields = GetFields<
  ReceptionApi,
  Reception,
  ReceptionMapData,
  null,
  { config: { apiKey: 'config' } }
>;

export function getFields(): ReceptionGetFields {
  const baseFields = getBaseEntityFields<ReceptionGetFields>({ dataSetName: receptionDataSetName })()

  return {
    ...baseFields,
    name: { dataSetName: receptionDataSetName, dbField: 'name', type: 'string' },
    status: {
      type: 'status',
      dictionaryKey: 'reception',
      values: ReceptionStatus,
      translationPath: 'receptions:status.values',
      dataSetName: receptionDataSetName,
      dbField: 'status',
      isEdit: true,
    },

    referenceNumber: {
      dataSetName: receptionDataSetName,
      dbField: 'reference_number',
      type: 'string',
    },
    originType: {
      dataSetName: receptionDataSetName,
      dbField: 'origin_type',
      type: 'string',
    },
    inventoryTags: {
      dataSetName: receptionDataSetName,
      dbField: 'inventory_tags',
      type: 'string',
    },
    plantName: {
      dataSetName: receptionDataSetName,
      dbField: 'plant_name',
      type: 'string',
    },

    formattedReceiptDate: {
      parse: parseReceptionDate,
      type: 'timestamp',
      customFieldTranslationKey: (t) => t('receptions:reception.fields.receiptPostingDate.label'),
      // We need the dbField to enable the sort, filter, etc.
      dbField: 'receipt_datetime',
      dataSetName: receptionDataSetName,
    },
    receiptDatetime: {
      dataSetName: receptionDataSetName,
      dbField: 'receipt_datetime',
      type: 'timestamp',
      isEdit: true,
    },
    receiptPostingDate: {
      dataSetName: receptionDataSetName,
      dbField: 'receipt_posting_date',
      type: 'date',
      isEdit: true,
    },
    plannedReceiptPostingDate: {
      dataSetName: receptionDataSetName,
      dbField: 'planned_receipt_posting_date',
      type: 'timestamp',
      isEdit: true,
    },
    weightInputIsGrossOrNet: {
      dataSetName: receptionDataSetName,
      dbField: 'weight_input_is_gross_or_net',
      type: 'boolean',
      isEdit: true,
    },
    carrier: {
      dataSetName: receptionDataSetName,
      dbField: 'carrier',
      type: 'string',
      isEdit: true,
    },
    driver: {
      dataSetName: receptionDataSetName,
      dbField: 'driver',
      type: 'string',
      isEdit: true,
    },
    notes: {
      dataSetName: receptionDataSetName,
      dbField: 'notes',
      type: 'text',
      isEdit: true,
    },
    checklistId: {
      dataSetName: receptionDataSetName,
      dbField: 'checklist_id',
      type: 'id',
      relationEntity: 'checklists',
    },

    grossWeightReadingRegisterId: {
      dataSetName: receptionDataSetName,
      dbField: 'gross_weight_reading_register_id',
      type: 'id',
      relationEntity: 'readings-register',
    },
    grossWeightManualReadingRegisterId: {
      dataSetName: receptionDataSetName,
      dbField: 'gross_weight_manual_reading_register_id',
      type: 'id',
      relationEntity: 'readings-register',
    },

    tareWeightReadingRegisterId: {
      dataSetName: receptionDataSetName,
      dbField: 'tare_weight_reading_register_id',
      type: 'id',
      relationEntity: 'readings-register',
    },
    tareWeightManualReadingRegisterId: {
      dataSetName: receptionDataSetName,
      dbField: 'tare_weight_manual_reading_register_id',
      type: 'id',
      relationEntity: 'readings-register',
    },

    netWeightReadingRegisterId: {
      dataSetName: receptionDataSetName,
      dbField: 'net_weight_reading_register_id',
      type: 'id',
      relationEntity: 'readings-register',
    },
    netWeightManualReadingRegisterId: {
      dataSetName: receptionDataSetName,
      dbField: 'net_weight_manual_reading_register_id',
      type: 'id',
      relationEntity: 'readings-register',
    },

    isPrintOnReceive: {
      dataSetName: receptionDataSetName,
      dbField: 'is_print_on_receive',
      type: 'boolean',
      isEdit: true,
    },
    isApplyWeightOnUniqueInventory: {
      dataSetName: receptionDataSetName,
      dbField: 'is_apply_weight_on_unique_inventory',
      type: 'boolean',
    },
    isShowWeighingsSection: {
      dataSetName: receptionDataSetName,
      dbField: 'is_show_weighings_section',
      type: 'boolean',
      isEdit: true,
    },

    // Vendor columns

    vendorId: {
      dataSetName: receptionDataSetName,
      dbField: 'vendor_id',
      type: 'id',
      relationEntity: 'contacts',
      isEdit: true,
    },
    vendorDisplayName: { dataSetName: receptionDataSetName, dbField: 'vendor_display_name' },
    vendorLanguage: { dataSetName: receptionDataSetName, dbField: 'vendor_language' },
    vendorDefaultChecklistId: {
      dataSetName: receptionDataSetName,
      dbField: 'vendor_default_checklist_id',
      type: 'id',
      relationEntity: 'checklists',
    },
    vendorAddress: { parse: parseVendorAddress },
    vendorMainPersonEmail: {
      dataSetName: receptionDataSetName,
      dbField: 'vendor_main_person_email',
      type: 'string',
    },

    // Line items columns

    lineItems: {
      parse: (data, mapData) => {
        return (data?.lineItems || []).map((lineItem) =>
          parseReceptionItem(lineItem, mapData),
        )
      },
    },

    ..._getWeightFields('gross'),
    ..._getWeightFields('tare'),
    ..._getWeightFields('net'),
    config: {
      dataSetName: receptionDataSetName,
      dbField: 'config',
      type: 'json',
      isEdit: true,
      properties: {
        language: {
          dataSetName: receptionDataSetName,
          dbField: 'language',
          type: 'language',
          isEdit: false,
        },
        hideWeights: {
          dataSetName: receptionDataSetName,
          dbField: 'hideWeights',
          type: 'boolean',
          isEdit: false,
        },
        showCustomFields: {
          dataSetName: receptionDataSetName,
          dbField: 'showCustomFields',
          type: 'boolean',
          isEdit: false,
        },
      },
    },
    checklist: { parse: (reception) => reception.checklist ? parseCheckList(reception.checklist) : null },
  }
}
export function parseReception(
  reception: ReceptionApi,
  ReceptionMapData?: ReceptionMapData,
  dataSetName?: string,
  options: {
    outPrefix?: string;
    apiPrefix?: string;
    overrideFields?: Partial<ReceptionGetFields>,
  } = {},
): Reception {
  const fields = options.overrideFields ?? initialState.fields

  const _options = {
    defaultData: getDefaultReception(fields),
    fields: fields,
    dataSetName: dataSetName ?? receptionDataSetName,
    isTimeOnReceptionDate: ReceptionMapData?.isTimeOnReceptionDate,
    ...options,
  }

  return parse(reception, _options)
}
export function getDefaultReception(fields = initialState.fields): Reception {
  return parse({}, { fields })
}

function parseVendorAddress(reception: ReceptionApi) {
  return {
    id: reception.vendor_default_billing_address_id,
    externalId: reception.vendor_default_billing_address_external_id,
    city: reception.vendor_default_billing_address_city,
    state: reception.vendor_default_billing_address_state,
    stateCode: reception.vendor_default_billing_address_state_code,
    zip: reception.vendor_default_billing_address_zip,
    country: reception.vendor_default_billing_address_country,
    attention: reception.vendor_default_billing_address_attention,
    street: reception.vendor_default_billing_address_address,
    street2: reception.vendor_default_billing_address_street2,
    phone: reception.vendor_default_billing_address_phone,
    fax: reception.vendor_default_billing_address_fax,
  }
}
