import {
  CREATE_SENDER,
  CLEAR_SENDER_CREATE_SUCCESS,
  UPDATE_SENDER,
  CLEAR_SENDER_UPDATE_SUCCESS,
  DELETE_SENDER,
  CLEAR_SENDER_DELETE_SUCCESS,
  REFRESH_DOMAIN,
  CLEAR_DOMAIN_REFRESH_SUCCESS,
  VERIFY_DOMAIN,
  CLEAR_DOMAIN_VERIFY_SUCCESS,
} from './types'

const initState = {
  isSenderCreateSuccess: null,
  isSenderUpdateSuccess: null,
  isSenderDeleteSuccess: null,
  isDomainRefreshSuccess: null,
  isDomainVerifySuccess: null,
}

export default function emailsSuccessReducer(state = initState, action) {
  const { payload } = action

  switch (action.type) {
  case CREATE_SENDER: {
    return {
      ...state,
      isSenderCreateSuccess: payload && payload.id ? true : false,
    }
  }
  case CLEAR_SENDER_CREATE_SUCCESS: {
    return {
      ...state,
      isSenderCreateSuccess: false,
    }
  }
  case UPDATE_SENDER: {
    return {
      ...state,
      isSenderUpdateSuccess: payload && payload.id ? true : false,
    }
  }
  case CLEAR_SENDER_UPDATE_SUCCESS: {
    return {
      ...state,
      isSenderUpdateSuccess: false,
    }
  }
  case DELETE_SENDER: {
    return {
      ...state,
      isSenderDeleteSuccess: payload.isSuccess,
    }
  }
  case CLEAR_SENDER_DELETE_SUCCESS: {
    return {
      ...state,
      isSenderDeleteSuccess: false,
    }
  }
  case REFRESH_DOMAIN: {
    return {
      ...state,
      isDomainRefreshSuccess: payload && payload.id ? true : false,
    }
  }
  case CLEAR_DOMAIN_REFRESH_SUCCESS: {
    return {
      ...state,
      isDomainRefreshSuccess: false,
    }
  }
  case VERIFY_DOMAIN: {
    return {
      ...state,
      isDomainVerifySuccess: payload && payload.id ? true : false,
    }
  }
  case CLEAR_DOMAIN_VERIFY_SUCCESS: {
    return {
      ...state,
      isDomainVerifySuccess: false,
    }
  }
  default:
    return state
  }
}

export function clearCreateSenderSuccess(dispatch) {
  dispatch({ type: CLEAR_SENDER_CREATE_SUCCESS })
}

export function clearUpdateSenderSuccess(dispatch) {
  dispatch({ type: CLEAR_SENDER_UPDATE_SUCCESS })
}

export function clearDeleteSenderSuccess(dispatch) {
  dispatch({ type: CLEAR_SENDER_DELETE_SUCCESS })
}

export function clearRefreshDomainSuccess(dispatch) {
  dispatch({ type: CLEAR_DOMAIN_REFRESH_SUCCESS })
}

export function clearVerifyDomainSuccess(dispatch) {
  dispatch({ type: CLEAR_DOMAIN_VERIFY_SUCCESS })
}
