export const GET_SALES_ORDERS_COUNT = 'GET_SALES_ORDERS_COUNT'
export const GET_SALES_ORDERS = 'GET_SALES_ORDERS'
export const GET_SALES_ORDER = 'GET_SALES_ORDER'

export const CREATE_SALES_ORDER = 'CREATE_SALES_ORDER'
export const UPDATE_SALES_ORDER = 'UPDATE_SALES_ORDER'
export const UPDATE_SALES_ORDERS = 'UPDATE_SALES_ORDERS'
export const UPDATE_ACTIVE_SALES_ORDER_STATUS = 'UPDATE_ACTIVE_SALES_ORDER_STATUS'
export const DELETE_SALES_ORDER = 'DELETE_SALES_ORDER'
export const DUPLICATE_SALES_ORDER = 'DUPLICATE_SALES_ORDER'

export const CLEAR_SALES_ORDER = 'CLEAR_SALES_ORDER'
export const CLEAR_CREATE_ERROR = 'CLEAR_SALES_ORDER_CREATE_ERROR'
export const CLEAR_CREATE_SUCCESS = 'CLEAR_SALES_ORDER_CREATE_SUCCESS'
export const CLEAR_UPDATE_ERROR = 'CLEAR_SALES_ORDER_UPDATE_ERROR'
export const CLEAR_UPDATE_SUCCESS = 'CLEAR_SALES_ORDER_UPDATE_SUCCESS'
export const CLEAR_DELETE_ERROR = 'CLEAR_SALES_ORDER_DELETE_ERROR'
export const CLEAR_DELETE_SUCCESS = 'CLEAR_SALES_ORDER_DELETE_SUCCESS'

export const GET_CURRENCIES = 'GET_SALES_ORDER_CURRENCIES'

export const SET_IS_CREATE = 'SET_SALES_ORDER_IS_CREATE'
export const SET_GLOBAL_FORM = 'SET_SALES_ORDER_GLOBAL_FORM'
export const SET_CUSTOMER_DETAILS = 'SET_SALES_ORDER_CUSTOMER_DETAILS'
export const SET_LINE_ITEMS_FORM = 'SET_SALES_ORDER_LINE_ITEMS_FORM'
export const DELETE_LINE_ITEMS_FROM_SELECTION = 'DELETE_SALES_ORDER_LINE_ITEMS_FROM_SELECTION'
export const SET_SHIP_TO_FORM = 'SET_SHIP_TO_FORM'
export const SET_BILL_TO_FORM = 'SET_BILL_TO_FORM'
export const RESET_FORM = 'RESET_SALES_ORDER_FORM'
export const DUPLICATE_LINE_ITEMS_FROM_SELECTION = 'DUPLICATE_LINE_ITEMS_FROM_SELECTION'

export const SET_TAXES = 'SET_TAXES'

export const UPDATE_SALES_ORDER_ITEMS = 'UPDATE_SALES_ORDER_ITEMS'
export const UPDATE_CREATING_SALES_ORDER_ITEMS = 'UPDATE_CREATING_SALES_ORDER_ITEMS'
export const SET_SO_ITEM_REPORTING_TAGS_FORM = 'SET_SO_ITEM_REPORTING_TAGS_FORM'
export const INIT_SO_ITEM_REPORTING_TAGS_FORM = 'INIT_SO_ITEM_REPORTING_TAGS_FORM'
export const DELETE_SO_ITEM_REPORTING_TAGS_FROM_SELECTION =
  'DELETE_SO_ITEM_REPORTING_TAGS_FROM_SELECTION'
export const SET_SO_ITEM_REPORTING_TAGS_FORM_SELECTION =
  'SET_SO_ITEM_REPORTING_TAGS_FORM_SELECTION'
