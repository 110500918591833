export function calculateCostWithDiscount(cost = 0, discount = 0, isCalculateListPrice = true) {
  if (!discount) {
    return cost
  }

  let calculatedDiscount
  if (discount < 0) {
    calculatedDiscount = 1+Math.abs(discount)/100
  } else if (discount > 0) {
    calculatedDiscount = 1-discount/100
    if (isCalculateListPrice && calculatedDiscount <= 0) {
      return 0
    }
  }

  return isCalculateListPrice ? cost/calculatedDiscount : cost*calculatedDiscount
}

export function getDiscountFromCosts(unitCostWithoutDiscount = 0, unitCost = 0) {
  if (unitCostWithoutDiscount <= 0) {
    return { discount: 0, unitCostWithoutDiscount: calculateCostWithDiscount(unitCost, 0) }
  }

  const newDiscount = (1-(unitCost/unitCostWithoutDiscount))*100
  if (newDiscount < 0) {
    return { unitCostWithoutDiscount: unitCost, discount: 0 }
  }
  return { unitCostWithoutDiscount, discount: newDiscount }
}
