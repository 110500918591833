import { AppDispatch } from 'store'

import {
  SET_LANGUAGE_ERROR,
  SET_PASSWORD_ERROR,
  SET_EMAIL_ERROR,
  PUT_LOGIN_USER_LANGUAGE,
  PUT_LOGIN_USER_PASSWORD,
  PUT_LOGIN_USER_EMAIL,
} from './types'

const initState = { languageError: null, passwordError: null, emailError: null }

export default function loginUserErrorReducer(state = initState, action) {
  const { error } = action

  switch (action.type) {
  case SET_LANGUAGE_ERROR:
  case PUT_LOGIN_USER_LANGUAGE: {
    return {
      ...state,
      languageError: error,
    }
  }
  case SET_PASSWORD_ERROR:
  case PUT_LOGIN_USER_PASSWORD: {
    return {
      ...state,
      passwordError: error,
    }
  }
  case SET_EMAIL_ERROR:
  case PUT_LOGIN_USER_EMAIL: {
    return {
      ...state,
      emailError: error,
    }
  }
  default:
    return state
  }
}

export function clearLanguageError(dispatch: AppDispatch) {
  dispatch({ type: SET_LANGUAGE_ERROR })
}

export function clearPasswordError(dispatch: AppDispatch) {
  dispatch({ type: SET_PASSWORD_ERROR })
}

export function setEmailError(error = null) {
  return function setEmailErrorThunk(dispatch: AppDispatch) {
    dispatch({ type: SET_EMAIL_ERROR, error })
  }
}
