import {
  ApiToSlice,
  BaseEntityApi,
  Modify,
  SliceKeysToApiKeys,
} from 'types/slices'
import { CamelCasePrefixedRecord, PrefixedRecord } from 'types/utils'

import { ChecklistApi, Checklist } from 'reducers/checklists/checkListsSlice'
import { CustomField, CustomFieldApi } from 'reducers/custom-fields/customFieldsSlice'
import {
  Inventory,
  InventoryApi,
  InventoryExceptions,
} from 'reducers/inventories/inventoriesSlice'

export type WeighType = 'gross' | 'tare' | 'net'

export type ReceptionMapData = {
  isTimeOnReceptionDate?: boolean;
};

export const ReceptionStatusEnum = {
  NEW: 'new',
  IN_PROGRESS: 'in_progress',
  RECEIVED: 'received',
  CANCELED: 'canceled',
} as const
export const ReceptionStatus = Object.values(ReceptionStatusEnum)

export const ReceptionOriginTypeEnum = {
  MANUAL: 'manual',
  SO: 'sales-orders',
  PO: 'purchase-orders',
} as const
export const ReceptionOriginType = Object.values(ReceptionOriginTypeEnum)
export type ReceptionOriginType = typeof ReceptionOriginType[number]

export type ReceptionApi = Modify<
  {
    carrier: string;
    driver: string;
    notes: string;
    checklist: ChecklistApi
    reference_number: string;
    inventory_tags: string;
    plant_name: string;
    origin_type: ReceptionOriginType

    checklist_id: ChecklistApi['id'];
    config: { language: string, hideWeights: boolean, showCustomFields: boolean } | null

    display_gross_register_created_by_id: string
    display_gross_register_created_by: string
    display_gross_register_end: Date
    display_gross_register_id: string
    display_gross_register_measure: number
    display_gross_register_name: string
    display_gross_register_reading_date: Date
    display_gross_register_start: Date

    display_net_register_created_by_id: string
    display_net_register_created_by: string
    display_net_register_end: Date
    display_net_register_id: string
    display_net_register_measure: number
    display_net_register_name: string
    display_net_register_reading_date: Date
    display_net_register_start: Date

    display_tare_register_created_by_id: string
    display_tare_register_created_by: string
    display_tare_register_end: Date
    display_tare_register_id: string
    display_tare_register_measure: number
    display_tare_register_name: string
    display_tare_register_reading_date: Date
    display_tare_register_start: Date

    gross_is_calculated: boolean
    gross_is_manual: boolean

    gross_weight_manual_reading_register_id: string
    gross_weight_reading_register_id: string

    is_print_on_receive: boolean
    lineItems: FullReceptionItemApi[];

    name: string

    net_is_calculated: boolean
    net_is_manual: boolean

    net_weight_manual_reading_register_id: string
    net_weight_reading_register_id: string

    old_scale_gross_register_created_by_id: string
    old_scale_gross_register_created_by: string
    old_scale_gross_register_id: string
    old_scale_gross_register_measure: number
    old_scale_gross_register_name: string
    old_scale_gross_register_reading_date: Date

    old_scale_net_register_created_by_id: string
    old_scale_net_register_created_by: string
    old_scale_net_register_id: string
    old_scale_net_register_measure: number
    old_scale_net_register_name: string
    old_scale_net_register_reading_date: Date

    old_scale_tare_register_created_by_id: string
    old_scale_tare_register_created_by: string
    old_scale_tare_register_id: string
    old_scale_tare_register_measure: number
    old_scale_tare_register_name: string
    old_scale_tare_register_reading_date: Date

    receipt_datetime: Date
    receipt_posting_date: Date
    planned_receipt_posting_date: Date;

    status: (typeof ReceptionStatus)[number];

    tare_is_calculated: boolean
    tare_is_manual: boolean

    tare_weight_manual_reading_register_id: string
    tare_weight_reading_register_id: string

    vendor_default_billing_address_address: string | null,
    vendor_default_billing_address_attention: string | null,
    vendor_default_billing_address_city: string | null,
    vendor_default_billing_address_country: string | null,
    vendor_default_billing_address_external_id: string | null,
    vendor_default_billing_address_fax: string | null
    vendor_default_billing_address_id: string | null,
    vendor_default_billing_address_phone: string | null,
    vendor_default_billing_address_state_code: string | null,
    vendor_default_billing_address_state: string | null,
    vendor_default_billing_address_street2: string | null,
    vendor_default_billing_address_zip: string | null,
    vendor_default_billing_address_zoho_books_address_id: string | null,

    vendor_display_name: string
    vendor_id: string
    vendor_language: string
    vendor_main_person_email: string
    vendor_default_checklist_id: ChecklistApi['id'];

    weight_input_is_gross_or_net: boolean
    is_apply_weight_on_unique_inventory: boolean;
    is_show_weighings_section: boolean;
  },
  BaseEntityApi
>;

export type ReceptionExceptions = {};

export type Reception = Modify<
  ApiToSlice<ReceptionApi, ReceptionExceptions>,
  {
    lineItems: FullReceptionItem[];
    formattedReceiptDate: Date;
    vendorAddress: Object,
    checklist: Checklist;
  }
>;

export const columnFromReception = [
  'carrier',
  'driver',
  'notes',
  'checklistId',
  'id',
  'name',
  'formattedReceiptDate',
  'receiptDatetime',
  'receiptPostingDate',
  'status',
  'isApplyWeightOnUniqueInventory',
  'originType',
  'plantName',
  'referenceNumber',
] as const satisfies readonly (keyof Reception)[]
type ColumnFromReception = (typeof columnFromReception)[number];
export type ApiKeysFromReception = SliceKeysToApiKeys<
  ColumnFromReception,
  ReceptionApi,
  ReceptionExceptions
>;

export const columnsFromInventory = [
  'id',
  'invoiceDate',
  'invoice',
  'locationId',
  'manufacturer',
  'partNumber',
  'plannedTargetInventoryId',
  'projectId',
  'revision',
  'serialNumber',
  'status',
  'vendorPartNumber',
  'tag',
  'description',
  'secondaryDescription',
  'plantId',
  'weightUnit',
  'plannedTargetTag',
  'plannedTargetDisplayName',
] as const satisfies readonly (keyof Inventory)[]

type ColumnFromInventory = (typeof columnsFromInventory)[number];
export type ApiKeysFromInventory = SliceKeysToApiKeys<
  ColumnFromInventory,
  InventoryApi,
  InventoryExceptions
>;

export const inventoryApiPrefix = 'inventory_'
export const inventoryFieldPrefix = 'inventory'

export const receptionApiPrefix = 'reception_'
export const receptionFieldPrefix = 'reception'

type InventoryApiFields = PrefixedRecord<
  Pick<InventoryApi, ApiKeysFromInventory>,
  typeof inventoryApiPrefix
>;
type ReceptionApiFields = PrefixedRecord<
  Pick<ReceptionApi, ApiKeysFromReception>,
  typeof receptionApiPrefix
>;

export type ReceptionItemApi = Modify<
  {
    dimension: string;
    measure_unit: string;
    unit_weight: number;
    partial_value_raw: number;
    is_partial: boolean;
    weight_input_is_gross_or_net: boolean;

    location_title: string;
    location_code: string;

    project_title: string;
    project_formated_number: number;

    planned_ledger_id: string;
    planned_ledger_partial_receipt_id: string;
    planned_ledger_planned_measure: number;
    planned_ledger_measure: number;

    purchase_order_item_id: string;
    consignment_item_id: string;

    purchase_order_id: string;
    sales_order_id: string;

    tare_type_id: string;
    tare_unit: string;
    tare_weight: number;

    template_id: string;
    template_sku: string;
    template_title: string;
    inventory_custom_fields: CustomFieldApi[];
    template_target_inventory_mandatory: boolean;

    vendor_display_name: string;
    vendor_language: string;

    template_supplier_id: string;
    template_supplier_custom_unit: string;
    template_supplier_conversion_factor: number;

    reading_register_ids: string[];

    group_name: string;
    material_title: string;
    category_title: string;
    raw_imperial_title: string;
    raw_metric_title: string;
    treatment_title: string;
  },
  BaseEntityApi
>;
export type ReceptionItem = ApiToSlice<Modify<ReceptionItemApi, {inventory_custom_fields: CustomField[]}>> & {
  convertedMeasure: number;
  projectDisplayTitle: string;
  locationDisplayTitle: string;
};

export type FullReceptionItemApi = ReceptionItemApi &
  InventoryApiFields &
  ReceptionApiFields;

type _Inventory = CamelCasePrefixedRecord<
  Pick<Inventory, ColumnFromInventory>,
  typeof inventoryFieldPrefix
>;
type _Reception = CamelCasePrefixedRecord<
  Pick<Reception, ColumnFromReception>,
  typeof receptionFieldPrefix
>;

export type FullReceptionItem = ReceptionItem & {
  netWeight: number;
  grossWeight: number;
} & _Inventory & _Reception;
