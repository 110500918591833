
export const GET_RAWS = 'GET_RAWS'
export const GET_RAWS_COUNT = 'GET_RAWS_COUNT'
export const GET_RAW = 'GET_RAW'
export const CREATE_RAW = 'CREATE_RAW'
export const UPDATE_RAW = 'UPDATE_RAW'
export const DELETE_RAW = 'DELETE_RAW'

export const CLEAR_RAW = 'CLEAR_RAW'
export const CLEAR_CREATE_SUCCESS = 'CLEAR_RAW_CREATE_SUCCESS'
export const CLEAR_UPDATE_SUCCESS = 'CLEAR_RAW_UPDATE_SUCCESS'
export const CLEAR_DELETE_SUCCESS = 'CLEAR_RAW_DELETE_SUCCESS'
export const CLEAR_CREATE_ERROR = 'CLEAR_RAW_CREATE_ERROR'
export const CLEAR_UPDATE_ERROR = 'CLEAR_RAW_UPDATE_ERROR'
export const CLEAR_DELETE_ERROR = 'CLEAR_RAW_DELETE_ERROR'

export const SET_IS_CREATE = 'SET_RAW_IS_CREATE'
export const SET_GLOBAL_FORM = 'SET_RAW_GLOBAL_FORM'
export const RESET_FORM = 'RESET_RAW_FORM'
