import { createSlice } from '@reduxjs/toolkit'
import { GetFields, ApiToSlice, Modify, BaseEntityApi } from 'types/slices'

import { buildGetUrl, parse } from 'utils/api'
import { EntityType } from 'utils/entities'
import { safeFetchJson, parsedResultOnSucessOrEmtpy } from 'utils/safeFetch'

const dataSetName = 'emailTemplate'

export type EmailTemplateApi = Modify<{
  entity_name: string
  name: string
  language: string
  subject: string
  message: string
}, BaseEntityApi>

export type EmailTemplate = ApiToSlice<Modify<EmailTemplateApi, {display_title: string}>>

export function getFields(): GetFields<EmailTemplateApi, EmailTemplate> {
  return {
    'exist': { dataSetName, dbField: 'exist', type: 'boolean' },

    'cid': { dataSetName, dbField: 'cid', type: 'id' },
    'createdById': { dataSetName, dbField: 'created_by_id', type: 'id' },
    'id': { dataSetName, dbField: 'id', type: 'id' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id', type: 'id' },

    'createdBy': { dataSetName, dbField: 'created_by', type: 'string' },
    'modifiedBy': { dataSetName, dbField: 'modified_by', type: 'string' },

    'entityName': { dataSetName, dbField: 'entity_name', type: 'entity' },
    'language': { dataSetName, dbField: 'language', type: 'language' },

    'message': { dataSetName, dbField: 'message', type: 'html', isEdit: true },
    'name': { dataSetName, dbField: 'name', type: 'string', isEdit: true },
    'subject': { dataSetName, dbField: 'subject', type: 'string', isEdit: true },

    'displayTitle': {
      dataSetName,
      dbField: 'subject',
      type: 'string',
      parse: (data) => `${data.name} — ${data.language.split('-')[0].toUpperCase()}`,
    },

    'createdDate': { dataSetName, dbField: 'created_date', type: 'date' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'date' },
  }
}

const initialState = {
  fields: getFields(),
  data: [] as EmailTemplate[],
  count: 0,
}

export function parseEmailTemplate(emailTemplate: EmailTemplateApi) {
  const fields = initialState.fields

  const options = {
    fields: fields,
    dataSetName,
  }
  return parse(emailTemplate, options)
}

export async function fetchEmailTemplatesByIds(ids: string[], data: any) {
  const response = await safeFetchJson(buildGetUrl(`/new_api/emails/templates/${ids}`, data))

  return parsedResultOnSucessOrEmtpy(response, parseEmailTemplate)
}

export async function fetchEmailTemplates(data: any): Promise<EmailTemplate[]> {
  const response = await safeFetchJson(buildGetUrl(`/new_api/emails/templates`, data))

  return parsedResultOnSucessOrEmtpy(response, parseEmailTemplate)
}

export async function fetchEmailTemplatesCount(data: any) {
  const response = await safeFetchJson(buildGetUrl(`/new_api/emails/templates/count`, data))

  return response.isSuccess ? Number(response.result[0]?.count) : 0
}

export const EmailTemplateEntity: EntityType = {
  translationFile: 'emailTemplates',
  coreRelationName: 'emailTemplate',
  getFields: getFields,
  namespace: (entity, field, options = {}) => {
    return `${entity.translationFile}:${entity.coreRelationName}.fields.${field}.label`
  },
  fetcher: fetchEmailTemplatesByIds as EntityType['fetcher'],
  getTitle: (emailTemplate) => emailTemplate.subject,
  relationTranslationFiles: [],
  getDimension: (entity, entityField) => null,
  getUnit: (entity, entityField) => null,
  parser: parseEmailTemplate,
}

const emailTemplatesSlice = createSlice({
  name: 'salesOrderItems',
  initialState,
  reducers: {},
})

export default emailTemplatesSlice.reducer
