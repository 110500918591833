export const planningTypes = {
  GET_PLANNING: 'GET_PLANNING_DATA',
  GET_PLANNING_COUNT: 'GET_PLANNING_COUNT',

  GET_PLANNING_DETAILS: 'GET_PLANNING_DETAIL_DATA',
  GET_PLANNING_DETAILS_COUNT: 'GET_PLANNING_DETAIL_COUNT',

  CLEAR_PLANNING_ERROR: 'SET_PLANNING_ERROR',
  CLEAR_PLANNING_COUNT_ERROR: 'CLEAR_PLANNING_COUNT_ERROR',
}
