import {
  CREATE_PLANT,
  CLEAR_CREATE_ERROR,
  UPDATE_PLANT,
  DELETE_PLANT,
  CLEAR_UPDATE_ERROR,
  CLEAR_DELETE_ERROR,
} from './types'

const initState = {
  deleteError: null,
}

export default function plantsErrorReducer(state = initState, action) {
  const { error } = action

  switch (action.type) {
  case CREATE_PLANT: {
    return {
      ...state,
      createError: error,
    }
  }
  case CLEAR_CREATE_ERROR: {
    return {
      ...state,
      createError: null,
    }
  }
  case UPDATE_PLANT: {
    return {
      ...state,
      updateError: error,
    }
  }
  case DELETE_PLANT: {
    return {
      ...state,
      deleteError: { ...error, entityName: 'plants' },
    }
  }
  case CLEAR_UPDATE_ERROR: {
    return {
      ...state,
      updateError: null,
    }
  }
  case CLEAR_DELETE_ERROR: {
    return {
      ...state,
      deleteError: null,
    }
  }
  default:
    return state
  }
}

export function clearCreateError(dispatch) {
  dispatch({ type: CLEAR_CREATE_ERROR })
}

export function clearUpdateError(dispatch) {
  dispatch({ type: CLEAR_UPDATE_ERROR })
}

export function clearDeleteError(dispatch) {
  dispatch({ type: CLEAR_DELETE_ERROR })
}
