import { buildGetUrl, parse } from 'utils/api'
import { safeFetch } from 'utils/safeFetch'

const dataSetName = 'file'
const initialState = {
  dataSetName,
  fields: getFields(),
}

export default function filesReducer(state = initialState, action) {
  switch (action.type) {
  default: {
    return state
  }
  }
}

export function getFields() {
  return {
    'id': { dataSetName, dbField: 'id' },
    'entity': { dataSetName, dbField: 'entity' },
    'entityId': { dataSetName, dbField: 'entity_id' },
    'name': { dataSetName, dbField: 'file_name' },
    'extension': { dataSetName, dbField: 'file_ext' },
    'type': { dataSetName, dbField: 'file_type' },
    'destination': { dataSetName, dbField: 'destination' },
    'etag': { dataSetName, dbField: 'etag' },
    'version': { dataSetName, dbField: 'version' },
    'isVersionActive': { dataSetName, dbField: 'version_active' },
    'uploadedDate': { dataSetName, dbField: 'created_date' },
    'uploadedBy': { dataSetName, dbField: 'created_by' },
    'uploadedById': { dataSetName, dbField: 'created_by_id' },
    'modifiedDate': { dataSetName, dbField: 'modified_date' },
    'modifiedBy': { dataSetName, dbField: 'modified_by' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id' },
    'isPrint': { dataSetName, dbField: 'is_print' },
    'size': { dataSetName, dbField: 'size' },
    'fileName': { parse: (file) => `${file.file_name}.${file.file_ext}` },
    'copiedFromId': { dataSetName, dbField: 'copied_from_id' },
  }
}

export async function _fetchFiles(data) {
  let files = []

  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/files', data))).json()
    if (result.isSuccess) {
      files = result.result.map((file) => parseFile(file))
    }
  } catch (err) {
    console.error(err)
  }

  return files
}

export async function _editFile(file) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ is_print: file.print }),
  }

  try {
    return (await safeFetch(`/new_api/files/${file.id}`, requestOptions)).json()
  } catch (error) {
    console.error(error)
  }
}

export async function _deleteFiles(ids) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  }

  try {
    return (await safeFetch(`/new_api/files/${ids}`, requestOptions)).json()
  } catch (error) {
    console.error(error)
  }
}

export async function getPreSignedUrl(name) {
  let url = ''
  try {
    const result = await (await safeFetch(buildGetUrl(`/new_api/files/logo/pre-signed-url`, { name }))).json()
    url = result.result
  } catch (err) {
    console.error(err)
  }
  return url
}

export async function getPreSignedUrlById(id) {
  let url = ''
  try {
    const result = await (await safeFetch(`/new_api/files/${id}/pre-signed-url`)).json()
    url = result.result
  } catch (err) {
    console.error(err)
  }
  return url
}

export function htmlToPDF(name, html) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ name, html }),
  }

  return fetch('/api/services/html-to-pdf', requestOptions).then((resp) => resp.arrayBuffer())
}

export function parseFile(file) {
  const options = {
    defaultData: getDefaultFile(),
    fields: initialState.fields,
    dataSetName,
  }
  return parse(file, options)
}

function getDefaultFile() {
  return parse({}, { fields: initialState.fields })
}
