import { DevLogs } from 'utils/devLogs'

type PositionProps = {
  position: number
  position_color: 'green' | 'yellow' | 'red'
}

export function parsePosition(props: PositionProps) {
  if (props.position === undefined) return
  // format : 1R-000%
  let rankColor
  switch (props.position_color) {
  case 'green': rankColor = '3G'; break
  case 'yellow': rankColor = '2Y'; break
  case 'red': rankColor = '1R'; break
  default: DevLogs.error('Unsupported position color', props.position_color)
  }
  const formatedPosition = props.position.toString().padStart(3, '0')
  return `${rankColor}-${formatedPosition}%`
}
