export const SET_UID = 'SET_AUTHENTICATION_UID'
export const SET_EMAIL_STATE = 'SET_AUTHENTICATION_EMAIL_STATE'
export const SET_PASSWORD_STATE = 'SET_AUTHENTICATION_PASSWORD_STATE'

export const SET_EMAIL_NOT_FOUND_ERROR = 'SET_AUTHENTICATION_EMAIL_NOT_FOUND_ERROR'
export const CLEAR_EMAIL_NOT_FOUND_ERROR = 'CLEAR_AUTHENTICATION_EMAIL_NOT_FOUND_ERROR'

export const SET_EMAIL_NOT_ACTIVATED_ERROR = 'SET_AUTHENTICATION_EMAIL_NOT_ACTIVATED_ERROR'
export const CLEAR_EMAIL_NOT_ACTIVATED_ERROR = 'CLEAR_AUTHENTICATION_EMAIL_NOT_ACTIVATED_ERROR'

export const SET_EMAIL_UNKOWN_ERROR = 'SET_AUTHENTICATION_EMAIL_UNKOWN_ERROR'
export const CLEAR_EMAIL_UNKOWN_ERROR = 'CLEAR_AUTHENTICATION_EMAIL_UNKOWN_ERROR'

export const SET_EMAIL_REQUIRED_ERROR = 'SET_AUTHENTICATION_EMAIL_REQUIRED_ERROR'
export const CLEAR_EMAIL_REQUIRED_ERROR = 'CLEAR_AUTHENTICATION_EMAIL_REQUIRED_ERROR'

export const SET_PASSWORD_REQUIRED_ERROR = 'SET_AUTHENTICATION_PASSWORD_REQUIRED_ERROR'
export const CLEAR_PASSWORD_REQUIRED_ERROR = 'CLEAR_AUTHENTICATION_PASSWORD_REQUIRED_ERROR'

export const SET_PASSWORD_RESET_ERROR = 'SET_AUTHENTICATION_PASSWORD_RESET_ERROR'
export const CLEAR_PASSWORD_RESET_ERROR = 'CLEAR_AUTHENTICATION_PASSWORD_RESET_ERROR'

export const SET_PASSWORD_CREDS_ERROR = 'SET_AUTHENTICATION_PASSWORD_CREDS_ERROR'
export const CLEAR_PASSWORD_CREDS_ERROR = 'CLEAR_AUTHENTICATION_PASSWORD_CREDS_ERROR'

export const SET_DEFAULT_LOGIN_DISABLED_ERROR = 'SET_AUTHENTICATION_DEFAULT_LOGIN_DISABLED_ERROR'
export const CLEAR_DEFAULT_LOGIN_DISABLED_ERROR = 'CLEAR_AUTHENTICATION_DEFAULT_LOGIN_DISABLED_ERROR'
