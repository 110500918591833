import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch } from 'store'
import { ApiToSlice, BaseEntityApi, GetFields, Modify } from 'types/slices'

import { buildGetUrl, parse } from 'utils/api'
import { isJob, safeFetchJson } from 'utils/safeFetch'

const dataSetName = 'taxes'

const fields = getFields()
const initialState = {
  dataSetName: dataSetName,
  fields,
  taxesCount: 0,
  taxes: [],
  taxDict: {},
}

const taxesSlice = createSlice({
  name: 'taxesSlice',
  initialState,
  reducers: {
    setTaxes: (state, action) => {
      state.taxes = action.payload.taxes
      state.taxDict = action.payload.taxDict
    },
    setTaxesCount: (state, action) => {
      state.taxesCount = action.payload.count
    },
  },
})

export const { setTaxes, setTaxesCount } = taxesSlice.actions

export type TaxApi = Modify<{
    company_id: number;
    external_id: string;
    tax_name: string;
    tax_percentage: number;
    tax_type: 'tax' | 'tax_group' | string;
    is_inactive: boolean;
    status: 'Active' | 'Inactive' | string;
    taxes: TaxApi[];
}, BaseEntityApi>;

export type Tax = ApiToSlice<Modify<TaxApi, {display_name: string, taxes: Tax[] }>, {external_id: 'relationId'}>

type TaxGetFields = GetFields<TaxApi, Tax>;

export function getFields(): TaxGetFields {
  return {
    id: { dataSetName, dbField: 'id', type: 'id' },
    relationId: { dataSetName, dbField: 'external_id', type: 'string' },
    taxName: { dataSetName, dbField: 'tax_name' },
    taxPercentage: { dataSetName, dbField: 'tax_percentage', type: 'float' },
    taxType: { dataSetName, dbField: 'tax_type' },
    status: { dataSetName, dbField: 'status' },
    taxes: { dataSetName, dbField: 'taxes' },
    exist: { dataSetName, dbField: 'exist', type: 'boolean' },
    displayName: { parse: parseTaxDisplayName },
  }
}

export async function fetchTaxesByIds(ids: string[], data = {}) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson<TaxApi>(
    buildGetUrl(`/new_api/accounting/taxes/${ids}`, { ...data, includeDeleted: true }),
  )
  return isSuccess && !isJob(result) ? result.map((tax) => parseTax(tax)) : []
}

export async function fetchTaxesByExternalIds(externalIds: string[], data = {}) {
  if (!externalIds?.length) return []
  return _fetchTaxes({ ...data, externalIds, withDictionary: false, includeDeleted: true })
}

type FetchOptions<B extends boolean> = { externalIds?: string[], includeDeleted?: boolean, withDictionary?: B}

export function fetchTaxes(options?: Omit<FetchOptions<boolean>, 'withDictionary'>) {
  return async function fetchTaxesThunk(dispatch: AppDispatch) {
    const { taxes, taxDict } = await _fetchTaxes({ ...options, withDictionary: true })
    dispatch(setTaxes({ taxes, taxDict }))
    return taxes
  }
}

export async function _fetchTaxes(options: FetchOptions<true>): Promise<{ taxes: Tax[]; taxDict: Record<string, Tax> }>;
export async function _fetchTaxes(options?: FetchOptions<false>): Promise<Tax[]>;
export async function _fetchTaxes(options: FetchOptions<boolean> = { withDictionary: false }) {
  const { isSuccess, result } = await safeFetchJson<TaxApi>('/new_api/accounting/taxes')
  const taxes = isSuccess && !isJob(result) ? result.map((tax) => parseTax(tax)) : []

  if (options.withDictionary) {
    return {
      taxes,
      taxDict: taxes.reduce((acc, tax) => {
        acc[tax.id] = tax
        return acc
      }, {}),
    }
  }

  return taxes
}

export function getTaxTitle(tax: Tax) {
  return tax.displayName
}

function parseTaxDisplayName(tax: TaxApi) {
  return `${tax.tax_name} [${tax.tax_percentage}%]`
}

export function parseTax(tax:TaxApi): Tax {
  const options = { defaultFata: getDefaultTax(), dataSetName, fields }

  const parsed: Tax = parse(tax, options)
  parsed.taxes = tax.taxes?.map((subtax) => parseTax(subtax)) ?? []

  return parsed
}

function getDefaultTax(): Tax {
  return parse({}, { fields })
}

export default taxesSlice.reducer
