import {
  CREATE_VIEW,
  CLEAR_CREATE_SUCCESS,
  UPDATE_VIEW,
  CLEAR_UPDATE_SUCCESS,
  DELETE_VIEW,
  CLEAR_DELETE_SUCCESS,
} from './types'

const initState = {
  isCreateSuccess: null,
  isUpdateSuccess: null,
  isDeleteSuccess: null,
}

export default function viewsSuccessReducer(state = initState, action) {
  const { payload } = action

  switch (action.type) {
  case CREATE_VIEW: {
    return {
      ...state,
      isCreateSuccess: payload && payload.id ? true : false,
    }
  }
  case CLEAR_CREATE_SUCCESS: {
    return {
      ...state,
      isCreateSuccess: false,
    }
  }
  case UPDATE_VIEW: {
    return {
      ...state,
      isUpdateSuccess: payload && payload.id ? true : false,
    }
  }
  case CLEAR_UPDATE_SUCCESS: {
    return {
      ...state,
      isUpdateSuccess: false,
    }
  }
  case DELETE_VIEW: {
    return {
      ...state,
      isDeleteSuccess: !!payload,
    }
  }
  case CLEAR_DELETE_SUCCESS: {
    return {
      ...state,
      isDeleteSuccess: false,
    }
  }
  default:
    return state
  }
}

export function clearCreateSuccess(dispatch) {
  dispatch({ type: CLEAR_CREATE_SUCCESS })
}

export function clearUpdateSuccess(dispatch) {
  dispatch({ type: CLEAR_UPDATE_SUCCESS })
}

export function clearDeleteSuccess(dispatch) {
  dispatch({ type: CLEAR_DELETE_SUCCESS })
}
