import { ApiToSlice, GetFields } from 'types/slices'

import { buildGetUrl, parse } from 'utils/api'
import { safeFetchJson, parsedResultOnSucessOrEmtpy, safeFetch } from 'utils/safeFetch'

const dataSetName = 'shift'

export type ShiftApi = {
  exist: boolean
  cid: string
  id: string
  code: string
  description: string
  title: string
  created_date: Date
  modified_date: Date
}

export type Shift = ApiToSlice<ShiftApi>

export function getFields(): GetFields<ShiftApi, Shift> {
  return {
    'exist': { dataSetName, dbField: 'exist', type: 'boolean' },

    'cid': { dataSetName, dbField: 'cid', type: 'id' },
    'id': { dataSetName, dbField: 'id', type: 'id' },

    'code': { dataSetName, dbField: 'code', type: 'string' },
    'description': { dataSetName, dbField: 'description', type: 'string' },
    'title': {
      dataSetName,
      dbField: 'title',
      type: 'string',
    },

    'createdDate': { dataSetName, dbField: 'created_date', type: 'date' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'date' },
  }
}

const initialState = {
  fields: getFields(),
}

export function parseShift(shift: ShiftApi): Shift {
  const fields = initialState.fields

  const options = {
    fields: fields,
    dataSetName,
  }
  return parse(shift, options)
}

export async function fetchShifts(data?: any) {
  let shifts: Shift[] = []

  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/resources/shifts', data))).json()
    if (result.isSuccess) {
      shifts = result.result.map((shift: ShiftApi) => parseShift(shift))
    }
  } catch (err) {
    console.error(err)
  }

  return shifts
}

export async function fetchShiftsByIds(ids, data = {}) {
  const response = await safeFetchJson(buildGetUrl(`/new_api/resources/shifts/${ids}`, data))

  return parsedResultOnSucessOrEmtpy(response, parseShift)
}

export function getTitle(shift: Shift) {
  return shift.title
}

