import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch } from 'store'
import { ApiToSlice, BaseEntityApi, GetFields, Modify } from 'types/slices'

import { buildGetUrl, parse } from 'utils/api'
import { isJob, safeFetchJson } from 'utils/safeFetch'

const dataSetName = 'contactCarrierAccountView'

const initialState = {
  dataSetName,
  fields: getFields(),
  contactCarrierAccountsCount: 0,
  contactCarrierAccounts: [],
}

const contactCarrierAccountsSlice = createSlice({
  name: 'contactCarrierAccounts',
  initialState,
  reducers: {
    setContactCarrierAccounts: (state, action) => {
      state.contactCarrierAccounts = action.payload.data
    },
    setContactCarrierAccountsCount: (state, action) => {
      state.contactCarrierAccountsCount = action.payload.count
    },
  },
})

export const { setContactCarrierAccounts, setContactCarrierAccountsCount } = contactCarrierAccountsSlice.actions

export type ContactCarrierAccountApi = Modify<{
  code: string
  description: string
  display_name: string
  contact_id: string
  contact: string
  carrier_id: string
  carrier: string
}, BaseEntityApi>

export type ContactCarrierAccount = ApiToSlice<ContactCarrierAccountApi>

type ContactCarrierAccountGetFields = GetFields<ContactCarrierAccountApi, ContactCarrierAccount>

export function getFields(editOnly = false): ContactCarrierAccountGetFields {
  const editFields: ContactCarrierAccountGetFields = {
    'id': { dataSetName, dbField: 'id', type: 'string' },
    'code': { dataSetName, dbField: 'code', type: 'string', isEdit: true },
    'description': { dataSetName, dbField: 'description', type: 'string', isEdit: true },
    'carrierId': { dataSetName, dbField: 'carrier_id', type: 'string', isEdit: true, relationEntity: 'contacts' },
  }

  if (editOnly) return editFields

  const fields: ContactCarrierAccountGetFields = {
    'exist': { dataSetName, dbField: 'exist', type: 'boolean' },
    'createdDate': { dataSetName, dbField: 'created_date', type: 'timestamp' },
    'createdBy': { dataSetName, dbField: 'created_by', type: 'string' },
    'createdById': { dataSetName, dbField: 'created_by_id', type: 'string' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'timestamp' },
    'modifiedBy': { dataSetName, dbField: 'modified_by', type: 'string' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id', type: 'string' },
    'displayName': { dataSetName, dbField: 'display_name', type: 'string' },
    'carrier': { dataSetName, dbField: 'carrier', type: 'string' },
    'contact': { dataSetName, dbField: 'contact', type: 'string' },
    'contactId': { dataSetName, dbField: 'contact_id', type: 'string', relationEntity: 'contacts' },
  }

  return { ...fields, ...editFields }
}

export function getContactCarrierAccountTitle(contactCarrierAccount: ContactCarrierAccount): string {
  return contactCarrierAccount.code
}

export async function fetchContactCarrierAccountsByIds(ids: string[], data?: Record<string, any>) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson<ContactCarrierAccountApi>(
    buildGetUrl(`/new_api/contact-carrier-accounts/${ids}`, data),
  )

  return isSuccess && !isJob(result) ?
    result.map((contactCarrierAccount) => parseContactCarrierAccount(contactCarrierAccount)) :
    []
}

export function fetchContactCarrierAccounts(fetchData?: Record<string, any>) {
  return async function fetchContactCarrierAccountsThunk(dispatch: AppDispatch) {
    const data = await _fetchContactCarrierAccounts(fetchData)
    dispatch(setContactCarrierAccounts({ data }))
    return data
  }
}

export async function _fetchContactCarrierAccounts(fetchData: Record<string, any>) {
  let contactCarrierAccounts = []

  try {
    const { isSuccess, result } = await safeFetchJson<ContactCarrierAccountApi>(
      buildGetUrl('/new_api/contact-carrier-accounts', fetchData),
    )
    if (isSuccess && !isJob(result)) {
      contactCarrierAccounts = result.map((contactCarrierAccount) => parseContactCarrierAccount(contactCarrierAccount))
    }
  } catch (err) {
    console.error(err)
  }

  return contactCarrierAccounts
}

export function fetchContactCarrierAccountsCount(fetchData?: Record<string, any>) {
  return async function fetchContactCarrierAccountsCountThunk(dispatch: AppDispatch) {
    const count = await _fetchContactCarrierAccountsCount(fetchData)
    dispatch(setContactCarrierAccountsCount({ count }))
    return count
  }
}

export async function _fetchContactCarrierAccountsCount(fetchData?: Record<string, any>) {
  let count = 0

  try {
    const { isSuccess, result } = await safeFetchJson<{count: string}>(
      buildGetUrl('/new_api/contact-carrier-accounts/count', fetchData),
    )
    if (isSuccess && !isJob(result)) {
      count = +result[0].count || 0
    }
  } catch (err) {
    console.error(err)
  }

  return count
}

export function createContactCarrierAccount(createData: Partial<ContactCarrierAccountApi>[]) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: createData }),
  }
  return async function createContactCarrierAccountThunk(dispatch: AppDispatch) {
    try {
      const { isSuccess, result } = await safeFetchJson<ContactCarrierAccountApi, false>(
        '/new_api/contact-carrier-accounts', requestOptions,
      )
      const contactCarrierAccount = isSuccess && !isJob<ContactCarrierAccountApi, false>(result) ?
        parseContactCarrierAccount(result) :
        null
      const error = !isSuccess ? result : null
      return { isSuccess, contactCarrierAccount, error }
    } catch (error) {
      console.error(error)
      return { isSuccess: false, error }
    }
  }
}

export function deleteContactCarrierAccounts(ids: string[], isBatch = false) {
  if (!ids?.length) return []

  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: isBatch ? JSON.stringify({ ids }) : undefined,
  }
  const url = isBatch ? '/new_api/contact-carrier-accounts/batch' : `/new_api/contact-carrier-accounts/${ids}`
  return async function deleteContactCarrierAccountsThunk(dispatch: AppDispatch) {
    try {
      const { isSuccess, result } = await safeFetchJson<ContactCarrierAccountApi, typeof isBatch>(url, requestOptions)
      const error = !isSuccess ? result : null
      const _result = { isSuccess, error, contactCarrierAccounts: undefined, contactCarrierAccount: undefined }
      if (Array.isArray(result)) {
        _result.contactCarrierAccounts = []
        if (isSuccess && !isJob(result)) {
          _result.contactCarrierAccounts = result.map(
            (contactCarrierAccount) => parseContactCarrierAccount(contactCarrierAccount),
          )
        }
      } else {
        _result.contactCarrierAccount = isSuccess && !isJob<ContactCarrierAccountApi, false>(result) ?
          parseContactCarrierAccount(result) :
          null
      }

      return _result
    } catch (error) {
      console.error(error)
      return { isSuccess: false, error }
    }
  }
}

export async function _updateContactCarrierAccount(id: string, updateData: Partial<ContactCarrierAccountApi>) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: updateData }),
  }

  try {
    const { isSuccess, result } = await safeFetchJson<ContactCarrierAccountApi, false>(
      `/new_api/contact-carrier-accounts/${id}`, requestOptions,
    )
    const contactCarrierAccount = isSuccess && !isJob<ContactCarrierAccountApi, false>(result) ?
      parseContactCarrierAccount(result) :
      null
    const error = !isSuccess ? result : null
    return { isSuccess, contactCarrierAccount, error }
  } catch (error) {
    console.error(error)
    return { isSuccess: false, error }
  }
}

export function parseContactCarrierAccount(contactCarrierAccount: ContactCarrierAccountApi): ContactCarrierAccount {
  const options = {
    defaultData: getDefaultContactCarrierAccount(),
    fields: initialState.fields,
    dataSetName: dataSetName,
  }

  return parse(contactCarrierAccount, options)
}

function getDefaultContactCarrierAccount(): ContactCarrierAccount {
  return parse({}, { fields: initialState.fields })
}

export default contactCarrierAccountsSlice.reducer
