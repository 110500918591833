import React, { useCallback, useEffect } from 'react'

import { DefaultSmartGridInputProps } from 'components/alix-front/smart-grid-input/SmartGridInput'
import SmartInputText from 'components/alix-front/smart-input-text/SmartInputText'

type SmartGridTextInputProps<
  EntityApi extends Record<string, any>,
  Entity extends Record<string, any>
> = DefaultSmartGridInputProps<EntityApi, Entity, string>

export function SmartGridTextInput<
  EntityApi extends Record<string, any>,
  Entity extends Record<string, any>
>({
  onChange,
  value,
  isLoading,
  isEdit,
  field,
}: SmartGridTextInputProps<EntityApi, Entity>) {
  const inputRef = React.useRef<HTMLInputElement>(null)

  const maxLength = field.maxLength

  useEffect(() => {
    if (isEdit) {
      inputRef.current?.focus()
    } else {
      inputRef.current?.blur()
    }
  }, [isEdit])

  const handleOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value ?? '')
  }, [onChange])

  return (
    <div className="a-form-element-value a-inline-input relative">
      <SmartInputText
        ref={inputRef}
        onChange={handleOnChange}
        initialValue={value ?? ''}
        disabled={isLoading || !isEdit}
        maxLength={maxLength}
        inlineMaxLength
      />
    </div>
  )
}
