import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch } from 'store'
import { ApiToSlice, GetFields } from 'types/slices'

import { buildGetUrl, parse } from 'utils/api'
import { isJob, safeFetchJson } from 'utils/safeFetch'

const dataSetName = 'label'

const initialState = {
  dataSetName,
  fields: getFields(),
  labelsCount: 0,
  labels: [],
}

const labelsSlice = createSlice({
  name: 'labelsSlice',
  initialState,
  reducers: {
    setLabels: (state, action) => {
      state.labels = action.payload.data
    },
    setLabelsCount: (state, action) => {
      state.labelsCount = action.payload.count
    },
  },
})

export const { setLabels, setLabelsCount } = labelsSlice.actions

export type LabelApi = {
  id: string;
  qr_code_size: string;
  label: string;
  description: string;
  name: string;
  config: string;
  zebra_label: string;
  type: string;
  status: string;
  exist: boolean;
};

export type Label = ApiToSlice<LabelApi>;

type LabelGetFields = GetFields<LabelApi, Label>;

export function getFields(editOnly = false): LabelGetFields {
  const editFields: LabelGetFields = {
    id: { dataSetName, dbField: 'id', type: 'string' },
    qrCodeSize: {
      dataSetName,
      dbField: 'qr_code_size',
      type: 'integer',
      isEdit: true,
    },
    label: { dataSetName, dbField: 'label', type: 'text', isEdit: true },
    description: {
      dataSetName,
      dbField: 'description',
      type: 'string',
      isEdit: true,
    },
    name: { dataSetName, dbField: 'name', type: 'string', isEdit: true },
    config: { dataSetName, dbField: 'config', type: 'json', isEdit: true },
    zebraLabel: {
      dataSetName,
      dbField: 'zebra_label',
      type: 'text',
      isEdit: true,
    },
    type: { dataSetName, dbField: 'type', type: 'string', isEdit: true },
    status: {
      dataSetName,
      dbField: 'status',
      type: 'status',
      dictionaryKey: 'label',
      values: ['active', 'inactive'],
      customEventValueTranslationKey: (value) => `labels:status.${value}`,
      isEdit: true,
    },
  }

  if (editOnly) {
    return editFields
  }

  const fields: LabelGetFields = {
    exist: { dataSetName, dbField: 'exist', type: 'boolean' },
  }

  return { ...editFields, ...fields }
}

export function getLabelTitle(label: Label): string {
  return label.name
}

export async function fetchLabelsByIds(ids: string[], data?: Record<string, any>) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson<LabelApi>(buildGetUrl(
    `/new_api/labels/${ids}`,
    { ...data, includeInactive: true },
  ))

  return isSuccess && !isJob(result) ? result.map((label) => parseLabel(label)) : []
}

export function fetchLabels(fetchData?: Record<string, any>) {
  return async function fetchLabelsThunk(dispatch: AppDispatch) {
    const data = await _fetchLabels(fetchData)
    dispatch(setLabels({ data }))
    return data
  }
}

export async function _fetchLabels(fetchData: Record<string, any>) {
  let labels = []

  try {
    const { isSuccess, result } = await safeFetchJson<LabelApi>(buildGetUrl(
      '/new_api/labels',
      { ...fetchData, includeInactive: true },
    ))
    if (isSuccess && !isJob(result)) {
      labels = result.map((label) => parseLabel(label))
    }
  } catch (err) {
    console.error(err)
  }

  return labels
}

export function fetchLabelCount(fetchData?: Record<string, any>) {
  return async function fetchLabelCountThunk(dispatch: AppDispatch) {
    const count = await _fetchLabelCount(fetchData)
    dispatch(setLabelsCount({ count }))
    return count
  }
}

export async function _fetchLabelCount(fetchData?: Record<string, any>) {
  let count = 0

  try {
    const { isSuccess, result } = await safeFetchJson<{ count: string }>(buildGetUrl(
      '/new_api/labels/count',
      { ...fetchData, includeInactive: true },
    ))
    if (isSuccess && !isJob(result)) {
      count = +result[0].count || 0
    }
  } catch (err) {
    console.error(err)
  }

  return count
}

export function createLabels(createData: Partial<LabelApi>[]) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: createData }),
  }
  return async function createLabelsThunk() {
    try {
      const { isSuccess, result } = await safeFetchJson<LabelApi, false>('/new_api/labels', requestOptions)
      const labels = isSuccess && !isJob<LabelApi, false>(result) ? parseLabel(result) : null
      const error = !isSuccess ? result : null
      return { isSuccess, labels, error }
    } catch (error) {
      console.error(error)
      return { isSuccess: false, error }
    }
  }
}

export function deleteLabels(ids: string[], isBatch = false) {
  if (!ids?.length) return []

  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: isBatch ? JSON.stringify({ ids }) : undefined,
  }
  const url = isBatch ? '/new_api/labels/batch' : `/new_api/labels/${ids}`
  return async function deleteLabelsThunk() {
    try {
      const { isSuccess, result } = await safeFetchJson<LabelApi, typeof isBatch>(url, requestOptions)
      const error = !isSuccess ? result : null
      const _result = { isSuccess, error, labels: undefined, label: undefined, result }
      if (Array.isArray(result)) {
        _result.labels = []
        if (isSuccess && !isJob(result)) {
          _result.labels = result.map((label) => parseLabel(label))
        }
      } else {
        _result.label = isSuccess && !isJob<LabelApi, false>(result) ? parseLabel(result) : null
      }

      return _result
    } catch (error) {
      console.error(error)
      return { isSuccess: false, error }
    }
  }
}

export async function _updateLabel(id: string, updateData: Partial<LabelApi>) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: updateData }),
  }

  try {
    const { isSuccess, result } = await safeFetchJson<LabelApi, false>(`/new_api/labels/${id}`, requestOptions)
    const label = isSuccess && !isJob<LabelApi, false>(result) ? parseLabel(result) : null
    const error = !isSuccess ? result : null
    return { isSuccess, label, error }
  } catch (error) {
    console.error(error)
    return { isSuccess: false, error }
  }
}

export function _changeLabelStatusFetch(ids: string[], status: string) {
  const update = ids.map((id) => ({ id, status }))
  const options = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: update }),
  }

  return safeFetchJson(`/new_api/labels/batch`, options)
}

export function parseLabel(label: LabelApi): Label {
  const options = {
    defaultData: getDefaultLabel(),
    fields: initialState.fields,
    dataSetName: dataSetName,
  }

  label.config = label.config ? JSON.stringify(label.config, null, 2) : null

  return parse(label, options)
}

function getDefaultLabel(): Label {
  return parse({}, { fields: initialState.fields })
}

export default labelsSlice.reducer
