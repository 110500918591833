import { AppDispatch } from 'store'

import {
  DELETE_RECEPTIONS,
  CLEAR_DELETE_ERROR,
} from './types'

const initState = {
  deleteError: null,
}

export default function receptionsErrorReducer(state = initState, action: any) {
  const { error } = action
  switch (action.type) {
  case DELETE_RECEPTIONS: {
    return {
      ...state,
      deleteError: error,
    }
  }
  case CLEAR_DELETE_ERROR: {
    return {
      ...state,
      deleteError: null,
    }
  }
  default:
    return state
  }
}

export function clearDeleteError(dispatch: AppDispatch) {
  dispatch({ type: CLEAR_DELETE_ERROR })
}
