import { GET_COST, CLEAR_GET_COST } from './types'

const initState = {
  error: null,
}

export default function itemGroupsErrorReducer(state = initState, action) {
  const { error } = action

  switch (action.type) {
  case GET_COST: {
    return {
      ...state,
      error: error,
    }
  }
  case CLEAR_GET_COST: {
    return {
      ...state,
      error: null,
    }
  }
  default:
    return state
  }
}

export function clearGetError(dispatch) {
  dispatch({ type: CLEAR_GET_COST })
}
