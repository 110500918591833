export const GET_TARES_TYPES_COUNT = 'GET_TARES_TYPES_COUNT'
export const GET_TARE_TYPES = 'GET_TARE_TYPES'
export const GET_TARE_TYPE = 'GET_TARE_TYPE'
export const CREATE_TARE_TYPE = 'CREATE_TARE_TYPE'
export const UPDATE_TARE_TYPE = 'UPDATE_TARE_TYPE'
export const DELETE_TARE_TYPE = 'DELETE_TARE_TYPE'

export const CLEAR_TARE_TYPE = 'CLEAR_TARE_TYPE'
export const CLEAR_CREATE_SUCCESS = 'CLEAR_TARE_TYPE_CREATE_SUCCESS'
export const CLEAR_UPDATE_SUCCESS = 'CLEAR_TARE_TYPE_UPDATE_SUCCESS'
export const CLEAR_DELETE_SUCCESS = 'CLEAR_TARE_TYPE_DELETE_SUCCESS'
export const CLEAR_CREATE_ERROR = 'CLEAR_TARE_TYPE_CREATE_ERROR'
export const CLEAR_UPDATE_ERROR = 'CLEAR_TARE_TYPE_UPDATE_ERROR'
export const CLEAR_DELETE_ERROR = 'CLEAR_TARE_TYPE_DELETE_ERROR'

export const SET_IS_CREATE = 'SET_TARE_TYPE_IS_CREATE'
export const RESET_FORM = 'RESET_TARE_TYPE_FORM'
export const SET_GLOBAL_FORM = 'SET_TARE_TYPE_GLOBAL_FORM'
