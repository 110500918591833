import { createSlice } from '@reduxjs/toolkit'
import { ApiToSlice, BaseEntityApi, GetFields, Modify } from 'types/slices'

import { buildGetUrl, parse } from 'utils/api'
import { isJob, safeFetchJson } from 'utils/safeFetch'

const dataSetName = 'tax-rules'

const fields = getFields()
const initialState = {
  dataSetName: dataSetName,
  fields,
  taxRules: [],
  taxRulesCount: 0,
}

const taxesRulesSlice = createSlice({
  name: 'taxesRulesSlice ',
  initialState,
  reducers: {
    setTaxRules: (state, action) => {
      state.taxRules = action.payload.data
    },
    setTaxRulesCount: (state, action) => {
      state.taxRulesCount = action.payload.count
    },
  },
})

export const { setTaxRules, setTaxRulesCount } = taxesRulesSlice.actions

export type TaxRuleApi = Modify<{
    company_id: number;
    external_id: string;
    tax_rule_name: string;
    tax_rule_type: string;
    tax_rule_transaction_type: string | null;
    transaction_type: string | null;
    is_default_tax_rule: boolean;
    is_system_rule: boolean;
    count: string;
}, BaseEntityApi>;

export type TaxRule = ApiToSlice<TaxRuleApi, {external_id: 'relationId'}>

type TaxRuleGetFields = GetFields<TaxRuleApi, TaxRule>;

export function getFields(): TaxRuleGetFields {
  return {
    id: { dataSetName, dbField: 'id', type: 'id' },
    relationId: { dataSetName, dbField: 'external_id', type: 'string' },
    companyId: { dataSetName, dbField: 'company_id', type: 'integer' },
    taxRuleName: { dataSetName, dbField: 'tax_rule_name' },
    taxRuleType: { dataSetName, dbField: 'tax_rule_type' },
    taxRuleTransactionType: { dataSetName, dbField: 'tax_rule_transaction_type' },
    transactionType: { dataSetName, dbField: 'transaction_type' },
    isDefaultTaxRule: { dataSetName, dbField: 'is_default_tax_rule', type: 'boolean' },
    isSystemRule: { dataSetName, dbField: 'is_system_rule', type: 'boolean' },
    count: { dataSetName, dbField: 'count', type: 'integer' },
    exist: { dataSetName, dbField: 'exist', type: 'boolean' },
  }
}

export async function fetchTaxRulesByIds(ids: string[], data = {}) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson<TaxRuleApi>(
    buildGetUrl(`/new_api/accounting/tax-rules/${ids}`, { ...data, includeDeleted: true }),
  )

  return isSuccess && !isJob(result) ? result.map((taxRule) => parseTaxRule(taxRule)) : []
}

export async function fetchTaxRulesByExternalIds(externalIds: string[], data = {}) {
  if (!externalIds?.length) return []
  return _fetchTaxRules({ ...data, externalIds, includeDeleted: true })
}

type FetchOptions = {type?: string, externalIds?: string[], includeDeleted?: boolean}

export async function _fetchTaxRules(options: FetchOptions = {}) {
  const { isSuccess, result } = await safeFetchJson<TaxRuleApi>(
    buildGetUrl('/new_api/accounting/tax-rules', options),
  )
  return isSuccess && !isJob(result) ? result.map((taxRule) => parseTaxRule(taxRule)) : []
}

export function parseTaxRule(taxRule: TaxRuleApi): TaxRule {
  const options = { defaultFata: getDefaultTaxRule(), dataSetName, fields }
  return parse(taxRule, options)
}

function getDefaultTaxRule(): TaxRule {
  return parse({}, { fields })
}

export function getTaxRuleTitle(taxRule: TaxRule) {
  return taxRule.taxRuleName
}

export default taxesRulesSlice.reducer
