import { AppDispatch } from 'store'
import { ApiToSlice, BaseEntityApi, GetFields, Modify } from 'types/slices'

import { buildGetUrl, parse } from 'utils/api'
import { isJob, safeFetch, safeFetchJson } from 'utils/safeFetch'

import { GET_REPORTING_TAGS } from './types'

const dataSetName = 'reportingTag'
const dataSetNameReportingTagOption = 'reportingTagOption'
const fields = getFields()
const reportingTagOptionFields = getReportingTagOptionFields()
const initialState = {
  dataSetName,
  fields,
  reportingTagOptionFields,
  reportingTags: [],
}

export default function reportingTagsReducer(state = initialState, action: any) {
  const { payload } = action
  switch (action.type) {
  case GET_REPORTING_TAGS: {
    return buildReportingTagState(state, payload)
  }
  default: {
    return state
  }
  }
}

export type ReportingTagOptionApi = Modify<{
  name: string
  reporting_tag_id: string
  zoho_books_reporting_tag_option_id: string
}, BaseEntityApi>

export type ReportingTagOption = ApiToSlice<ReportingTagOptionApi>

export type ReportingTagApi = Modify<{
  name: string
  options: ReportingTagOptionApi[]
}, BaseEntityApi>

export type ReportingTag = ApiToSlice<Modify<ReportingTagApi, {options: ReportingTagOption[]}>>

export type ReportingTagAssociationApi = {
  id: string,
  reporting_tag_id: string,
  reporting_tag_name: string,
  reporting_tag_option_id: string,
  reporting_tag_option_name: string,
  zoho_books_reporting_tag_id: string,
  zoho_books_reporting_tag_option_id: string,
}

export function getFields(editOnly?: boolean): GetFields<ReportingTagApi, ReportingTag> {
  const fields: GetFields<ReportingTagApi, ReportingTag> = {
    id: { dataSetName, dbField: 'id', type: 'id' },
    cid: { dataSetName, dbField: 'cid', type: 'string' },
    name: { dataSetName, dbField: 'name', type: 'string' },
    options: {
      dataSetName,
      dbField: 'options',
      type: 'array',
      parse: (reportingTag) =>
        reportingTag.options?.map((reportingTagOptions) => parseReportingTagOption(reportingTagOptions)) ?? [],
    },
    exist: { dataSetName, dbField: 'exist', type: 'boolean' },
    createdDate: { dataSetName, dbField: 'created_date', type: 'date' },
    createdBy: { dataSetName, dbField: 'created_by' },
    createdById: { dataSetName, dbField: 'created_by_id', type: 'id' },
    modifiedDate: { dataSetName, dbField: 'modified_date', type: 'date' },
    modifiedBy: { dataSetName, dbField: 'modified_by' },
    modifiedById: { dataSetName, dbField: 'modified_by_id', type: 'id' },
  }

  let fieldsToReturn = Object.keys(fields)
  if (editOnly) {
    fieldsToReturn = fieldsToReturn.filter((key) => fields[key].isEdit)
    fieldsToReturn.push(...['id', 'name'])
  }

  return fieldsToReturn.reduce((acc, key) => {
    const newAcc = { ...acc }
    newAcc[key] = fields[key]
    return newAcc
  }, {})
}

export function getReportingTagOptionFields(editOnly?: boolean): GetFields<ReportingTagOptionApi, ReportingTagOption> {
  const fields: GetFields<ReportingTagOptionApi, ReportingTagOption> = {
    id: { dataSetName: dataSetNameReportingTagOption, dbField: 'id', type: 'id' },
    cid: { dataSetName: dataSetNameReportingTagOption, dbField: 'cid', type: 'string' },
    name: { dataSetName: dataSetNameReportingTagOption, dbField: 'name', type: 'string' },
    reportingTagId: { dataSetName: dataSetNameReportingTagOption, dbField: 'reporting_tag_id', type: 'string' },
    zohoBooksReportingTagOptionId: {
      dataSetName: dataSetNameReportingTagOption,
      dbField: 'zoho_books_reporting_tag_option_id',
      type: 'string',
    },
    exist: { dataSetName: dataSetNameReportingTagOption, dbField: 'exist', type: 'boolean' },
    createdDate: { dataSetName: dataSetNameReportingTagOption, dbField: 'created_date', type: 'date' },
    createdBy: { dataSetName: dataSetNameReportingTagOption, dbField: 'created_by' },
    createdById: { dataSetName: dataSetNameReportingTagOption, dbField: 'created_by_id', type: 'id' },
    modifiedDate: { dataSetName: dataSetNameReportingTagOption, dbField: 'modified_date', type: 'date' },
    modifiedBy: { dataSetName: dataSetNameReportingTagOption, dbField: 'modified_by' },
    modifiedById: { dataSetName: dataSetNameReportingTagOption, dbField: 'modified_by_id', type: 'id' },
  }

  let fieldsToReturn = Object.keys(fields)
  if (editOnly) {
    fieldsToReturn = fieldsToReturn.filter((key) => fields[key].isEdit)
    fieldsToReturn.push(...['id', 'name'])
  }

  return fieldsToReturn.reduce((acc, key) => {
    const newAcc = { ...acc }
    newAcc[key] = fields[key]
    return newAcc
  }, {})
}

export function getReportingTagTitle(reportingTag) {
  return reportingTag.name
}

export function fetchReportingTags(data: Record<string, any>, mapData?: Record<string, any>) {
  return async function fetchReportingTagsThunk(dispatch: AppDispatch) {
    const reportingTags = await _fetchReportingTags(data, mapData)
    dispatch({ type: GET_REPORTING_TAGS, payload: reportingTags })
    return reportingTags
  }
}

export async function _fetchReportingTags(data: Record<string, any>, mapData?: Record<string, any>) {
  let reportingTags = []

  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/accounting/reporting-tags', data))).json()
    if (result.isSuccess) {
      reportingTags = result.result.map((reportingTag: Record<string, any>) =>
        parseReportingTag(reportingTag, mapData),
      )
    }
  } catch (err) {
    console.error(err)
  }

  return reportingTags
}

export async function fetchReportingTagsByIds(
  ids: any[],
  data?: Record<string, any>,
  mapData?: Record<string, any>,
) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson<Record<string, any>>(
    buildGetUrl(`/new_api/reporting-tags/${ids}`, data),
  )

  return isSuccess && !isJob(result) ?
    result.map((reportingTag) => parseReportingTag(reportingTag, mapData)) :
    []
}

function getDefaultReportingTag() {
  return parse({}, { fields })
}

export function parseReportingTag(reportingTag: Record<string, any>, mapData: Record<string, any> = {}) {
  const options = {
    ...mapData,
    defaultData: getDefaultReportingTag(),
    fields: fields,
    dataSetName,
  }

  return parse(reportingTag, options)
}

function getDefaultReportingTagOption() {
  return parse({}, { fields: initialState.reportingTagOptionFields })
}

export function parseReportingTagOption(
  reportingTagOption: Record<string, any>,
  mapData: Record<string, any> = {},
): any {
  const options = {
    ...mapData,
    defaultData: getDefaultReportingTagOption(),
    fields: initialState.reportingTagOptionFields,
    dataSetName: dataSetNameReportingTagOption,
  }

  return parse(reportingTagOption, options)
}

function buildReportingTagState(state, payload) {
  if (!payload) {
    return state
  }

  return {
    ...state,
    reportingTags: payload,
  }
}

export async function fetchReportingTagOptionsByIds(
  ids: any[],
) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson<Record<string, any>>(
    buildGetUrl(`/new_api/accounting/reporting-tags-options`, {
      id: ids,
    }),
  )

  return isSuccess && !isJob(result) ?
    result.map((reportingTagOption) => parseReportingTag(reportingTagOption)) :
    []
}

export function getReportingTagOptionTitle(reportingTagOption) {
  return reportingTagOption.name
}
