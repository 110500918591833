import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch } from 'store'
import { ApiToSlice, GetFields, Modify } from 'types/slices'

import { parse } from 'utils/api'
import { DevLogs } from 'utils/devLogs'
import { safeFetch } from 'utils/safeFetch'

const dataSetName = 'genCompany'
const fields = getFields()

export const initialState = {
  dataSetName,
  fields,
  preferredCompany: getDefaultCompany(),
  supportedLanguages: [
    { value: 'en', label: 'English' },
    { value: 'fr', label: 'Français' },
  ],
}

const companiesSlice = createSlice({
  name: 'companiesSlice',
  initialState,
  reducers: {
    setPreferredCompany: (state, action) => {
      state.preferredCompany = action.payload.preferred
    },
  },
})

export const { setPreferredCompany } = companiesSlice.actions

export type CompanyApi = {
    id: string;
    exist: boolean;
    created_date: string;
    modified_date: string;
    company_name: string;
    contact_name: string;
    logo_name: string;
    website: string;
    email: string;
    timezone: string;
    language: string;
    secondary_language: string;
    is_large_mp_label: boolean;
    is_large_item_label: boolean;
    fiscal_year: string;
    is_preferred: boolean;
    address_id: string;
    address: string;
    street2: string;
    city: string;
    zip: string;
    state: string;
    country: string;
    phone:string;
    fax: string;
    item_label_id: string;
    raw_mat_label_id: string;
    item_large_label_id: string;
    raw_mat_large_label_id: string;
    countryCode: string;
};

export type Company = ApiToSlice<Modify<CompanyApi, {
  'address': Record<string, any>,
}>>

type CompanyGetFields = GetFields<CompanyApi, Company>;

export function getFields(editOnly = false): CompanyGetFields {
  const editFields: CompanyGetFields = {
    'id': { dataSetName, dbField: 'id', type: 'id' },
    'companyName': { dataSetName, dbField: 'company_name', type: 'string', isEdit: true },
    'logoName': { dataSetName, dbField: 'logo_name', type: 'string', isEdit: true },
    'website': { dataSetName, dbField: 'website', type: 'string', isEdit: true },
    'email': { dataSetName, dbField: 'email', type: 'string', isEdit: true },
    'countryCode': { dataSetName, dbField: 'countryCode', type: 'string', isEdit: true },
    'timezone': { dataSetName, dbField: 'timezone', type: 'string', isEdit: true },
    'language': { dataSetName, dbField: 'language', type: 'language', isEdit: true },
    'secondaryLanguage': { dataSetName, dbField: 'secondary_language', type: 'language', isEdit: true },
    'isLargeMpLabel': { dataSetName, dbField: 'is_large_mp_label', type: 'boolean', isEdit: true },
    'isLargeItemLabel': { dataSetName, dbField: 'is_large_item_label', type: 'boolean', isEdit: true },
    'fiscalYear': { dataSetName, dbField: 'fiscal_year', type: 'string', isEdit: true },
    'isPreferred': { dataSetName, dbField: 'is_preferred', type: 'boolean', isEdit: true },
    'itemLabelId': { dataSetName, dbField: 'item_label_id', type: 'id', isEdit: true },
    'rawMatLabelId': { dataSetName, dbField: 'raw_mat_label_id', type: 'id', isEdit: true },
    'itemLargeLabelId': { dataSetName, dbField: 'item_large_label_id', type: 'id', isEdit: true },
    'rawMatLargeLabelId': { dataSetName, dbField: 'raw_mat_large_label_id', type: 'id', isEdit: true },
    'address': {
      parse: (apiData) => ({
        'id': apiData.address_id,
        'street': apiData.address,
        'street2': apiData.street2,
        'city': apiData.city,
        'zip': apiData.zip,
        'state': apiData.state,
        'country': apiData.country,
        'phone': apiData.phone,
        'fax': apiData.fax,
      }),
    },
  }

  if (editOnly) {
    return editFields
  }

  const fields: CompanyGetFields = {
    exist: { dataSetName, dbField: 'exist', type: 'boolean' },
    createdDate: { dataSetName, dbField: 'created_date', type: 'timestamp' },
    modifiedDate: { dataSetName, dbField: 'modified_date', type: 'timestamp' },
  }

  return { ...editFields, ...fields }
}

export function getCompanyTitle(company:Company) :string {
  return company.companyName
}

// TODO (bzoretic): Implement once the company api is implemented and has a /:ids route
export async function fetchCompaniesByIds(ids: Array<string>, data?:Record<string, any>): Promise<Company[]> {
  DevLogs.error('\'fetchCompaniesByIds\' is not implemented')
  return []
}

export async function fetchCompanies(data = {}) {
  let companies = []

  try {
    const response = await safeFetch('/api/config/enterprise_infos', data)
    const result: CompanyApi[] = await response.json()
    if (result) {
      companies = result.map((company) => parseCompany(company))
    }
  } catch (err) {
    console.error(err)
  }

  return companies
}

export function fetchPreferredCompany() {
  return async function fetchPreferredCompanyThunk(dispatch: AppDispatch) {
    const [preferredCompany] = await fetchCompanies({ isPreferred: true })
    dispatch(setPreferredCompany({ preferred: preferredCompany || getDefaultCompany() }))
  }
}

export function parseCompany(company: CompanyApi): Company {
  const options = {
    defaultData: getDefaultCompany(),
    fields: initialState.fields,
    dataSetName,
  }
  return parse(company, options)
}

export function getDefaultCompany() {
  return parse({}, { fields })
}

export default companiesSlice.reducer
