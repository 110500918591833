import { Dispatch, SetStateAction } from 'react'

export default async function fetchLoad(
  fetch: () => Promise<unknown>,
  setLoading: Dispatch<SetStateAction<boolean>>,
  initLoading = true,
): Promise<unknown> {
  if (initLoading) {
    setLoading(true)
  }

  let isDispatchResolved = false
  let isTimeoutResolved = false

  setTimeout(() => {
    isTimeoutResolved = true

    if (isDispatchResolved) {
      setLoading(false)
    }
  }, 500)

  const data = (await fetch()) || []
  isDispatchResolved = true

  if (isTimeoutResolved) {
    setLoading(false)
  }
  return data
}

export async function delayPromise<T>(promise: Promise<T>): typeof promise {
  const [result] = await Promise.all([
    promise,
    new Promise((resolve) => setTimeout(resolve, 500)),
  ])
  return result
}
